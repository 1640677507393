
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin-bottom: 32px;

  &:not(:first-child) {
    margin-top: 32px;
  }

  @include at-breakpoint(medium) {
    width: 130%;
    inline-start: -15%;
  }
}

.error {
  .description {
    // Brands added for specificity
    :global(.go-solutions) & {
      color: $color-dark-red;
    }

    :global(.now-fresh) & {
      color: $color-dark-red;
    }
  }

  .error-msg {
    display: block;
  }
}

.description {
  margin-bottom: 16px;
  width: 100%;
  text-align: center;
}

.option {
  margin: 5px;
}

.input {
  @include visually-hidden;

  &:checked + .label {
    .picky-eater-approved {
      path {
        fill: $go-orange;
      }
    }

    .improved-health {
      path {
        fill: #d94699;
      }
    }

    .easy-digestion {
      path {
        fill: #fa6464;
      }
    }

    .skin-coat-results {
      path {
        fill: #3c78be;
      }
    }

    .happy-pet-parent {
      path {
        fill: $go-teal;
      }
    }

    .increased-energy {
      path {
        fill: $go-yellow;
      }
    }
  }
}

.label {
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  padding: 6px 10px 6px 6px;
}

.text {
  margin-inline-start: 6px;
}

.error-msg {
  @include errormsg();
}

:global(.go-solutions) {
  .label {
    border-color: rgba($color-white, 0.4);
    color: rgba($color-white, 0.8);

    path {
      fill: $go-gray-midlight;
    }
  }

  .input {
    &:checked + .label {
      border-color: $go-teal;
      color: $color-white;
    }

    &:focus + .label {
      box-shadow: 0px 0px 0px 1px rgba($color-white, 0.4) inset;
    }

    &:focus:checked + .label {
      box-shadow: 0px 0px 0px 1px $go-teal inset;
    }
  }
}

:global(.now-fresh) {
  .label {
    border-color: rgba($now-brown, 0.4);
    color: rgba($now-brown, 0.8);
    letter-spacing: 0.8px;

    path {
      fill: $now-grey;
    }
  }

  .input {
    &:checked + .label {
      border-color: $now-brown;
      color: $now-brown;
    }

    &:focus + .label {
      box-shadow: 0px 0px 0px 1px rgba($now-brown, 0.4) inset;
    }

    &:focus:checked + .label {
      box-shadow: 0px 0px 0px 1px $now-brown inset;
    }
  }
}
