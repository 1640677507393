
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  padding-bottom: 103px;

  @include at-breakpoint(medium) {
    padding-bottom: 42px;
  }

  @include at-breakpoint(large) {
    padding-bottom: 72px;
  }

  @include at-breakpoint(x-large) {
    padding-bottom: 62px;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    border-top: 1px solid $now-brown;
    padding: 32px;
    max-width: none;
    margin: 0;

    @include at-breakpoint(medium) {
      padding: 42px;
    }

    @include at-breakpoint(large) {
      padding: 62px;
    }
  }

  :global(.petcurean) & {
    padding-bottom: 0;
    margin-bottom: 80px;
  }
}

.separator {
  border-top: 6px solid;
  width: 100%;
  grid-column: span 6;
  margin: 0 0 20px;

  @include at-breakpoint(large) {
    grid-column: span 12;
  }

  :global(.go-solutions) & {
    border-color: $color-black;

    @include at-breakpoint(medium) {
      margin: 0 0 47px;
    }
  }

  :global(.now-fresh) & {
    display: none;
  }
}

.headline {
  @include h5();
  grid-column: span 6;
  margin-bottom: 16px;

  @include at-breakpoint(medium) {
    grid-column: span 2;
    margin-bottom: 0;
  }

  &.single-author {
    @include at-breakpoint(large) {
      grid-column: span 3;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 2;
    }
  }

  @include at-breakpoint(x-large) {
    grid-column: span 2;
  }

  :global(.go-solutions) & {
    text-transform: uppercase;
  }

  :global(.now-fresh) & {
    @include h4();
    font-weight: 400;

    @include at-breakpoint(medium) {
      grid-column: span 1;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 2;
    }
  }
}

.authors-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-template-columns: repeat(4, 1fr);
    grid-column: 3/7;
  }

  &.single-author {
    @include at-breakpoint(large) {
      grid-column: 4/13;
    }

    @include at-breakpoint(x-large) {
      grid-column: 3/13;
    }
  }

  @include at-breakpoint(large) {
    grid-template-columns: repeat(10, 1fr);
    grid-column: 3/13;
  }
}

.author-card {
  display: flex;

  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: span 4;
  }

  @include at-breakpoint(large) {
    grid-column: span 5;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 3;

    :global(.now-fresh) & {
      grid-column: span 4;
    }
  }
}

.image {
  height: 64px;
  width: 64px;
  margin: 0 24px auto 0;
}

.name {
  font-size: 14px;
  line-height: 24px;
  margin-top: 15px;

  :global(.go-solutions) & {
    font-weight: 900;
    text-transform: uppercase;
  }

  :global(.now-fresh) & {
    @include h4();
    font-weight: 400;
    font-family: 'Stag', sans-serif !important;
    margin-top: 0;
  }
}

.title {
  @include p3();
  margin-bottom: 23px;

  :global(.go-solutions) & {
    color: $go-light-charcoal;
  }

  :global(.now-fresh) & {
    font-size: 16px;
    font-style: italic;
  }
}

.bio {
  @include p2();

  :global(.go-solutions) & {
    color: $go-light-charcoal;
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }
}

.single-author {
  @include at-breakpoint(large) {
    grid-column: span 6;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 5;
  }
}
