
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.background {
  background-color: $grey;
}

.container {
  grid-row-gap: 24px;
}

.identifier {
  font-size: 18px;
  grid-column: span 1;
  border-top: 1px solid black;
  padding-top: 24px;

  @include at-breakpoint(large) {
    grid-column: span 2;
  }

  &:first-child {
    margin-top: 29px;

    @include at-breakpoint(large) {
      margin-top: 67px;
    }

    :global(.now-fresh) & {
      margin-top: 36px;

      @include at-breakpoint(large) {
        margin-top: 59px;
      }

      @include at-breakpoint(x-large) {
        margin-top: 70px;
      }
    }
  }
}

.showcase {
  grid-column: span 5;
  border-top: 1px solid black;
  padding-top: 24px;

  @include at-breakpoint(large) {
    grid-column: span 10;
  }

  b {
    display: block;
    margin-top: 24px;
  }
}
