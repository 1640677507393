
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.background {
  overflow: hidden;

  :global(.gather) & {
    background-color: $gather-cream;
    color: $gather-black;
  }

  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;

    @include at-breakpoint(large) {
      padding-top: 44px;
    }

    @include at-breakpoint(x-large) {
      padding-top: 18px;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    display: flex;
    padding: 50px 0;

    @include at-breakpoint(medium) {
      padding: 100px 0;
    }
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;
  }
}

.container {
  @include grid-container();
  align-items: center;
  position: relative;
}

.copy-container {
  grid-column: span 6;
  z-index: 2;
  margin-top: 157px;

  :global(.now-fresh) & {
    margin-top: 0;
  }

  :global(.gather) &,
  :global(.summit) & {
    text-align: center;
    margin-top: 65px;
  }

  @include at-breakpoint(medium) {
    grid-column: span 3;
    margin-top: 40px;

    :global(.now-fresh) & {
      grid-column: span 6;
    }

    :global(.gather) &,
    :global(.summit) & {
      margin-top: 105px;
      grid-column: span 6;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 6;

    :global(.gather) &,
    :global(.summit) & {
      grid-column: span 12;
    }
  }
}

.eyebrow {
  @include eyebrow-box();
  margin-bottom: 8px;
}

.headline {
  margin: 0 0 16px;

  @include at-breakpoint(x-large) {
    margin: 0 0 24px;

    :global(.gather) & {
      display: inline-block;
      width: calc(8 / 12 * 100%);
      margin: 0 auto 30px;
    }
  }

  :global(.now-fresh) & {
    @include font-sizes(30px, 48px, 48px);
    @include at-breakpoint(x-large) {
      margin: 0 0 16px;
    }
  }

  :global(.gather) &,
  :global(.summit) & {
    margin: 0 0 30px;
  }
}

.description {
  p {
    @include p2();
    :global(.gather) & {
      @include font-sizes(16px, 16px, 19px);
      @include line-heights(1.75, 1.75, 1.89);
      margin-bottom: 50px;

      @include at-breakpoint(large) {
        margin-bottom: 72px;
      }

      @include at-breakpoint(x-large) {
        display: inline-block;
        width: calc(8 / 12 * 100%);
        margin: 0 auto 80px;
      }
    }

    :global(.go-solutions) & {
      margin-bottom: 40px;
      color: $color-white;
    }

    :global(.now-fresh) & {
      margin-bottom: 32px;
      font-size: 16px;
    }

    :global(.summit) & {
      margin-bottom: 50px;
      color: $summit-white;

      @include at-breakpoint(medium) {
        @include p1();
      }

      @include at-breakpoint(large) {
        margin-bottom: 72px;
      }

      @include at-breakpoint(x-large) {
        margin-bottom: 80px;
      }
    }
  }

  a {
    :global(.gather) &,
    :global(.go-solutions) &,
    :global(.now-fresh) &,
    :global(.petcurean) &,
    :global(.summit) & {
      text-decoration: underline;
    }

    :global(.go-solutions) & {
      color: $color-white;
    }

    :global(.summit) & {
      color: $summit-white;
    }

    svg {
      display: none;
    }
  }
}

.separator {
  align-self: flex-start;
  width: 100%;

  @include at-breakpoint(large) {
    width: 151px;
  }

  :global(.go-solutions) & {
    border-top: 1px solid $go-bullet-separator;
    margin: 0 auto 41px 0;

    :global(.locale-he) & {
      margin: 0 0 41px auto;
    }
  }

  :global(.now-fresh) & {
    border-top: 1px solid $now-brown;
    width: 70%;
    margin: 0 auto 32px 0;

    :global(.locale-he) & {
      margin: 0 0 32px auto;
    }
  }

  :global(.gather) &,
  :global(.summit) & {
    display: none;
  }
}

.bullets {
  @include p3();

  :global(.gather) & {
    @include font-sizes(12px, 12px, 19px);
    @include line-heights(1.75, 1.75, 1.89);
    margin-bottom: 80px;
  }

  :global(.summit) & {
    @include p2();
    margin-bottom: 70px;
  }

  @include at-breakpoint(medium) {
    :global(.summit) & {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 105px;
    }

    :global(.gather) & {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }

  @include at-breakpoint(large) {
    @include p2();

    :global(.summit) & {
      @include p1();
    }
  }
}

.bullet {
  display: flex;
  margin-bottom: 24px;

  &:last-child {
    :global(.go-solutions) & {
      margin-bottom: 40px;
    }

    :global(.go-solutions) & {
      margin-bottom: 58px;
    }
  }

  :global(.gather) &,
  :global(.summit) & {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @include at-breakpoint(medium) {
      flex-direction: column;
      align-items: center;
      max-width: 200px;
    }

    @include at-breakpoint(large) {
      max-width: 275px;
    }
  }
}

.bullet-image {
  display: flex;
  width: 27px;
  flex-shrink: 0;

  svg {
    width: 27px;
  }

  :global(.gather) & {
    width: 55px;
    margin-inline-end: 16px;

    svg {
      width: 55px;

      path {
        fill: $gather-green;
      }
    }

    @include at-breakpoint(medium) {
      margin-inline-end: 0;
      width: 100px;
      height: 100px;
      margin-bottom: 25px;

      svg {
        width: 100px;
        max-height: 100%;
      }
    }
  }

  :global(.go-solutions) & {
    margin-inline-end: 28px;
    align-self: flex-start;

    svg {
      path {
        fill: $color-white;
      }
    }

    @include at-breakpoint(large) {
      margin-inline-end: 36px;
    }
  }

  :global(.now-fresh) & {
    margin-inline-end: 16px;
    align-items: center;

    svg {
      path {
        fill: $now-brown;
      }
    }

    @include at-breakpoint(large) {
      margin-inline-end: 18px;
    }
  }

  :global(.summit) & {
    width: 75px;
    margin-inline-end: 16px;

    svg {
      width: 75px;

      path {
        fill: $summit-white;
      }
    }

    @include at-breakpoint(medium) {
      margin-inline-end: 0;
      width: 100px;
      height: 100px;
      margin-bottom: 25px;

      svg {
        width: 100px;
        max-height: 100%;
      }
    }
  }
}

.bullet-text {
  display: flex;
  align-items: center;

  :global(.now-fresh) & {
    font-size: 16px;
  }

  :global(.gather) &,
  :global(.summit) & {
    text-align: left;

    @include at-breakpoint(medium) {
      text-align: center;
    }
  }
}

.img-container {
  position: absolute;
  height: 426px;
  top: 0;
  right: -50px;

  :global(.locale-he) & {
    right: auto;
    left: -50px;
  }

  :global(.go-solutions) & {
    width: 247px;

    img {
      width: 100%;
    }
  }

  :global(.now-fresh) & {
    display: none;
  }

  :global(.gather) &,
  :global(.summit) & {
    display: none;
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;

    :global(.go-solutions) & {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 100%);
    }

    :global(.now-fresh) & {
      background: linear-gradient(
        to bottom,
        rgba(246, 243, 223, 0) 30%,
        rgba(246, 243, 223, 1) 100%
      );
    }
  }

  @include at-breakpoint(medium) {
    height: auto;
    position: relative;
    grid-column: 4/7;
    align-self: flex-start;

    :global(.go-solutions) & {
      width: auto;
    }

    :global(.now-fresh) & {
      width: auto;
      min-width: 505px;
      min-height: 514px;
      right: -10vw;

      :global(.locale-he) & {
        right: auto;
        left: -10vw;
      }

      img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;

        :global(.locale-he) & {
          left: auto;
          right: 0;
        }
      }
    }
  }

  @include at-breakpoint(large) {
    grid-column: 8/12;

    :global(.now-fresh) & {
      grid-column: 7/13;
      right: -15vw;

      :global(.locale-he) & {
        right: auto;
        left: -15vw;
      }

      img {
        position: static;
      }
    }
  }

  @include at-breakpoint(x-large) {
    grid-column: 9/13;

    :global(.now-fresh) & {
      right: -10.5vw;

      :global(.locale-he) & {
        right: auto;
        left: -10.5vw;
      }
    }
  }
}
