
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.category {
  @include grid-container();
  position: relative;
  margin-top: 40px;

  @include at-breakpoint(large) {
    margin-top: 80px;
  }

  @include at-breakpoint(x-large) {
    margin-top: 138px;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &.anim-in {
    .category-image {
      @include animateIn();
    }

    .category-content {
      @include animateIn(0.4s);
    }
  }

  &.align-right {
    .category-image {
      @include at-breakpoint(medium) {
        grid-column: 4 / span 3;
        left: 32px;

        :global(.locale-he) & {
          left: auto;
          right: 32px;
        }
      }

      @include at-breakpoint(large) {
        grid-column: 9 / span 4;
        left: 0;

        :global(.locale-he) & {
          right: 0;
        }
      }
    }

    .category-content {
      @include at-breakpoint(medium) {
        grid-row: 1;
      }

      @include at-breakpoint(large) {
        grid-column: span 8;
        padding-inline-start: 0;
      }

      @include at-breakpoint(x-large) {
        padding-inline-end: 71px;
      }

      :global(.now-fresh) & {
        @include at-breakpoint(large) {
          padding-inline-end: 0;
        }
      }
    }
  }

  :global(.now-fresh) & {
    border: 1px solid $now-brown;
    margin-bottom: -1px;
    margin-top: 0;
    padding: 0;
    max-width: none;
    width: auto;
    column-gap: 0;

    @include at-breakpoint(medium) {
      border: none;
      outline: 1px solid $now-brown;
      margin-bottom: 1px;
    }

    &.align-right {
      .category-image {
        left: 0;
        right: 0;

        @include at-breakpoint(medium) {
          grid-column: span 6;
          grid-row: 1;
        }

        @include at-breakpoint(large) {
          grid-column: 7 / span 6;
          border-left: 1px solid $now-brown;
          border-right: none;

          :global(.locale-he) & {
            border-left: none;
            border-right: 1px solid $now-brown;
          }
        }
      }

      .category-content {
        @include at-breakpoint(medium) {
          grid-row: 2;
        }
        @include at-breakpoint(large) {
          grid-row: 1;
          grid-column: span 6;
          padding-inline-end: 0;
          padding: 80px;
        }
      }
    }
  }
}

.category-image {
  @include willAnimateIn();
  position: relative;
  grid-column: span 5;
  left: -16px;

  :global(.locale-he) & {
    left: auto;
    right: -16px;
  }

  @include at-breakpoint(medium) {
    grid-column: span 3;
    left: -32px;

    :global(.locale-he) & {
      left: auto;
      right: -32px;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 4;
    left: 0;

    :global(.locale-he) & {
      left: auto;
      right: 0;
    }
  }

  img {
    display: block;
    width: 100%;
  }

  :global(.now-fresh) & {
    @include willAnimateInNowFresh();
    grid-column: span 6;
    border-bottom: 1px solid $now-brown;
    left: 0;
    right: 0;

    @include at-breakpoint(large) {
      border-bottom: none;
      border-right: 1px solid $now-brown;

      :global(.locale-he) & {
        border-right: none;
        border-left: 1px solid $now-brown;
      }
    }

    img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.category-content {
  @include willAnimateIn();
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: span 3;
  }

  @include at-breakpoint(large) {
    grid-column: span 8 / -1;
    padding-inline-start: 48px;
  }

  @include at-breakpoint(x-large) {
    padding-inline-start: 71px;
  }

  :global(.now-fresh) & {
    @include willAnimateInNowFresh();
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: span 6;
    padding: 60px 20px;

    @include at-breakpoint(medium) {
      padding: 80px;
    }

    @include at-breakpoint(large) {
      grid-column: span 6 / -1;
      padding-inline-start: 0;
      padding: 80px;
    }
  }
}

.category-eyebrow {
  @include eyebrow-box();
  margin-top: 40px;

  @include at-breakpoint(medium) {
    margin-top: 0;
  }

  :global(.go-solutions) & {
    @include at-breakpoint(large) {
      font-size: 20px;
      line-height: 1.2;
    }
  }

  :global(.petcurean) & {
    color: $petcurean-grey;

    @include at-breakpoint(large) {
      font-size: 20px;
      line-height: 1.2;
    }
  }

  :global(.now-fresh) & {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.0769;
    margin-top: 0;

    @include at-breakpoint(medium) {
      line-height: 1.1429;
      letter-spacing: 1px;
    }
  }
}

.product-list {
  margin-top: 15px;

  @include at-breakpoint(medium) {
    margin-top: 30px;
  }

  @include at-breakpoint(large) {
    margin-top: 40px;
  }

  @include at-breakpoint(x-large) {
    margin-top: 30px;
  }

  :global(.now-fresh) & {
    margin-top: 28px;

    @include at-breakpoint(medium) {
      margin-top: 36px;
    }

    @include at-breakpoint(large) {
      margin-top: 0;
    }
  }
}

.product {
  border-bottom: 1px solid $color-white;

  &:last-of-type {
    border-bottom: none;
  }

  :global(.now-fresh) & {
    border-color: $now-brown;
  }

  :global(.petcurean) & {
    border-color: $petcurean-grey;
  }
}

.product-link {
  position: relative;
  display: block;
  padding: 20px 40px 20px 0;

  :global(.locale-he) & {
    padding: 20px 0 20px 40px;
  }

  @include at-breakpoint(large) {
    padding: 24px 90px 24px 0;

    :global(.locale-he) & {
      padding: 24px 0px 24px 90px;
    }
  }

  @include at-breakpoint(x-large) {
    padding: 34px 90px 34px 0;

    :global(.locale-he) & {
      padding: 34px 0 34px 90px;
    }
  }

  & > span {
    display: block;
    margin: 0;
  }

  svg {
    position: absolute;
    right: 5px;
    top: 50%;
    transition: right 0.3s ease-out;

    :global(.locale-he) & {
      right: auto;
      left: 5px;
      transform: rotate(180deg);
      transition: left 0.3s ease-out;
    }

    @include at-breakpoint(large) {
      transform: scale(1.3);
      right: 40px;

      :global(.locale-he) & {
        transform: scale(1.3) rotate(180deg);
        right: auto;
        left: 40px;
      }
    }
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      svg {
        right: 0;

        :global(.locale-he) & {
          right: auto;
          left: 0;
        }
      }
    }
  }

  :global(.go-solutions) & {
    color: $color-white;

    &:hover {
      color: $color-white;
    }

    svg {
      path {
        fill: $go-yellow;
      }
    }
  }

  :global(.petcurean) & {
    color: $petcurean-grey;

    &:hover {
      color: $petcurean-orange;
    }

    svg {
      path {
        fill: $petcurean-orange;
      }
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    padding: 12px 40px 12px 0;

    :global(.locale-he) & {
      padding: 12px 0 12px 40px;
    }

    @include at-breakpoint(medium) {
      padding: 20px 90px 20px 0;

      :global(.locale-he) & {
        padding: 20px 0 20px 90px;
      }
    }

    @include at-breakpoint(large) {
      padding: 34px 84px 34px 0;

      :global(.locale-he) & {
        padding: 34px 0 34px 84px;
      }
    }

    svg {
      margin-top: 5px;
      path {
        fill: $now-brown;
      }

      @include at-breakpoint(medium) {
        right: 40px;
        transform: scale(1.3);

        :global(.locale-he) & {
          right: auto;
          left: 40px;
          transform: scale(1.3) rotate(180deg);
        }
      }
    }

    @media (hover: hover) {
      &:hover,
      &:focus {
        color: $now-brown;

        svg {
          right: 0;

          :global(.locale-he) & {
            right: auto;
            left: 0;
          }

          path {
            fill: $now-brown;
          }
        }
      }
    }
  }
}

.product-title {
  @include h3();
  text-transform: uppercase;
  display: block;

  :global(.locale-ru) & {
    line-height: 1.25;
  }

  @include at-breakpoint(x-large) {
    font-size: 67px;
    line-height: 0.9552;
  }

  :global(.go-solutions) & {
    @include font-sizes(24px, 28px, 28px, 45px);
  }

  :global(.now-fresh) & {
    @include font-sizes(26px, 35px, 35px);
    @include line-heights(28px, 64px, 64px);
    text-transform: none;
    font-style: italic;

    :global(.locale-ru) & {
      line-height: 1.25;
    }
  }
}

.product-desc {
  @include p2();
  margin-top: 8px;
  display: block;

  @include at-breakpoint(medium) {
    margin-top: 0;
  }

  @include at-breakpoint(large) {
    margin-top: 1px;
  }

  @include at-breakpoint(x-large) {
    margin-top: 8px;
  }
}
