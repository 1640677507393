
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
// benefit widget styling
.benefits {
  display: flex;
  padding: 32px 12px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;

  @include at-breakpoint(medium) {
    display: block;
    width: calc(50% - 8px);
    margin-bottom: 15px;
    padding: 32px 24px;
  }

  @include at-breakpoint(large) {
    margin-bottom: 20px;
    padding: 32px 56px;
  }

  @include at-breakpoint(x-large) {
    width: calc(25% - 12px);
    padding: 56px 40px;
  }

  :global(.gather) & {
    background-color: $gather-green;
    color: $gather-white;
  }

  :global(.now-fresh) & {
    margin-bottom: 0;
    padding: 32px;
    border-bottom: 1px solid $now-brown;
    border-radius: 0;

    @include at-breakpoint(medium) {
      width: auto;
      grid-column: span 3;
      border-right: 1px solid $now-brown;
      padding: 40px;
    }

    @include at-breakpoint(large) {
      grid-column: span 6;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    color: $petcurean-white;
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;
  }

  .benefits-icon {
    width: 50px;
    height: 50px;
    margin-inline-end: 16px;
    display: flex;
    justify-content: center;

    @include at-breakpoint(medium) {
      margin-bottom: 16px;
    }

    svg * {
      :global(.gather) & {
        fill: $gather-white;
      }
      :global(.now-fresh) & {
        fill: $now-brown;
      }
      :global(.petcurean) & {
        fill: $summit-white;
      }
      :global(.summit) & {
        fill: $summit-white;
      }
    }
  }

  .text-container {
    width: 85%;

    :global(.summit) & {
      width: 100%;
    }

    @include at-breakpoint(medium) {
      width: auto;
    }
  }

  .benefits-eyebrow {
    font-size: 14px;
    line-height: 115%;
    font-weight: 600;
    margin-bottom: 8px;

    @include at-breakpoint(medium) {
      margin-bottom: 16px;
    }

    :global(.now-fresh) & {
      font-weight: 500;
      font-size: 16px;
      font-family: Stag, sans-serif;
    }

    :global(.gather) & {
      font-weight: 700;
    }

    :global(.summit) & {
      @include eyebrow();
      margin-bottom: 16px;
    }

    :global(.petcurean) & {
      @include eyebrow();
    }
  }

  .benefits-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 119%;

    :global(.now-fresh) & {
      font-weight: 300;
      font-family: Stag, sans-serif;
    }

    :global(.gather) & {
      font-weight: 400;
      @include line-heights(175%, 150%, 175%, 150%);
    }

    :global(.summit) & {
      @include p2();
    }

    :global(.petcurean) & {
      @include p2();
      @include font-sizes(18px, 21px, 21px);
    }
  }
}

// NF Grid Border Logic for benefits
:global(.now-fresh) {
  .benefits:nth-last-child(1) {
    border-bottom: none;
  }

  @include at-breakpoint(medium) {
    .benefits:nth-child(even) {
      border-right: none;
    }

    .benefits:last-child:nth-child(odd) {
      border-bottom: none;
    }

    .benefits:nth-last-child(2):nth-child(odd) {
      border-bottom: none;
    }

    .benefits:nth-last-child(1):nth-child(even) {
      border-bottom: none;
    }
  }
}

// main component
.container {
  grid-auto-flow: dense;
  padding-bottom: 80px;

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
    color: $now-brown;
    padding-bottom: 0;
  }

  .top {
    @include flex-container();
    flex-direction: column;

    :global(.now-fresh) & {
      border-bottom: 1px solid $now-brown;
      margin: 0;
      max-width: none;
      width: auto;
      padding: 0;
    }

    @include at-breakpoint(medium) {
      @include grid-container();

      :global(.now-fresh) & {
        grid-column-gap: 0;
      }
    }
  }

  .image {
    :global(.now-fresh) & {
      border-bottom: 1px solid $now-brown;
    }

    @include at-breakpoint(medium) {
      grid-column: 1 / span 3;
      :global(.now-fresh) & {
        border-right: 1px solid $now-brown;
        border-bottom: none;
      }
    }

    @include at-breakpoint(large) {
      grid-column: 1 / span 6;

      :global(.summit) & {
        grid-column: span 5;
      }
    }

    @include at-breakpoint(x-large) {
      :global(.summit) & {
        grid-column: span 6;
      }
    }

    > img {
      object-fit: contain;
      width: 100%;
      height: 100%;

      :global(.now-fresh) & {
        object-fit: cover;
      }
    }
  }

  .info {
    grid-column: 8 / span 6;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :global(.now-fresh) & {
      padding: 56px;
    }

    @include at-breakpoint(small) {
      padding: 0;
    }

    @include at-breakpoint(medium) {
      grid-column: 4 / span 3;
    }

    @include at-breakpoint(large) {
      grid-column: 8 / span 6;

      :global(.now-fresh) & {
        grid-column: 7 / span 6;
      }

      :global(.summit) & {
        grid-column: span 7;
      }
    }

    @include at-breakpoint(x-large) {
      :global(.summit) & {
        grid-column: span 6;
      }
    }

    & * {
      margin: 10px 0;
    }
  }

  .eyebrow {
    font-size: 14px;
    line-height: 114%;
    @include eyebrow-box();

    :global(.gather) & {
      font-weight: 700;
      color: $gather-green;
    }

    :global(.petcurean) & {
      font-weight: 600;
      text-transform: uppercase;
    }

    :global(.summit) & {
      @include eyebrow();
      margin-bottom: 8px;
    }
  }

  .header {
    @include font-sizes(28px, 28px, 45px, 45px);

    :global(.gather) & {
      @include line-heights(132%, 132%, 113%, 113%);
      color: $gather-black;
    }

    :global(.petcurean) & {
      @include line-heights(114%, 114%, 111%, 111%);
      font-weight: 600;
      color: $petcurean-orange;
    }

    :global(.summit) & {
      @include h3();
      color: $summit-blue;
      margin-bottom: 16px;
    }
  }

  .description {
    :global(.gather) & {
      @include font-sizes(16px, 16px, 19px, 19px);
      @include line-heights(175%, 175%, 189%, 189%);
      color: $petcurean-grey;
      font-weight: 300;
    }

    :global(.petcurean) & {
      @include font-sizes(16px, 16px, 21px, 21px);
      @include line-heights(162%, 162%, 176%, 176%);
      font-weight: 300;
    }

    :global(.summit) & {
      @include p2();

      @include at-breakpoint(x-large) {
        @include p1();
      }
    }
  }

  .bottom {
    @include flex-container();
    margin-top: 44px;
    flex-wrap: wrap;
    justify-content: space-between;
    :global(.now-fresh) & {
      margin: 0;
      max-width: none;
      width: auto;
    }

    @include at-breakpoint(medium) {
      :global(.now-fresh) & {
        @include grid-container();
        padding: 0 !important;
        margin: 0;
        max-width: none !important;
        width: auto;
        grid-column-gap: 0 !important;
      }
    }

    :global(.summit) & {
      margin-top: 32px;

      @include at-breakpoint(medium) {
        margin-top: 50px;
      }

      @include at-breakpoint(large) {
        margin-top: 56px;
      }

      @include at-breakpoint(x-large) {
        margin-top: 90px;
      }
    }
  }
}
