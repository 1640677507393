
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  width: 100%;
  height: auto;
  background-color: $color-white;

  :global(.gather) & {
    background-color: $gather-cream;
  }

  :global(.summit) & {
    background-color: $summit-white;
  }

  &.selected {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    @include at-breakpoint(large) {
      position: relative;
    }
  }

  @include at-breakpoint(large) {
    height: 610px;
    overflow: auto;
    position: static;
    border-top: 1px solid;

    :global(.go-solutions) & {
      border-color: $go-light-opaque-black;
    }

    :global(.now-fresh) & {
      border-color: $now-grey;
    }
  }

  &.grid {
    @include at-breakpoint(large) {
      height: auto;

      .load-more {
        padding: 42px 0;
      }
    }
  }

  &.top-border {
    // Done with before so it only shows when tab is visible
    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      border-top: 1px solid $go-gray;

      :global(.now-fresh) & {
        border-color: rgba($now-brown, 0.2);
      }
    }

    @include at-breakpoint(large) {
      &:before {
        display: none;
      }
    }
  }
}

.store-list {
  &.grid {
    @include at-breakpoint(large) {
      @include grid-container;

      height: auto;
    }

    & .card {
      display: flex;
      grid-column: span 6;

      @include at-breakpoint(large) {
        padding-inline-end: 6px;
      }

      @include at-breakpoint(x-large) {
        grid-column-start: 7;
        grid-column-end: 12;
        padding-inline-start: 32px;
        padding-inline-end: 32px;

        &:nth-child(2n + 1) {
          grid-column-start: 2;
          grid-column-end: 7;
        }
      }
    }
  }
}

.load-more {
  display: none;
  padding: 24px 0;
  justify-content: center;
  align-items: center;

  &.visible-mobile {
    display: flex;
  }

  @include at-breakpoint(large) {
    display: flex;
  }
}
