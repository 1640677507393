
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  word-break: break-word;
  padding: 30px 30px 15px 30px;

  :global(.gather) & {
    padding: 32px 16px;

    @include at-breakpoint(medium) {
      padding: 32px;
    }
  }

  :global(.now-fresh) & {
    padding: 0;
  }

  :global(.summit) & {
    padding: 24px 16px;

    @include at-breakpoint(medium) {
      padding: 32px 24px;
    }

    @include at-breakpoint(large) {
      padding: 32px;
    }
  }

  @include at-breakpoint(medium) {
    flex-direction: row;
  }
}

.reviewer-container {
  margin: 0 0 24px;

  :global(.now-fresh) & {
    margin: 0;

    @include at-breakpoint(medium) {
      padding: 40px 20px;
      border-right: 1px solid $now-brown;

      :global(.locale-he) & {
        border-right: none;
        border-left: 1px solid $now-brown;
      }
    }
  }

  :global(.summit) & {
    margin-bottom: 16px;
  }

  @include at-breakpoint(medium) {
    width: 215px;
    flex-shrink: 0;
    margin: 0 50px 0 0;

    :global(.summit) & {
      margin-bottom: 48px;
    }

    :global(.locale-he) & {
      margin: 0 0 0 50px;
    }
  }

  .pet-bio {
    display: none;

    @include at-breakpoint(medium) {
      display: block;
    }

    :global(.now-fresh) & {
      color: $now-brown;
    }
  }
}

.reviewer {
  @include p2();
  @include bold();
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;

  @include at-breakpoint(medium) {
    display: block;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-size: 16px;
  }

  :global(.summit) & {
    font-weight: 700;
    font-size: 16px;
  }

  .date {
    display: inline-block;
    margin-inline-start: auto;
    flex-shrink: 0;
    padding-inline-start: 15px;

    :global(.gather) & {
      display: none;
      font-weight: 900;
    }

    :global(.summit) & {
      font-weight: 900;
      font-size: 12px;
      color: $summit-black;
    }

    @include at-breakpoint(medium) {
      display: none;
    }
  }
}

.reviewer-location {
  font-size: 16px;
  text-transform: capitalize;

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.summit) & {
    font-size: 16px;
  }
}

.feeding-since {
  font-size: 12px;

  :global(.gather) & {
    font-size: 12px;
    color: $gather-dark-grey;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.summit) & {
    font-size: 14px;
  }
}

.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  :global(.summit) & {
    margin-bottom: 16px;
  }
}

.rating {
  height: 22px;

  span {
    width: 22px;
    margin-inline-end: 4px;
  }

  :global(.gather) &,
  :global(.summit) & {
    height: 15px;

    span {
      width: 15px;
    }

    svg path {
      fill: $go-yellow;
    }
  }

  :global(.now-fresh) & {
    svg {
      path {
        fill: $now-brown;
      }
    }
  }
}

.date {
  @include p3();
  display: none;
  color: $go-dark-gray;

  :global(.gather) & {
    display: inline-block;
    font-weight: 900;
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }

  :global(.summit) & {
    color: $summit-black;
    font-weight: 900;
    font-size: 12px;
  }

  @include at-breakpoint(medium) {
    display: inline-block;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }
}

.expandable {
  button {
    margin: 10px auto 10px 0;

    :global(.locale-he) & {
      margin: 10px 0 10px auto;
    }

    :global(.now-fresh) & {
      color: $now-brown;
      border-color: $now-light-tan;

      p {
        font-size: 16px;
        line-height: 1.5em;
        font-weight: normal;
      }
    }

    :global(.go-solutions) & {
      p {
        @include p2();
        text-transform: none;
        font-weight: normal;
      }
    }
  }

  svg {
    display: none;
  }
}

.review-title {
  @include h5();
  margin-bottom: 16px;

  :global(.gather) & {
    @include h5(true);
    font-weight: 400;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.summit) & {
    font-size: 20px;
    line-height: 24px;
  }
}

.review-body {
  @include p2();
  white-space: pre-line;
  margin-bottom: 26px;

  :global(.dots) {
    display: none;
  }

  :global(.expanded) {
    visibility: visible;
  }

  :global(.collapsed) & {
    :global(.dots) {
      display: inline;
    }

    :global(.expanded) {
      visibility: hidden;
    }
  }

  @include at-breakpoint(medium) {
    :global(.dots) {
      display: none;
    }

    :global(.expanded) {
      visibility: visible;
    }
  }

  :global(.gather) & {
    @include p3(true);
    font-weight: 300;

    @include at-breakpoint(medium) {
      @include p2(true);
      font-weight: 300;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-size: 16px;
  }

  :global(.summit) & {
    @include p3();

    @include at-breakpoint(medium) {
      @include p2();
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.pet-bio {
  margin-top: 30px;

  :global(.now-fresh) & {
    color: $now-brown;
  }

  @include at-breakpoint(medium) {
    display: none;
    margin-top: 50px;
  }

  .headline {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    svg {
      max-width: 26px;
      height: auto;
      margin-inline-end: 8px;
      width: 100%;

      :global(.now-fresh) & {
        path {
          fill: $now-brown;
        }
      }
    }

    h4 {
      font-size: 16px;
      text-transform: uppercase;

      :global(.gather) &,
      :global(.now-fresh) &,
      :global(.summit) & {
        display: none;
      }
    }
  }

  p {
    font-size: 14px;

    :global(.now-fresh) &,
    :global(.summit) & {
      font-size: 16px;
    }
  }

  span {
    font-weight: 700;
  }
}

.review-container {
  overflow: hidden;
  flex-grow: 1;

  :global(.now-fresh) & {
    padding: 0;

    @include at-breakpoint(medium) {
      padding: 40px;
    }
  }
}

.recommended-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  :global(.now-fresh) & {
    color: $now-brown;
  }

  @include at-breakpoint(medium) {
    margin-top: 20px;
  }

  p {
    font-size: 14px;
    font-weight: 700;

    :global(.gather) &,
    :global(.summit) & {
      font-size: 16px;
    }
  }

  svg {
    max-width: 23px;
    height: auto;
    margin-inline-end: 8px;
    flex-shrink: 0;

    :global(.now-fresh) & {
      path {
        fill: $now-grey;
      }
    }
  }
}

.helpful-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  flex-wrap: wrap;

  @include at-breakpoint(medium) {
    margin-top: 10px;
    flex-wrap: nowrap;
  }

  & > p {
    font-size: 14px;
    margin-inline-end: 15px;
    word-break: keep-all;
    flex-shrink: 0;

    :global(.gather) & {
      color: rgba($gather-black, 0.35);
    }

    :global(.go-solutions) & {
      color: $go-dark-gray;
    }

    :global(.now-fresh) & {
      color: $now-brown;
    }

    :global(.summit) & {
      color: rgba($summit-black, 0.35);
    }
  }

  .button-wrapper {
    display: flex;
    width: 100%;
    margin-top: 10px;

    :global(.gather) &,
    :global(.summit) & {
      margin-top: 0;
      width: auto;
    }

    @include at-breakpoint(medium) {
      margin-top: 0;
      width: auto;
    }
  }

  .helpful-positive {
    margin-inline-end: 15px;
    padding: 7px 5px;

    :global(.go-solutions) & {
      padding: 7px 5px 7px 7px;

      :global(.locale-he) & {
        padding: 7px 7px 7px 5px;
      }
    }

    :global(.gather) & {
      svg path {
        stroke: $gather-cream;
      }
    }

    :global(.now-fresh) & {
      margin-inline-end: 10px;

      svg path {
        stroke: $now-tan;
      }
    }

    :global(.summit) & {
      svg path {
        stroke: $summit-white;
      }
    }
  }

  .helpful-negative {
    padding: 5px 5px 5px 7px;

    :global(.locale-he) & {
      padding: 5px 7px 5px 5px;
    }

    :global(.gather) & {
      svg path {
        stroke: $gather-green;
      }
    }

    :global(.summit) & {
      svg path {
        stroke: $summit-blue;
      }
    }
  }

  .helpful-positive,
  .helpful-negative {
    min-width: 50px;
    flex-shrink: 0;

    :global(.go-solutions) & {
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);

      p {
        margin-inline-end: 10px;
      }

      &:after {
        bottom: -25px;
      }
    }

    :global(.go-solutions) & {
      svg {
        margin: 0;
      }
    }

    :global(.summit) & {
      padding: 3px 11px;
      margin-inline-end: 8px;

      p {
        @include p3();
        @include summit-font-i18n(false, false);
        text-transform: none;
        font-weight: 700;
      }
    }

    &.not-supported {
      background: transparent;
      border: none;
      padding: 0;
      opacity: 1;

      svg {
        display: none;
      }

      :global(.go-solutions) & {
        font-weight: 400;
        font-size: 14px;
        color: $go-dark-grey;
        letter-spacing: 0;
        text-transform: none;
      }

      :global(.now-fresh) & {
        font-weight: 300;
        color: $now-grey;
      }
    }
  }
}

.response-container {
  padding: 30px 30px 25px 30px;
  border-top: 1px solid $color-black;

  :global(.gather) & {
    border-color: $gather-dark-green;
  }

  :global(.now-fresh) & {
    border-top: 1px solid $now-brown;
  }

  :global(.summit) & {
    border-color: $summit-light-blue;
  }

  @include at-breakpoint(medium) {
    padding: 24px;
  }
}

.response-header {
  font-size: 14px;
  margin-bottom: 10px;
  color: $go-dark-grey;

  :global(.gather) & {
    color: $gather-black;
    @include h5(true);
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.summit) & {
    color: $summit-black;
  }
}

.response-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    margin: 0 10px;
    height: 28px;
    height: 28px;
  }

  .response-body-wrapper {
    margin-top: 6px;
  }
}

.response-title {
  @include eyebrow();
  margin: 0;

  :global(.now-fresh) & {
    color: $now-brown;
  }
}

.response-body {
  @include p2();
  word-break: break-word;

  :global(.gather) & {
    @include p3(true);

    @include at-breakpoint(medium) {
      @include p2(true);
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }
}

.translation-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.google-attribution {
  display: inline-block;
  margin-top: 15px;

  img {
    height: auto;
    margin: 0;
  }
}

.translate-btn {
  @include p2();
  margin-top: 15px;
  text-decoration: underline;

  :global(.go-solutions) & {
    font-weight: 400;

    &:hover {
      color: $go-yellow;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }
}

.incentivized-review {
  margin-top: 10px;
  font-size: 12px;
}
