
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  :global(.now-fresh) & {
    color: $now-brown;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .input {
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin-inline-end: 12px;
    appearance: none;
    display: inline-table;
    border: none;
    background-color: transparent;

    :global(.tabbing) &:focus {
      &:after {
        box-shadow: 0 0 2px 2px $outline;
      }
    }

    @media (hover: hover) {
      &:hover {
        &:after {
          border: none;

          :global(.gather) & {
            background-color: $gather-green;
          }

          :global(.go-solutions) & {
            background-color: $go-teal;
          }

          :global(.now-fresh) & {
            background-color: $now-grey;
            border: 1px solid $now-brown;
          }

          :global(.summit) & {
            background-color: $summit-blue;
          }
        }
      }
    }

    &:checked {
      &:after {
        border: none;

        :global(.gather) & {
          background-color: $gather-green;
        }

        :global(.go-solutions) & {
          background-color: $go-teal;
        }

        :global(.now-fresh) & {
          background-color: $now-grey;
          border: 1px solid $now-brown;
        }

        :global(.petcurean) & {
          background-color: $petcurean-orange;
        }

        :global(.summit) & {
          background-color: $summit-blue;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 1;
        width: 15px;
        height: 8px;
        border-left: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        transform: rotate(-45deg);

        :global(.now-fresh) & {
          border-color: $now-brown;
        }

        :global(.locale-he) & {
          left: auto;
          right: 5px;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border: 1px solid $color-black;
      border-radius: 4px;

      :global(.locale-he) & {
        left: auto;
        right: 0;
      }

      :global(.gather) & {
        border: 0;
        background-color: rgba($gather-black, 0.05);
      }

      :global(.summit) & {
        border: 0;
        background-color: rgba($summit-black, 0.07);
      }
    }
  }
}

.disabled {
  pointer-events: none;
  color: $go-light-grey;

  .input:after {
    border: 1px solid $go-light-grey;
  }

  :global(.now-fresh) & {
    color: $now-grey;
  }
}
