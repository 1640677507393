
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.link {
  display: inline-flex;
  align-items: center;
  position: relative;
  letter-spacing: 1px;
  padding: 2px;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;

    :global(.locale-he) & {
      left: auto;
      right: 0;
    }
  }

  svg {
    margin-inline-start: 8px;
  }

  &:focus {
    outline: none;

    & > p {
      box-shadow: 0 0 2px 2px $outline;
    }
  }
}

.p3 {
  &:after {
    bottom: -2px;
  }

  svg {
    transform: scale(0.8);
  }
}

.p2 {
  &:after {
    bottom: -4px;
  }
}

.p1 {
  &:after {
    bottom: -4px;
  }
}

.eyebrow {
  @include eyebrow();
  &:after {
    bottom: -4px;
  }
}

:global(.gather) {
  .link {
    &:hover {
      text-decoration: none;

      &:after {
        background-position: 0%;
        transition: background-position 0.2s ease-out;
      }
    }

    &:after {
      background-image: $gather-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    p {
      font-weight: 700;
    }
  }

  .dark {
    color: $gather-black;

    &:after {
      background-image: $gather-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    svg path {
      fill: $gather-black;
    }

    &:hover {
      color: $gather-black;

      svg path {
        fill: $gather-black;
      }
    }
  }

  .light {
    color: $gather-white;

    &:after {
      background-image: $gather-light-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    svg path {
      fill: $gather-white;
    }
  }
}
/* Gather Styles End */

/* Go Solutions Styles */
:global(.go-solutions) {
  .link {
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
    }

    &:after {
      background-color: $go-yellow;
    }
  }

  .light {
    color: $color-black;

    &:hover {
      color: $color-black;

      &:after {
        background-color: $color-black;
      }

      svg path {
        fill: $color-black;
      }
    }

    svg path {
      fill: $color-black;
    }
  }

  .dark {
    color: $color-white;

    &:hover {
      color: $color-white;

      &:after {
        background-color: $color-white;
      }

      svg path {
        fill: $color-white;
      }
    }

    svg path {
      fill: $color-white;
    }
  }

  .styled-content {
    @include bold;
  }
}
/* Go Solutions Styles End */

/* Now Fresh Styles */
:global(.now-fresh) {
  .link {
    text-decoration: none;

    p {
      font-family: Stag, sans-serif !important;
      font-size: 15px;

      @include at-breakpoint(large) {
        font-size: 18px;
      }
    }
    color: $now-brown;

    svg path {
      fill: $now-brown;
    }

    &:hover {
      text-decoration: none;
      color: $now-brown;
      p {
        font-weight: 500;
      }

      svg path {
        fill: $now-brown;
        stroke: $now-brown;
      }

      &:after {
        background-color: $now-brown;
        height: 3px;
      }
    }

    &:after {
      background-color: $now-brown;
    }
  }

  .styled-content {
    @include bold();
  }
}
/* Now Fresh Styles End */

/* Petcurean Styles */
:global(.petcurean) {
  .link {
    &:hover {
      text-decoration: none;
      color: $petcurean-white;

      &:after {
        background-position: 0%;
        transition: background-position 0.2s ease-out;
      }
    }

    &:after {
      background-image: $pc-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    p {
      font-weight: 600;
    }
  }

  .dark {
    color: $petcurean-grey;

    &:after {
      background-image: $pc-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    svg path {
      fill: $petcurean-grey;
    }
  }

  .light {
    color: $petcurean-white;

    &:after {
      background-image: $pc-light-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    svg path {
      fill: $petcurean-white;
    }
  }
}
/* Petcurean  Styles End */

/* Summit Styles */
:global(.summit) {
  .link {
    @include eyebrow();

    &:hover {
      text-decoration: none;

      &:after {
        background-position: 0%;
        transition: background-position 0.2s ease-out;
      }
    }

    &:after {
      background-image: $summit-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
      bottom: 2px;
    }

    p {
      font-size: 18px;
      font-weight: 600;

      @each $locale, $font-stack in $summit-tertiary-fonts {
        :global(.locale-#{$locale}) & {
          font-family: $font-stack;
        }
      }
    }
  }

  .dark {
    color: $summit-black;

    &:after {
      background-image: $summit-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    svg path {
      fill: $summit-black;
    }

    &:hover {
      color: $summit-black;

      svg path {
        fill: $summit-black;
      }
    }
  }

  .light {
    color: $summit-white;

    &:after {
      background-image: $summit-light-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    &:hover {
      color: $summit-white;

      svg path {
        fill: $summit-white;
      }
    }

    svg path {
      fill: $summit-white;
    }
  }
}
/* Summit Styles End */
