
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  @include default-margin-bottom();

  :global(.gather) & {
    overflow: hidden;

    @include at-breakpoint(large) {
      padding: 60px 0 0 0;
    }

    @include at-breakpoint(x-large) {
      padding: 200px 0;
      margin-bottom: 0;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    outline: 1px solid $now-brown;
    margin-bottom: -1px;

    @include at-breakpoint(medium) {
      margin-bottom: 1px;
    }
  }

  :global(.petcurean) & {
    overflow: hidden;

    @include at-breakpoint(large) {
      padding: 60px 0 0 0;
    }

    @include at-breakpoint(x-large) {
      padding: 200px 0;
      margin-bottom: 0;
    }
  }

  :global(.summit) & {
    @include at-breakpoint(large) {
      padding-top: 40px;
    }

    @include at-breakpoint(x-large) {
      padding-top: 140px;
      padding-bottom: 150px;
    }
  }
}

.benefits-anim-wrapper {
  @include grid-container();

  :global(.gather) &,
  :global(.petcurean) & {
    @include flex-container();
    flex-direction: column-reverse;

    @include at-breakpoint(large) {
      @include grid-container();
      grid-auto-flow: row;
    }

    @include at-breakpoint(x-large) {
      grid-auto-flow: dense;
    }
  }

  :global(.now-fresh) & {
    margin: 0;
    max-width: none;
    width: auto;
    column-gap: 0;
    padding: 0;
  }

  :global(.summit) & {
    align-items: center;
    @include flex-container();
    flex-direction: column-reverse;
    padding-left: 0;
    padding-right: 0;

    @include at-breakpoint(medium) {
      @include grid-container();
    }
  }

  &.benefits-anim-in {
    .benefits {
      @include animateIn();
    }

    .callout {
      @include animateIn(0.5s);
    }
  }

  @include at-breakpoint(x-large) {
    max-width: $max-width;
    margin: 0 auto;

    :global(.go-solutions) & {
      padding: 0;
    }

    :global(.now-fresh) & {
      padding: 0;
    }

    :global(.petcurean) & {
      padding: 0;
    }
  }
}

.benefits {
  @include willAnimateIn();
  position: relative;
  grid-column: span 6;
  padding-inline-end: 8px;
  z-index: 1;

  @include at-breakpoint(medium) {
    grid-column: span 6 / -1;
    padding-inline-end: 0;
  }

  @include at-breakpoint(large) {
    grid-column: span 10 / -1;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 6 / -1;
  }

  :global(.gather) &,
  :global(.petcurean) & {
    @include at-breakpoint(large) {
      grid-column: 3 / span 8;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 6 / -1;
    }
  }

  :global(.now-fresh) & {
    @include willAnimateInNowFresh();
    padding-inline-end: 0;
    grid-column: span 6;

    @include at-breakpoint(medium) {
      grid-column: span 6;
    }

    @include at-breakpoint(large) {
      grid-column: span 12;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 6;
    }
  }

  :global(.petcurean) & {
    padding-inline-end: 0;
  }

  :global(.summit) & {
    padding-inline-end: 0;
    margin-top: -25px;

    @include at-breakpoint(medium) {
      margin-top: -30px;
    }

    @include at-breakpoint(large) {
      grid-column: 4 / 12;
    }

    @include at-breakpoint(x-large) {
      margin-inline-start: -30px;
      margin-top: 0;
      grid-column: 7 / -1;
    }
  }
}

.accent-image {
  display: none;

  :global(.gather) & {
    @include at-breakpoint(large) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 800px;
      height: auto;
      background-position: right -150px top 0;
      background-repeat: no-repeat;
      background-size: 760px;
    }

    @include at-breakpoint(x-large) {
      width: 70%;
      height: 100%;
      background-position: right -80px top 0;
      background-size: 600px;
    }
  }

  :global(.now-fresh) & {
    display: none;
  }

  :global(.petcurean) & {
    @include at-breakpoint(large) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 800px;
      height: 70%;
      background-position: right -150px top 280px;
      background-repeat: no-repeat;
      background-size: 700px;
    }

    @include at-breakpoint(x-large) {
      width: 60%;
      height: 80%;
      background-size: 850px;
      background-position: right -250px top 100px;
    }
  }

  :global(.summit) & {
    @include at-breakpoint(large) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 80%;
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;

      @include at-breakpoint(x-large) {
        height: 100%;
      }
    }
  }
}

.content-wrapper {
  :global(.now-fresh) & {
    background: $color-black-vignette;
    width: 100%;
    height: 100%;
    padding: 200px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include at-breakpoint(medium) {
      padding: 200px 64px;
    }

    @include at-breakpoint(x-large) {
      border-right: 1px solid $now-brown;

      :global(.locale-he) & {
        border-left: 1px solid $now-brown;
        border-right: none;
      }
    }
  }
}

.callout {
  @include willAnimateIn();
  position: relative;
  grid-column: span 6;
  padding: 165px 68px 60px 40px;
  margin: -108px -16px 0 -16px;

  :global(.locale-he) & {
    padding: 165px 40px 60px 68px;
  }

  @include at-breakpoint(medium) {
    grid-column: span 5;
    margin: -88px 0 0 -32px;
    padding: 163px 122px 80px 64px;

    :global(.locale-he) & {
      margin: -88px -32px 0 0;
      padding: 163px 64px 80px 122px;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 8;
    padding: 213px 188px 107px 84px;
    margin: -83px -40px 0 0;

    :global(.locale-he) & {
      padding: 213px 84px 107px 188px;
      margin: -83px 0 0 -40px;
    }
  }

  @include at-breakpoint(x-large) {
    grid-column: 2 / span 6;
    padding: 170px 188px 150px 84px;
    margin: -333px -7px 0 0;

    :global(.locale-ru) & {
      margin: -357px -7px 0 0;
    }

    :global(.locale-he) & {
      padding: 170px 84px 150px 188px;
      margin: -333px 0 0 -7px;
    }
  }

  :global(.gather) & {
    grid-column: 1 / span 6;
    padding: 0;
    margin: 0 0 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include at-breakpoint(medium) {
      margin: 0 0 70px 0;
    }

    @include at-breakpoint(large) {
      margin: 0 0 76px 0;
      grid-column: 1 / span 8;
      grid-row: 1;
    }

    @include at-breakpoint(x-large) {
      grid-column: 1 / span 6;
      margin: 0;
    }
  }

  :global(.go-solutions) & {
    background: $go-yellow;
    color: $color-black;
  }

  :global(.now-fresh) & {
    @include willAnimateInNowFresh();
    grid-row: 1;
    color: $color-white;
    margin: 0;
    position: relative;
    z-index: 1;
    padding: 0;

    @include at-breakpoint(medium) {
      grid-column: span 6;
    }

    @include at-breakpoint(large) {
      grid-column: span 12;
    }

    @include at-breakpoint(x-large) {
      grid-column: 1 / span 6;
    }
  }

  :global(.petcurean) & {
    grid-column: 1 / span 6;
    padding: 0;
    margin: 0 0 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include at-breakpoint(large) {
      margin: 0 0 76px 0;
      grid-column: 1 / span 8;
      grid-row: 1;
    }

    @include at-breakpoint(x-large) {
      grid-column: 1 / span 6;
      margin: 0;
    }
  }

  :global(.summit) & {
    background-color: $summit-light-desaturated-blue;
    color: $summit-blue;
    width: calc(100% + 32px);
    padding: 45px 60px 62px 60px;
    margin: 0;

    @include at-breakpoint(medium) {
      width: auto;
      grid-column: 1 / span 6;
      grid-row: 1;
      padding: 135px 90px 100px 60px;
      margin: 0 50px 0 -35px;
    }

    @include at-breakpoint(large) {
      grid-column: 1 / 9;
      padding: 132px 90px 95px 60px;
      margin: 0 -80px 0 80px;
    }

    @include at-breakpoint(x-large) {
      grid-column: 1 / span 6;
    }
  }
}

.callout-headline {
  @include h3;

  @include at-breakpoint(medium) {
    font-size: 45px;
    line-height: 1.0667;
  }

  :global(.gather) & {
    @include font-sizes(34px, 64px, 64px, 64px);
    @include line-heights(43px, 75px, 75px, 75px);
  }

  :global(.now-fresh) & {
    @include font-sizes(25px, 40px, 40px);
    line-height: 1.3793;
    font-weight: 400;
    font-family: inherit;

    :global(.locale-en) &,
    :global(.locale-fr) & {
      font-family: 'Stag' !important;
    }

    @include at-breakpoint(medium) {
      line-height: 1.0566;
    }
  }

  :global(.petcurean) & {
    color: $petcurean-orange;
    font-weight: 900;
    @include font-sizes(34px, 67px, 67px, 67px);
    @include line-heights(40px, 68px, 68px, 68px);
  }

  :global(.summit) & {
    @include h2();
  }
}

.callout-cta {
  display: inline-block;
  margin-top: 31px;

  @include at-breakpoint(medium) {
    margin-top: 35px;
  }

  @include at-breakpoint(large) {
    margin-top: 40px;
  }

  :global(.now-fresh) & {
    width: fit-content;
    @include at-breakpoint(medium) {
      margin-top: 40px;
    }

    @include at-breakpoint(large) {
      margin-top: 32px;
    }
  }

  :global(.gather) &,
  :global(.petcurean) & {
    display: flex;
    width: auto;
  }
}

.callout-btn {
  :global(.gather) & {
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    text-align: center;
  }

  :global(.go-solutions) & {
    color: $color-black;
    border-color: $color-black;

    &::after {
      background-color: $color-black;
    }

    &:hover {
      color: $color-black;
    }
  }

  :global(.petcurean) & {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
  }
}

.bg-image {
  :global(.now-fresh) & {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    picture,
    img {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
    }
  }
}
