
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.carousel {
  position: relative;
  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    overflow: hidden;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: none;

  @include at-breakpoint(x-large) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  :global(.now-fresh) & {
    @include at-breakpoint(medium) {
      @media (orientation: portrait) {
        height: calc(100vh - 88px);
      }
    }

    @include at-breakpoint(large) {
      height: calc(100vh - 88px);
    }
  }
}

.content-wrapper {
  position: relative;

  :global(.now-fresh) & {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    background-color: $color-white;
    border-bottom: 1px solid $now-brown;

    @include at-breakpoint(medium) {
      padding: 40px;
    }

    @include at-breakpoint(x-large) {
      max-height: none;
      padding: 60px;
      border-bottom: none;
      border-right: 1px solid $now-brown;
      grid-column: span 6;
    }
  }
}

.headline {
  margin: 0;
  grid-column: span 4;
  color: $now-brown;

  :global(.now-fresh) & {
    @include font-sizes(32px, 32px, 40px, 67px);
    @include line-heights(1.037, 1.037, 0.9697, 1);
  }
}

.subhead {
  p,
  a {
    @include font-sizes(16px, 16px, 18px);
  }

  a {
    text-decoration: underline;

    svg {
      display: none;
    }
  }

  :global(.now-fresh) & {
    p,
    a {
      color: $now-brown;
      margin: 16px 0;

      @include at-breakpoint(x-large) {
        margin-top: 24px;
      }

      :global(.locale-zh) & {
        @include at-breakpoint(x-large) {
          grid-column: 1 / -1;
        }
      }
    }
  }
}

.ctas {
  :global(.now-fresh) & {
    @include at-breakpoint(x-large) {
      margin-top: 40px;
    }
  }
}

.cta {
  display: inline-block;
  margin: 8px 16px 8px 0;
}

/** Hero Carousel Media Renderer Styles Start */
.bg-image {
  grid-column: span 6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    display: block;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
    display: block;
  }
}

.bg-image-mobile {
  display: block;

  @include at-breakpoint(large) {
    display: none;
  }
}

.bg-image-hide-on-mobile {
  display: none;

  @include at-breakpoint(large) {
    display: block;
  }
}

/** Hero Carousel Media Renderer Styles End */
