
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include flex-container();
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  background-color: $petcurean-white;
  padding-top: 40px;
  padding-bottom: 90px;

  @include at-breakpoint(large) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

.link {
  display: block;
  width: calc(50% - 20px);

  @include at-breakpoint(large) {
    width: auto;
    max-width: calc(25% - 20px);
  }

  span {
    display: block;
  }
}

.linked-image {
  display: block;
  max-width: 250px;
  margin: 0 auto;

  @include at-breakpoint(large) {
    margin: 0;
  }
}
