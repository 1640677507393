
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  position: relative;

  @include at-breakpoint(small) {
    padding: 0;
  }

  :global(.go-solutions) & {
    @include at-breakpoint(medium) {
      margin-inline-start: -32px;
    }

    @include at-breakpoint(large) {
      margin-inline-start: 0;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    background-color: $now-tan;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    width: auto;
    max-width: none;
    padding: 0;
  }

  :global(.petcurean) & {
    color: $petcurean-grey;
    padding: 0;
    background-color: $petcurean-transparent-light-tan;
    justify-items: center;
    align-items: center;
    max-width: none;
    width: 100%;
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;
  }

  &.has-animation {
    .content,
    .image,
    .copy-container {
      @include willAnimateIn();
      :global(.now-fresh) & {
        @include willAnimateInNowFresh();
      }
    }

    &.anim-in {
      .content,
      .image {
        @include animateIn();
      }

      .copy-container {
        @include animateIn(0.5s);
      }
    }
  }

  &.has-background {
    @include default-margin-bottom();
    margin-bottom: 0;

    .image {
      transition-delay: 0.5s;
      margin-top: -108px;

      @include at-breakpoint(medium) {
        margin-top: -124px;
      }

      @include at-breakpoint(large) {
        margin-top: 0;
      }
    }

    :global(.gather) & {
      .image {
        margin-top: 0;
      }
    }

    .copy-container {
      transition-delay: 0.8s;
    }

    :global(.now-fresh) & {
      background-color: transparent;
      .image {
        margin-top: 0;
      }
    }

    :global(.petcurean) & {
      .image {
        margin-top: 0;
      }
    }

    :global(.go-solutions) & {
      color: $color-black;

      .content {
        background-color: $go-yellow;
      }

      .cta {
        color: $color-black;

        &:not(:hover) {
          border-color: $color-black;

          &::after {
            background-color: $color-black;
          }
        }

        &:hover {
          color: $color-black;
        }
      }
    }

    :global(.petcurean) & {
      margin-bottom: 40px;

      @include at-breakpoint(large) {
        margin-bottom: 80px;
      }
    }

    :global(.summit) & {
      @include default-margin-bottom();
      .image {
        margin-top: 0px;
      }
    }
  }

  &.has-banner {
    // Margins are default margins + the .banner bottom position
    margin-bottom: 205px;

    @include at-breakpoint(medium) {
      margin-bottom: 240px;
    }

    @include at-breakpoint(large) {
      margin-bottom: 270px;
    }

    @include at-breakpoint(x-large) {
      margin-bottom: 256px;
    }

    :global(.now-fresh) & {
      margin-bottom: 1px;
    }

    .content {
      padding-bottom: 134px;

      @include at-breakpoint(medium) {
        padding-bottom: 195px;
      }

      @include at-breakpoint(large) {
        padding-bottom: 144px;
      }

      @include at-breakpoint(x-large) {
        padding-bottom: 136px;
      }

      :global(.now-fresh) & {
        padding: 0;
      }

      :global(.petcurean) & {
        padding-bottom: 69px;

        @include at-breakpoint(medium) {
          padding-bottom: 76px;
        }

        @include at-breakpoint(large) {
          padding-bottom: 116px;
        }

        @include at-breakpoint(x-large) {
          padding-bottom: 140px;
        }
      }
    }
  }

  &.has-no-image {
    margin-bottom: 1px;
    .copy-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 64px 48px;

      @include at-breakpoint(large) {
        padding: 90px 0;
        grid-column: 3 / 11;
      }

      @include at-breakpoint(x-large) {
        grid-column: 4 / 10;
      }
    }

    .headline {
      padding: 0;
    }

    .cta {
      margin: 0;
    }
  }
}

.content {
  @include grid-container();
  position: relative;
  grid-column: 1 / -1;
  padding: 0 16px 60px 16px;
  margin-top: 108px;
  z-index: 1;

  @include at-breakpoint(medium) {
    padding: 0 0 100px 32px;
    margin-top: 124px;

    :global(.locale-he) & {
      padding: 0 32px 100px 0;
    }
  }

  @include at-breakpoint(large) {
    padding: 114px 0;
    margin-top: 0;
    align-items: center;

    :global(.locale-he) & {
      padding: 144px 0;
    }
  }

  @include at-breakpoint(x-large) {
    padding: 97px 0;

    :global(.locale-he) & {
      padding: 97px 0;
    }
  }

  :global(.gather) & {
    margin-top: 0;

    @include at-breakpoint(medium) {
      padding: 0;
      width: 100%;
    }

    @include at-breakpoint(large) {
      height: 100%;
    }
  }

  :global(.now-fresh) &,
  :global(.petcurean) & {
    display: flex;
    grid-column: 1 / -1;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 0 56px 0;
    max-width: none;
    width: 100%;

    @include at-breakpoint(large) {
      display: grid;
      grid-auto-flow: dense;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding: 0;
    }
  }

  :global(.now-fresh) & {
    column-gap: 0;
    padding: 0;
  }

  :global(.summit) & {
    margin-top: 35px;

    @include at-breakpoint(medium) {
      padding-left: 0;
    }
  }
}

.image {
  position: relative;
  grid-column: 1/5;
  width: 100%;
  margin-bottom: 45px;
  align-self: flex-start;

  @include at-breakpoint(medium) {
    grid-column: span 3;
    left: 40px;
    padding-inline-end: 32px;
    margin-bottom: 55px;

    :global(.locale-he) & {
      left: auto;
      right: 40px;
    }
  }

  @include at-breakpoint(large) {
    left: 0;
    padding: 16px 0;
    margin-bottom: 0;
    grid-column: 2/6;

    :global(.locale-he) & {
      left: auto;
      right: 0;
    }
  }

  @include at-breakpoint(x-large) {
    padding: 36px 0;
  }

  img {
    display: block;
    width: 100%;
  }

  :global(.gather) & {
    grid-column: 1 / 7;
    overflow: hidden;
    margin-bottom: 0;
    padding-inline-end: 0;
    left: 0;

    @include at-breakpoint(large) {
      grid-column: span 5 / -1;
      padding: 0;
      order: 1;
    }

    img {
      border-radius: 20px 20px 100px 20px;

      @include at-breakpoint(large) {
        border-radius: 20px 20px 20px 100px;
      }
    }
  }

  :global(.go-solutions) & {
    img {
      border-radius: 50%;
    }
  }

  :global(.now-fresh) & {
    width: 100%;
    left: 0;
    padding-inline-end: 0;
    margin: 0;
    height: 100%;
    padding: 0;
    align-items: center;
    border-bottom: 1px solid $now-brown;

    @include at-breakpoint(large) {
      grid-column: 7 / 13;
      display: flex;
      justify-content: flex-end;
      border-bottom: none;
      border-left: 1px solid $now-brown;

      :global(.locale-he) & {
        border-left: none;
        border-right: 1px solid $now-brown;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  :global(.petcurean) & {
    width: 100%;
    left: 0;
    padding-inline-end: 0;
    margin: 0;
    height: 100%;
    padding: 0;
    align-items: center;

    @include at-breakpoint(large) {
      grid-column: 7 / 13;
      display: flex;
      justify-content: flex-end;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include at-breakpoint(large) {
        width: 90%;
        max-height: 767px;
      }
    }
  }

  :global(.summit) & {
    grid-column: span 6;
    left: 0;
    padding: 0;

    @include at-breakpoint(large) {
      grid-column: span 5;
    }
  }
}

.copy-container {
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: span 5;
    text-align: start;
    padding-inline-start: 40px;
  }

  @include at-breakpoint(large) {
    grid-column: 7/12;
    padding-inline-start: 0;
    margin-inline-start: -24px;
  }

  :global(.gather) & {
    margin-top: 25px;
    padding: auto;
    @include at-breakpoint(medium) {
      margin-top: 55px;
      grid-column: span 6;
      padding-inline-start: 0px;
    }

    @include at-breakpoint(large) {
      margin-top: 0;
      margin-inline-start: 0px;
      padding: 70px 0;
      grid-column: span 6;
    }
  }

  :global(.now-fresh) &,
  :global(.petcurean) & {
    margin-inline-start: 0;
    padding: 0 20px;
    margin-top: 60px;

    @include at-breakpoint(medium) {
      padding-inline-start: 30px;
      margin-top: 80px;
      padding: 0 32px;
    }

    @include at-breakpoint(large) {
      grid-column: 2 / 7;
      padding-inline-start: 24px;
      margin: auto;
      padding: 145px 0;
    }

    @include at-breakpoint(x-large) {
      padding-inline-start: 0;
      padding: 180px auto;
    }
  }

  :global(.now-fresh) & {
    padding: 64px 48px;
    margin-top: 0;
    text-align: center;

    @include at-breakpoint(large) {
      text-align: start;
      margin: 0;
      grid-column: 1 / 7;
    }

    @include at-breakpoint(x-large) {
      padding: 90px 100px;
    }
  }

  :global(.summit) & {
    grid-column: span 6;
    padding-inline-start: 0;

    @include at-breakpoint(large) {
      grid-column: 7 / -1;
    }
  }
}

.eyebrow {
  @include eyebrow-box();
  margin-bottom: 8px;

  :global(.gather) & {
    color: $gather-green;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }

  :global(.now-fresh) & {
    display: inline-block;
  }

  :global(.petcurean) & {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  :global(.summit) & {
    @include font-sizes(18px, 16px, 16px);
    margin-bottom: 0px;

    @include at-breakpoint(medium) {
      margin-bottom: 8px;
    }
  }
}

.headline {
  margin-bottom: 16px;
  @include h3();

  @include at-breakpoint(medium) {
    margin-bottom: 24px;
    font-size: 45px;
    line-height: 1.0666;
  }

  @include at-breakpoint(x-large) {
    padding-inline-end: 20%;
  }

  :global(.gather) & {
    @include font-sizes(28px, 45px, 45px, 45px);
    @include line-heights(37px, 51px, 51px, 51px);
    font-weight: 400;
  }

  :global(.now-fresh) & {
    @include font-sizes(30px, 30px, 36px, 36px);
  }

  :global(.petcurean) & {
    @include font-sizes(28px, 45px, 45px, 45px);
    @include line-heights(32px, 50px, 50px, 50px);
    color: $petcurean-orange;
    margin-bottom: 24px;
  }

  :global(.summit) & {
    @include font-sizes(28px, 45px, 45px);
    @include line-heights(1.14, 1.07, 1.07);
  }
}

.description {
  @include p2();
  white-space: pre-wrap;

  @include at-breakpoint(medium) {
    font-size: 21px;
    line-height: 1.52381;
  }

  :global(.gather) & {
    font-weight: 300;
    @include font-sizes(16px, 16px, 19px, 19px);
    @include line-heights(28px, 28px, 36px, 36px);
  }

  :global(.now-fresh) & {
    font-size: 16px;
    line-height: 24px;
  }

  :global(.petcurean) & {
    @include font-sizes(16px, 21px, 21px, 21px);
    @include line-heights(26px, 37px, 37px, 37px);
    font-weight: 300;
  }

  :global(.summit) & {
    font-weight: 300;
  }
}

.author-and-cta-container {
  :global(.gather) &,
  :global(.petcurean) &,
  :global(.summit) & {
    display: flex;
    flex-direction: column;
  }
}

.author-wrapper {
  margin-top: 24px;
  padding-bottom: 13px;

  @include at-breakpoint(medium) {
    margin-top: 32px;
  }

  :global(.petcurean) & {
    margin-top: 32px;
  }
}

.author {
  @include eyebrow();

  :global(.gather) & {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }

  :global(.go-solutions) & {
    font-size: 12px;
    line-height: 1.3333;
  }

  :global(.now-fresh) & {
    @include p2();
    text-transform: none;
    font-weight: 600;
    letter-spacing: 1px;
  }

  :global(.petcurean) & {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 1.3;
    text-transform: uppercase;
  }
}

.author-title {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 8px;

  :global(.gather) & {
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
  }

  :global(.now-fresh) & {
    letter-spacing: 1px;
  }

  :global(.petcurean) & {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0px;
    line-height: 18px;
  }

  :global(.summit) & {
    font-weight: 300;
  }
}

.cta {
  display: inline-block;
  text-align: center;
  @include bold();

  @include at-breakpoint(small) {
    margin-right: 24px;
    margin-top: 24px;
  }

  @include at-breakpoint(medium) {
    margin-right: 24px;
    margin-top: 24px;
  }

  :global(.gather) & {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin-right: 0;
  }

  :global(.petcurean) & {
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    margin-right: 0;

    :global(.locale-zh) & {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1.15;
      min-width: auto;
      padding: 16px;
    }
  }

  :global(.summit) & {
    margin-right: 0;
  }
}

.banner {
  position: absolute;
  bottom: -125px;
  left: 0;
  right: 0;

  @include at-breakpoint(medium) {
    bottom: -140px;
    padding-inline-end: 56px;
  }

  @include at-breakpoint(large) {
    bottom: -150px;
    padding-inline-end: 72px;
  }

  @include at-breakpoint(x-large) {
    bottom: -136px;
    padding-inline-end: 32%;
  }

  :global(.now-fresh) & {
    position: static;
    display: block;
    padding-inline-end: 0;
    padding: 0;

    @include at-breakpoint(medium) {
      padding: 0 20px 60px 20px;
    }

    @include at-breakpoint(large) {
      grid-column: 3 / 11;
      padding: 0 0 90px 0;
    }

    @include at-breakpoint(x-large) {
      grid-column: 4 / 10;
    }
  }
}
