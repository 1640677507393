
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  overflow-wrap: break-word;
  word-wrap: break-word;

  a {
    :global(.gather) & {
      color: $gather-green;
      font-weight: 700;
      text-decoration: none;
    }

    :global(.go-solutions) & {
      color: $go-light-charcoal;
    }

    :global(.petcurean) & {
      color: $petcurean-orange;
      font-weight: 300;
      text-decoration: none;
    }
  }

  a:hover {
    text-decoration: underline;
  }

  // changing font for NF hyperlinks only
  [class^='genericLink_wrapper'] {
    :global(.now-fresh) & {
      font-family: Almarai, sans-serif;
    }
  }

  & > * + * {
    margin-top: 32px;

    @include at-breakpoint(large) {
      margin-top: 40px;
    }

    :global(.petcurean) & {
      margin-top: 16px;

      @include at-breakpoint(medium) {
        margin-top: 32px;
      }
    }
  }

  & > p {
    :global(.gather) & {
      color: $gather-black;
      font-weight: 300;
      @include font-sizes(12px, 19px, 19px, 19px);
      @include line-heights(21px, 36px, 36px, 36px);
    }

    :global(.go-solutions) & {
      color: $go-light-charcoal;
    }

    :global(.petcurean) & {
      font-weight: 300;
      @include font-sizes(16px, 21px, 21px, 21px);
      @include line-heights(27px, 37px, 37px, 37px);
    }
  }

  p + p {
    margin-top: 32px;

    :global(.petcurean) & {
      margin-top: 32px;
    }
  }

  & > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    :global(.petcurean) & {
      color: $petcurean-orange;
    }

    :global(.summit) & {
      color: $summit-blue;
    }
  }

  h2 {
    :global(.go-solutions) & {
      @include font-sizes(32px, 55px, 55px, 55px);
    }

    :global(.now-fresh) & {
      @include font-sizes(40px, 55px, 55px, 55px);
    }

    :global(.petcurean) & {
      @include font-sizes(32px, 45px, 45px, 45px);
    }
  }

  h3 {
    :global(.gather) & {
      color: $gather-black;
      font-weight: 400;
      @include font-sizes(28px, 45px, 45px, 45px);
      @include line-heights(37px, 51px, 51px, 51px);
    }

    :global(.petcurean) & {
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
    }
  }

  h4 {
    :global(.gather) & {
      color: $gather-black;
      font-weight: 400;
      @include font-sizes(22px, 30px, 30px, 30px);
      @include line-heights(28px, 40px, 40px, 40px);
    }

    :global(.petcurean) & {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
    }
  }

  ul {
    :global(.gather) &,
    :global(.petcurean) &,
    :global(.summit) & {
      margin-inline-start: 1em;
      padding-inline-start: 0;
    }

    li {
      @include p2;
      @include bold;

      & + li {
        margin-top: 8px;
      }

      :global(.gather) & {
        margin-bottom: 16px;

        @include at-breakpoint(medium) {
          margin-bottom: 32px;
        }
      }

      :global(.go-solutions) & {
        align-items: flex-start;
        display: flex;
        list-style: none;

        &::before {
          background-color: $color-black;
          border-radius: 50%;
          content: '';
          display: block;
          height: 8px;
          margin: 12px 19px 0 32px;
          width: 8px;
          flex-shrink: 0;

          :global(.locale-he) & {
            margin: 12px 32px 0 19px;
          }

          @include at-breakpoint(medium) {
            margin-inline-end: 15px;
          }

          @include at-breakpoint(large) {
            margin-inline-end: 24px;
          }

          @include at-breakpoint(x-large) {
            margin-inline-end: 30px;
          }
        }
      }

      :global(.now-fresh) & {
        list-style-type: disc;
        margin-inline-start: 25px;
        padding-inline-start: 12px;

        @include at-breakpoint(medium) {
          margin-inline-start: 52px;
          padding-inline-start: 24px;
        }

        @include at-breakpoint(large) {
          margin-inline-start: 50px;
        }
      }

      :global(.petcurean) & {
        margin-bottom: 24px;
      }

      :global(.gather) &,
      :global(.petcurean) &,
      :global(.summit) & {
        list-style-type: disc;
      }
    }

    li * {
      :global(.gather) &,
      :global(.petcurean) & {
        @include font-sizes(12px, 19px, 19px, 19px);
        @include line-heights(21px, 36px, 36px, 36px);
      }
    }
  }

  ol {
    :global(.gather) &,
    :global(.petcurean) &,
    :global(.summit) & {
      margin-inline-start: 1em;
      padding-inline-start: 0;
    }

    li {
      list-style-type: decimal;
      margin-inline-start: 45px;
      padding-inline-start: 25px;

      :global(.gather) &,
      :global(.petcurean) &,
      :global(.summit) & {
        margin-inline-start: 0;
        padding-inline-start: 0.5em;
      }

      :global(.gather) &,
      :global(.petcurean) & {
        margin-bottom: 16px;
        @include font-sizes(12px, 19px, 19px, 19px);
        @include line-heights(21px, 36px, 36px, 36px);

        @include at-breakpoint(medium) {
          margin-bottom: 32px;
        }
      }
    }

    li * {
      :global(.gather) &,
      :global(.petcurean) & {
        @include font-sizes(12px, 19px, 19px, 19px);
        @include line-heights(21px, 36px, 36px, 36px);
      }

      :global(.now-fresh) & {
        margin-top: 8px;
      }
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.petcurean) & {
    color: $petcurean-grey;
  }

  //RichText Table styling
  /* if we add rich text table to other sites,
  format it like above */
  .table-container {
    grid-column: span 6;
    overflow: auto;

    @include at-breakpoint(large) {
      grid-column: 2 / 12;
    }

    @include at-breakpoint(x-large) {
      grid-column: 3 / 11;
    }
  }

  table {
    @include font-sizes(16px, 16px, 21px, 21px);
    border-collapse: collapse;
  }

  th {
    :global(.now-fresh) & {
      background-color: $color-white;
      color: $now-brown;
      border: 1px solid $now-brown;
    }

    :global(.petcurean) & {
      background-color: $petcurean-orange;
      color: $petcurean-white;
      border: 1px solid $petcurean-orange;
    }
    padding: 24px;
    min-width: 150px;

    * {
      font-weight: 600;
      @include line-heights(119%, 119%, 152%, 152%);
    }
  }

  td {
    :global(.now-fresh) & {
      border: 1px solid $now-brown;
      background-color: $now-tan;
    }
    :global(.petcurean) & {
      border: 1px solid $petcurean-grey;
      background-color: $petcurean-white;
    }
    min-width: 150px;
    padding: 24px;

    * {
      font-weight: 300;
      @include line-heights(162%, 162%, 138%, 138%);
    }
  }
}
