@mixin visually-hidden() {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin active-indicator() {
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    opacity: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;

    :global(.go-solutions) & {
      border-top: 4px solid $go-teal;
    }
  }
}

@mixin bold-hover-indicator() {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 9px 2px;

  &:before {
    content: attr(data-text);
    visibility: hidden;
    height: 0;
    overflow: hidden;
    user-select: none;
    pointer-events: none;

    :global(.go-solutions) & {
      font-weight: 700;
    }

    :global(.now-fresh) & {
      font-weight: 500;
    }

    @media speech {
      display: none;
    }
  }
}

@mixin default-margin-bottom() {
  margin-bottom: 70px;

  @include at-breakpoint(medium) {
    margin-bottom: 100px;
  }

  @include at-breakpoint(large) {
    margin-bottom: 120px;
  }

  :global(.now-fresh) & {
    margin-bottom: 1px;
  }
}

@mixin default-padding-bottom() {
  padding-bottom: 70px;

  @include at-breakpoint(medium) {
    padding-bottom: 100px;
  }

  @include at-breakpoint(large) {
    padding-bottom: 120px;
  }
}

@mixin page-container {
  // Footer top has top padding on large, so negate bottom margin of last component
  @include at-breakpoint(large) {
    margin-bottom: -120px;
  }

  :global(.gather) & {
    background-color: $gather-cream;
    color: $gather-black;
  }

  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;
    padding-bottom: 1px;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    color: $now-brown;

    @include at-breakpoint(large) {
      margin-bottom: 0;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-white;
    color: $petcurean-grey;

    @include at-breakpoint(large) {
      margin-bottom: 0;
    }
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-black;
  }
}

@mixin errormsg {
  @include p3;
  @include bold;
  margin: 10px 0 0 8px;
  display: none;
  color: $color-dark-red;

  :global(.locale-he) & {
    margin: 10px 8px 0 0;
  }

  :global(.go-solutions) & {
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-size: 16px;
  }

  :global(.summit) & {
    @include p2();
  }
}

@mixin scroll-offset {
  &:target {
    padding-top: $nav-offset;
    margin-top: -$nav-offset;
  }
}
