
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
//Gather styling
:global(.gather) {
  .container {
    border-bottom: 2px solid $gather-opaque-green;
    border-top: 2px solid $gather-opaque-green;
    color: $gather-black;

    @include at-breakpoint(medium) {
      padding: 57px 0 53px 41px;
    }

    @include at-breakpoint(large) {
      padding: 57px 0 49px 25px;
    }

    @include at-breakpoint(x-large) {
      margin: 40px 0 8px;
      padding: 57px 0 62px 41px;
    }
  }

  .headline {
    margin-bottom: 24px;
    @include font-sizes(22px, 30px, 30px, 30px);
    @include line-heights(127%, 133%, 133%, 133%);
    font-weight: 400;
    text-transform: capitalize;

    @include at-breakpoint(small) {
      margin-top: 40px;
    }
  }

  .list {
    li {
      padding-inline-start: 0;
      font-weight: 700;
      margin-top: 24px;
      & > span {
        @include font-sizes(12px, 16px, 16px, 16px);
        @include line-heights(133%, 156%, 156%, 156%);
        font-weight: 700;
      }

      @include at-breakpoint(small) {
        margin-inline-start: 15px;
      }

      @include at-breakpoint(medium) {
        margin-inline-start: 35px;
        margin-top: 25px;
      }
    }

    @include at-breakpoint(small) {
      margin-bottom: 40px;
    }
  }
}

//Go solutions testing
:global(.go-solutions) {
  .container {
    border-bottom: 2px solid $go-light-opaque-black;
    border-top: 2px solid $go-light-opaque-black;
    padding: 48px 20px 47px;

    @include at-breakpoint(medium) {
      padding: 57px 0 53px 41px;
    }

    @include at-breakpoint(large) {
      padding: 57px 0 49px 25px;
    }

    @include at-breakpoint(x-large) {
      margin: 40px 0 8px;
      padding: 57px 0 62px 41px;
    }

    .list {
      li {
        &:before {
          margin-top: 8px;
        }
      }
    }
  }

  .headline {
    margin-bottom: 24px;
    padding: 0 6px;

    @include at-breakpoint(medium) {
      padding: 0;
    }
  }

  .list {
    padding: 0 30px 0 17px;

    @include at-breakpoint(medium) {
      padding: 0 0 0 36px;
    }

    @include at-breakpoint(large) {
      padding: 0 0 0 44px;
    }

    @include at-breakpoint(x-large) {
      padding: 0 40px 0 36px;
    }
  }
}

//Now fresh styling
:global(.now-fresh) {
  .content {
    border: 1px solid $now-brown;
    background-color: $color-white;
    padding: 32px;

    @include at-breakpoint(large) {
      margin: 0 0 8px;
      padding: 63px 83px 74px 77px;
    }

    @include at-breakpoint(x-large) {
      padding: 60px;
    }
  }

  .headline {
    margin-bottom: 24px;
    font-size: 32px !important;
  }

  .list > li > span {
    font-size: 16px;
  }
}

.item {
  flex: 1;
}

//Petcurean styling
:global(.petcurean) {
  .container {
    border-bottom: 2px solid $petcurean-opaque-orange;
    border-top: 2px solid $petcurean-opaque-orange;
    color: $petcurean-grey;

    @include at-breakpoint(medium) {
      padding: 57px 0 53px 41px;
    }

    @include at-breakpoint(large) {
      padding: 57px 0 49px 25px;
    }

    @include at-breakpoint(x-large) {
      margin: 40px 0 8px;
      padding: 57px 0 62px 41px;
    }

    //china container styling
    :global(.local-zh) & {
      .list {
        li {
          padding-inline-start: 0;
          margin-inline-start: 54px;
          margin-top: 24px;
          & > span {
            @include font-sizes(16px, 21px, 21px, 21px);
            @include line-heights(24px, 32px, 32px, 32px);
            font-weight: 700;
          }

          @include at-breakpoint(medium) {
            margin-inline-start: 103px;
            margin-top: 20px;
          }

          @include at-breakpoint(large) {
            margin-inline-start: 55px;
          }

          @include at-breakpoint(x-large) {
            margin-inline-start: 90px;
          }
        }
      }
    }
  }

  .headline {
    color: $petcurean-grey !important;
    margin-bottom: 24px;
    font-size: 22px;
    @include line-heights(127%, 117%, 117%, 117%);
    font-weight: 600;
    text-transform: capitalize;

    @include at-breakpoint(small) {
      margin-top: 40px;
    }

    :global(.locale-zh) & {
      @include font-sizes(22px, 32px, 45px, 45px);
      @include line-heights(28px, 36px, 54px, 54px);
      font-weight: 900;
      margin-bottom: 30px;

      @include at-breakpoint(medium) {
        margin-bottom: 40px;
      }
    }
  }

  .list {
    li {
      padding-inline-start: 0;
      margin-top: 24px;

      @include at-breakpoint(small) {
        margin-inline-start: 15px;
      }

      @include at-breakpoint(medium) {
        margin-inline-start: 35px;
        margin-top: 25px;
      }
    }

    .item {
      @include font-sizes(16px, 21px, 21px, 21px);
      @include line-heights(27px, 37px, 37px, 37px);
      font-weight: 300;
    }

    @include at-breakpoint(small) {
      margin-bottom: 40px;
    }
  }
}

.container {
  ol.list {
    li {
      @include p2;
      @include bold;
      padding-inline-start: 14px;

      & + li {
        margin-top: 8px;
      }

      :global(.go-solutions) & {
        @include at-breakpoint(medium) {
          padding-inline-start: 10px;
        }

        @include at-breakpoint(large) {
          padding-inline-start: 20px;
        }

        @include at-breakpoint(x-large) {
          padding-inline-start: 24px;
        }
      }

      :global(.now-fresh) & {
        margin-inline-start: 25px;
        padding-inline-start: 12px;

        @include at-breakpoint(medium) {
          margin-inline-start: 52px;
          padding-inline-start: 24px;
        }

        @include at-breakpoint(large) {
          margin-inline-start: 50px;
        }
      }

      :global(.petcurean) & {
        margin-inline-start: 25px;
        padding-inline-start: 12px;
      }
    }
  }
}

// Summit styling
:global(.summit) {
  .container {
    border-bottom: 2px solid $summit-opaque-blue;
    border-top: 2px solid $summit-opaque-blue;
    color: $summit-black;

    @include at-breakpoint(medium) {
      padding: 57px 0 53px 41px;
    }

    @include at-breakpoint(large) {
      padding: 57px 0 49px 25px;
    }

    @include at-breakpoint(x-large) {
      margin: 40px 0 8px;
      padding: 57px 0 62px 41px;
    }
  }

  .headline {
    margin-bottom: 24px;
    @include font-sizes(22px, 30px, 30px, 30px);
    @include line-heights(127%, 133%, 133%, 133%);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include at-breakpoint(small) {
      margin-top: 40px;
    }
  }

  .list {
    li {
      padding-inline-start: 0;
      margin-top: 24px;
      & > span {
        @include font-sizes(12px, 18px, 18px, 18px);
        @include line-heights(133%, 156%, 156%, 156%);
        font-weight: 700;
      }

      @include at-breakpoint(small) {
        margin-inline-start: 15px;
      }

      @include at-breakpoint(medium) {
        margin-inline-start: 35px;
        margin-top: 25px;
      }
    }

    @include at-breakpoint(small) {
      margin-bottom: 40px;
    }
  }
}
