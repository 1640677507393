
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  grid-column: span 7;
  @include at-breakpoint(large) {
    grid-column: span 3;
  }

  &.filters-stuck {
    &:before {
      content: '';
      display: block;
      width: 10%;
      height: 70px;

      @include at-breakpoint(large) {
        display: none;
      }
    }
  }

  :global(.now-fresh) {
    color: $now-brown;
  }
}

.sticky-section {
  position: relative;
  padding: 23px 34px 18px;
  margin: 0 -16px;
  z-index: $below-nav-z-index;

  .filters-stuck & {
    position: fixed;
    width: 100%;
    top: 87px;

    :global(.now-fresh) & {
      top: 0;
      width: calc(100% - 28px);

      @include at-breakpoint(medium) {
        width: calc(100% - 48px);
      }
    }
  }

  @include at-breakpoint(medium) {
    margin: 0 -32px;
  }

  @include at-breakpoint(large) {
    display: none;
  }

  :global(.go-solutions) & {
    border-bottom: 1px solid $go-filter-border;
    background-color: $color-white;
  }

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    background-color: $now-tan;
    margin: 0;
  }

  :global(.summit) & {
    border-bottom: 1px solid $summit-dark-desaturated-blue;
    background-color: $summit-white;
  }
}

.open-filters-btn {
  @include p2();
  @include bold();
  display: flex;
  align-items: center;

  svg {
    margin-inline-start: 6px;

    path {
      :global(.go-solutions) & {
        fill: $color-black;
      }

      :global(.now-fresh) & {
        fill: $now-brown;
      }

      :global(.summit) & {
        fill: $summit-blue;
      }
    }
  }

  :global(.now-fresh) & {
    @include h5();
    font-weight: 400;
    color: $now-brown;
    span {
      font-family: 'Stag', sans-serif;
    }
  }

  :global(.summit) & {
    @include h5();
    color: $summit-black;
  }
}

.filters-container {
  position: fixed;
  height: 100%;
  width: 100vw;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    left 0.25s,
    opacity 0.25s,
    visibility 0.25s;

  :global(.locale-he) & {
    left: auto;
    right: 0;
    transition:
      right 0.25s,
      opacity 0.25s,
      visibility 0.25s;
  }

  &.visible {
    left: 0;
    opacity: 1;
    visibility: visible;
    z-index: $filters-z-index;

    :global(.now-fresh) & {
      border-right: 1px solid $now-brown;
    }

    :global(.locale-he) & {
      left: auto;
      right: 0;
    }
  }

  @include at-breakpoint(medium) {
    left: -50vw;
    width: 50vw;
    opacity: 1;
    visibility: hidden;

    :global(.locale-he) & {
      left: auto;
      right: -50vw;
    }

    &.visible {
      left: 0;
      visibility: visible;
      z-index: $filters-z-index;

      :global(.locale-he) & {
        left: auto;
        right: 0;
      }
    }
  }

  @include at-breakpoint(large) {
    width: auto;
    position: sticky;
    top: 88px;
    margin-bottom: 1px;
    height: auto;
    max-height: calc(100vh - 120px);
    overflow: auto;
    z-index: 1;
    visibility: visible;
  }

  @include reducedMotion() {
    transition: none;
  }

  :global(.go-solutions) & {
    background-color: $color-white;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;

    @include at-breakpoint(large) {
      background-color: transparent;
    }
  }

  :global(.summit) & {
    background-color: $summit-white;
  }
}

.categories-container {
  max-height: calc(100% - 190px);
  overflow: auto;

  @include at-breakpoint(large) {
    max-height: none;
    overflow: visible;
  }
}

.interactive-headline-container {
  display: flex;
  padding: 37px 24px 52px;
  width: 100%;

  & > span {
    display: flex;
    align-items: center;
  }

  svg {
    position: absolute;
    transform: scale(0.6);

    :global(.locale-he) & {
      transform: scale(0.6) rotate(180deg);
    }
  }

  :global(.now-fresh) & {
    padding-bottom: 24px;
  }

  @include at-breakpoint(large) {
    display: none;
  }
}

.caret {
  pointer-events: none;
}

.interactive-headline {
  @include eyebrow();
  width: 100%;
  flex: 1 1 calc(100% - 100px);

  :global(.locale-ru) & {
    font-size: 10px;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-weight: 500;
  }

  :global(.summit) & {
    color: $summit-black;
  }
}

.clear-cta {
  display: none;
  min-width: 0;

  &.visible {
    display: inline-block;
    position: absolute;
    right: 18px;

    :global(.locale-he) & {
      right: auto;
      left: 18px;
    }

    @include at-breakpoint(large) {
      position: static;
      margin-top: 18px;

      :global(.summit) & {
        position: relative;
      }
    }

    // Added here to overwrite p3 font weights
    :global(.go-solutions) & {
      p {
        font-weight: 700;
      }
    }

    :global(.now-fresh) & {
      border-bottom: 1px solid $now-brown;

      &:hover {
        border-bottom: 3px solid $now-brown;
      }

      p {
        font-weight: 500;
        font-size: 16px;
      }
    }

    :global(.summit) & {
      color: $summit-black;
    }
  }

  :global(.go-solutions) & {
    p {
      @include p3();
      text-transform: capitalize;
      padding-bottom: 2px;
    }
  }

  :global(.now-fresh) & {
    margin-top: 2px;
    padding-bottom: 2px;
    border: none;
    color: $now-brown;

    @include at-breakpoint(large) {
      margin-top: 22px;
    }

    p {
      @include p3();
    }
  }

  :global(.locale-ru) & {
    margin-top: 0;
  }

  :global(.summit) & {
    &:after {
      background-image: $summit-dark-gradient;
    }

    p {
      @include p3();
      @include bold();
      padding-bottom: 2px;
    }
  }
}

.headline-container {
  display: none;

  @include at-breakpoint(large) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin: 10px 3px 0 0;

      :global(.locale-he) & {
        margin: 10px 0 0 3px;
      }
    }
  }

  :global(.now-fresh) & {
    height: 150px;
    padding: 40px;
  }
}

.headline {
  display: none;
  @include p2();

  @include at-breakpoint(large) {
    display: block;
    font-weight: 700;
    margin: 56px 0 44px;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-size: 36px;
    font-family: 'Stag', sans-serif !important;
    font-weight: 400;

    @include at-breakpoint(large) {
      margin-bottom: 41px;
    }
  }

  :global(.summit) & {
    @include h5();
    color: $summit-blue;
  }
}

.category-container {
  overflow: hidden;
  transition: height 0.25s;
  padding: 24px 16px 24px;

  @include at-breakpoint(large) {
    padding: 24px 5px 24px 3px;

    :global(.locale-he) & {
      padding: 24px 3px 24px 5px;
    }
  }

  @include reducedMotion() {
    transition: none;
  }

  :global(.go-solutions) & {
    border-bottom: 1px solid $go-filter-border;

    &:first-of-type {
      border-top: 1px solid $go-filter-border;
    }
  }

  :global(.now-fresh) & {
    padding: 24px 40px;

    &:first-of-type {
      border-top: none;
    }

    @include at-breakpoint(large) {
      padding: 0;
      &:first-of-type {
        border-top: 1px solid $now-brown;
      }

      border-bottom: 1px solid $now-brown;
    }
  }

  :global(.summit) & {
    border-bottom: 1px solid $summit-dark-desaturated-blue;

    &:first-of-type {
      border-top: 1px solid $summit-dark-desaturated-blue;
    }
  }
}

.category-title {
  @include eyebrow();
  width: 100%;

  :global(.summit) & {
    color: $summit-black;
  }

  span {
    display: flex;
    align-items: center;
    text-align: start;
    width: 100%;

    svg {
      transform: scale(0.5) rotate(-90deg);
      margin-inline-start: auto;
      pointer-events: none;
      transition: transform 0.25s;

      @include reducedMotion() {
        transition: none;
      }

      path {
        :global(.go-solutions) & {
          fill: $color-black;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.summit) & {
          fill: $summit-blue;
        }
      }
    }
  }

  .open & {
    svg {
      transform: scale(0.5) rotate(-270deg);
      margin-inline-start: auto;
      pointer-events: none;

      path {
        :global(.go-solutions) & {
          fill: $go-teal;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }
      }
    }
  }

  :global(.now-fresh) & {
    font-weight: 400;
    color: $now-brown;

    @include at-breakpoint(large) {
      padding: 20px 40px;
    }
  }
}

.filters {
  padding: 2px;
  visibility: hidden;
  transition: visibility 0.25s;
  margin-top: 32px;

  .open & {
    visibility: visible;
  }

  @include reducedMotion() {
    transition: none;
  }

  :global(.now-fresh) & {
    @include at-breakpoint(large) {
      margin-top: 0;
      background-color: $color-white;
      border-top: 1px solid $now-brown;
      border-bottom: 1px solid $now-brown;
      padding: 20px 40px;
    }
  }
}

.filter {
  @include p2();
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.filters-btn {
  @include eyebrow();
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  :global(.locale-he) & {
    left: auto;
    right: 0;
  }

  span {
    padding: 32px 66px;
  }

  @include at-breakpoint(large) {
    display: none;
  }

  :global(.go-solutions) & {
    background-color: $go-yellow;

    &:disabled {
      background-color: $go-light-grey;
      color: $color-black;
    }
  }

  :global(.now-fresh) & {
    font-weight: 500;
    color: $now-brown;
    background-color: $color-white;
    border-top: 1px solid $now-brown;

    &:disabled {
      background-color: $now-grey;
      color: $now-brown;
    }
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;

    &:disabled {
      background-color: $summit-grey;
      color: $summit-black;
    }
  }
}
