
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  overflow: hidden;
  position: relative;
  align-items: center;
  grid-auto-flow: dense;
  margin: 10px 0;

  @include at-breakpoint(medium) {
    margin: 50px 0;
  }

  @include at-breakpoint(large) {
    margin: 80px 0;
  }

  :global(.gather) & {
    background-color: $gather-cream;
    color: $gather-black;
    margin: 0;
    @include flex-container();
    justify-content: center;
    align-items: center;
    @include default-margin-bottom();

    @include at-breakpoint(medium) {
      height: 150px;
    }

    @include at-breakpoint(large) {
      height: 320px;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    color: $petcurean-white;
    margin: 0;
    padding: 0;
    @include default-margin-bottom();

    @include at-breakpoint(large) {
      max-width: 100%;
    }
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-blue;
  }
}

.hero-bg-container {
  :global(.gather) & {
    height: 100%;
    position: absolute;
    left: -100px;

    @include at-breakpoint(medium) {
      left: 0;
    }

    @include at-breakpoint(large) {
      left: -150px;
    }
  }

  :global(.summit) & {
    height: 100%;
    position: absolute;
    left: -40px;

    @include at-breakpoint(medium) {
      position: relative;
      grid-column: 1 / span 2;
    }
    @include at-breakpoint(large) {
      grid-column: 1;
      position: absolute;
      width: auto;
    }
  }

  :global(.petcurean) & {
    height: 100%;
    position: absolute;
    left: -100px;

    @include at-breakpoint(medium) {
      left: 0;
    }

    @include at-breakpoint(large) {
      left: -130px;
    }
  }
}

.hero-bg {
  height: 100%;
  width: 100%;

  :global(.petcurean) & {
    height: 150%;
  }
}

.hero-title {
  z-index: 1;
  grid-column: 3 / span 4;
  display: flex;
  text-align: right;
  justify-content: center;
  position: relative;

  @include at-breakpoint(large) {
    grid-column: 6 / span 7;
  }

  :global(.gather) & {
    @include h1();
    @include line-heights(44px, 44px, 92px);
  }

  :global(.petcurean) & {
    @include font-sizes(44px, 44px, 101px);
    @include line-heights(44px, 44px, 92px);
    font-weight: 900;
    align-self: center;
    margin-inline-end: 40px;

    @include at-breakpoint(large) {
      grid-column: 5 / span 8;
    }

    @include at-breakpoint(x-large) {
      grid-column: 6 / span 6;
    }
  }
}

.accent-image {
  :global(.petcurean) & {
    transform: rotate(-70deg);
    position: absolute;
    right: -80px;
    top: -40px;
    width: 30%;
    height: 100%;

    @include at-breakpoint(medium) {
      transform: rotate(360deg);
      right: -80px;
      width: 20%;
      top: -20px;
    }

    @include at-breakpoint(large) {
      width: 30%;
      transform: rotate(-70deg);
      right: -200px;
      top: -50px;
    }

    @include at-breakpoint(x-large) {
      transform: rotate(160deg);
      right: -250px;
      top: -50px;
      width: 30%;
    }
  }
}
