
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  position: relative;
  align-items: flex-end;
  padding: 24px 25px 11px 0;
  margin: 0 16px;
  color: $color-white;

  @include at-breakpoint(medium) {
    padding: 0 46px 17px 48px;
    margin: 0;
  }

  @include at-breakpoint(large) {
    padding: 0 90px 17px 64px;
  }

  &.has-animation {
    @include willAnimateIn();
    :global(.now-fresh) & {
      @include willAnimateInNowFresh();
    }

    &.anim-in {
      @include animateIn();
    }
  }

  :global(.go-solutions) & {
    background: $go-charcoal;
    margin-inline-start: 0;
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    outline: 1px solid $now-brown;
    color: $now-brown;
    padding: 0;
    align-items: center;
    flex-direction: column;
    margin: 0;

    @include at-breakpoint(medium) {
      flex-direction: row;
    }
  }
}

.image {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  max-width: 120px;

  img {
    display: block;
  }

  @include at-breakpoint(medium) {
    max-width: 200px;
  }

  :global(.go-solutions) & {
    @include at-breakpoint(medium) {
      margin-top: -49px;
    }
  }

  :global(.now-fresh) & {
    position: static;
    width: 100%;
    max-width: none;

    @include at-breakpoint(medium) {
      width: auto;
      border-right: 1px solid $now-brown;

      :global(.locale-he) & {
        border-right: none;
        border-left: 1px solid $now-brown;
      }
    }

    img {
      display: flex;
      position: static;
      width: 100%;
      object-fit: cover;
    }
  }
}

.content {
  position: relative;
  padding: 0 0 40px 16px;
  z-index: 1;

  @include at-breakpoint(medium) {
    padding: 30px 0 57px 30px;
  }

  @include at-breakpoint(large) {
    padding: 30px 0 65px 45px;
  }

  :global(.now-fresh) & {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px;
    border-top: 1px solid $now-brown;

    @include at-breakpoint(medium) {
      border-top: none;
    }

    @include at-breakpoint(x-large) {
      padding: 0 24px 0 60px;
    }
  }
}

.headline {
  @include p2();

  @include at-breakpoint(medium) {
    @include p1();
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }
}

.cta {
  margin-top: 14px;

  @include at-breakpoint(medium) {
    margin-top: 20px;
  }

  :global(.go-solutions) & {
    text-transform: none;
    letter-spacing: 0;

    @include at-breakpoint(large) {
      margin-top: 24px;
    }
  }

  :global(.now-fresh) & {
    width: fit-content;
    p {
      font-weight: normal;
    }
  }
}
