
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;

  :global(.gather) &,
  :global(.petcurean) & {
    border-radius: 20px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
