
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include at-breakpoint(large) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
}

:global(.gather) {
  .container {
    background-color: $gather-cream;
  }
}

:global(.now-fresh) {
  .container {
    background-color: $now-tan;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
  }
}

:global(.summit) {
  .container {
    background-color: $summit-white;
  }
}
