
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  outline: 1px solid $now-brown;
  margin-bottom: 1px;
  max-width: none !important;
  column-gap: 0 !important;
  width: auto;
  padding: 0 !important;
  color: $now-brown;
}

.top-wrapper {
  display: flex;
  flex-direction: column;
  padding: 40px;
  grid-column: span 6;
  border-bottom: 1px solid $now-brown;

  @include at-breakpoint(medium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include at-breakpoint(large) {
    grid-column: span 12;
    padding: 40px 80px;
  }

  @include at-breakpoint(xlarge) {
    padding: 40px 100px;
  }
}

.eyebrow {
  @include eyebrow-box();
  margin-bottom: 12px;
  text-align: center;
}

.title {
  @include h3();
}

.description {
  padding-top: 20px;
  @include at-breakpoint(medium) {
    padding: 0 80px;
  }
}

.prod-cta {
  min-width: 236px;
  width: fit-content;
  display: inline-block;
  margin-top: 24px;

  @include at-breakpoint(medium) {
    margin-top: 0;
  }
}

.product-wrapper {
  grid-column: span 6;
  background-color: $color-white;
  border-bottom: 1px solid $now-brown;

  &:last-child {
    border-right: none;
    border-bottom: none;
  }

  @include at-breakpoint(medium) {
    span {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
    }
    gap: 0;
    grid-auto-flow: dense;

    &.right-align {
      .img-container {
        grid-column: 4 / span 3;
        border-right: none;
        border-left: 1px solid $now-brown;
      }

      .info-container {
        grid-column: 1 / span 3;
      }
    }

    :global(.locale-he) & {
      &.right-align {
        .img-container {
          grid-column: 4 / span 3;
          border-right: 1px solid $now-brown;
          border-left: none;
        }

        .info-container {
          grid-column: 1 / span 3;
        }
      }
    }
  }

  @include at-breakpoint(large) {
    border-bottom: none;
    border-right: 1px solid $now-brown;
    grid-column: span 4;

    span {
      display: block;
    }

    &:nth-of-type(3):not(:last-child) {
      border-right: none;
    }

    &.right-align {
      .img-container {
        border-left: none;
      }
    }

    :global(.locale-he) & {
      border-right: none;
      border-left: 1px solid $now-brown;

      &:last-child {
        border-left: none;
      }

      &.right-align {
        .img-container {
          border-right: none;
        }
      }
    }
  }
}

.img-container {
  width: 100%;
  height: 530px;
  transition: opacity 400ms ease-in;
  border-bottom: none;
  padding: 0;

  @include at-breakpoint(medium) {
    width: auto;
    grid-column: 1 / span 3;
    border-right: 1px solid $now-brown;

    :global(.locale-he) & {
      border-right: none;
      border-left: 1px solid $now-brown;
    }
  }

  @include at-breakpoint(large) {
    border-right: none;
    width: 100%;

    :global(.locale-he) & {
      border-left: none;
    }
  }

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
}

.anim-in {
  opacity: 1;
}

.info-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include at-breakpoint(medium) {
    width: 100%;
    grid-column: 4 / span 3;
    grid-row: 1;
    height: 100%;
    justify-content: center;
  }

  @include at-breakpoint(large) {
    width: 100%;
    justify-content: flex-start;
  }
}

.prod-rating {
  margin-bottom: 8px;
  transition:
    transform 0.5s,
    opacity 0.5s;
  display: flex;
  align-items: center;
}

.rating {
  height: 12px;

  span {
    width: 12px;
    margin: 0 4px;
  }

  svg {
    path {
      fill: $now-brown;
    }
  }

  @include at-breakpoint(medium) {
    height: 20px;

    span {
      width: 20px;
      margin: 0 7px;
    }
  }

  @include at-breakpoint(large) {
    height: 12px;

    span {
      width: 12px;
      margin: 0 4px;
    }
  }
}

.prod-eyebrow {
  margin-bottom: 8px;
  p {
    @include eyebrow();
  }
}

.prod-title {
  margin-bottom: 8px;
  h3 {
    @include p1();
    text-align: center;
  }
}

.styled-link {
  display: inline-block !important;
}
