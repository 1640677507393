
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  height: 48px;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  padding: 2px 16px 0 16px;
  overflow: hidden;
  transition: height 0.25s;

  @include reducedMotion() {
    transition: none;
  }

  @include at-breakpoint(medium) {
    padding: 2px 34px 0 39px;
  }

  @include at-breakpoint(large) {
    flex-direction: column;
    margin: auto auto 12px;
    max-width: $max-width;
    padding: 0 32px;
  }

  &.grid {
    @include at-breakpoint(large) {
      @include grid-container;

      margin-bottom: 32px;

      > * {
        grid-column-start: 2;
        grid-column-end: 7;
        padding-inline-start: 32px;
        padding-inline-end: 6px;

        @include at-breakpoint(x-large) {
          padding-inline-end: 32px;
        }
      }
    }
  }

  &:before {
    content: '';
    visibility: hidden;
    opacity: 0;
    position: fixed;
    background-color: $color-black;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: $over-nav-z-index;
    transition:
      visibility 0.25s,
      opacity 0.25s;

    @include at-breakpoint(large) {
      content: none;
    }
  }

  :global(.gather) & {
    @include at-breakpoint(large) {
      padding: 0;
    }
  }
}

.filters-open {
  &:before {
    visibility: visible;
    opacity: 0.5;
  }
}

.open-filters-btn {
  @include p2();
  @include bold();
  display: flex;
  align-items: center;

  @include at-breakpoint(large) {
    padding: 10px 0 30px 0;
  }

  svg {
    margin-inline-start: 6px;

    path {
      transform-origin: center;
      transition: transform 0.25s ease;

      @include reducedMotion() {
        transition: none;
      }

      :global(.gather) & {
        fill: $gather-black;
      }

      :global(.go-solutions) & {
        fill: $color-black;
      }

      :global(.now-fresh) & {
        fill: $now-brown;
      }

      :global(.summit) & {
        fill: $summit-black;
      }
    }
  }

  :global(.gather) & {
    color: $gather-black;
  }

  :global(.go-solutions) & {
    color: $color-black;
  }

  :global(.now-fresh) & {
    font-weight: 500;
    color: $now-brown;
  }

  :global(.summit) & {
    color: $summit-black;
  }

  &.open {
    svg {
      path:first-child {
        transform: rotate(-90deg);
      }
    }
  }
}

.clear-cta {
  display: none;
  min-width: 0;

  &.visible {
    display: inline-block;
    position: absolute;
    right: 18px;

    @include at-breakpoint(large) {
      position: static;
      margin-top: 18px;
    }

    &:after {
      :global(.gather) &,
      :global(.summit) & {
        display: inline-block;

        @include at-breakpoint(large) {
          display: none;
        }
      }
    }

    // Added here to overwrite p3 font weights
    :global(.go-solutions) & {
      p {
        font-weight: 700;
      }
    }

    :global(.now-fresh) & {
      p {
        font-weight: 500;
      }
    }
  }

  :global(.gather) & {
    border-color: $gather-dark-green;
    padding-bottom: 2px;
    p {
      @include p3();
      @include bold();
      text-transform: capitalize;
      color: $gather-black;
    }
  }

  :global(.go-solutions) & {
    p {
      @include p3();
      text-transform: capitalize;
      padding-bottom: 2px;
    }
  }

  :global(.now-fresh) & {
    margin-top: 2px;
    padding-bottom: 2px;
    border-color: $now-light-tan;

    @include at-breakpoint(large) {
      margin-top: 22px;
    }

    p {
      @include p3();
    }
  }

  :global(.summit) & {
    border-color: $summit-dark-blue;
    padding-bottom: 2px;
    p {
      @include p3();
      @include bold();
      color: $summit-black;
    }
  }
}

.headline-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include at-breakpoint(large) {
    flex: 1 0 auto;
  }

  .clear-cta.visible {
    display: none;

    @include at-breakpoint(large) {
      display: inline-block;
      margin-top: 10px;
    }
  }
}

.filters-container {
  position: fixed;
  height: 100%;
  width: 100vw;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    left 0.25s,
    opacity 0.25s,
    visibility 0.25s;

  &.visible {
    left: 0;
    opacity: 1;
    visibility: visible;
    z-index: $filters-z-index;

    :global(.gather) & {
      background-color: $gather-cream;
    }

    :global(.summit) & {
      background-color: $summit-white;
    }
  }

  @include at-breakpoint(medium) {
    left: -50vw;
    width: 50vw;
    opacity: 1;

    &.visible {
      left: 0;
    }
  }

  @include at-breakpoint(large) {
    position: static;
    height: auto;
    width: 100%;
    overflow: visible;
    z-index: 1;
    visibility: visible;

    &.visible {
      z-index: 1;
    }
  }

  @include reducedMotion() {
    transition: none;
  }

  :global(.go-solutions) & {
    background-color: $color-white;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;

    @include at-breakpoint(large) {
      background-color: transparent;
    }
  }
}

.categories-container {
  width: 100%;
}

.interactive-headline-container {
  display: flex;
  padding: 37px 24px 52px;
  width: 100%;

  & > span {
    display: flex;
    align-items: center;
  }

  svg {
    position: absolute;
    transform: scale(0.6);
  }

  @include at-breakpoint(large) {
    display: none;
  }
}

.caret {
  pointer-events: none;
}

.interactive-headline {
  @include eyebrow();
  width: 100%;
  flex: 1 1 calc(100% - 100px);

  :global(.gather) & {
    color: $gather-black;
  }

  :global(.go-solutions) & {
    color: $color-black;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-weight: 500;
  }

  :global(.summit) & {
    color: $summit-black;
  }
}

.category-container {
  padding: 24px 16px 24px;

  @include at-breakpoint(large) {
    padding: 0px 5px 14px 3px;
  }
}

.filters {
  padding: 2px;
  visibility: hidden;
  transition: visibility 0.25s;

  &.open {
    visibility: visible;
  }

  @include reducedMotion() {
    transition: none;
  }
}

.filter {
  @include p2();
  margin-bottom: 16px;
  width: 100%;

  :global(.gather) &,
  :global(.summit) & {
    @include bold();
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.filters-btn {
  @include eyebrow();
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  span {
    padding: 32px 66px;
  }

  @include at-breakpoint(large) {
    display: none;
  }

  :global(.gather) & {
    background-color: $gather-green;
    color: $gather-white;

    &:disabled {
      background-color: $gather-opaque-green;
      color: $gather-black;
    }
  }

  :global(.go-solutions) & {
    background-color: $go-yellow;
    color: $color-black;

    &:disabled {
      background-color: $go-light-grey;
      color: $color-black;
    }
  }

  :global(.now-fresh) & {
    font-weight: 500;
    color: $now-tan;
    background-color: $now-brown;

    &:disabled {
      background-color: $now-grey;
      color: $now-brown;
    }
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;

    &:disabled {
      background-color: $summit-grey;
      color: $summit-black;
    }
  }
}

.cta {
  align-items: center;
  border-bottom: 4px solid transparent;
  display: flex;
  padding-bottom: 18px;
  transition: border-color 0.25s;
}

.icons {
  display: flex;
  height: 46px;
  margin-inline-start: auto;

  @include at-breakpoint(large) {
    display: none;
  }

  button {
    &:focus {
      outline: none;
      box-shadow: 0 0 2px 2px $outline;
    }
  }
}

.map-icon-cta {
  margin-inline-end: 23px;
}

:global(.gather) {
  .cta {
    &.active {
      border-color: $gather-green;

      & path {
        fill: $gather-green;
      }
    }
  }
}

:global(.go-solutions) {
  .cta {
    &.active {
      border-color: $go-teal;

      & path {
        fill: $go-teal;
      }
    }
  }
}

:global(.now-fresh) {
  .cta {
    padding-bottom: 14px;

    & path {
      fill: $now-brown;
    }

    &.active {
      border-color: $now-grey;

      & path {
        fill: $now-grey;
      }
    }
  }
}

:global(.summit) {
  .cta {
    &.active {
      border-color: $summit-blue;

      & path {
        fill: $summit-blue;
      }
    }
  }
}
