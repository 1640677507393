
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.promoted {
  display: flex;
  align-items: center;
  grid-column: span 12;
  margin-top: 48px;
  border-top: 1px solid $color-white;
  padding-top: 38px;

  @include at-breakpoint(large) {
    margin-inline-start: auto;
    grid-column: span 5;
    margin-top: 0;
    border-top: none;
    padding-top: 0;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 4;
  }
}

.promoted-image {
  display: flex;
  object-fit: cover;
  min-width: 103px;
  width: 103px;
  height: 103px;

  img {
    width: 100%;
    height: 100%;
  }

  @include at-breakpoint(large) {
    min-width: 140px;
    width: 140px;
    height: 140px;
  }
}

.promoted-text-container {
  width: 100%;
}

.promoted-header {
  display: block;
  @include eyebrow();
  color: $go-teal;
  margin: 9px 0 17px;
}

.promoted-copy {
  @include p2();
  position: relative;
  width: calc(100% - 34px);
  display: inline-block;

  svg {
    margin-inline-start: 10px;
    position: absolute;
    bottom: -2px;
  }
}

/* Gather Styles */
:global(.gather) {
  .promoted {
    gap: 40px;
    align-items: flex-start;
    padding: 40px 0 20px 0;

    @include at-breakpoint(large) {
      padding: 0;
    }

    @include at-breakpoint(x-large) {
      margin-inline-start: 0;
      grid-column: span 5;
    }
  }

  .promoted-image {
    border-radius: 50%;
    border: 4px solid $gather-light-sapling-green;
    overflow: hidden;
  }

  .promoted-header {
    @include eyebrow();
    color: $gather-white;
    margin-bottom: 7px;
  }

  .promoted-copy {
    font-weight: 400;
    margin-bottom: 24px;
    line-height: 1.69;

    svg {
      left: 0;
      margin: 0;
      bottom: -24px;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          path {
            fill: $gather-dark-green;
          }
        }
      }
    }
  }
}
/* End Gather Styles */

/* Go Solutions Styles */
:global(.go-solutions) {
  .promoted-image {
    margin-inline-end: 26px;
    border-radius: 97px;
    border: 4px solid $color-white;
    overflow: hidden;

    @include at-breakpoint(large) {
      border-radius: 150px;
      margin-inline-end: 40px;
    }
  }
}

/* Now Fresh Styles */
:global(.now-fresh) {
  .promoted {
    border-top: 1px solid $now-brown;

    @include at-breakpoint(large) {
      border-top: none;
    }
  }

  .promoted-image {
    margin-inline-end: 40px;
    border-radius: 12px;
    border: 4px solid $now-dark-tan;
    overflow: hidden;
  }

  .promoted-header {
    color: $now-brown;
    font-size: 16px;
    line-height: 16px;

    @include at-breakpoint(large) {
      color: $now-brown;
    }
  }

  .promoted-copy {
    color: $now-brown;
    @include bold();
    @include p2();

    @include at-breakpoint(large) {
      color: $now-brown;
    }

    a,
    span {
      font-size: 18px;
      line-height: 18px;
      font-family: Almarai, sans-serif;
    }

    svg {
      path {
        fill: $now-brown;
      }
    }

    @media (hover: hover) {
      &:hover {
        svg {
          path {
            fill: $now-brown;
          }
        }
        text-decoration: underline;
      }
    }
  }
}
/* End Now Fresh Styles */

/* Petcurean Styles */
:global(.petcurean) {
  .promoted {
    gap: 40px;
    align-items: center;
    padding: 40px 0 20px 0;

    @include at-breakpoint(large) {
      padding: 0;
    }

    @include at-breakpoint(x-large) {
      margin-inline-start: 0;
      grid-column: span 5;
    }
  }

  .promoted-image {
    border-radius: 50%;
    border: 4px solid $petcurean-light-tan;
    overflow: hidden;
  }

  .promoted-header {
    color: $petcurean-white;
    margin-bottom: 7px;
  }

  .promoted-copy {
    font-weight: 600;
    margin-bottom: 24px;

    svg {
      left: 0;
      margin: 0;
      bottom: -24px;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          path {
            fill: $petcurean-dark-tan;
          }
        }
      }
    }
  }
}
/* End Petcurean Styles */

/* Summit Styles */
:global(.summit) {
  .promoted {
    gap: 40px;
    align-items: flex-start;
    padding: 40px 0 20px 0;

    @include at-breakpoint(large) {
      padding: 0;
    }

    @include at-breakpoint(x-large) {
      margin-inline-start: 0;
      grid-column: span 5;
    }
  }

  .promoted-image {
    border-radius: 50%;
    border: 4px solid $summit-desaturated-blue;
    overflow: hidden;
  }

  .promoted-header {
    @include eyebrow();
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: $summit-white;
    margin-bottom: 7px;
  }

  .promoted-copy {
    font-weight: 400;
    margin-bottom: 24px;
    line-height: 1.69;

    svg {
      left: 0;
      margin: 0;
      bottom: -24px;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          path {
            fill: $summit-light-desaturated-blue;
          }
        }
      }
    }
  }
}
/* End Summit Styles */
