
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding: 24px 36px 16px 36px;
  text-align: center;

  @include at-breakpoint(large) {
    padding-top: 42px;
    padding-bottom: 32px;
  }

  :global(.gather) & {
    background-color: $gather-green;
    color: $gather-white;
  }

  :global(.go-solutions) & {
    color: $color-white;
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    padding: 64px;
    border-bottom: 1px solid $now-brown;
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;
  }

  &.has-background {
    :global(.now-fresh) & {
      color: $now-brown;
      background-color: $now-tan;
    }
  }

  &:not(.has-background) {
    padding-top: 0;

    .description {
      margin-bottom: 0;

      & + .tab-header {
        margin-top: 40px;
      }
    }

    :global(.now-fresh) & {
      .tab-item {
        .tab-button {
          color: $now-brown;
        }

        &:hover {
          & .tab-button {
            color: $now-light-tan;
          }
        }
      }
    }
  }
}

.logo {
  margin-bottom: 16px;

  @include at-breakpoint(x-large) {
    margin-bottom: 24px;
  }

  img {
    max-width: 100px;
  }

  & + .title {
    margin-top: 0;
  }
}

.title {
  :global(.locale-ru) & {
    text-transform: uppercase;
  }

  @include at-breakpoint(large) {
    margin-bottom: 16px;
  }

  :global(.now-fresh) & {
    @include font-sizes(30px, 48px, 48px);
    margin-bottom: 24px;

    @include at-breakpoint(large) {
      margin-bottom: 16px;
    }
  }
}

.description {
  line-height: 1.5;
  margin: 0 auto 42px;
  max-width: 303px;

  :global(.gather) &,
  :global(.summit) & {
    @include p2();
  }

  :global(.now-fresh) & {
    font-size: 16px;
    margin-bottom: 0;
  }

  @include at-breakpoint(medium) {
    max-width: 432px;
  }

  @include at-breakpoint(large) {
    font-size: 16px;
    max-width: 600px;
  }
}

.tab-header {
  align-items: center;
  display: flex;
  justify-content: center;
}

.tab-item {
  margin: 0 12px;
  position: relative;

  @include at-breakpoint(large) {
    margin: 0 15px;
  }

  & .tab-button {
    @include bold();

    span {
      padding: 0 8px 13px;

      :global(.gather) &,
      :global(.summit) & {
        padding: 20px 23px;
      }
    }
  }

  @include active-indicator();

  &.active,
  &:hover {
    &:after {
      opacity: 1;
    }

    & .tab-button {
      :global(.gather) & {
        background-color: $gather-dark-green;
      }

      :global(.go-solutions) & {
        color: $color-white;
      }

      :global(.now-fresh) & {
        color: $now-tan;
      }

      :global(.summit) & {
        background-color: $summit-dark-blue;
      }
    }
  }
}

.tab-button {
  color: rgba($color-white, 0.8);

  :global(.gather) & {
    color: $gather-white;
  }

  :global(.summit) & {
    color: $summit-white;
  }
}
