
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;

  :global(.gather) & {
    color: $gather-white;

    @include at-breakpoint(large) {
      color: $petcurean-grey;
    }
  }

  :global(.go-solutions) & {
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;

    @include at-breakpoint(large) {
      outline: 1px solid $now-brown;
    }
  }

  :global(.petcurean) & {
    color: $petcurean-white;

    @include at-breakpoint(large) {
      color: $petcurean-grey;
    }
  }

  :global(.summit) & {
    color: $summit-white;

    @include at-breakpoint(large) {
      color: $petcurean-grey;
    }
  }

  &.desktop-only {
    display: none;

    @include at-breakpoint(large) {
      display: flex;
    }
  }

  &.show-container {
    .content-wrapper {
      @include at-breakpoint(large) {
        @include flex-container();
        justify-content: flex-end;
        padding: 1px 0;
      }
    }

    .dropdown-wrapper {
      margin-inline-end: -15px;
    }

    :global(.gather) & {
      background-color: $color-light-grey;
    }

    :global(.go-solutions) & {
      background-color: $go-charcoal;
    }

    :global(.petcurean) & {
      background-color: $color-light-grey;
    }

    :global(.summit) & {
      background-color: $color-light-grey;
    }
  }
}

.dropdown-wrapper {
  display: inline-block;
}

.dropdown {
  :global(.gather) & {
    @include p3();
    svg path {
      fill: $gather-white;

      @include at-breakpoint(large) {
        fill: $petcurean-grey;
      }
    }

    [class^='styledSelect_wrapper']::after {
      @include at-breakpoint(large) {
        border-bottom-color: $petcurean-grey;
        border-left-color: $petcurean-grey;
      }
    }
  }

  :global(.now-fresh) & {
    @include p3();
    @include bold();
    line-height: 1.33333;

    [class^='styledSelect_wrapper'] {
      border: none;
    }

    svg {
      path {
        fill: $now-brown;
      }
    }
  }

  :global(.petcurean) & {
    @include p3();
    svg path {
      fill: $petcurean-white;

      @include at-breakpoint(large) {
        fill: $petcurean-grey;
      }
    }

    [class^='styledSelect_wrapper']::after {
      @include at-breakpoint(large) {
        border-bottom-color: $petcurean-grey;
        border-left-color: $petcurean-grey;
      }
    }
  }

  :global(.summit) & {
    @include p3();
    svg path {
      fill: $summit-white;

      @include at-breakpoint(large) {
        fill: $petcurean-grey;
      }
    }

    [class^='styledSelect_wrapper']::after {
      @include at-breakpoint(large) {
        border-bottom-color: $petcurean-grey;
        border-left-color: $petcurean-grey;
      }
    }
  }
}
