
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include page-container();

  :global(.gather) & {
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;

    @include at-breakpoint(large) {
      margin-bottom: 0;
    }
  }
}
