
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  padding-top: 195px;
  color: $color-white;

  @include at-breakpoint(medium) {
    padding-top: 384px;
  }

  @include at-breakpoint(large) {
    padding-top: 160px;
  }

  &.margin-bottom {
    @include default-margin-bottom();

    :global(.go-solutions) & {
      margin-bottom: 0;

      .content-wrapper {
        @include default-padding-bottom();
      }
    }
  }

  :global(.go-solutions) & {
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 195px;
      left: 0;
      right: 0;
      height: 350px;
      background-image: linear-gradient(0deg, $color-black 53%, rgba(0, 0, 0, 0) 92%);
      z-index: 1;

      @include at-breakpoint(medium) {
        top: 376px;
        height: 456px;
      }
    }

    @include at-breakpoint(medium) {
      min-height: 0;
    }

    @include at-breakpoint(large) {
      min-height: 500px;
      overflow: visible;

      &::before {
        display: none;
      }
    }

    @include at-breakpoint(x-large) {
      min-height: 36vw;
    }

    @media (min-width: 1800px) {
      min-height: 655px;
    }
  }

  :global(.now-fresh) & {
    padding-top: 0;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    max-width: none;

    @include at-breakpoint(medium) {
      @media (orientation: portrait) {
        height: calc(100vh - 88px);
      }
    }

    @include at-breakpoint(large) {
      height: calc(100vh - 88px);
    }

    @include at-breakpoint(x-large) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    }
  }

  :global(.petcurean) & {
    padding-top: 0;

    @include at-breakpoint(small) {
      margin-bottom: 56px;
    }

    @include at-breakpoint(medium) {
      margin-bottom: 75px;
    }

    @include at-breakpoint(large) {
      margin-bottom: 104px;
    }
  }

  :global(.summit) & {
    overflow: hidden;
    padding-top: 40px;

    @include at-breakpoint(medium) {
      padding-top: 65px;
    }

    @include at-breakpoint(large) {
      padding-top: 105px;
    }
  }
}

.background {
  display: none;

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    bottom: 89px;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    @include at-breakpoint(medium) {
      bottom: 290px;
    }

    @include at-breakpoint(large) {
      bottom: 90px;
    }

    @include at-breakpoint(x-large) {
      bottom: 137px;
    }
  }
}

.content-wrapper {
  @include grid-container();
  position: relative;
  padding-top: 61px;
  padding-bottom: 72px;
  z-index: 1;

  @include at-breakpoint(medium) {
    padding-top: 0;
    padding-bottom: 120px;
  }

  @include at-breakpoint(x-large) {
    padding-bottom: 203px;
  }

  :global(.now-fresh) & {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    background-color: $color-white;
    border-bottom: 1px solid $now-brown;

    @include at-breakpoint(medium) {
      padding: 40px;
    }

    @include at-breakpoint(x-large) {
      max-height: none;
      padding: 60px;
      border-bottom: none;
      border-right: 1px solid $now-brown;
      grid-column: span 6;
    }
  }

  :global(.petcurean) & {
    padding-bottom: 24px;
    padding-top: 64px;

    @include at-breakpoint(medium) {
      padding-bottom: 38px;
      padding-top: 48px;
    }

    @include at-breakpoint(large) {
      grid-template-rows: min-content;
      padding-bottom: 198px;
      padding-top: 123px;
      min-height: 550px;
      z-index: 2;
    }

    @include at-breakpoint(x-large) {
      padding-bottom: 208px;
      padding-top: 115px;
      min-height: 400px;
    }
  }

  :global(.summit) & {
    padding-top: 0;
    padding-bottom: 30px;

    @include at-breakpoint(medium) {
      padding-bottom: 55px;
    }

    @include at-breakpoint(large) {
      padding-bottom: 105px;
    }
  }
}

.adjustContentToMiddle {
  :global(.petcurean) & {
    @include at-breakpoint(large) {
      grid-template-rows: auto;

      .headline {
        display: flex;
        align-items: center;
      }

      .subhead {
        margin-top: 0;
      }
    }
  }
}

.headline {
  @include font-sizes(35px, 68px, 68px);
  @include line-heights(1.2, 1.044, 1.044);
  grid-column: span 6;
  max-width: 375px;
  margin: 0;

  @include at-breakpoint(medium) {
    width: 648px;
    grid-column: 1 / -1;
    max-width: none;
  }

  @include at-breakpoint(large) {
    width: 590px;
  }

  @include at-breakpoint(x-large) {
    font-size: 101px;
    line-height: 0.9505;
    width: 1028px;
  }

  :global(.now-fresh) & {
    @include font-sizes(38px, 38px, 66px);
    @include line-heights(1.037, 1.037, 0.9697, 1);
    grid-column: span 5;
    max-width: none;
    color: $now-brown;

    :global(.locale-ru) & {
      @include at-breakpoint(x-large) {
        font-size: 75px;
      }
    }

    @include at-breakpoint(large) {
      width: auto;
    }

    :global(.locale-zh) & {
      @include at-breakpoint(large) {
        grid-column: 1 / 7;
        width: 100%;
      }

      @include at-breakpoint(x-large) {
        grid-column: 1 / -1;
        width: 660px;
      }
    }
  }

  :global(.petcurean) & {
    @include font-sizes(33px, 52px, 92px, 92px);
    line-height: 1;
    grid-column: span 5;
    max-width: none;

    :global(.locale-zh) & {
      line-height: 1;
    }

    @include at-breakpoint(small) {
      font-weight: 900;
      grid-column: 1 / -2;
    }

    @include at-breakpoint(medium) {
      font-weight: 900;
      grid-column: 1 / -1;
      width: 604px;
    }

    @include at-breakpoint(large) {
      grid-column: 1 / 6;
      width: 100%;
    }

    @include at-breakpoint(x-large) {
      grid-column: 1/ 6;
      min-width: 485px;
    }
  }

  :global(.go-solutions) & {
    :global(.locale-zh) & {
      @include h1();
      @include at-breakpoint(x-large) {
        width: 70%;
      }
    }
  }

  :global(.summit) & {
    @include h1();
    color: $summit-blue;
    max-width: none;

    @include at-breakpoint(medium) {
      width: auto;
      font-size: 101px;
      line-height: 0.95;
    }

    @include at-breakpoint(large) {
      grid-column: 1 / 9;
      width: auto;
    }

    @include at-breakpoint(x-large) {
      grid-column: 1 / 7;
      width: auto;
    }
  }
}

.subhead {
  a {
    text-decoration: underline;

    svg {
      display: none;
    }
  }

  @include p2();
  margin-top: 8px;
  grid-column: span 6;
  max-width: 375px;

  @include at-breakpoint(medium) {
    @include p1();
    grid-column: span 5;
    max-width: none;
    margin-top: 16px;
  }

  @include at-breakpoint(large) {
    grid-column: span 7;
    margin-top: 24px;
  }

  :global(.now-fresh) & {
    margin-top: 13px;
    max-width: none;

    p,
    a {
      @include font-sizes(16px, 18px, 18px);
      color: $now-brown;
      @include at-breakpoint(medium) {
        margin-top: 16px;
      }

      @include at-breakpoint(large) {
        margin-top: 18px;
        grid-column: span 6;
      }

      @include at-breakpoint(x-large) {
        margin-top: 24px;
      }

      :global(.locale-zh) & {
        @include at-breakpoint(large) {
          grid-column: 1 / 7;
          width: 100%;
        }

        @include at-breakpoint(x-large) {
          grid-column: 1 / -1;
        }
      }
    }
  }

  :global(.petcurean) & {
    margin-top: 40px;

    @include at-breakpoint(large) {
      grid-column: 1 / 6;
    }
  }

  :global(.go-solutions) & {
    p,
    a {
      color: $color-white;
    }
    :global(.locale-zh) & {
      @include at-breakpoint(x-large) {
        grid-column: 1 / -1;
        width: 70%;
      }
    }
  }

  :global(.summit) & {
    grid-column: span 6;
    color: $summit-black;
    max-width: none;
    margin-top: 0;

    @include at-breakpoint(medium) {
      @include p1();
    }

    @include at-breakpoint(large) {
      grid-column: 1 / 8;
    }

    @include at-breakpoint(x-large) {
      grid-column: 1 / 6;
    }
  }
}

.ctas {
  margin-top: 24px;
  grid-column: span 6;

  @include at-breakpoint(medium) {
    margin-top: 38px;
  }

  @include at-breakpoint(large) {
    grid-column: span 8;
    margin-top: 32px;
  }

  @include at-breakpoint(x-large) {
    margin-top: 56px;
  }

  :global(.now-fresh) & {
    margin-top: 32px;

    @include at-breakpoint(medium) {
      margin-top: 42px;
    }

    @include at-breakpoint(large) {
      margin-top: 32px;
    }

    @include at-breakpoint(x-large) {
      margin-top: 40px;
    }
  }

  :global(.petcurean) & {
    margin-top: 25px;

    @include at-breakpoint(large) {
      margin-top: 40px;
    }
  }

  :global(.summit) & {
    margin-top: 4px;

    @include at-breakpoint(medium) {
      margin-top: 24px;
    }
  }
}

.cta {
  display: inline-block;
  margin: 8px 16px 8px 0;

  :global(.locale-he) & {
    margin: 8px 0 8px 16px;
  }

  span {
    font-size: 16px;

    :global(.go-solutions) & {
      :global(.locale-ru) & {
        font-size: 11px;
      }
    }

    :global(.petcurean) & {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  @include at-breakpoint(medium) {
    margin: 12px 24px 12px 0;

    :global(.locale-he) & {
      margin: 12px 0 12px 24px;
    }
  }

  &:last-of-type {
    margin-inline-end: 0;
  }

  :global(.petcurean) & {
    margin: 0 16px 0 0;

    :global(.locale-he) & {
      margin: 0 0 0 16px;
    }
  }
}

.bg-image {
  picture {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  :global(.go-solutions) & {
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      display: block;
      width: 272px;
    }

    @include at-breakpoint(medium) {
      top: 0;
      right: 24px;

      img {
        width: 434px;
      }
    }

    @include at-breakpoint(large) {
      top: 80px;
      right: 0;
      transition: opacity 1s ease-out;

      &.anim-out {
        opacity: 0;
      }

      img {
        width: 424px;
      }
    }

    @include at-breakpoint(x-large) {
      top: 10px;

      img {
        width: 33%;
        max-width: 650px;
      }
    }
  }

  :global(.now-fresh) & {
    grid-column: span 6;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      display: block;
    }
  }

  :global(.petcurean) & {
    img {
      object-fit: cover;
      width: 80%;
      z-index: 2;
    }

    @include at-breakpoint(medium) {
      img {
        height: 454px;
        max-width: 544px;
        width: 76%;
      }
    }

    @include at-breakpoint(large) {
      bottom: 0;
      margin-bottom: 0;
      right: 0;
      position: absolute;
      width: 495px;
      z-index: 1;

      img {
        height: 423px;
        max-width: initial;
        width: 100%;
      }
    }

    @include at-breakpoint(x-large) {
      max-width: 716px;
      width: 42%;

      img {
        height: 597px;
        width: 100%;
      }
    }
  }

  :global(.summit) & {
    padding: 0 16px;

    @include at-breakpoint(large) {
      position: absolute;
      height: calc(100% - 120px);
      right: -40px;
      top: 38px;
      padding: 0;
    }

    @include at-breakpoint(x-large) {
      // align to right edge of grid
      right: calc((100% - $max-width) / 2);
    }

    picture {
      height: auto;
      justify-content: flex-start;

      @include at-breakpoint(large) {
        height: 100%;
      }
    }

    img {
      object-fit: contain;

      @include at-breakpoint(large) {
        height: 100%;
        object-position: right center;
      }

      @include at-breakpoint(x-large) {
        width: 576px;
      }
    }
  }
}
