
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  padding: 0 32px;
  flex-direction: column;
  text-align: center;

  :global(.gather) & {
    background-color: $gather-cream;
  }

  :global(.go-solutions) & {
    background-color: $color-white;
    color: $color-black;
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    color: $now-brown;
  }

  :global(.petcurean) & {
    background-color: $petcurean-white;
    color: $petcurean-grey;
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-black;
  }
}

.copy-container {
  max-width: 472px;
  margin: 0 auto;
}

.subhead {
  @include p2();
  margin-top: 8px;
}

.headline {
  text-transform: uppercase;

  :global(.gather) &,
  :global(.now-fresh) &,
  :global(.petcurean) & {
    text-transform: none;
  }
}
.separator {
  border-top: 2px solid;
  margin: 32px auto;
  width: 40px;

  :global(.gather) & {
    border-color: $gather-green;
  }

  :global(.go-solutions) & {
    border-color: $color-black;
  }

  :global(.go-solutions) & {
    border-color: $now-brown;
  }

  :global(.petcurean) & {
    border-color: $petcurean-orange;
  }

  :global(.summit) & {
    border-color: $summit-blue;
  }
}

.ctas-container {
  display: flex;
  flex-wrap: wrap;
  margin: 40px auto 0;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include at-breakpoint(medium) {
    flex-direction: row;
  }

  @include at-breakpoint(large) {
    :global(.go-solutions) & {
      flex-direction: column;
      align-items: center;
    }

    :global(.now-fresh) & {
      margin-top: 48px;
    }
  }

  @include at-breakpoint(x-large) {
    :global(.go-solutions) & {
      flex-direction: row;
    }
  }

  :global(.now-fresh) & {
    max-width: 504px;
  }

  & > a:first-child {
    margin-bottom: 16px;

    @include at-breakpoint(medium) {
      width: calc(50% - 12px);
      margin-inline-end: 24px;
      margin-bottom: 0;

      :global(.now-fresh) & {
        margin-inline-end: 16px;
      }
    }

    @include at-breakpoint(large) {
      :global(.go-solutions) & {
        margin-inline-end: 0;
        margin-bottom: 16px;
      }
    }

    @include at-breakpoint(x-large) {
      :global(.go-solutions) & {
        margin-inline-end: 24px;
        margin-bottom: 0;
      }
    }
  }
}

.cta {
  width: 100%;

  @include at-breakpoint(medium) {
    width: calc(50% - 12px);
  }

  :global(.now-fresh) & {
    span,
    p {
      line-height: 16px;
      margin: 0;
    }
  }
}

// i18n font stack overwrites
.container {
  :global(.gather) & {
    @each $locale, $font-stack in $gather-primary-fonts {
      :global(.font-#{$locale}).subhead {
        font-family: $font-stack;
      }
    }
    @each $locale, $font-stack in $gather-secondary-fonts {
      :global(.font-#{$locale}).headline {
        font-family: $font-stack;
      }
    }
  }

  :global(.now-fresh) & {
    @each $locale, $font-stack in $nf-primary-fonts {
      :global(.font-#{$locale}).subhead {
        font-family: $font-stack;
      }
    }
    @each $locale, $font-stack in $nf-secondary-fonts {
      :global(.font-#{$locale}).headline {
        font-family: $font-stack;
      }
    }
  }

  :global(.go-solutions) & {
    @each $locale, $font-stack in $go-primary-fonts {
      :global(.font-#{$locale}) {
        font-family: $font-stack;
      }
    }
  }

  :global(.petcurean) & {
    @each $locale, $font-stack in $pc-primary-fonts {
      :global(.font-#{$locale}).subhead {
        font-family: $font-stack;
      }
    }
    @each $locale, $font-stack in $pc-secondary-fonts {
      :global(.font-#{$locale}).headline {
        font-family: $font-stack;
      }
    }
  }

  :global(.summit) & {
    @each $locale, $font-stack in $summit-primary-fonts {
      :global(.font-#{$locale}).subhead {
        font-family: $font-stack;
      }
    }
    @each $locale, $font-stack in $summit-secondary-fonts {
      :global(.font-#{$locale}).headline {
        font-family: $font-stack;
      }
    }
  }
}
