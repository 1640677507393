
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  @include default-margin-bottom();
  position: relative;
  color: $color-white;

  :global(.now-fresh) & {
    max-width: none;
    color: $now-brown;
    outline: 1px solid $now-brown;
    padding: 32px;

    @include at-breakpoint(medium) {
      padding: 40px;
    }

    @include at-breakpoint(large) {
      padding: 80px;
    }
  }

  :global(.petcurean) & {
    color: $petcurean-grey;
  }

  :global(.summit) & {
    color: $summit-black;
  }
}

.headline {
  @include h3();
  margin-bottom: 16px;
  text-align: center;

  @include at-breakpoint(medium) {
    @include h2();
  }

  @include at-breakpoint(large) {
    @include h3();
  }

  :global(.now-fresh) & {
    @include font-sizes(25px, 36px, 36px);
    margin-bottom: 8px;
  }

  :global(.gather) & {
    @include font-sizes(28px, 45px, 45px);
    color: $gather-black;
    font-weight: 400;
    letter-spacing: 0px;
    margin-bottom: 8px;

    :global(.locale-zh) & {
      @include line-heights(1.2, 1.2, 1.3, 1.3);
    }
  }

  :global(.petcurean) & {
    @include font-sizes(28px, 45px, 45px);
    color: $petcurean-orange;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 8px;

    :global(.locale-zh) & {
      @include line-heights(1.2, 1.2, 1.3, 1.3);
    }
  }

  :global(.summit) & {
    @include font-sizes(28px, 45px, 45px);
    color: $summit-blue;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 8px;

    :global(.locale-zh) & {
      @include line-heights(1.2, 1.2, 1.3, 1.3);
    }
  }
}

.content-wrapper {
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: 3 / span 8;
  }
}
