
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  &:before {
    content: '';
    visibility: hidden;
    opacity: 0;
    position: fixed;
    background-color: $color-black;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: $over-nav-z-index;
    transition:
      visibility 0.25s,
      opacity 0.25s;
  }
}

.content {
  @include grid-container;
  min-height: 700px;

  :global(.now-fresh) & {
    max-width: none;
    width: auto;
    padding: 0;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    column-gap: 0;
  }
}

.filters {
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: span 3;

    :global(.now-fresh) & {
      grid-column: span 4;
      border-right: 1px solid $now-brown;
    }
  }
}

.filters-open {
  &:before {
    visibility: visible;
    opacity: 0.5;
  }
}

.products {
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: 5/13;
  }

  &.full-width-grid {
    @include at-breakpoint(large) {
      grid-column: span 12;
    }
  }
}
