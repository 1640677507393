
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  color: $color-white;
  margin: 116px 0 132px 0;
  overflow: hidden;

  @include at-breakpoint(medium) {
    margin-top: 200px;
    overflow: visible;
  }

  @include at-breakpoint(x-large) {
    margin-bottom: 166px;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    margin: 0;
  }
}
