
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  margin: 24px;
  text-align: center;

  :global(.now-fresh) & {
    color: $now-brown;
  }
}

.ctas-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;

  & > button:first-child {
    margin-inline-end: 16px;
  }
}
