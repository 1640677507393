
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.close {
  position: absolute;
  right: 16px;
  top: 24px;

  :global(.locale-he) & {
    right: auto;
    left: 16px;
  }

  @include at-breakpoint(medium) {
    right: 36px;
    top: 26px;

    :global(.locale-he) & {
      right: auto;
      left: 36px;
    }
  }

  @include at-breakpoint(large) {
    right: 49px;
    top: 49px;

    :global(.locale-he) & {
      right: auto;
      left: 49px;
    }
  }

  @include at-breakpoint(large) {
    right: 180px;

    :global(.locale-he) & {
      right: auto;
      left: 180px;
    }
  }
}

.message {
  text-align: center;
}

:global(.gather) {
  .container {
    background-color: $gather-cream;
    color: $gather-green;
  }

  .image {
    width: 345px;
    margin-bottom: 28px;

    @include at-breakpoint(medium) {
      width: 507px;
    }
  }

  .message {
    margin-bottom: 28px;
    max-width: 345px;

    @include at-breakpoint(medium) {
      max-width: 532px;
    }
  }
}

:global(.go-solutions) {
  .container {
    background-color: $color-black;
    color: $color-white;
  }

  .image {
    margin-bottom: 21px;
    width: 164px;

    @include at-breakpoint(medium) {
      width: 192px;
    }

    @include at-breakpoint(large) {
      width: 210px;
    }

    @include at-breakpoint(x-large) {
      margin-bottom: 30px;
    }
  }

  .message {
    margin-bottom: 40px;
    max-width: 333px;

    @include at-breakpoint(medium) {
      max-width: 430px;
    }

    @include at-breakpoint(large) {
      max-width: 453px;
    }

    @include at-breakpoint(x-large) {
      margin-bottom: 30px;
      max-width: 544px;
    }
  }
}

:global(.now-fresh) {
  .container {
    background-color: $now-tan;
    color: $now-brown;
  }

  .close {
    path {
      fill: $now-brown;
    }
  }

  .image {
    margin-bottom: 53px;
    width: 221px;

    @include at-breakpoint(medium) {
      margin-bottom: 32px;
      width: 285px;
    }

    @include at-breakpoint(large) {
      margin-bottom: 40px;
    }

    @include at-breakpoint(x-large) {
      width: 296px;
    }
  }

  .message {
    margin-bottom: 27px;
    max-width: 333px;

    @include at-breakpoint(medium) {
      margin-bottom: 40px;
      max-width: 435px;
    }

    @include at-breakpoint(large) {
      margin-bottom: 32px;
    }

    @include at-breakpoint(x-large) {
      max-width: 532px;
    }
  }
}

:global(.petcurean) {
  .container {
    background-color: $petcurean-white;
    color: $petcurean-grey;
  }

  .image {
    width: 333px;
    margin-bottom: 28px;

    @include at-breakpoint(medium) {
      width: 532px;
    }
  }

  .message {
    margin-bottom: 28px;
    max-width: 345px;

    @include at-breakpoint(medium) {
      max-width: 532px;
    }
  }
}

:global(.summit) {
  .container {
    background-color: $summit-white;
    color: $summit-blue;
  }

  .image {
    width: 333px;
    margin-bottom: 28px;

    @include at-breakpoint(medium) {
      width: 532px;
    }
  }

  .message {
    margin-bottom: 28px;
    max-width: 345px;

    @include at-breakpoint(medium) {
      max-width: 532px;
    }
  }
}
