
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.background {
  overflow: auto;

  :global(.gather) & {
    background-color: $gather-cream;
    color: $gather-black;
  }

  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    color: $now-brown;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    padding: 56px 0;
  }

  :global(.summit) & {
    color: $summit-black;
  }
}

.header-text {
  @include flex-container();

  :global(.gather) & {
    margin-top: 30px;

    @include at-breakpoint(medium) {
      margin-top: 50px;
    }
  }

  :global(.now-fresh) & {
    margin-top: 0 auto;
  }

  :global(.summit) & {
    color: $summit-blue;
  }

  h2 {
    @include h2();
    margin-bottom: 32px;

    @include at-breakpoint(large) {
      @include h3();
    }
  }
}

.tab-items {
  @include flex-container();

  :global(.gather) & {
    border-bottom: 3px solid $gather-green;
    margin: 0 auto 32px;
  }

  :global(.go-solutions) & {
    border-bottom: 2px solid $go-yellow;
    margin: 0 auto 28px;

    @include at-breakpoint(medium) {
      margin-bottom: 34px;
    }

    @include at-breakpoint(large) {
      margin-bottom: 57px;
    }
  }

  :global(.now-fresh) & {
    border-bottom: 1px solid $now-brown;
    margin: 0 auto 34px;
  }

  :global(.summit) & {
    border-bottom: 3px solid $summit-black;
    margin: 0 auto 32px;
  }
}

.tab-item {
  position: relative;

  @include at-breakpoint(small) {
    flex-grow: 1;
  }

  :global(.gather) & {
    border: 3px solid $gather-green;
    border-bottom: 0;
    border-right: 0;
    overflow: hidden;

    &:first-child {
      border-radius: 20px 0 0 0;
    }

    &:last-child {
      border-right: 3px solid $gather-green;
      border-radius: 0 20px 0 0;
    }
  }

  :global(.go-solutions) & {
    border: 2px solid $go-yellow;
    border-bottom: 0;
  }

  :global(.now-fresh) & {
    border: 1px solid $now-brown;
    border-bottom: 0;

    &:first-child {
      border-right: none;

      :global(.locale-he) & {
        border-right: 1px solid $now-brown;
        border-left: none;
      }
    }
  }

  :global(.summit) & {
    border: 3px solid $summit-black;
    border-bottom: 0;
    border-right: 0;
    overflow: hidden;

    &:first-child {
      border-radius: 0;
    }

    &:last-child {
      border-right: 3px solid $summit-black;
      border-radius: 0;
    }
  }

  button {
    height: 100%;

    :global(.gather) & {
      color: $gather-green;
      font-weight: 700;
    }

    :global(.summit) & {
      color: $summit-black;
      text-transform: uppercase;
      font-weight: 600;
    }

    span {
      @include bold-hover-indicator();
      padding: 10px 15px;
      margin: 0;
      height: 100%;
      justify-content: center;

      @include at-breakpoint(small) {
        width: 100%;
      }

      :global(.summit) & {
        padding: 15px 15px;
      }
    }

    @include at-breakpoint(small) {
      width: 100%;
    }
  }

  &:last-child {
    margin-inline-end: 0;
  }

  :global(.go-solutions) & {
    &:global(.active),
    &:hover {
      button {
        font-weight: 700;
      }
    }

    button {
      color: $color-white-light;
    }

    span {
      padding: 15px 15px;
    }
  }

  :global(.now-fresh) & {
    button {
      color: $now-brown;
    }

    span {
      padding: 16px 32px;
    }
  }

  &:global(.active),
  &:hover {
    :global(.gather) & {
      background-color: $gather-green;
    }

    :global(.summit) & {
      background-color: $summit-black;
    }

    button {
      font-weight: 500;

      :global(.gather) & {
        color: $gather-white;
        font-weight: 700;
      }

      :global(.go-solutions) & {
        color: $go-yellow;
      }

      :global(.now-fresh) & {
        color: $now-light-tan;
      }

      :global(.summit) & {
        color: $summit-white;
      }
    }

    span {
      :global(.go-solutions) & {
        background-color: $go-yellow;
        color: $color-black;
      }
      :global(.now-fresh) & {
        background-color: $color-white;
        color: $now-brown;
      }
    }
  }
}

.tab-contents {
  @include flex-container();
  padding-bottom: 104px;

  :global(.now-fresh) & {
    padding-bottom: 0;
  }

  @include at-breakpoint(medium) {
    padding-bottom: 76px;
  }

  @include at-breakpoint(large) {
    padding-bottom: 108px;
  }
}

.tab-content {
  visibility: hidden;
  opacity: 0;
  display: none;

  & > section {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &:global(.active) {
    visibility: visible;
    opacity: 1;
    width: 100%;
    display: block;
    animation: fadeInFromNone 0.5s ease-out;

    @include reducedMotion {
      animation: none;
    }
  }

  :global(.now-fresh) &:first-child {
    width: calc(100% - 31px);
    margin: 0 0 0 auto;

    :global(.locale-he) & {
      margin: 0 auto 0 0;
    }

    @include at-breakpoint(medium) {
      width: calc(100%);
      margin: 0 auto;

      :global(.locale-he) & {
        margin: 0 auto;
      }
    }
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
