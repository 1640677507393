
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include margin-container();
  @include default-margin-bottom();
  position: relative;
  z-index: 1;

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
    max-width: none;
    width: auto;
    margin: 0;
    padding: 32px;

    @include at-breakpoint(large) {
      padding: 80px calc((100% - #{$max-width}) / 2);
    }
  }
}

.rich-text-content {
  p,
  a {
    :global(.go-solutions) & {
      color: $color-white;
    }

    :global(.now-fresh) & {
      color: $now-brown;
    }
  }

  a {
    text-decoration: underline;

    :global(.gather) & {
      text-decoration: none;
      font-weight: 700;
    }

    :global(.petcurean) & {
      text-decoration: none;
    }

    :global(.summit) & {
      text-decoration: none;
      font-weight: 700;
    }

    svg {
      display: none;
    }
  }

  hr {
    :global(.go-solutions) & {
      border-color: $color-white;
    }

    :global(.summit) & {
      border-color: $summit-blue;
    }
  }

  ul {
    li {
      &::before {
        :global(.go-solutions) & {
          background-color: $color-white;
        }

        :global(.now-fresh) & {
          background-color: $now-brown;
        }

        :global(.summit) & {
          background-color: $summit-blue;
        }
      }
    }
  }
}
