
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding: 24px 16px 16px;
  width: 100%;

  @include at-breakpoint(medium) {
    padding-inline-start: 34px;
    padding-inline-end: 36px;
  }

  @include at-breakpoint(large) {
    margin: auto;
    max-width: $max-width;
    padding: 48px 32px 16px;
  }

  &.grid {
    @include at-breakpoint(large) {
      @include grid-container;

      margin-bottom: 16px;
      margin-top: 48px;

      & .form-field {
        grid-column-start: 2;
        grid-column-end: 12;
        margin-inline-start: 32px;
        margin-inline-end: 32px;
      }
    }
  }
}

.form-field {
  align-items: center;
  background-color: rgba($go-light-grey, 0.25);
  border-radius: 9px;
  display: flex;
  height: 66px;
  padding: 10px 16px;
}

.input-container {
  display: flex;
  // Visually the label comes before the input.
  // In the markup the label is placed after the input
  // to be able to style it based on the input focus.
  // That's why we reverse the order.
  flex-flow: column-reverse;
  margin-inline-end: auto;
  padding-inline-end: 16px;
  width: 100%;
}

.input {
  background: none;
  border: 0;
  line-height: 24px;
  padding: 0;
  text-overflow: ellipsis;

  // Visually, the label will be the placeholder for the input
  &::placeholder {
    color: transparent;
  }

  // When we haven't typed anything this places the input vertically centered
  // (with the label in the same position as the placeholder
  &:not(:focus):placeholder-shown {
    transform: translateY(-15px);
  }

  // When we start typing the input changes to be a little lower (with the label a little higher top)
  &:focus {
    transform: translateY(0);
  }

  &::-webkit-search-cancel-button {
    appearance: none;
  }
}

.label {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 6px;
}

// When we haven't typed anything this places the label vertically centered as the input placeholder
// (with the input in the same position)
.input:not(:focus):placeholder-shown + .label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transform: translateY(14px);

  :global(.now-fresh) & {
    font-weight: 300;
  }
}

// When we start typing the label changes to be a little higher (with the input a little lower on the bottom)
.input:focus + .label {
  transform: translateY(0);
}

// When there is something in the input we don't show the label/placeholder
.input:not(:focus):not(:placeholder-shown) + .label {
  height: 0;
  margin: 0;
  transform: translateY(0);
  visibility: hidden;
}

.submit {
  border-left: 1px solid $go-light-opaque-black;
  padding-inline-start: 12px;

  :global(.locale-he) & {
    border-left: none;
    border-right: 1px solid $go-light-opaque-black;
  }

  path {
    fill: $color-black;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px $outline;
  }

  &.submit-focused {
    path {
      fill: $go-teal;
    }
  }
}

.location-prompt {
  border-right: 1px solid rgba($go-charcoal, 0.2);
  display: flex;
  height: 34px;
  margin-inline-end: 14px;
  padding-inline-end: 19px;
}

.location-prompt.is-hidden {
  display: none;
}

:global(.gather) {
  .container {
    padding: 0 32px;

    @include at-breakpoint(large) {
      padding: 0;
    }
  }

  .form-field {
    background-color: $gather-dark-cream;
    margin: 30px auto 16px auto;

    @include at-breakpoint(large) {
      margin: 0 auto 16px auto;
    }
  }

  .submit {
    border-color: rgb($gather-black, 0.5);

    path {
      fill: $gather-black;
    }

    &.submit-focused {
      path {
        fill: $gather-green;
      }
    }
  }
}

:global(.now-fresh) {
  .container {
    padding-top: 29px;

    @include at-breakpoint(large) {
      padding-top: 48px;

      &.grid {
        padding-top: 0;
      }
    }
  }

  .form-field {
    background-color: rgba($now-light-tan, 0.2);
    border-radius: 8px;
  }

  .location-prompt {
    border-color: $now-light-tan;
  }

  .location-prompt-icon {
    circle {
      fill: $now-brown;
    }
  }

  .submit {
    border-color: $now-light-tan;

    path {
      fill: $now-brown;
    }

    &.submit-focused {
      path {
        fill: $now-grey;
      }
    }
  }
}

:global(.summit) {
  .form-field {
    background-color: $summit-light-opaque-blue;
    margin: 30px auto 16px auto;

    @include at-breakpoint(large) {
      margin: 0 auto 16px auto;
    }
  }

  .submit {
    border-color: rgb($gather-black, 0.5);

    path {
      fill: $summit-black;
    }

    &.submit-focused {
      path {
        fill: $summit-blue;
      }
    }
  }
}
