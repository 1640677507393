
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.button {
  cursor: pointer;

  &:focus {
    outline: none;

    & > .wrapper,
    & > .styled-content {
      box-shadow: 0 0 2px 2px $outline;
      position: relative;
      z-index: 1;
    }
  }

  :global(.go-solutions) & {
    font-weight: 700;
  }

  :global(.now-fresh) & {
    font-weight: 400;
  }

  :global(.petcurean) & {
    font-weight: 600;
  }

  &:active {
    color: inherit;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.wrapper {
  display: block;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  margin: 2px;

  :global(.gather) &,
  :global(.petcurean) &,
  :global(.summit) & {
    margin: 0;
  }

  @include at-breakpoint(large) {
    margin: 0;
  }

  &:focus {
    outline: none;
  }
}

$a11ybtn-edge: 18px;
$a11ybtn-border: 2px;
$a11ybtn-padver: 20px;
$a11ybtn-padhor: 16px;
$animation-timing: 0.2s ease-out;

.styled {
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  justify-content: center;

  svg {
    margin-inline-start: 8px;
  }
}

.primary {
  padding: $a11ybtn-padver $a11ybtn-padhor;
  min-width: 120px;

  :global(.go-solutions) & {
    :global(.locale-ru) & {
      padding: 16px 14px;
    }
  }
}

.secondary {
  position: relative;
  padding: calc(#{$a11ybtn-padver} - #{$a11ybtn-border})
    calc(#{$a11ybtn-padhor} - #{$a11ybtn-border});
  min-width: 120px;
}

/* Gather Styles */
:global(.gather) {
  .styled {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: normal;
    border-radius: 28px;
    line-height: 16px;
    transition:
      background-color $animation-timing,
      border $animation-timing,
      color $animation-timing;
    text-align: center;

    svg path {
      fill: $gather-white;
      transition: fill $animation-timing;
    }

    p {
      font-weight: 700;
    }
  }

  .small {
    padding: 10px 24px;

    .styled-content {
      font-size: 12px;
      line-height: 1.67;
    }
  }

  .primary {
    padding: 18px 33px;
    background-color: $gather-green;
    border: 2px solid $gather-green;
    color: $gather-white;

    svg {
      margin: 0 0 0 6px;
    }

    svg path {
      fill: $gather-white;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $gather-lighter-green;
        border: 2px solid $gather-lighter-green;
        color: $gather-white;

        svg path {
          fill: $gather-white;
        }
      }
    }
  }

  .primary-light {
    background-color: $gather-white;
    border-color: $gather-white;
    color: $gather-green;

    svg path {
      fill: $gather-green;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $gather-light-sapling-green;
        border-color: $gather-light-sapling-green;
        color: $gather-green;
      }
    }
  }

  .secondary {
    padding: 18px 33px;
    border: 2px solid $gather-green;
    color: $gather-green;

    svg {
      margin: 0 0 0 6px;
    }

    svg path {
      fill: $gather-green;
    }

    @media (hover: hover) {
      &:hover {
        color: $gather-lighter-green;
        border: 2px solid $gather-lighter-green;
        svg path {
          fill: $gather-lighter-green;
        }
      }
    }
  }

  .secondary-light {
    color: $gather-white;
    border-color: $gather-white;
    svg path {
      fill: $gather-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $gather-light-sapling-green;
        border-color: $gather-light-sapling-green;
        svg path {
          fill: $gather-light-sapling-green;
        }
      }
    }
  }

  .styled-content {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }

  .link {
    &:hover {
      text-decoration: none;

      &:after {
        background-position: 0%;
        transition: background-position 0.2s ease-out;
      }
    }

    &:after {
      background-image: $gather-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
      content: '';
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
    }

    p {
      font-weight: 700;
    }
  }

  .link-light {
    color: $gather-white;

    &:after {
      background-image: $gather-light-gradient;
    }

    svg path {
      fill: $gather-white;
    }
  }

  .link-dark {
    color: $gather-black;

    &:after {
      background-image: $gather-dark-gradient;
    }

    svg path {
      fill: $gather-black;
    }

    &:hover {
      color: $gather-black;

      svg path {
        fill: $gather-black;
      }
    }
  }
}
/* Gather Styles End */

/* Go Solutions Styles */
:global(.go-solutions) {
  .styled {
    text-transform: uppercase;
    color: $color-black;
    font-size: 12px;

    svg path {
      fill: $color-black;
    }
  }

  .primary {
    background-color: $go-yellow;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 18px), calc(100% - 18px) 100%, 0 100%);
  }

  .primary-dark {
    &:hover {
      color: $color-black;
      background-color: $color-white;

      svg path {
        fill: $color-black;
      }
    }
  }

  .primary-light {
    &:hover {
      color: $color-white;
      background-color: $color-black;

      svg path {
        fill: $color-white;
      }
    }
  }

  .secondary {
    border: 2px solid $go-yellow;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 18px), calc(100% - 18px) 100%, 0 100%);

    &:after {
      content: '';
      position: absolute;
      bottom: -17px;
      right: -17px;
      width: 30px;
      height: 30px;
      transform: rotate(45deg);
      background-color: $go-yellow;
    }
  }

  .secondary-dark {
    &:hover {
      border: 2px solid $color-white;

      &:after {
        background-color: $color-white;
      }
    }
  }

  .secondary-light {
    &:hover {
      border: 2px solid $color-black;

      &:after {
        background-color: $color-black;
      }
    }
  }

  .styled-content {
    @include p3;
    font-weight: inherit;
  }

  .link {
    border-bottom: 2px solid $go-yellow;

    // clicking on the button padding triggers the focus state, so changed the paddig to
    // the contents instead
    p {
      padding-bottom: 8px;
    }
    svg {
      margin-bottom: 10px;
    }
  }

  .link-light {
    @media (hover: hover) {
      &:hover {
        border-bottom-color: $color-black;
      }
    }
  }

  .link-dark {
    @media (hover: hover) {
      &:hover {
        border-bottom-color: $color-white;
      }
    }
  }
}
/* Go Solutions Styles End */

/* Now Fresh Styles */
:global(.now-fresh) {
  .styled {
    border-radius: 36px;
    @include font-sizes(16px, 18px, 18px);
    border: 1px solid $now-brown;
    transition: 0.3s;
    letter-spacing: 0;
    text-align: center;

    p {
      font-family: Stag, sans-serif;
    }

    &:hover {
      p,
      a,
      span {
        font-weight: 400;
      }
    }
  }

  .primary {
    background-color: $color-white;
    color: $now-brown;

    a,
    span {
      font-weight: 400;
    }

    svg path {
      fill: $now-brown;
    }

    &:hover {
      background-color: $now-grey;

      a,
      span {
        font-weight: 400;
      }
    }
  }

  .secondary {
    color: $now-brown;
    font-size: 18px;
    font-weight: 400;

    svg path {
      fill: $now-brown;
    }

    &:hover {
      background-color: $now-grey;
    }
  }

  .styled-content {
    font-size: 18px;
    line-height: 16px;
    font-weight: inherit;
  }

  .small {
    .styled-content {
      font-size: 15px;
    }
  }

  .link {
    border-bottom: 2px solid $now-brown;
    padding-bottom: 8px;
    border-radius: 0;

    @media (hover: hover) {
      &:hover {
        border-bottom: 3px solid $now-brown;
      }
    }
  }
}
/* Now Fresh Styles End */

/* Petcurean Styles */
:global(.petcurean) {
  .styled {
    font-size: 15px;
    border-radius: 28px;
    padding: 18px 33px;
    line-height: 16px;
    transition:
      background-color $animation-timing,
      border $animation-timing,
      color $animation-timing;

    svg path {
      fill: $petcurean-white;
      transition: fill $animation-timing;
    }

    p {
      font-weight: 600;
    }
  }

  .small {
    padding: 10px 24px;

    .styled-content {
      font-size: 12px;
      line-height: 1.67;
      letter-spacing: 1px;
    }
  }

  .primary {
    background-color: $petcurean-orange;
    border: 2px solid $petcurean-orange;
    color: $petcurean-white;

    svg {
      margin: 0 0 0 6px;
    }

    svg path {
      fill: $petcurean-white;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $petcurean-light-orange;
        border: 2px solid $petcurean-light-orange;
        color: $petcurean-white;

        svg path {
          fill: $petcurean-white;
        }
      }
    }
  }

  .primary-light {
    background-color: $petcurean-white;
    border-color: $petcurean-white;
    color: $petcurean-orange;

    svg path {
      fill: $petcurean-orange;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $petcurean-light-tan;
        color: $petcurean-orange;
        border-color: $petcurean-light-tan;

        svg path {
          fill: $petcurean-orange;
        }
      }
    }
  }

  .secondary {
    border: 2px solid $petcurean-orange;
    color: $petcurean-orange;

    svg {
      margin: 0 0 0 6px;
    }

    svg path {
      fill: $petcurean-orange;
    }

    @media (hover: hover) {
      &:hover {
        color: $petcurean-light-orange;
        border: 2px solid $petcurean-light-orange;
        svg path {
          fill: $petcurean-light-orange;
        }
      }
    }
  }

  .secondary-light {
    color: $petcurean-white;
    border-color: $petcurean-white;
    svg path {
      fill: $petcurean-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $petcurean-light-tan;
        border-color: $petcurean-light-tan;
        svg path {
          fill: $petcurean-light-tan;
        }
      }
    }
  }

  .styled-content {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
  }
}
/* Petcurean Styles End */

/* Summit Styles */
:global(.summit) {
  .styled {
    @include eyebrow();
    padding: 20px 33px;
    text-transform: uppercase;
    transition:
      background-color $animation-timing,
      border $animation-timing,
      color $animation-timing;

    svg path {
      fill: $summit-white;
      transition: fill $animation-timing;
    }

    p {
      @include eyebrow();
    }
  }

  .small {
    padding: 10px 14px;

    .styled-content {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .primary {
    background-color: $summit-blue;
    border: 2px solid $summit-blue;
    color: $summit-white;

    svg {
      margin: 0 0 0 6px;
    }

    svg path {
      fill: $summit-white;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $summit-light-blue;
        border: 2px solid $summit-light-blue;
        color: $summit-white;

        svg path {
          fill: $summit-white;
        }
      }
    }
  }

  .primary-light {
    background-color: $summit-white;
    border-color: $summit-white;
    color: $summit-blue;

    svg path {
      fill: $summit-blue;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $summit-light-desaturated-blue;
        border-color: $summit-light-desaturated-blue;
        color: $summit-blue;

        svg path {
          fill: $summit-blue;
        }
      }
    }
  }

  .secondary {
    border: 2px solid $summit-blue;
    color: $summit-blue;

    svg {
      margin: 0 0 0 6px;
    }

    svg path {
      fill: $summit-blue;
    }

    @media (hover: hover) {
      &:hover {
        color: $summit-light-blue;
        border: 2px solid $summit-light-blue;
        svg path {
          fill: $summit-light-blue;
        }
      }
    }
  }

  .secondary-light {
    color: $summit-white;
    border-color: $summit-white;
    svg path {
      fill: $summit-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $summit-desaturated-blue;
        border-color: $summit-desaturated-blue;
        svg path {
          fill: $summit-desaturated-blue;
        }
      }
    }
  }

  .styled-content {
    @include eyebrow();
  }

  .link {
    padding: 0;

    &:hover {
      text-decoration: none;

      &:after {
        background-position: 0%;
        transition: background-position 0.2s ease-out;
      }
    }

    &:after {
      background-image: $summit-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
      content: '';
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
    }

    p {
      font-weight: 700;
    }
  }

  .link-light {
    color: $summit-white;

    &:after {
      background-image: $summit-light-gradient;
    }

    svg path {
      fill: $summit-white;
    }
  }

  .link-dark {
    color: $summit-black;

    &:after {
      background-image: $summit-dark-gradient;
    }

    svg path {
      fill: $summit-black;
    }

    &:hover {
      color: $summit-black;

      svg path {
        fill: $summit-black;
      }
    }
  }
}
/* Summit Styles End */
