
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding-top: 24px;

  @include at-breakpoint(large) {
    padding-top: 48px;
  }

  :global(.gather) & {
    padding-top: 0;
    position: relative;
    z-index: 1;
    background-color: $gather-dark-green;

    @include at-breakpoint(large) {
      background-color: $gather-green;
    }
  }

  :global(.go-solutions) & {
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    padding-top: 0;
  }

  :global(.petcurean) & {
    padding-top: 0;
    position: relative;
    z-index: 1;
    background-color: $petcurean-slightly-dark-orange;

    @include at-breakpoint(large) {
      background-color: $petcurean-orange;
    }
  }
}

.category-list {
  @include flex-container();
  color: $color-white;
  border-top: 1px solid;
  overflow-x: auto;
  width: 100%;
  // prevent cls
  min-height: 73px;
  // eslint-disable
  scrollbar-width: none; //firefox exclusive
  // eslint-enable
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
    display: none;
  }

  :global(.gather) & {
    border-top: none;
    min-height: 64px;
  }

  :global(.go-solutions) & {
    color: $color-white;
    border-color: $color-white-lighter;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    border: none;
    min-height: 64px;
  }

  :global(.petcurean) & {
    border-top: none;
    min-height: 64px;
  }

  @include at-breakpoint(medium) {
    width: 100%;
  }

  @include at-breakpoint(large) {
    width: calc(100% - 64px);
    min-height: 94px;
  }
}

.category-container {
  margin-inline-end: 24px;
  padding: 2px;

  :global(.gather) &,
  :global(.petcurean) & {
    padding: 0;
    display: flex;
  }

  :global(.now-fresh) & {
    border: 1px solid $now-brown;
    border-bottom: none;
    border-right: none;
    margin-inline-end: 0;
    padding: 0;

    &:first-child {
      margin-inline-start: 0;

      @include at-breakpoint(medium) {
        margin-inline-start: 32px;
      }

      @include at-breakpoint(large) {
        margine-inline-start: 24px;
      }
    }

    &:last-child {
      border-right: 1px solid $now-brown;
      margin-inline-end: 0;
      padding-inline-end: 0;

      @include at-breakpoint(medium) {
        margin-inline-end: 32px;
      }

      @include at-breakpoint(large) {
        margine-inline-end: 24px;
      }
    }
  }

  @include at-breakpoint(large) {
    margin-inline-end: 32px;
  }

  &:first-child {
    margin-inline-start: 16px;

    @include at-breakpoint(medium) {
      margin-inline-start: 32px;
    }

    @include at-breakpoint(large) {
      margin-inline-start: 0;
    }
  }

  &:last-child {
    padding-inline-end: 24px;

    @include at-breakpoint(large) {
      padding-inline-end: 0;
    }
  }
}

.category {
  @include p2();
  white-space: nowrap;
  position: relative;

  span {
    @include bold-hover-indicator();
    padding: 20px 0;

    @include at-breakpoint(large) {
      padding: 31px 0 34px;
    }

    :global(.gather) & {
      font-weight: 700;
      height: 64px;
      display: flex;
      justify-content: center;
      padding: 0 24px;
    }

    :global(.go-solutions) & {
      font-weight: 500;
    }

    :global(.now-fresh) & {
      font-family: Stag, sans-serif;
      font-weight: 400;
      padding: 24px 32px;
    }

    :global(.petcurean) & {
      font-weight: 600;
      line-height: 19px;
      // fixed height from design
      height: 64px;
      display: flex;
      justify-content: center;
      padding: 0 16px;
    }
  }

  .active &,
  &:hover {
    @include active-indicator();

    &:after {
      opacity: 1;
    }

    :global(.gather) & {
      span {
        background-color: $gather-lighter-sapling-green;
        color: $gather-dark-green;

        @include at-breakpoint(large) {
          background-color: $gather-dark-green;
          color: $gather-white;
        }
      }
    }

    :global(.go-solutions) & {
      span {
        font-weight: 700;
      }
    }

    :global(.now-fresh) & {
      background-color: $color-white;
    }

    :global(.petcurean) & {
      span {
        border-radius: 20px 20px 0px 0px;
        background-color: $petcurean-dark-orange;
      }
    }
  }

  :global(.gather) & {
    color: $gather-cream;
  }

  :global(.go-solutions) & {
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.petcurean) & {
    color: $petcurean-white;
  }
}
