
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  align-items: center;
  position: relative;
  overflow: hidden;

  :global(.gather) & {
    background-color: $gather-green;
    color: $gather-white;
    padding-bottom: 86px;
    position: static;
    padding-top: 80px;

    @include at-breakpoint(large) {
      padding-top: 40px;
    }
  }

  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    min-height: 350px;
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    color: $petcurean-white;
    position: static;
    padding-bottom: 81px;
    padding-top: 80px;

    @include at-breakpoint(large) {
      padding-top: 40px;
    }
  }
}

.copy-container {
  display: flex;
  flex-direction: column;
  grid-column: span 4;
  z-index: 1;

  a {
    :global(.gather) &,
    :global(.go-solutions) &,
    :global(.now-fresh) &,
    :global(.petcurean) & {
      text-decoration: underline;
    }

    svg {
      display: none;
    }
  }

  p,
  a {
    :global(.go-solutions) & {
      color: $color-white;
    }

    :global(.petcurean) & {
      color: $petcurean-white;
    }
  }

  @include at-breakpoint(small) {
    :global(.gather) &,
    :global(.petcurean) & {
      grid-column: span 4;
      width: 93%;
    }
  }

  @include at-breakpoint(medium) {
    grid-column: span 3;

    :global(.gather) & {
      grid-column: span 4;
      width: 90%;
    }

    :global(.petcurean) & {
      grid-column: span 3;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 7;

    :global(.gather) &,
    :global(.petcurean) & {
      grid-column: span 6;
      width: 100%;
    }
  }

  @include at-breakpoint(x-large) {
    grid-column: span 6;
  }

  p {
    :global(.gather) & {
      @include font-sizes(12px, 16px, 19px, 19px);
      @include line-heights(21px, 28px, 36px, 36px);
      font-weight: 300;
    }

    :global(.petcurean) & {
      @include font-sizes(12px, 16px, 21px, 21px);
      @include line-heights(21px, 26px, 37px, 37px);
    }
  }
}

.title {
  @include h2();
  margin: 0 0 16px;

  @include at-breakpoint(large) {
    margin: 0 0 24px;
  }

  :global(.gather) & {
    @include font-sizes(34px, 64px, 87px, 87px);
    @include line-heights(43px, 75px, 92px, 92px);
    text-transform: capitalize;
    font-weight: 400;

    @include at-breakpoint(small) {
      margin: 0 0 8px;
    }

    @include at-breakpoint(medium) {
      margin: 0 0 24px;
    }

    @include at-breakpoint(large) {
      margin: 0 0 32px;
    }
  }

  :global(.petcurean) & {
    @include font-sizes(35px, 67px, 101px, 101px);
    @include line-heights(40px, 68px, 96px, 96px);
    text-transform: capitalize;

    @include at-breakpoint(small) {
      margin: 0 0 8px;
    }

    @include at-breakpoint(medium) {
      margin: 0 0 24px;
    }

    @include at-breakpoint(large) {
      margin: 0 0 32px;
    }
  }
}

.description {
  @include p1();
}

.image {
  grid-column: 5/7;
  position: relative;
  right: calc(-100% + 104px);
  max-height: 100%;
  width: 300%;

  @include at-breakpoint(medium) {
    margin-inline-start: auto;
    position: static;
    width: auto;
  }

  @include at-breakpoint(large) {
    grid-column: 10/13;
  }

  @include at-breakpoint(x-large) {
    grid-column: 11/13;
  }

  :global(.gather) & {
    position: absolute;
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 0;
    left: 60%;
    bottom: 0;
    &.has-categories {
      // height of category bar
      bottom: 64px;
    }

    @include at-breakpoint(medium) {
      left: auto;
      right: 32px;
    }

    @include at-breakpoint(large) {
      z-index: 2;
      right: 44px;
      bottom: 0;

      &.has-categories {
        bottom: 0;
      }
    }

    @include at-breakpoint(x-large) {
      right: calc((100% - $max-width) / 2);
    }

    img {
      width: auto;

      @include at-breakpoint(small) {
        height: 63%;
      }

      @include at-breakpoint(medium) {
        height: 75%;
      }

      @include at-breakpoint(large) {
        height: 90%;
      }

      @include at-breakpoint(x-large) {
        height: 100%;
      }
    }
  }

  :global(.now-fresh) & {
    display: none;
  }

  :global(.petcurean) & {
    position: absolute;
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 0;

    &.has-categories {
      // height of category bar
      bottom: 64px;
    }

    @include at-breakpoint(small) {
      left: 60%;
      bottom: 20%;
    }

    @include at-breakpoint(medium) {
      left: auto;
      right: -45px;
    }

    @include at-breakpoint(large) {
      z-index: 2;
      right: -50px;
      bottom: 0;

      &.has-categories {
        bottom: 0;
      }
    }

    @include at-breakpoint(x-large) {
      right: calc((100% - 1530px) / 2);
    }

    img {
      width: auto;

      @include at-breakpoint(small) {
        height: 63%;
      }

      @include at-breakpoint(medium) {
        height: 75%;
      }

      @include at-breakpoint(large) {
        height: 90%;
      }

      @include at-breakpoint(x-large) {
        height: 100%;
      }
    }
  }
}
