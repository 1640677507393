
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
  }
  &.inline-component {
    @include default-margin-bottom();
    @include scroll-offset;

    .tab-content {
      :global(.go-solutions) & {
        color: $color-black;
        background-color: $color-white;
      }
    }
  }
}

.map-tab {
  :global(.gather) & {
    @include at-breakpoint(large) {
      margin: 80px 0;
    }
  }
}

.tab-content {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.active {
  height: auto;
  visibility: visible;
}

:global(.gather) {
  .tab-content:first-of-type {
    margin-top: 100px;
  }
}
