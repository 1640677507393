
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal {
  opacity: 0;
  pointer-events: none;
  overflow: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
  text-align: center;

  &:not([open]) {
    display: none;
  }

  &[open] {
    animation: appear 0.25s;
    opacity: 1;
    pointer-events: all;
  }

  :global(.gather) & {
    background-color: $color-black-60;
  }

  :global(.go-solutions) & {
    background-color: $color-black-75;
  }

  :global(.now-fresh) & {
    background-color: $color-black-60;
  }

  :global(.petcurean) & {
    background-color: $color-black-60;
  }

  :global(.summit) & {
    background-color: $color-black-60;
  }

  &::backdrop {
    display: none;
  }

  // To center the dialog in the window we use the good ol' method of
  // using inline elements. This pseudo-element is used as the anchor to position
  // the .wrapper in the middle of the container.
  &::before {
    content: '';
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
}

.wrapper {
  padding-top: 16px;
  padding-bottom: 32px;
  vertical-align: middle;
  text-align: initial;

  @include at-breakpoint(medium) {
    padding-top: 24px;
    padding-bottom: 64px;
  }

  :global(.gather) & {
    background-color: $gather-cream;
    color: $gather-black;
  }

  :global(.go-solutions) & {
    &.dark {
      background-color: $color-black;
      color: $color-white;

      svg {
        path {
          fill: $color-white;
        }
      }
    }

    &.light {
      background-color: $color-white;
      color: $color-black;

      svg {
        path {
          fill: $color-black;
        }
      }
    }
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
    color: $now-brown;
  }

  :global(.petcurean) & {
    background-color: $petcurean-white;
    color: $petcurean-grey;
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-black;
  }

  &:not(.fullsize) {
    display: inline-block;
    width: calc(100% - 32px);
    margin: 32px 0;

    @include at-breakpoint(medium) {
      width: 656px;
    }

    @include at-breakpoint(large) {
      width: 616px;
    }

    @include at-breakpoint(x-large) {
      width: 644px;
    }

    .close {
      margin-inline-end: 16px;

      @include at-breakpoint(medium) {
        margin-inline-end: 24px;
      }
    }

    .content {
      padding-top: 8px;

      @include at-breakpoint(medium) {
        padding-top: 24px;
      }
    }
  }

  &.fullsize {
    width: 100%;
    min-height: 100%;

    @include grid-container;
    display: inline-grid;
    grid-template-rows: 24px calc(100% - 24px);
    padding-top: 28px;
    padding-bottom: 28px;

    @include at-breakpoint(medium) {
      padding-top: 36px;
      padding-bottom: 36px;
    }

    @include at-breakpoint(large) {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    @include at-breakpoint(x-large) {
      width: calc(100% - 88px);
      min-height: calc(100% - (48px * 2));
    }
  }
}

.form {
  text-align: end;
  grid-column: span 6;
  height: 24px;

  @include at-breakpoint(large) {
    grid-column: span 12;
  }
}

.close {
  background-color: transparent;
  z-index: 1;
  padding: 2px;

  :global(.gather) & {
    svg {
      path {
        fill: $gather-green;
      }
    }
  }

  :global(.now-fresh) & {
    svg {
      path {
        fill: $now-brown;
      }
    }
  }

  :global(.petcurean) & {
    svg {
      path {
        fill: $petcurean-orange;
      }
    }
  }

  :global(.summit) & {
    svg {
      path {
        fill: $summit-blue;
      }
    }
  }
}

.content {
  height: calc(100% - 24px);
  width: 100%;
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: span 12;
  }
}
