
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include flex-container();
}

.ingredients {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 18px;
  grid-row-gap: 4px;
  justify-items: stretch;
  width: 100%;

  @include at-breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
    grid-row-gap: 21px;
  }

  :global(.gather) & {
    grid-row-gap: 16px;

    @include at-breakpoint(large) {
      grid-row-gap: 24px;
    }
  }

  :global(.go-solutions) & {
    grid-row-gap: 4px;

    @include at-breakpoint(medium) {
      grid-row-gap: 18px;
    }

    @include at-breakpoint(large) {
      grid-row-gap: 21px;
    }
  }

  :global(.now-fresh) & {
    grid-row-gap: 11px;

    @include at-breakpoint(medium) {
      grid-row-gap: 72px;
    }
  }

  :global(.summit) & {
    grid-row-gap: 16px;

    @include at-breakpoint(large) {
      grid-row-gap: 21px;
    }
  }
}

.ingredient {
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: span 3;
  }

  @include at-breakpoint(large) {
    grid-column: span 6;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 3;
  }
}
