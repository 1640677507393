
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.related {
  padding-top: 80px;

  :global(.go-solutions) & {
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    padding-top: 0;
  }
}
