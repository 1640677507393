
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include page-container();

  :global(.gather) & {
    overflow: hidden;
    position: relative;
  }
}

.value-proposition {
  position: relative;

  :global(.now-fresh) & {
    margin-top: 0;
    margin: 0;
  }

  & + .benefits {
    // Shifts up into the margin bottom of ImageWithText
    @include at-breakpoint(large) {
      margin-top: -40px;
    }

    @include at-breakpoint(x-large) {
      margin-top: -120px;
    }

    :global(.now-fresh) & {
      margin-top: 0;
    }
  }
}
