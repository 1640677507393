
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();

  grid-column: span 6;
  padding: 0;

  @include at-breakpoint(large) {
    // we repeat the same class for specificity
    // to override the parent blog post grid-column: 2/12;
    &.container {
      grid-column: span 12;
    }
  }
}

.image-and-content-container {
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: span 7;
  }
}

.image {
  display: block;
}

.form {
  grid-column: span 6;
  padding: 0 16px;

  @include at-breakpoint(large) {
    grid-column: span 5;
  }
}

.label {
  @include visually-hidden;
}

.input {
  appearance: none;
  border: none;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
}

.submit {
  width: 100%;

  @include at-breakpoint(large) {
    width: auto;
  }
}

:global(.go-solutions) {
  .container {
    background-color: $color-black;
    color: $color-white;
    padding: 36px 28px 32px;

    @include at-breakpoint(medium) {
      padding: 32px 125px 60px;
    }

    @include at-breakpoint(large) {
      padding: 54px 63px 51px 58px;
    }

    @include at-breakpoint(x-large) {
      padding: 84px 116px 53px 62px;
    }
  }

  .image-and-content {
    @include at-breakpoint(large) {
      display: flex;
    }
  }

  .image-container {
    @include at-breakpoint(large) {
      display: block;
      flex-shrink: 0;
      margin-inline-end: 62px;
    }

    @include at-breakpoint(x-large) {
      margin-inline-end: 50px;
    }
  }

  .image {
    margin-bottom: 43px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    width: 88px;

    @include at-breakpoint(medium) {
      margin-bottom: 26px;
      width: 92px;
    }

    @include at-breakpoint(large) {
      margin-top: 12px;
      width: 84px;
    }

    @include at-breakpoint(x-large) {
      margin-top: 27px;
      width: 112px;
    }
  }

  .content {
    text-align: center;

    @include at-breakpoint(large) {
      margin-top: 33px;
      padding-inline-end: 40px;
      text-align: start;
    }

    @include at-breakpoint(x-large) {
      margin-top: 23px;
    }
  }

  .headline {
    margin-bottom: 20px;

    @include at-breakpoint(medium) {
      margin-bottom: 22px;
    }

    @include at-breakpoint(large) {
      font-size: 22px;
      line-height: 1.27;
      margin-bottom: 10px;
    }

    @include at-breakpoint(x-large) {
      font-size: 30px;
      line-height: 1.0666;
      margin-bottom: 18px;
    }
  }

  .copy {
    margin: 0 auto 37px;
    max-width: 280px;

    @include at-breakpoint(medium) {
      max-width: 400px;
      margin-bottom: 40px;
    }

    @include at-breakpoint(x-large) {
      @include p1;

      max-width: 420px;
    }
  }

  .form {
    grid-column: span 6;
    padding: 0 16px;

    @include at-breakpoint(large) {
      grid-column: span 5;
    }

    @include at-breakpoint(medium) {
      margin: auto;
      max-width: 398px;
    }

    @include at-breakpoint(large) {
      margin: 0;
      padding: 0;
    }

    @include at-breakpoint(x-large) {
      max-width: 420px;
    }

    div[class^='richText'] {
      a {
        color: $color-white;
      }
    }
  }
}

:global(.now-fresh) {
  .container {
    background-color: $now-tan;
    overflow: hidden;
    border: 1px solid $now-brown;
    padding: 0;
    column-gap: 0;
  }

  .image-and-content-container {
    display: flex;
    padding-inline-end: 0;
    background-color: $color-white;
    border-bottom: 1px solid $now-brown;

    @include at-breakpoint(large) {
      border-bottom: none;
      border-right: 1px solid $now-brown;
      grid-column: span 5;
      padding-top: 35px;
    }
  }

  .image-container {
    display: none;
  }

  .content {
    max-width: none;
    width: 100%;
    padding: 40px;
  }

  .headline {
    line-height: 1.03;
    margin-bottom: 6px;
    font-size: 32px !important;

    @include at-breakpoint(medium) {
      line-height: 1.15;
    }
  }

  .copy {
    font-size: 16px !important;
    @include at-breakpoint(x-large) {
      @include p1;
    }
  }

  .form {
    grid-column: span 6;
    padding: 24px;

    @include at-breakpoint(medium) {
      margin: auto;
    }

    @include at-breakpoint(large) {
      max-width: none;
      grid-column: 7 / span 5;
      padding: 80px 0;
    }

    @include at-breakpoint(x-large) {
      margin-inline-start: 0;
    }
  }
}

.newsletter-success {
  grid-column: span 6;
  padding: 0 16px;
  text-align: center;

  @include at-breakpoint(large) {
    grid-column: span 5;
  }

  :global(.go-solutions) & {
    @include at-breakpoint(medium) {
      margin: auto;
      max-width: 398px;
    }

    @include at-breakpoint(large) {
      margin: 0;
      padding: 0;
    }

    @include at-breakpoint(x-large) {
      max-width: 420px;
    }
  }

  :global(.now-fresh) & {
    padding: 40px;

    @include at-breakpoint(medium) {
      margin: auto;
      max-width: 412px;
    }

    @include at-breakpoint(large) {
      max-width: none;
      padding-bottom: 47px;
      padding-top: 25px;
      grid-column: 7 / span 5;
    }

    @include at-breakpoint(x-large) {
      margin-inline-start: 0;
      padding-bottom: 62px;
      padding-top: 72px;
    }
  }

  svg {
    margin-bottom: 24px;

    :global(.go-solutions) & {
      path {
        fill: $color-white;
      }
    }

    :global(.now-fresh) & {
      path {
        fill: $now-brown;
      }
    }
  }
}

.success-image {
  max-width: 100px;
  margin-bottom: 16px;
}

.success-title {
  margin-bottom: 16px;

  @include at-breakpoint(medium) {
    @include h2;
  }

  @include at-breakpoint(large) {
    @include h4;
  }
}
