
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  min-width: 0;
  min-height: 0;
  width: 100%;
  margin-top: 20px;
  background-color: $color-black;

  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
  }

  :global(.summit) & {
    background-color: $summit-black;
  }

  .slide {
    cursor: zoom-in;
    display: block;
    margin: 0 auto;

    .thumbnail {
      display: block;
      max-height: 170px;
      max-width: 100%;

      @include at-breakpoint(large) {
        max-height: 200px;
      }
    }
  }

  :global .slick-slider {
    line-height: 1;
    height: 170px;

    @include at-breakpoint(large) {
      height: 200px;
    }

    .slick-track {
      display: flex;
      align-items: center;
    }

    // hide focusable children for accessibility
    .slick-slide[aria-hidden='true'] {
      visibility: hidden;
    }
  }

  :global .slick-arrow {
    z-index: 1;
    height: 100%;
    width: 40px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba($color-black, 0.5) 50%,
      rgba(255, 255, 255, 0) 100%
    );

    :global(.now-fresh) & {
      width: 28px;
      background: $now-light-tan;
    }

    :global(.summit) & {
      background: $summit-black;
    }

    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 auto;
      border-bottom: 2px solid $color-white;
      opacity: 1;

      :global(.now-fresh) & {
        border-bottom: 2px solid $now-brown;
      }
    }

    &.slick-next {
      left: auto;
      right: 0;

      :global(.now-fresh) & {
        border-left: 1px solid $now-brown;
      }

      &:before {
        border-right: 2px solid $color-white;
        margin-right: 10px;
        transform: rotate(-45deg);

        :global(.now-fresh) & {
          border-right: 2px solid $now-brown;
        }
      }

      &:hover:before {
        border-color: $go-yellow;

        :global(.now-fresh) & {
          border-color: $now-brown;
          border-right: 3px solid $now-brown;
          border-bottom: 3px solid $now-brown;
        }

        :global(.summit) & {
          border-color: $summit-blue;
        }
      }
    }

    &.slick-prev {
      left: 0;
      right: auto;

      :global(.now-fresh) & {
        border-right: 1px solid $now-brown;
      }

      &:before {
        border-left: 2px solid $color-white;
        margin-left: 10px;
        transform: rotate(45deg);

        :global(.now-fresh) & {
          border-left: 2px solid $now-brown;
        }
      }

      &:hover:before {
        border-color: $go-yellow;

        :global(.now-fresh) & {
          border-color: $now-brown;
          border-left: 3px solid $now-brown;
          border-bottom: 3px solid $now-brown;
        }

        :global(.summit) & {
          border-color: $summit-blue;
        }
      }
    }
  }
}

.lightbox {
  :global .slick-track {
    display: flex;
    align-items: center;
  }

  :global .slick-slide {
    img {
      display: block;
      margin: 0 auto;
      max-height: 80vh;
      width: auto;
    }
  }

  :global .slick-arrow {
    z-index: 1;
    height: 100%;
    width: 40px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(255, 255, 255, 0) 100%
    );

    :global(.now-fresh) & {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba($now-tan, 0.5) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 auto;
      border-bottom: 2px solid $color-white;
      opacity: 1;

      :global(.now-fresh) & {
        border-bottom: 2px solid $now-brown;
      }
    }

    &.slick-next {
      left: auto;
      right: 0;

      &:before {
        border-right: 2px solid $color-white;
        margin-right: 10px;
        transform: rotate(-45deg);

        :global(.now-fresh) & {
          border-right: 2px solid $now-brown;
        }
      }

      &:hover:before {
        border-color: $go-yellow;

        :global(.now-fresh) & {
          border-color: $now-light-tan;
        }

        :global(.summit) & {
          border-color: $summit-blue;
        }
      }
    }

    &.slick-prev {
      left: 0;
      right: auto;

      &:before {
        border-left: 2px solid $color-white;
        margin-left: 10px;
        transform: rotate(45deg);

        :global(.now-fresh) & {
          border-left: 2px solid $now-brown;
        }
      }

      &:hover:before {
        border-color: $go-yellow;

        :global(.now-fresh) & {
          border-color: $now-light-tan;
        }

        :global(.summit) & {
          border-color: $summit-blue;
        }
      }
    }
  }
}
