
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: grid;
  grid-gap: 4px;

  :global(.now-fresh) & {
    grid-gap: 0;
  }

  :global(.petcurean) & {
    grid-gap: 8px;
  }

  :global(.summit) & {
    grid-gap: 8px;
  }

  @include at-breakpoint(medium) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 24px;
  }

  @include at-breakpoint(large) {
    :global(.petcurean) & {
      grid-gap: 40px;
    }
  }

  &.x-large-no-wrap {
    @include at-breakpoint(x-large) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
    }

    :global(.now-fresh) & {
      grid-gap: 0;
      grid-column: span 12;
      padding: 0;

      @include at-breakpoint(x-large) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
    }
  }

  &.has-eyebrow {
    :global(.gather) & {
      grid-gap: 10px;
    }

    :global(.now-fresh) & {
      grid-gap: 0;
    }

    @include at-breakpoint(medium) {
      grid-gap: 16px;
    }

    @include at-breakpoint(large) {
      grid-gap: 24px;
    }

    .ingredient {
      align-items: flex-start;
      padding-inline-end: 16px;

      @include at-breakpoint(medium) {
        padding: 24px 24px 32px 24px;
      }

      @include at-breakpoint(x-large) {
        padding: 24px 28px 32px 24px;

        :global(.locale-he) & {
          padding: 24px 24px 32px 28px;
        }
      }

      :global(.petcurean) & {
        padding-top: 32px;

        @include at-breakpoint(x-large) {
          padding-top: 56px;
        }
      }

      :global(.now-fresh) & {
        @include at-breakpoint(x-large) {
          padding: 100px 60px;
        }
      }

      :global(.summit) & {
        align-items: center;
        padding: 32px 24px;

        @include at-breakpoint(medium) {
          padding: 25px 24px;
        }

        @include at-breakpoint(large) {
          padding: 28px 24px;
        }
      }
    }

    .ingredient-img {
      @include at-breakpoint(medium) {
        margin-bottom: 24px;
      }

      :global(.petcurean) & {
        margin-bottom: 16px;
      }

      :global(.summit) & {
        @include at-breakpoint(medium) {
          margin-bottom: 22px;
        }
      }
    }

    .ingredient-desc {
      @include p2();

      :global(.gather) & {
        font-size: 16px;
        line-height: 1.75;
        font-weight: 300;
      }

      :global(.go-solutions) & {
        text-transform: none;
      }

      :global(.petcurean) & {
        font-weight: 300;
        @include font-sizes(18px, 21px, 21px);
      }
    }
  }
}

.ingredient {
  display: flex;
  align-items: center;
  padding: 32px 24px;

  @include at-breakpoint(medium) {
    display: block;
    padding: 38px 32px 32px 32px;
  }

  @include at-breakpoint(large) {
    padding: 40px 32px;
  }

  @include at-breakpoint(x-large) {
    padding: 32px 30px 32px 32px;

    :global(.locale-he) & {
      padding: 32px 32px 32px 30px;
    }
  }

  :global(.gather) & {
    background-color: $gather-green;
    color: $gather-white;
    border-radius: 20px;
  }

  :global(.go-solutions) & {
    background-color: $go-charcoal;
  }

  :global(.now-fresh) & {
    flex-direction: column;
    align-items: flex-start;
    background-color: $color-white;
    color: $now-brown;
    border-bottom: 1px solid $now-brown;

    @include at-breakpoint(medium) {
      border-right: 1px solid $now-brown;

      :global(.locale-he) & {
        border-right: none;
        border-left: 1px solid $now-brown;
      }
    }

    &:nth-child(even) {
      @include at-breakpoint(medium) {
        border-right: none;

        :global(.locale-he) & {
          border-left: none;
        }
      }
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      @include at-breakpoint(medium) {
        border-bottom: none;
      }
    }

    @include at-breakpoint(x-large) {
      padding: 100px 60px;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    color: $petcurean-white;
    border-radius: 20px;

    @include at-breakpoint(medium) {
      padding-top: 48px;
    }
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;
    padding: 20px 32px;

    @include at-breakpoint(medium) {
      padding: 54px 32px;
    }
  }
}

.ingredient-img {
  display: block;
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }

  @include at-breakpoint(medium) {
    width: 48px;
    height: 48px;
    margin-bottom: 56px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  :global(.gather) & {
    svg {
      path,
      polygon {
        fill: $gather-white;
      }
    }
  }

  :global(.go-solutions) & {
    svg {
      path,
      polygon {
        fill: $color-white;
      }
    }
  }

  :global(.now-fresh) & {
    svg {
      path,
      polygon {
        fill: $now-brown;
      }
    }
  }

  :global(.petcurean) & {
    @include at-breakpoint(medium) {
      margin-bottom: 28px;
    }

    svg {
      path,
      polygon {
        fill: $petcurean-white;
      }
    }
  }

  :global(.summit) & {
    svg {
      path,
      polygon {
        fill: $summit-white;
      }
    }
  }
}

.ingredient-text {
  margin-inline-start: 24px;

  @include at-breakpoint(medium) {
    margin-inline-start: 0;
  }

  :global(.now-fresh) & {
    margin-inline-start: 0;
    margin-top: 25px;

    @include at-breakpoint(medium) {
      margin-top: 0;
    }
  }
}

.ingredient-eyebrow {
  @include eyebrow();
  margin-bottom: 8px;

  :global(.gather) & {
    margin-bottom: 16px;
  }

  :global(.petcurean) & {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 16px;
  }

  :global(.summit) & {
    @include h5();
    margin-bottom: 10px;
  }
}

.ingredient-desc {
  font-size: 18px;
  line-height: 1.3333;
  flex-grow: 1;

  @include at-breakpoint(x-large) {
    font-size: 20px;
    line-height: 1.2;
  }

  :global(.gather) & {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 300;
  }

  :global(.go-solutions) & {
    text-transform: uppercase;
    font-weight: 900;
  }

  :global(.now-fresh) & {
    @include font-sizes(18px, 18px, 22px);
    font-family: 'Stag', sans-serif;
    line-height: 1.5;
    font-weight: 400;

    @include at-breakpoint(medium) {
      font-size: 20px;
      line-height: 1.35;
    }
  }

  :global(.petcurean) & {
    @include p2();
    @include font-sizes(18px, 21px, 21px);
    font-weight: 300;
  }

  :global(.summit) & {
    @include p3();

    @include at-breakpoint(medium) {
      @include p2();
    }
  }
}
