$breakpoints: (
  'small': (
    max-width: 719px,
  ),
  'medium': (
    min-width: 720px,
  ),
  'large': (
    min-width: 1024px,
  ),
  'x-large': (
    min-width: 1280px,
  ),
) !default;

@mixin at-breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
