
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.footer {
  padding: 60px 0 29px;

  @include at-breakpoint(large) {
    padding: 80px 0 46px;
  }

  @include at-breakpoint(x-large) {
    padding: 99px 0 50px;
  }
}

.container {
  @include grid-container();
}

.column {
  grid-column: span 3;
  margin-bottom: 60px;

  @include at-breakpoint(large) {
    margin-bottom: 0;
  }
}

.column-header {
  @include h5();
  text-transform: uppercase;
  margin-bottom: 16px;

  :global(.now-fresh) & {
    letter-spacing: 1px;
  }

  @include at-breakpoint(large) {
    margin-bottom: 24px;
  }

  @include at-breakpoint(x-large) {
    margin-bottom: 32px;
  }
}

.column-link-container {
  @include p2();
  margin-bottom: 8px;
}

.column-link {
  margin-bottom: 8px;

  @include at-breakpoint(large) {
    @include p2();
  }
}

.social-column-link-container {
  display: flex;
  align-items: center;

  .column-link {
    margin-bottom: 0;

    span {
      display: flex;
      align-items: center;
      margin: 0 0 8px;

      svg {
        transform: none;
        margin: 0 auto;
        display: block;
      }
    }
  }
}

.social-icon {
  width: 16px;
  margin: 0 18px 0 0;
  height: 24px;
  padding-top: 3px;

  :global(.locale-he) & {
    margin: 0 0 0 18px;
    order: 1;
  }

  @include at-breakpoint(x-large) {
    height: 24px;
  }
}

.bottom {
  margin-top: 47px;

  @include at-breakpoint(large) {
    margin-top: 109px;
  }
}

.branding {
  display: flex;
  align-items: center;
  grid-column: span 12;
  flex-wrap: wrap;

  @include at-breakpoint(x-large) {
    grid-column: span 4;
  }
}

.logo {
  margin: 13px 30px 0 0;

  :global(.locale-he) & {
    margin: 13px 0 0 30px;
  }

  &:focus {
    outline: 2px solid $outline;
  }

  @include at-breakpoint(large) {
    margin-top: 0;

    :global(.locale-he) & {
      margin-top: 0;
    }
  }
}

.brand-slogan {
  white-space: nowrap;
  margin-top: 13px;

  @include at-breakpoint(large) {
    margin-top: 0;
  }

  & > * {
    @include p2();
  }
}

.bottom-links {
  display: flex;
  align-items: center;
  grid-column: span 12;
  margin-top: 21px;
  flex-wrap: wrap;

  @include at-breakpoint(x-large) {
    grid-column: 7/13;
    justify-content: flex-end;
    margin-top: 0;
  }
}

.bottom-link {
  font-size: 13px;
  line-height: 22px;
  text-decoration: underline;
  margin-inline-end: 12px;

  svg {
    path {
      fill: $color-black;
    }
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}

:global(.go-solutions) {
  .footer {
    background-color: $color-white;
  }

  .column-link {
    color: black;
  }

  .social-column-link-container {
    @media (hover: hover) {
      .column-link:hover {
        svg {
          path {
            fill: $go-yellow;
          }
        }
      }
    }
  }

  .brand-slogan {
    a {
      font-weight: 600;
      color: $color-black;

      svg {
        display: none;
      }

      @media (hover: hover) {
        &:hover {
          color: $go-yellow;
        }
      }
    }
  }

  .bottom-link {
    color: $color-black;
  }
}

:global(.now-fresh) {
  .footer {
    background-color: $color-white;
    outline: 1px solid $now-brown;

    svg {
      path {
        fill: $now-brown;
      }
    }
  }

  .column-header {
    color: $now-brown;
    margin-top: 12px;
    text-transform: none;
    letter-spacing: 0;
    @include font-sizes(16px, 16px, 20px);

    @include at-breakpoint(large) {
      margin-top: 24px;
    }
  }

  .column-link {
    color: $now-brown;
    @include font-sizes(14px, 14px, 16px);

    a,
    span {
      font-family: Almarai, sans-serif !important;
    }

    &:hover {
      a,
      span {
        text-decoration: underline;
      }
    }
  }

  .social-column-link-container {
    @media (hover: hover) {
      .column-link:hover {
        svg {
          path {
            fill: $now-brown;
          }
        }
      }
    }
  }

  .brand-slogan {
    color: $now-brown;
    font-weight: normal;

    p,
    a {
      @include font-sizes(14px, 14px, 16px);
    }

    a {
      font-weight: 500;
      color: $now-brown;

      svg {
        display: none;
      }

      @media (hover: hover) {
        &:hover {
          color: $now-brown;
        }
      }
    }
  }

  .bottom-link {
    @include font-sizes(14px, 14px, 16px);
    color: $now-brown;
  }
}

/* Gather Styles */
:global(.gather) {
  .footer {
    background-color: $gather-light-cream;
  }

  .container {
    @include flex-container();
    justify-content: flex-start;
    padding: 0;

    @include at-breakpoint(medium) {
      padding: 0 32px;
      justify-content: flex-start;
      gap: calc(1 / 12 * 100%);
    }

    @include at-breakpoint(large) {
      width: calc(8 / 12 * $max-width);
    }
  }

  .column {
    width: fit-content;

    margin-bottom: 35px;

    @include at-breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  .column-header {
    font-size: 22px;
    line-height: 120%;
    color: $gather-green;
    text-transform: none;
  }

  .column-link {
    font-size: 16px;
    font-weight: 300;
    color: $gather-black;
  }

  .bottom {
    display: grid;
    margin-top: 0;

    @include at-breakpoint(medium) {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
    }

    @include at-breakpoint(large) {
      margin-top: 50px;
    }
  }

  .brand-slogan {
    a,
    p {
      font-size: 12px;
      color: $gather-black;
    }
  }

  .bottom-links {
    position: relative;
    margin-top: 8px;

    @include at-breakpoint(large) {
      gap: 35px;
    }

    @include at-breakpoint(x-large) {
      margin-top: 0;
    }
  }

  .bottom-link {
    @include font-sizes(10px, 10px, 13px, 13px);
    color: $gather-black;
  }
}
/* Gather Styled End */

/* Petcurean  Styles */
:global(.petcurean) {
  .footer {
    background-color: $petcurean-white;
    padding: 60px 0 29px;

    svg {
      path {
        fill: $petcurean-grey;
      }
    }

    @include at-breakpoint(large) {
      padding: 48px 0 73px;
    }

    @include at-breakpoint(x-large) {
      padding: 68px 0 41px;
    }
  }

  .column-full-width {
    & > .column:last-child {
      grid-column: span 6;
    }
  }

  .container:nth-child(1) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :global(.locale-zh) & {
      justify-content: flex-start;

      @include at-breakpoint(medium) {
        justify-content: space-evenly;
      }
    }
  }

  .container:nth-child(2) {
    display: flex;
    justify-content: flex-start;

    @include at-breakpoint(medium) {
      justify-content: space-evenly;
    }

    :global(.locale-zh) & {
      justify-content: flex-start;

      @include at-breakpoint(medium) {
        justify-content: space-evenly;
      }
    }
  }

  .column {
    @include at-breakpoint(small) {
      width: 40%;
    }

    @include at-breakpoint(medium) {
      width: auto;
    }
  }

  .column-header {
    color: $petcurean-orange;
    text-transform: none;
    font-size: 22px;
    line-height: 127%;
    font-weight: 600;
    margin: 22px 0;

    @include at-breakpoint(large) {
      margin: 24px 0 35px;
    }
  }

  .column-link-container {
    margin-bottom: 9px;

    @include at-breakpoint(large) {
      margin-bottom: 25px;
    }

    & {
      color: $petcurean-grey;
    }

    &:hover {
      color: $petcurean-orange;
      cursor: pointer;
    }
  }

  .column-link {
    font-size: 16px;
    color: $petcurean-grey;
    position: relative;

    & > svg {
      position: absolute;
      bottom: 7px;
    }

    &:hover {
      color: $petcurean-orange;

      & > svg {
        path {
          fill: $petcurean-orange;
        }
      }
    }
  }

  .social-column-link-container {
    color: $petcurean-grey;
    line-height: 1.5;
    @media (hover: hover) {
      .column-link:hover {
        svg {
          path {
            fill: $petcurean-orange;
          }
        }
        color: $petcurean-orange;
        cursor: pointer;
      }
    }

    @include at-breakpoint(medium) {
      margin-bottom: 20px;
    }

    @include at-breakpoint(large) {
      margin-bottom: 12px;
    }
  }

  .branding {
    display: none;
  }

  .bottom {
    margin-top: 27px;

    @include at-breakpoint(large) {
      margin-top: 109px;
    }

    @include at-breakpoint(x-large) {
      margin-top: 116px;
    }
  }

  .bottom-links {
    margin-bottom: 49px;
    padding: 0;

    & > li {
      grid-column: span 3;
      margin-inline-end: 16px;
    }

    display: flex;
    justify-content: space-between;

    :global(.locale-zh) & {
      justify-content: space-between;
      width: 65%;
    }

    @include at-breakpoint(medium) {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      :global(.locale-zh) & {
        justify-content: center;
      }

      & > li {
        grid-column: span 1;
        &:not(:first-child):not(:nth-child(2)) {
          padding-top: 0;
        }
      }
    }

    @include at-breakpoint(large) {
      @include grid-container();
      display: flex;
      grid-column: span 6;
      justify-content: flex-end;
      margin: 0;

      & > li {
        margin-inline-start: 6px;
      }
    }

    @include at-breakpoint(x-large) {
      margin-bottom: 0;
    }
  }

  .bottom-link {
    color: $petcurean-grey;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.7;
    margin-inline-end: 0;

    &:hover {
      color: $petcurean-orange;

      & > svg {
        path {
          fill: $petcurean-orange;
        }
      }
    }
  }
}
/* Petcurean  Styles End */

/*Summit Styles */
:global(.summit) {
  .footer {
    background-color: $gather-light-cream;
    padding: 24px 0;

    @include at-breakpoint(medium) {
      padding: 48px 0 25px 0;
    }

    @include at-breakpoint(large) {
      padding: 56px 0 48px 0;
    }
  }

  .container {
    @include flex-container();
    justify-content: flex-start;
    padding: 0;

    @include at-breakpoint(medium) {
      padding: 0 32px;
      justify-content: flex-start;
      gap: calc(1 / 12 * 100%);
    }

    @include at-breakpoint(large) {
      width: calc(8 / 12 * $max-width);
    }
  }

  .column {
    width: fit-content;

    margin-bottom: 35px;

    @include at-breakpoint(medium) {
      margin-bottom: 0;
    }
  }

  .column-header {
    font-size: 20px;
    line-height: 120%;
    font-weight: 400;
    color: $summit-blue;
  }

  .column-link {
    font-size: 16px;
    font-weight: 300;
    color: $summit-black;
  }

  .bottom {
    display: grid;
    margin-top: 0;

    @include at-breakpoint(medium) {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
    }

    @include at-breakpoint(large) {
      margin-top: 50px;
    }
  }

  .brand-slogan {
    p,
    a {
      font-size: 12px;
      font-weight: 300;
      color: $summit-black;
    }

    a {
      font-weight: 700;

      svg {
        display: none;
      }
    }
  }

  .bottom-links {
    position: relative;
    margin-top: 8px;

    @include at-breakpoint(large) {
      gap: 35px;
    }

    @include at-breakpoint(x-large) {
      margin-top: 0;
    }
  }

  .bottom-link {
    @include font-sizes(10px, 10px, 13px, 13px);
    color: $summit-black;
  }
}
/* Summit Styles End */
