
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding: 0;
  text-align: start;
  margin-bottom: 32px;

  &:not(:first-child) {
    margin-top: 32px;
  }
}

.option {
  display: flex;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0px;
  }
}

.label {
  :global(.now-fresh) & {
    font-size: 16px;
  }
}

.error {
  .input {
    box-shadow: 0px 0px 0px 2px $now-grey inset;
    background-color: $color-light-red;

    &::after {
      svg {
        background-color: $color-dark-red;
      }
    }

    :global(.go-solutions) & {
      background-color: $go-pink;

      &::after {
        svg {
          background-color: $color-dark-red;
        }
      }
    }

    :global(.now-fresh) & {
      background-color: $now-transparent-red;
      box-shadow: 0px 0px 0px 2px $now-red inset;
      border-color: $now-red;

      &::after {
        svg {
          background-color: $now-red;
        }
      }
    }
  }

  .description {
    color: $color-dark-red;
    // Brands added for specificity
    :global(.go-solutions) & {
      color: $color-dark-red;
    }

    :global(.now-fresh) & {
      color: $now-red;
    }
  }

  .error-msg {
    display: block;

    :global(.summit) & {
      @include errormsg();
    }
  }
}

.description {
  margin-bottom: 16px;

  :global(.now-fresh) & {
    font-size: 16px !important;
    font-family: Stag, sans-serif !important;
  }
}

.input-wrapper {
  position: relative;
}

.input {
  position: relative;
  appearance: none;
  border: none;
  border-radius: 100%;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  margin-inline-end: 12px;

  &:focus {
    box-shadow: 0px 0px 0px 2px $outline inset;

    :global(.go-solutions) & {
      box-shadow: 0px 0px 0px 2px $color-white inset;
    }

    :global(.now-fresh) & {
      box-shadow: 0px 0px 0px 2px $now-brown inset;
    }
  }

  &:checked {
    :global(.gather) & {
      background-color: $gather-green;
    }

    :global(.now-fresh) & {
      background-color: $now-grey;
    }

    :global(.petcurean) & {
      background-color: $petcurean-orange;
    }

    :global(.summit) & {
      background-color: $summit-blue;
    }

    &:focus {
      :global(.go-solutions) & {
        box-shadow: 0px 0px 0px 2px $go-yellow inset;
      }

      :global(.now-fresh) & {
        box-shadow: 0px 0px 0px 2px $now-brown inset;
      }
    }

    &::after {
      display: block;

      :global(.gather) & {
        background-color: $gather-cream;
      }

      :global(.go-solutions) & {
        background-color: $color-white;
      }

      :global(.now-fresh) & {
        background-color: $now-brown;
      }

      :global(.petcurean) & {
        background-color: $petcurean-white;
      }

      :global(.summit) & {
        background-color: $summit-white;
      }
    }
  }

  :global(.gather) & {
    background-color: rgba($gather-black, 0.07);
  }

  :global(.go-solutions) & {
    background-color: rgba(255, 255, 255, 0.18);
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
  }

  :global(.petcurean) & {
    background-color: rgba($petcurean-grey, 0.05);
  }

  :global(.summit) & {
    background-color: rgba($summit-black, 0.07);
  }

  &::after {
    content: '';
    display: none;
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    height: 12px;
    width: 12px;
  }
}

.error-msg {
  @include errormsg;
}
