
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.header {
  overflow: hidden;
  position: relative;

  :global(.go-solutions) & {
    background-color: $color-black;
    height: 262px;

    @include at-breakpoint(medium) {
      height: 315px;
    }

    @include at-breakpoint(large) {
      height: 314px;
    }
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    height: 257px;

    @include at-breakpoint(medium) {
      height: 304px;
    }

    @include at-breakpoint(large) {
      height: 360px;
    }
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;
  }

  &.margin-bottom {
    @include default-margin-bottom();
  }
}

.hero-container {
  position: relative;
  height: 100%;
  @include margin-container;
  text-align: start;

  :global(.now-fresh) & {
    @include grid-container();
    align-items: center;

    @include at-breakpoint(large) {
      padding: 0;
    }
  }
}

.hero-bg-container {
  :global(.now-fresh) & {
    position: absolute;
    grid-column: 4/7;
    width: 286px;
    height: 257px;
    right: -150px;

    :global(.locale-he) & {
      right: auto;
      left: -150px;
    }

    @include at-breakpoint(medium) {
      padding-bottom: 0;
      width: 353px;
      height: 304px;
      right: -100px;

      :global(.locale-he) & {
        right: auto;
        left: -100px;
      }
    }

    @include at-breakpoint(large) {
      grid-column: 9/13;
      width: 418px;
      height: 360px;
      right: -110px;

      :global(.locale-he) & {
        right: auto;
        left: -110px;
      }
    }

    @include at-breakpoint(x-large) {
      position: relative;
      grid-column: 9/13;
      right: 0;

      :global(.locale-he) & {
        right: auto;
        left: 0;
      }
    }
  }
}

.hero-bg {
  height: 100%;
  position: absolute;

  :global(.go-solutions) & {
    bottom: 0;
    right: -115px;
    width: 400px;
    height: auto;
    opacity: 0.5;

    :global(.locale-he) & {
      right: auto;
      left: -115px;
    }

    @include at-breakpoint(medium) {
      width: 480px;
      right: -110px;

      :global(.locale-he) & {
        right: auto;
        left: -110px;
      }
    }

    @include at-breakpoint(large) {
      width: 450px;
      right: 0;

      :global(.locale-he) & {
        right: auto;
        left: 0;
      }
    }
  }

  :global(.now-fresh) & {
    display: none;
  }
}

.hero-title {
  position: absolute;
  margin: 0;
  z-index: 1;

  :global(.go-solutions) & {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    color: $color-white;
    max-width: 80%;

    :global(.locale-he) & {
      left: auto;
      right: 0;
    }

    @include at-breakpoint(large) {
      @include h2;
    }
  }

  :global(.now-fresh) & {
    grid-column: span 4;
    position: static;
    @include font-sizes(30px, 48px, 48px);
    color: $now-brown;

    @include at-breakpoint(large) {
      grid-column: span 7;
    }
  }

  :global(.summit) & {
    position: relative;
    text-align: center;
    margin: 64px 0 30px;

    @include at-breakpoint(medium) {
      margin: 104px 0 60px;
    }
  }
}
