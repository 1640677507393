
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(7, 1fr);

  @include at-breakpoint(x-large) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.link-category {
  @include p2();
  display: flex;
  align-items: center;
  font-weight: 600;
  grid-column: span 12;
  margin: 16px 0 12px;

  &:first-child {
    margin-top: 2px;
  }

  &:after {
    display: inline-block;
    content: '';
    height: 8px;
    width: 8px;
    transform: rotate(-45deg);
    margin-inline-start: 10px;

    :global(.locale-he) & {
      transform: rotate(135deg);
    }
  }

  &.external {
    &:after {
      display: none;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 2;

    :global(.summit) &,
    :global(.gather) & {
      grid-column: span 3;
    }

    &:last-of-type {
      margin-bottom: auto;
    }

    &.full-width {
      grid-column: span 12;
    }
  }
}

.category {
  @include p2();
  text-transform: none;
  margin: 16px 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  vertical-align: top;
  align-self: baseline;
  text-align: start;
  grid-column: span 12;

  &:first-child {
    margin: 4px 2px 2px;
  }

  @include at-breakpoint(large) {
    grid-column: span 2;

    &:after {
      display: inline-block;
      content: '';
      height: 8px;
      width: 8px;
      transform: rotate(-45deg);
      margin-inline-start: 10px;

      :global(.locale-he) & {
        transform: rotate(135deg);
      }
    }
  }
}

.category-header {
  composes: category;
  pointer-events: none;
  margin-top: 3px;

  @include at-breakpoint(large) {
    pointer-events: auto;

    &.active {
      pointer-events: none;
    }
  }
}

.column-header-categories {
  display: flex;
  grid-column: span 12;
  flex-direction: column;

  @include at-breakpoint(large) {
    display: none;
  }
}

.column-header-category {
  .category {
    &:after {
      display: inline-block;
      content: '';
      height: 8px;
      width: 8px;
      transform: rotate(-45deg);
      margin-inline-start: 10px;

      :global(.locale-he) & {
        transform: rotate(135deg);
      }
    }
  }
}

.subnav-content {
  position: fixed;
  left: calc(100%);
  width: 100vw;
  height: 100vh;
  top: 0;
  align-items: flex-start;
  grid-column: span 6;
  background-color: $color-black;
  display: inline-flex;
  flex-direction: column;

  @include at-breakpoint(medium) {
    min-height: 968px;
  }

  @include at-breakpoint(large) {
    position: static;
    left: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    grid-column: span 5;
    flex-direction: row;
    min-height: auto;

    :global(.summit) &,
    :global(.gather) & {
      grid-column: span 4;
    }
  }

  @include at-breakpoint(x-large) {
    grid-column: span 6;

    :global(.summit) &,
    :global(.gather) & {
      grid-column: 4/-1;
    }
  }

  :global(.now-fresh) & {
    left: calc(103%);

    @include at-breakpoint(large) {
      left: 0;
    }
  }
}

.column {
  width: 100%;
  display: none;

  @include at-breakpoint(large) {
    display: block;
    width: auto;

    :global(.now-fresh) & {
      margin-top: 5px;
    }
  }

  .interactive-column-header {
    @include eyebrow();
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: $color-white;
    margin: 37px 0 59px;

    &:before {
      display: inline-block;
      content: '';
      width: 8px;
      height: 8px;
      border-bottom: 2px solid $color-white;
      border-left: 2px solid $color-white;
      transform: rotate(45deg);
      position: absolute;
      left: 24px;

      :global(.locale-he) & {
        left: auto;
        right: 24px;
        transform: rotate(225deg);
      }
    }

    @include at-breakpoint(medium) {
      &:before {
        left: 40px;

        :global(.locale-he) & {
          left: auto;
          right: 24px;
        }
      }
    }

    @include at-breakpoint(large) {
      display: none;
    }
  }
}

.active-column {
  display: block;
}

.column-header {
  display: none;
  @include eyebrow();
  color: $go-teal;
  margin: 9px 0 17px;

  @include at-breakpoint(large) {
    display: block;
  }
}

.links {
  display: flex;
  flex-direction: column;

  @include at-breakpoint(large) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 24px;
    grid-auto-flow: column;

    :global(.gather) & {
      grid-template-rows: 1fr auto;
    }
  }
}

.link {
  @include p3();
  font-size: 14px;
  margin: 0 16px 12px;
  text-transform: none;
  font-weight: 600;

  @include at-breakpoint(medium) {
    margin: 0 32px 32px;
  }

  @include at-breakpoint(large) {
    font-size: 12px;
    min-width: 160px;
    margin: 4px 0 0;
    line-height: 2;
  }
}

.background-image {
  composes: background-image from './navigation.module.scss';
}

/* Gather Styles */
:global(.gather) {
  .external {
    svg {
      path {
        fill: $gather-white;
      }
    }

    @media (hover: hover) {
      &:hover {
        svg {
          path {
            fill: $gather-dark-green;
          }
        }
      }
    }
  }

  .link-category {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.33;

    @include at-breakpoint(large) {
      font-weight: 400;
    }

    &:after {
      content: '';
      border-bottom: 2px solid $gather-white;
      border-right: 2px solid $gather-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $gather-dark-green;

        &:after {
          content: '';
          border-bottom: 2px solid $gather-lighter-green;
          border-right: 2px solid $gather-lighter-green;

          @include at-breakpoint(large) {
            border-bottom: 2px solid $gather-dark-green;
            border-right: 2px solid $gather-dark-green;
          }
        }
      }
    }
  }

  .link {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: $gather-white;
    margin-bottom: 6px;
    font-size: 16px;
    text-transform: none;

    svg {
      margin: -15px 0 0 5px;
      stroke: $gather-white;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          stroke: $gather-dark-green;
        }
      }
    }
  }

  .category {
    color: $gather-white;
    font-weight: 300;

    @include at-breakpoint(large) {
      font-weight: 700;
    }

    &:after {
      border-bottom: 2px solid $gather-white;
      border-right: 2px solid $gather-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $gather-lighter-green;

        &:after {
          border-bottom: 2px solid $gather-lighter-green;
          border-right: 2px solid $gather-lighter-green;
        }
      }

      @include at-breakpoint(large) {
        &:hover {
          color: $gather-dark-green;

          &:after {
            border-bottom: 2px solid $gather-dark-green;
            border-right: 2px solid $gather-dark-green;
          }
        }
      }
    }
  }

  .column-header,
  .interactive-column-header span {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.33;
    color: $gather-dark-green;
  }

  .column-header {
    margin-top: 2px;
  }

  .subnav-content {
    background-color: $gather-green;
    color: $gather-white;

    @include at-breakpoint(large) {
      background-color: transparent;
      color: $gather-white;
    }
  }
}
/* End Gather Styles */

/* Go Solutions Styles */
:global(.go-solutions) {
  .external {
    svg {
      path {
        fill: $color-white;
      }
    }

    @media (hover: hover) {
      &:hover {
        svg {
          path {
            fill: $go-yellow;
          }
        }
      }
    }
  }

  .link-category {
    &:after {
      content: '';
      border-bottom: 2px solid $color-white;
      border-right: 2px solid $color-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $go-yellow;

        &:after {
          content: '';
          border-bottom: 2px solid $go-yellow;
          border-right: 2px solid $go-yellow;
        }
      }
    }
  }

  .links {
    @include at-breakpoint(large) {
      grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
      grid-column-gap: 500px; // temporary fix
      grid-auto-flow: row;
    }
  }

  .column {
    @include at-breakpoint(large) {
      margin-right: 30px;
    }
  }

  .category {
    color: $color-white;

    &:after {
      border-bottom: 2px solid $color-white;
      border-right: 2px solid $color-white;
    }
    @media (hover: hover) {
      &:hover {
        color: $go-yellow;

        &:after {
          border-bottom: 2px solid $go-yellow;
          border-right: 2px solid $go-yellow;
        }
      }
    }
  }

  .subnav-content {
    @include at-breakpoint(large) {
      align-items: flex-start;
    }
  }
}
/* End Go Solutions Styles */

/* Now Fresh Styles */
:global(.now-fresh) {
  .external {
    @media (hover: hover) {
      &:hover {
        svg {
          path {
            stroke: $now-light-tan;
          }
        }
      }
    }
  }

  .link-category {
    color: $now-brown;
    @include bold();

    a,
    span {
      @include font-sizes(17px, 17px, 16px);
    }

    &:after {
      border-bottom: 2px solid $now-brown;
      border-right: 2px solid $now-brown;
    }

    @media (hover: hover) {
      &:hover {
        color: $now-light-tan;

        &:after {
          border-bottom: 2px solid $now-brown;
          border-right: 2px solid $now-brown;
        }
      }
    }

    @include at-breakpoint(large) {
      color: $now-brown;

      &:after {
        border-bottom: 2px solid $now-brown;
        border-right: 2px solid $now-brown;
      }
    }
  }

  .category {
    @include p2();
    font-weight: normal;
    color: $now-brown;

    &:after {
      border-bottom: 2px solid $now-brown;
      border-right: 2px solid $now-brown;
    }

    @media (hover: hover) {
      &:hover {
        color: $now-brown;

        &:after {
          border-bottom: 2px solid $now-brown;
          border-right: 2px solid $now-brown;
        }
      }
    }
  }

  .column-header {
    @include eyebrow();
    margin-top: 5px;
    color: $now-brown;
  }

  .link {
    @include p3();
    font-size: 14px;
    @include bold();
    color: $now-brown;
    margin-bottom: 12px;

    &:hover {
      span {
        text-decoration: underline;
      }
    }

    span {
      font-family: Almarai, sans-serif;
    }
  }

  .category {
    color: $now-brown;
    @include bold();
  }

  .subnav-content {
    background-color: $now-tan;
    color: $now-brown;

    @include at-breakpoint(large) {
      background-color: transparent;
      color: $now-tan;
    }
  }

  .column {
    .interactive-column-header {
      font-family: Stag, sans-serif;
      color: $now-brown;

      @each $locale, $font-stack in $nf-primary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }

      &:before {
        border-color: $now-brown;
      }
    }
  }
}
/* End Now Fresh Styles */

/* Petcurean Styles */
:global(.petcurean) {
  .external {
    svg {
      path {
        fill: $petcurean-white;
      }
    }

    @media (hover: hover) {
      &:hover {
        svg {
          path {
            fill: $petcurean-dark-tan;
          }
        }
      }
    }
  }

  .link-category {
    font-weight: 600;
    &:after {
      content: '';
      border-bottom: 2px solid $petcurean-white;
      border-right: 2px solid $petcurean-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $petcurean-dark-tan;

        &:after {
          content: '';
          border-bottom: 2px solid $petcurean-dark-tan;
          border-right: 2px solid $petcurean-dark-tan;
        }
      }
    }
  }

  .link {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $petcurean-white;
    margin-bottom: 12px;
    font-size: 14px;
    text-transform: none;

    svg {
      margin: -15px 0 0 5px;
      stroke: $petcurean-white;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          stroke: $petcurean-dark-tan;
        }
      }
    }
  }

  .category {
    font-weight: 600;
    color: $petcurean-white;

    &:after {
      border-bottom: 2px solid $petcurean-white;
      border-right: 2px solid $petcurean-white;
    }
    @media (hover: hover) {
      &:hover {
        color: $petcurean-dark-tan;

        &:after {
          border-bottom: 2px solid $petcurean-dark-tan;
          border-right: 2px solid $petcurean-dark-tan;
        }
      }
    }
  }

  .column-header {
    @include eyebrow();
    margin-top: 5px;
    color: $petcurean-dark-tan;
  }

  .subnav-content {
    background-color: $petcurean-orange;
    color: $petcurean-white;

    @include at-breakpoint(large) {
      background-color: transparent;
      color: $petcurean-white;
    }
  }
}
/* End Petcurean Styles */

/* Summit Styles */
:global(.summit) {
  .external {
    svg {
      path {
        fill: $summit-white;
      }
    }

    @media (hover: hover) {
      &:hover {
        svg {
          path {
            fill: $summit-light-desaturated-blue;
          }
        }
      }
    }
  }

  .link-category {
    @include p2();
    font-weight: 700;
    font-size: 16px;
    line-height: 1.1;
    letter-spacing: normal;

    @include at-breakpoint(large) {
      @include summit-font-i18n(false, true, false);
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    &:after {
      content: '';
      border-bottom: 2px solid $summit-white;
      border-right: 2px solid $summit-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $summit-light-desaturated-blue;

        &:after {
          content: '';
          border-bottom: 2px solid $summit-light-desaturated-blue;
          border-right: 2px solid $summit-light-desaturated-blue;
        }
      }
    }
  }

  .link {
    display: flex;
    align-items: center;
    font-weight: 300;
    color: $summit-white;
    margin-bottom: 18px;
    font-size: 16px;
    text-transform: none;
    letter-spacing: 0;
    line-height: 1.2;

    svg {
      margin: -15px 0 0 5px;
      stroke: $summit-white;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          stroke: $summit-light-desaturated-blue;
        }
      }
    }
  }

  .category {
    color: $summit-white;
    font-weight: 400;

    @include at-breakpoint(large) {
      font-weight: 600;
    }

    &:after {
      border-bottom: 2px solid $summit-white;
      border-right: 2px solid $summit-white;
    }
    @media (hover: hover) {
      &:hover {
        color: $summit-light-desaturated-blue;

        &:after {
          border-bottom: 2px solid $summit-light-desaturated-blue;
          border-right: 2px solid $summit-light-desaturated-blue;
        }
      }
    }
  }

  .column-header,
  .interactive-column-header {
    @include eyebrow();
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: 2px;
    color: $summit-white;
  }

  .column-header {
    margin-top: 2px;
  }

  .subnav-content {
    background-color: $summit-blue;
    color: $summit-white;

    @include at-breakpoint(large) {
      background-color: transparent;
      color: $summit-white;
    }
  }
}
/* End Summit Styles */
