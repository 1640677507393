
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;

  span {
    display: block;
  }

  svg {
    height: 100%;
    display: block;
    margin: 0;

    path {
      :global(.gather) & {
        fill: $go-yellow;
      }

      :global(.go-solutions) & {
        fill: $go-yellow;
      }

      :global(.now-fresh) & {
        fill: $now-brown;
      }

      :global(.summit) & {
        fill: $summit-black;
      }
    }

    :global(.locale-he) & {
      // flip the stars so that any cutoff is at the inline end
      transform: scaleX(-1);
    }
  }
}
