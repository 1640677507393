
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;

  &.dark {
    .dot {
      background-color: $color-black;

      :global(.gather) & {
        background-color: $gather-green;
      }

      :global(.go-solutions) & {
        background-color: $go-charcoal;
      }

      :global(.now-fresh) & {
        background-color: $now-brown;
      }

      :global(.petcurean) & {
        background-color: $petcurean-orange;
      }

      :global(.summit) & {
        background-color: $summit-blue;
      }
    }
  }

  &.hover-safe {
    .dot {
      background-color: $color-black;

      :global(.gather) & {
        background-color: $gather-green;
      }

      :global(.go-solutions) & {
        background-color: $go-charcoal;
      }

      :global(.now-fresh) & {
        background-color: $now-tan;
      }

      :global(.petcurean) & {
        background-color: $petcurean-orange;
      }

      :global(.summit) & {
        background-color: $summit-blue;
      }
    }
  }
}

.dot {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: $color-white;
  animation: bouncedelay 1.4s infinite ease-in-out both;
  margin: 0 5px;

  :global(.gather) & {
    background-color: $gather-lighter-sapling-green;
  }

  :global(.go-solutions) & {
    background-color: $go-gray-lighter;
  }

  :global(.now-fresh) & {
    background-color: $now-light-tan;
  }

  :global(.petcurean) & {
    background-color: $petcurean-light-tan;
  }

  :global(.summit) & {
    background-color: $summit-light-desaturated-blue;
  }
}

.dot1 {
  animation-delay: -0.32s;
}

.dot2 {
  animation-delay: -0.16s;
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
