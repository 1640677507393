
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  @include grid-container();
  @include default-margin-bottom();

  :global(.go-solutions) & {
    background-color: $go-charcoal;
    padding-top: 48px;
    padding-bottom: 48px;

    @include at-breakpoint(medium) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    outline: 1px solid $now-brown;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.type-instagram {
    :global(.go-solutions) & {
      background-color: transparent;
      padding-top: 0;
      padding-bottom: 0;
    }

    .accent-images {
      :global(.now-fresh) & {
        display: none;
      }
    }

    .content {
      grid-column: span 6;

      @include at-breakpoint(medium) {
        grid-column: 2 / span 4;
      }

      @include at-breakpoint(large) {
        grid-column: span 10;
      }

      @include at-breakpoint(x-large) {
        grid-column: span 7;
      }

      :global(.go-solutions) & {
        @include at-breakpoint(large) {
          grid-column: span 9;
        }

        @include at-breakpoint(x-large) {
          grid-column: span 7;
        }
      }
    }

    .description {
      :global(.now-fresh) & {
        @include at-breakpoint(x-large) {
          margin-top: 24px;
        }
      }
    }

    .media-container {
      grid-column: span 6;
      padding: 0 16px;

      @include at-breakpoint(medium) {
        grid-column: 2 / span 4;
        padding: 0;
      }

      @include at-breakpoint(large) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-column: span 12;
        margin-inline-start: -24px;
      }
    }

    .media-element {
      @include at-breakpoint(large) {
        width: calc(33.3333% - 24px);
        margin-inline-start: 24px;

        &:only-child {
          width: 41.6666%;
        }
      }

      // Have to overwrite inline js value set
      iframe {
        min-width: 250px !important;
      }
    }

    &:not(.inline) {
      .media-element {
        @include at-breakpoint(large) {
          &:nth-child(-n + 3) {
            margin-top: 0;
          }
        }
      }

      // 2 column variant
      &.col2 {
        .content {
          @include at-breakpoint(large) {
            grid-column: 2 / span 10;
          }

          @include at-breakpoint(x-large) {
            grid-column: 3 / span 8;
          }
        }

        .media-container {
          @include at-breakpoint(large) {
            grid-column: 2 / span 10;
          }

          @include at-breakpoint(x-large) {
            grid-column: 3 / span 8;
          }

          .media-element {
            margin-top: 32px;

            &:first-child {
              margin-top: 0;
            }

            @include at-breakpoint(medium) {
              margin-top: 24px;
            }

            @include at-breakpoint(large) {
              width: calc(50% - 24px);
              margin-inline-start: 24px;

              &:nth-child(-n + 2) {
                margin-top: 0;
              }

              &:only-child {
                width: 41.6666%;
              }
            }
          }
        }
      }
    }

    .caption {
      display: none;
    }
  }

  &.inline {
    margin: 40px 0;
    padding: 0;

    :global(.go-solutions) & {
      background-color: transparent;
      padding-top: 0;
      padding-bottom: 0;
    }

    .media-container {
      grid-column: span 6;

      @include at-breakpoint(large) {
        grid-column: span 12;
      }
    }

    &.type-instagram {
      .media-element {
        @include at-breakpoint(large) {
          width: calc(50% - 24px);

          &:only-child {
            width: 60%;
          }

          &:nth-child(-n + 2) {
            margin-top: 0;
          }
        }

        & > div {
          display: flex;
          justify-content: center;

          iframe {
            :global(.gather) &,
            :global(.petcurean) & {
              // we need to overwrite the inline styles
              border-radius: 20px !important;
            }
          }
        }
      }
    }
  }
}

.accent-images {
  display: none;

  :global(.now-fresh) & {
    @include at-breakpoint(large) {
      position: absolute;
      display: block;
      width: 100%;
      height: calc(100% + 200px);
      overflow: hidden;
    }
  }
}

.accent {
  position: absolute;

  @include at-breakpoint(large) {
    top: 125px;
    left: calc(50% - (100% / 2));
    transform: translate(-30%, 0);

    &:last-of-type {
      top: 334px;
      left: auto;
      overflow: hidden;
      right: calc(50% - (100% / 2));
      transform: translate(30%, 0);

      img {
        max-width: 460px;
        width: 100%;
      }
    }

    img {
      max-width: 460px;
      width: 86%;
      width: 100%;
    }
  }

  @include at-breakpoint(x-large) {
    top: 40px;
    left: calc(50% - (1320px / 2));
    transform: translate(-30%, 0);

    &:last-of-type {
      top: 330px;
      right: calc(50% - (1320px / 2));
      transform: translate(30%, 0);
    }
  }
}

.content {
  position: relative;
  grid-column: span 6;
  z-index: 1;

  @include at-breakpoint(large) {
    grid-column: 2 / span 10;
  }

  @include at-breakpoint(x-large) {
    grid-column: 3 / span 8;
  }

  + .media-container {
    margin-top: 40px;
  }
}

.headline {
  @include h2();

  @include at-breakpoint(large) {
    @include h3();
  }

  :global(.gather) & {
    @include h3();
  }

  :global(.now-fresh) & {
    @include h2();
    font-weight: 400;
    @include font-sizes(25px, 40px, 40px);
  }

  :global(.petcurean) & {
    @include h3();
    color: $petcurean-orange;
  }

  :global(.summit) & {
    @include h3();
    color: $summit-blue;
  }
}

.description {
  @include p2();
  margin-top: 16px;

  @include at-breakpoint(large) {
    margin-top: 24px;
  }

  :global(.now-fresh) & {
    margin-top: 16px;
    font-size: 16px;
  }

  :global(.gather) &,
  :global(.petcurean) &,
  :global(.summit) & {
    @include p3();

    @include at-breakpoint(medium) {
      @include p2();
    }
  }
}

.media-container {
  position: relative;
  grid-column: span 6;
  z-index: 1;

  @include at-breakpoint(large) {
    grid-column: 2 / span 10;
  }

  @include at-breakpoint(x-large) {
    grid-column: 3 / span 8;
  }
}

.media-element {
  margin-top: 32px;

  @include at-breakpoint(medium) {
    margin-top: 24px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.caption {
  position: relative;
  @include p3();
  margin-top: 16px;
  grid-column: span 6;
  z-index: 1;

  @include at-breakpoint(large) {
    grid-column: 2 / span 10;
  }

  @include at-breakpoint(x-large) {
    grid-column: 3 / span 8;
  }

  :global(.now-fresh) & {
    letter-spacing: 1px;
  }
}
