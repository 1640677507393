
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
div.container {
  @include grid-container();
  padding: 0;
  grid-column: span 6;
  align-items: center;
  flex-direction: column;

  :global(.gather) &,
  :global(.petcurean) & {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @include at-breakpoint(medium) {
    padding: 0;

    :global(.gather) &,
    :global(.petcurean) & {
      position: relative;
      @include flex-container();
      margin-top: 32px;
      margin-bottom: 32px;
      flex-direction: row;
      padding: 0;
      height: auto;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 12;
    display: flex;
    flex-direction: row;
    padding: 0;
    grid-column-gap: 0;

    :global(.gather) &,
    :global(.petcurean) & {
      grid-column: span 12;
      display: flex;
      flex-direction: row;
    }
  }

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
  }
}

.image-container {
  position: relative;
  grid-column: 2/6;
  padding-bottom: calc(106% + 76px);
  margin: 0 auto -40% -37px;
  z-index: 1;
  width: calc(100% + 76px);

  :global(.gather) &,
  :global(.petcurean) & {
    width: 100%;
    margin: 0 auto -65% 0;
  }

  :global(.now-fresh) & {
    border: 1px solid $now-brown;
    border-top: none;
    height: 100%;
    background-color: $color-white;

    @include at-breakpoint(large) {
      border: none;
      border-right: 1px solid $now-brown;
    }
  }

  @include at-breakpoint(medium) {
    padding-bottom: 80%;
    margin: 0 auto -40%;
    width: 80%;

    :global(.gather) &,
    :global(.petcurean) & {
      position: absolute;
      min-width: 288px;
      min-height: 355px;
      left: -35px;
      width: auto;
      height: auto;
      padding-bottom: 0;
      margin-bottom: -5%;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 4;
    min-width: 355px;
    width: 355px;
    height: 355px;
    padding-bottom: 0;
    margin-bottom: 0;

    :global(.gather) &,
    :global(.petcurean) & {
      left: -35px;
      position: relative;
      grid-column: span 4;
    }

    :global(.now-fresh) & {
      min-width: 0;
      width: 500px;
    }
  }
}

.image,
.image > img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  :global(.gather) &,
  :global(.petcurean) & {
    left: auto;
    width: 100%;
    height: auto;

    @include at-breakpoint(medium) {
      left: -20px;
    }

    @include at-breakpoint(large) {
      left: auto;
    }
  }

  :global(.now-fresh) & {
    object-fit: contain;
  }
}

.background-shadow {
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: span 8;
    width: calc(100% + 24px);
    margin-inline-start: -156px;
  }

  @include at-breakpoint(x-large) {
    width: 100%;
  }

  &.no-image {
    grid-column: span 6;

    @include at-breakpoint(large) {
      grid-column: span 12;
      margin-inline-start: 0;
    }
  }
}

.background {
  display: flex;
  padding: 33% 40px 64px;
  flex-direction: column;

  @include at-breakpoint(medium) {
    padding: 33% 140px 64px;
  }

  @include at-breakpoint(large) {
    flex-direction: row;
    padding: 48px 45px 59px 210px;
  }

  @include at-breakpoint(x-large) {
    padding: 70px 103px 70px 210px;
  }

  //padding of content containers for psg
  :global(.gather) &,
  :global(.petcurean) &,
  :global(.summmit) & {
    @include at-breakpoint(medium) {
      padding: 48px 35px 48px 250px;
    }

    @include at-breakpoint(large) {
      padding: 48px 35px 48px 130px;
    }

    @include at-breakpoint(x-large) {
      padding: 48px 75px 48px 130px;
    }
  }

  :global(.gather) & {
    background-color: $gather-green;
    border-radius: 25px;
  }

  :global(.go-solutions) & {
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    @include at-breakpoint(large) {
      padding: 125px 103px 125px 210px;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    border-radius: 20px;
  }

  :global(.summit) & {
    background-color: $summit-blue;
  }

  .no-image & {
    @include grid-container();
    padding: 0;

    @include at-breakpoint(large) {
      margin-inline-start: 0;
    }
  }
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;

  @include at-breakpoint(large) {
    flex-direction: row;
  }

  .no-image & {
    padding: 64px 42px;
    grid-column: span 6;

    :global(.go-solutions) & {
      padding: 64px 42px;
    }

    :global(.now-fresh) & {
      padding: 80px 22px 85px;
    }

    @include at-breakpoint(large) {
      grid-column: 2/12;

      :global(.go-solutions) & {
        padding: 53px 0;
      }

      :global(.now-fresh) & {
        padding: 34px 0;
      }
    }
  }
}

.copy-container {
  color: $color-white;
  text-align: center;

  :global(.gather) & {
    color: $gather-white;
    text-align: left;
  }

  :global(.petcurean) & {
    color: $petcurean-white;
    text-align: left;
  }

  :global(.summit) & {
    color: $summit-white;
    text-align: left;
  }

  @include at-breakpoint(large) {
    max-width: 379px;
    text-align: start;
  }

  @include at-breakpoint(x-large) {
    max-width: 531px;
  }

  .no-image & {
    @include at-breakpoint(large) {
      max-width: 752px;
    }
  }
}

.eyebrow {
  @include eyebrow-box();
  margin-bottom: 0;

  :global(.gather) & {
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
    line-height: 16px;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    display: inline-block;
  }

  :global(.petcurean) & {
    font-weight: 600;
  }
}

.copy-container > .headline {
  @include h4();
  text-transform: uppercase;
  margin: 16px 0 8px;

  :global(.go-solutions) & {
    @include h4();
  }

  :global(.now-fresh) & {
    text-transform: none;
    color: $now-brown;
  }

  :global(.gather) & {
    text-transform: capitalize;
    margin-top: 10px;
    @include font-sizes(30px, 30px, 30px, 45px);
    @include line-heights(40px, 40px, 40px, 50px);
    font-weight: 400;
  }

  :global(.petcurean) & {
    color: $petcurean-white;
    font-weight: 600;
    margin-top: 3px;
    margin-bottom: 0;
    @include font-sizes(30px, 30px, 30px, 45px);
    @include line-heights(40px, 40px, 40px, 50px);
    text-transform: capitalize;
  }
}

.headline + p.copy {
  @include p2();
  margin-top: 0;

  :global(.gather) & {
    font-weight: 300;
    @include font-sizes(16px, 16px, 16px, 21px);
    @include line-heights(28px, 28px, 28px, 37px);
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-size: 16px;
  }

  :global(.petcurean) & {
    font-weight: 700;
    margin-top: 8px;
    @include font-sizes(16px, 16px, 16px, 21px);
    font-weight: 400;

    @include at-breakpoint(large) {
      margin-top: 24px;
    }
  }
}

.cta {
  display: block;
  margin: 32px auto 0;

  @include at-breakpoint(large) {
    margin: auto 0 auto auto;
    margin-inline-start: 74px;
  }

  @include at-breakpoint(x-large) {
    margin-inline-start: auto;
  }

  p {
    font-size: 16px;
    line-height: 1.5em;
    font-weight: normal;
    white-space: nowrap;
    text-transform: none;
    position: relative;
    color: white;
  }

  :global(.gather) & {
    margin-left: 0;
    @include at-breakpoint(large) {
      margin-left: auto;
    }

    text-align: center;
    * {
      font-size: 14px;
      font-weight: 700;
    }
  }

  :global(.now-fresh) & {
    p {
      color: $now-brown;
    }
  }

  :global(.petcurean) & {
    margin-left: 0;
    @include at-breakpoint(large) {
      margin-left: auto;
    }

    text-align: center;
    * {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
}
