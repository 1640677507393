
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding: 24px 16px;
  border-bottom: 1px solid $go-gray;
  text-align: start;
  width: 100%;
  position: relative;
  display: none;

  :global(.gather) & {
    color: $gather-black;

    @include at-breakpoint(large) {
      padding: 24px 0;
    }
  }

  :global(.summit) & {
    color: $summit-black;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    top: 0;
    transition:
      transform 0.25s,
      height 0.25s;

    @include reducedMotion() {
      transition: none;
    }

    @include at-breakpoint(large) {
      width: 8px;
      height: 100%;
      transform: translateX(-8px);

      :global(.gather) & {
        border-left: 8px solid $gather-green;
      }

      :global(.go-solutions) & {
        border-left: 8px solid $go-teal;
      }

      :global(.now-fresh) & {
        border-left: 8px solid $now-grey;
      }

      :global(.summit) & {
        border-left: 8px solid $summit-blue;
      }
    }
  }

  &.grid {
    display: flex;
    cursor: initial;

    @include at-breakpoint(large) {
      cursor: auto;
      padding-inline-start: 0;
      padding-inline-end: 0;

      &::before {
        display: none;
      }
    }
  }

  &.visible {
    display: block;

    &.grid {
      display: flex;

      & > span {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        height: 100%;
      }
    }
  }

  &.active {
    @include at-breakpoint(large) {
      &::before {
        transform: translateX(0);
      }
    }

    :global(.gather) & {
      border-top: 8px solid $gather-green;

      @include at-breakpoint(large) {
        border-top: 0;
        padding-inline-start: 24px;
      }
    }

    :global(.go-solutions) & {
      border-top: 8px solid $go-teal;

      @include at-breakpoint(large) {
        border-top: 0;
      }
    }

    :global(.now-fresh) & {
      border-top: 8px solid $now-grey;

      @include at-breakpoint(large) {
        border-top: 0;
      }
    }

    :global(.summit) & {
      border-top: 8px solid $summit-blue;

      @include at-breakpoint(large) {
        border-top: 0;
      }
    }
  }

  @include at-breakpoint(medium) {
    padding: 32px 32px 30px;
  }

  @include at-breakpoint(large) {
    display: block;
  }

  :global(.now-fresh) & {
    border-color: rgba($now-brown, 0.2);
  }
}

.close-icon {
  position: absolute;
  right: 16px;
  top: 16px;

  @include at-breakpoint(large) {
    display: none;
  }

  :global(.gather) & {
    svg {
      path {
        fill: $gather-black;
      }
    }
  }

  :global(.go-solutions) & {
    svg {
      path {
        fill: $go-charcoal;
      }
    }
  }

  :global(.now-fresh) & {
    svg {
      path {
        fill: $now-brown;
      }
    }
  }
}

.store-name {
  @include p1();
  @include bold();

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.gather) & {
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
  }
}

.distance {
  @include p3();
  color: $go-semidark-grey;
  margin-bottom: 14px;

  :global(.now-fresh) & {
    color: $now-grey;
  }

  :global(.gather) & {
    color: $gather-black;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }

  :global(.summit) & {
    color: $summit-black;
    margin-top: 10px;
    font-size: 12px;
    line-height: 21px;
    font-weight: 300;
  }
}

.address-container {
  flex-grow: 1;
}

.address {
  @include p2();
  margin-bottom: 13px;

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.gather) & {
    @include bold();
    @include font-sizes(12px, 16px, 16px);
    @include line-heights(16px, 24px, 24px);
  }

  :global(.summit) & {
    @include bold();
    @include font-sizes(12px, 16px, 16px);
    @include line-heights(16px, 18px, 18px);
  }
}

.curbside {
  @include p3();
  color: $go-semidark-grey;

  :global(.gather) & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: $gather-black;

    svg {
      path {
        fill: $gather-black;
      }
    }
  }

  :global(.summit) & {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: $summit-black;

    svg {
      path {
        fill: $summit-black;
      }
    }
  }

  svg {
    width: 12px;
    height: 11px;
    margin-inline-end: 13px;
  }

  :global(.now-fresh) & {
    color: $now-grey;

    svg {
      path {
        fill: $now-brown;
      }
    }
  }
}

.ctas-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 22px;
  margin-top: 43px;

  @include at-breakpoint(medium) {
    display: flex;
    margin-top: 24px;
    justify-items: flex-start;
    max-width: 500px;
  }

  @include at-breakpoint(large) {
    max-width: none;
    flex-wrap: wrap;
  }
}

.cta {
  @include p3();
  grid-column: span 3;
  display: flex;
  align-items: center;
  margin-inline-end: 24px;
  white-space: nowrap;

  @include at-breakpoint(medium) {
    grid-column: span 1;
  }

  a > svg {
    display: none;
  }

  &:last-child {
    margin-inline-end: 0;
  }

  a,
  button {
    color: $color-black;
    @include bold();
    display: block;

    :global(.gather) & {
      color: $gather-black;
      .cta-text {
        border-bottom: 2px solid $gather-sapling-green;
      }

      &:hover {
        color: $gather-lighter-green;

        .cta-text {
          border-bottom: 2px solid $gather-lighter-green;
        }

        svg {
          path {
            fill: $gather-lighter-green;
          }
        }

        .cta-icon-buy {
          svg {
            path {
              stroke: $gather-lighter-green;
            }
          }
        }
      }
    }

    :global(.go-solutions) & {
      .cta-text {
        border-bottom: 2px solid $color-black;
      }

      &:hover {
        color: $go-yellow;

        .cta-text {
          border-bottom: 2px solid $go-yellow;
        }

        svg {
          path {
            fill: $go-yellow;
          }
        }

        .cta-icon-buy {
          svg {
            path {
              stroke: $go-yellow;
            }
          }
        }
      }
    }

    :global(.now-fresh) & {
      .cta-text {
        border-bottom: 2px solid $now-brown;
      }

      color: $now-brown;

      &:hover {
        color: $now-light-tan;

        .cta-text {
          border-bottom: 2px solid $now-light-tan;
        }

        svg {
          path {
            fill: $now-light-tan;
          }
        }

        .cta-icon-buy {
          svg {
            path {
              stroke: $now-light-tan;
            }
          }
        }
      }
    }

    :global(.summit) & {
      color: $summit-black;
      .cta-text {
        border-bottom: 2px solid $summit-dark-desaturated-blue;
      }

      svg {
        path {
          fill: $summit-black;
        }
      }

      .cta-icon-buy {
        svg {
          path {
            stroke: $summit-black;
          }
        }
      }

      &:hover {
        color: $summit-blue;

        .cta-text {
          border-bottom: 2px solid $summit-blue;
        }

        svg {
          path {
            fill: $summit-blue;
          }
        }

        .cta-icon-buy {
          svg {
            path {
              stroke: $summit-blue;
            }
          }
        }
      }
    }
  }

  button {
    span {
      display: inline;
    }

    svg {
      margin: -5px 0 0 5px;
    }
  }

  :global(.now-fresh) & {
    svg {
      path {
        fill: $now-brown;
      }
    }
  }
}

.cta-link {
  & > span {
    display: flex;
    margin: 0;

    @include at-breakpoint(medium) {
      flex-direction: column;
      min-height: 50px;
    }
  }
}

.cta-icon {
  display: block;
  text-align: center;
  flex-grow: 1;

  svg {
    margin: 0;

    @include at-breakpoint(medium) {
      vertical-align: top;
    }

    :global(.now-fresh) & {
      margin: 0;
    }
  }
}

.cta-text {
  margin-inline-start: 10px;
  padding: 0 2px;

  @include at-breakpoint(medium) {
    margin-inline-start: 0;
  }

  svg {
    margin-top: -4px;
  }

  :global(.now-fresh) & {
    svg {
      margin-top: -4px;
    }
  }
}
