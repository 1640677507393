
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.hero-container {
  position: relative;
  overflow-x: hidden;

  :global(.now-fresh) & {
    margin-bottom: 1px;
    outline: 1px solid $now-brown;
  }
}
