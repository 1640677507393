
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.top {
  @include grid-container;
  padding-bottom: 80px;

  @include at-breakpoint(medium) {
    padding-bottom: 80px;

    :global(.summit) & {
      grid-template-columns: repeat(12, 1fr);
    }

    :global(.petcurean) & {
      padding-bottom: 50px;
    }
  }

  @include at-breakpoint(large) {
    padding-top: 140px;
  }

  :global(.gather) & {
    background-color: $gather-cream;
    overflow: hidden;
    position: relative;
    margin-top: 40px;

    @include at-breakpoint(large) {
      margin-top: 80px;
    }
  }

  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;

    a {
      color: $color-white;
    }
  }

  :global(.now-fresh) & {
    background-color: transparent;
    color: $now-brown;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    margin-top: 1px;
    padding-top: 80px;
  }

  :global(.petcurean) & {
    padding-bottom: 60px;
    background-color: $petcurean-orange;
    color: $petcurean-white;

    @include at-breakpoint(small) {
      padding-top: 0;
    }

    @include at-breakpoint(large) {
      padding-bottom: 0;
      padding-top: 0px;
    }
  }

  :global(.summit) & {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: $summit-white;
  }
}

.bgimg-section {
  grid-column: span 6;
  position: relative;

  :global(.gather) & {
    position: absolute;

    @include at-breakpoint(medium) {
      width: 50%;
      height: 100%;
    }

    @include at-breakpoint(large) {
      left: -5%;
    }
    @include at-breakpoint(x-large) {
      left: 0;
    }
  }

  :global(.go-solutions) & {
    height: 280px;
  }

  @include at-breakpoint(medium) {
    grid-column: span 4;

    :global(.go-solutions) & {
      height: 510px;
    }

    :global(.petcurean) & {
      margin-bottom: 48px;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 4;
    :global(.go-solutions) & {
      height: auto;
    }
  }

  :global(.petcurean) & {
    position: relative;
    padding-top: 0;
  }

  :global(.summit) & {
    display: none;

    @include at-breakpoint(medium) {
      display: block;
      grid-column: span 5;
    }

    @include at-breakpoint(large) {
      grid-column: 2 / 7;
      width: auto;
    }

    @include at-breakpoint(x-large) {
      grid-column: 3 / 7;
    }
  }
}

.bgImgSectionReducedVersion {
  grid-column: span 6;
  margin: auto 0;

  :global(.petcurean) & {
    grid-column: 1 / 7;
  }

  @include at-breakpoint(x-large) {
    :global(.petcurean) & {
      grid-column: 1 / span 4;
    }
  }

  @include at-breakpoint(large) {
    :global(.petcurean) & {
      grid-column: 1 / span 7;
      margin: auto 0;
    }
  }

  @include at-breakpoint(small) {
    :global(.petcurean) & {
      grid-column: 2 / span;
      margin-bottom: 48px;
    }
  }
}

.bgimg {
  display: block;

  :global(.gather) & {
    display: none;

    @include at-breakpoint(medium) {
      display: block;
      width: 100%;
    }

    @include at-breakpoint(large) {
      width: auto;
    }
  }

  :global(.go-solutions) & {
    position: absolute;
    left: -64px;
    top: 8px;

    :global(.locale-he) & {
      left: auto;
      right: -64px;
      transform: scaleX(-1);
    }
  }

  > img {
    object-fit: contain;

    :global(.gather) & {
      @include at-breakpoint(medium) {
        position: absolute;
        width: 100%;
        left: -20%;
        height: 500px;
        top: 20px;
      }

      @include at-breakpoint(large) {
        position: relative;
        height: 100%;
        left: 0;
        top: 60px;
      }

      @include at-breakpoint(x-large) {
        position: relative;
        height: 100%;
        left: 0;
        top: 0;
      }
    }

    @include at-breakpoint(small) {
      :global(.petcurean) & {
        width: 80%;
      }
    }

    @include at-breakpoint(medium) {
      :global(.petcurean) & {
        padding-inline-start: 50px;
        width: 70%;
      }
    }

    @include at-breakpoint(large) {
      :global(.petcurean) & {
        width: 100%;
      }
    }

    @include at-breakpoint(x-large) {
      :global(.petcurean) & {
        width: 100%;
      }
    }

    :global(.summit) & {
      margin: 0 auto;
    }
  }

  @include at-breakpoint(medium) {
    :global(.go-solutions) & {
      left: -130px;

      :global(.locale-he) & {
        left: auto;
        right: -130px;
      }
    }

    :global(.now-fresh) & {
      margin: 48px 0;
    }
  }

  @include at-breakpoint(large) {
    :global(.go-solutions) & {
      position: relative;
      top: 0;
      left: 0;
    }

    :global(.now-fresh) & {
      margin: 0;
    }
  }

  img {
    display: block;

    :global(.go-solutions) & {
      height: 265px;
    }

    :global(.now-fresh) & {
      height: 215px;
    }

    @include at-breakpoint(medium) {
      :global(.go-solutions) & {
        height: 495px;
      }

      :global(.now-fresh) & {
        height: 415px;
      }
    }

    @include at-breakpoint(large) {
      :global(.go-solutions) & {
        width: 100%;
        height: auto;
      }

      :global(.now-fresh) & {
        margin: 0 auto;
      }

      :global(.summit) & {
        position: relative;
        width: 100%;
      }
    }
  }
}

.hotline-section {
  grid-column: span 6;
  margin-bottom: 45px;

  @include at-breakpoint(medium) {
    grid-column: span 4;
  }

  @include at-breakpoint(large) {
    grid-column: span 4;
    margin-bottom: 24px;
    order: -1;
  }

  :global(.gather) & {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include at-breakpoint(small) {
      width: auto;
    }

    @include at-breakpoint(medium) {
      grid-column: 4 / span 3;
    }

    @include at-breakpoint(large) {
      grid-column: 8 / span 5;
    }
  }

  :global(.now-fresh) & {
    margin-bottom: 56px;

    @include at-breakpoint(large) {
      margin-bottom: 24px;
    }
  }

  :global(.petcurean) & {
    & h3:nth-of-type(2) {
      color: $petcurean-orange;
      font-weight: 900;
      margin-bottom: 12px;
      margin-top: 100px;
      text-transform: uppercase;
    }
  }

  :global(.summit) & {
    @include at-breakpoint(medium) {
      grid-column: span 7;
    }

    @include at-breakpoint(large) {
      grid-column: 7 / 12;
    }

    @include at-breakpoint(x-large) {
      grid-column: 7 / 11;
      padding-inline-start: 40px;
    }
  }
}

.gather-hotline-image {
  @include at-breakpoint(small) {
    width: 25%;
    height: 25%;
  }
  @include at-breakpoint(medium) {
    width: 25%;
    height: 25%;
  }
  @include at-breakpoint(large) {
    width: 30%;
    height: 30%;
  }
  @include at-breakpoint(x-large) {
    width: 30%;
    height: 30%;
  }
}

.hotlineSectionReducedVersion {
  order: initial;
  grid-column: span 6;
  margin: auto 0;

  @include at-breakpoint(large) {
    grid-column: 8 / span 4;
  }

  :global(.now-fresh) & {
    @include at-breakpoint(large) {
      grid-column: span 12;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      margin-bottom: 0;

      .section-title {
        grid-column: 3 / span 4;
        grid-row: 1;
        margin-bottom: 0;
      }

      .phonenum {
        margin-top: 45px;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: fit-content;
      }

      .nutrition-hotline-body {
        grid-column: 7 / span 4;
        grid-row: 1;
      }
    }
  }
}

.section-title {
  margin-bottom: 24px;
  @include h3();

  :global(.gather) & {
    font-size: 45px;
    line-height: 113%;
  }

  :global(.now-fresh) & {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 28px;
  }

  :global(.petcurean) & {
    @include font-sizes(45px, 45px, 45px);
    font-weight: 600;
    color: $petcurean-white;
    line-height: 107%;
    letter-spacing: 2px;
    margin-bottom: 12px;
  }

  :global(.summit) & {
    font-size: 45px;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $summit-blue;
  }

  @include at-breakpoint(medium) {
    margin-bottom: 40px;
  }
}

.phonenum {
  margin-bottom: 24px;
  // Star used to set more specificity
  :global(.gather) & * {
    color: $gather-black;
    font-size: 22px;
  }

  :global(.go-solutions) & * {
    color: $color-white;
  }

  :global(.petcurean) & {
    word-break: break-word;
    * {
      font-weight: 900;
    }
  }

  :global(.summit) & * {
    color: $summit-black;
    font-size: 18px;
    letter-spacing: 1.5px;
  }
}

.contact-btn {
  margin-top: 32px;
}

.static-form {
  @include visually-hidden;
}

.newsletter-section {
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: span 4;
  }

  @include at-breakpoint(large) {
    grid-column: span 4;
  }

  & > p {
    margin-bottom: 24px;
  }

  :global(.now-fresh) & {
    @include at-breakpoint(large) {
      grid-column: 9 / span 4;
    }
  }
}

.newsletter-success {
  grid-column: span 6;
  padding: 36px 0;
  text-align: center;

  @include at-breakpoint(medium) {
    grid-column: span 6;
    padding: 52px 0;
  }

  @include at-breakpoint(large) {
    grid-column: span 4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 80px 0 0 0;

    :global(.now-fresh) & {
      grid-column: 9 / span 4;
    }
  }

  svg {
    margin-bottom: 24px;

    :global(.go-solutions) & {
      path {
        fill: $color-white;
      }
    }

    :global(.now-fresh) & {
      path {
        fill: $now-brown;
      }
    }
  }
}

.success-image {
  max-width: 100px;
  margin-bottom: 16px;
}

.success-title {
  margin-bottom: 16px;

  @include at-breakpoint(medium) {
    @include h2;
  }

  @include at-breakpoint(large) {
    @include h4;
  }
}
