@mixin reducedMotion() {
  @media (prefers-reduced-motion: reduce) {
    @content;
  }
}

@mixin willAnimateIn() {
  transform: translateY(50px);
  opacity: 0;

  @include reducedMotion() {
    transform: none;
  }
}

@mixin animateIn($delay: 0s) {
  transform: none;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  transition:
    opacity 0.6s ease-out $delay,
    transform 0.6s ease-out $delay;

  :global(.now-fresh) & {
    transition-duration: 0.9s;
  }
}

@mixin willAnimateInNowFresh() {
  @include willAnimateIn();
  transform: none;
}
