
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
}

.content-container {
  @include flex-container();
  flex-direction: column;
  @include default-margin-bottom();
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: span 12;
  }

  :global(.summit) & {
    @include at-breakpoint(large) {
      grid-column: 3 / span 8;
    }
    width: 100%;
  }
}

.headline {
  @include h2();
  margin-bottom: 16px;

  :global(.petcurean) & {
    color: $petcurean-orange;
  }

  :global(.summit) & {
    @include h3();
    color: $summit-blue;
  }
}

.description {
  margin-bottom: 16px;

  :global(.summit) & {
    @include p2();
    @include font-sizes(16px, 16px, 21px);
  }
}

.cta-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @include at-breakpoint(medium) {
    flex-direction: row;
  }

  margin-bottom: 64px;
}

.cta {
  width: fit-content;
}

.link-container {
  border-bottom: 1px solid $color-white;

  :global(.petcurean) & {
    border-color: $petcurean-grey;
  }

  :global(.summit) & {
    border-color: $summit-blue;
  }
}

.link-elements {
  position: relative;
  display: block;
  padding: 20px 40px 20px 0;

  @include at-breakpoint(large) {
    padding: 24px 90px 24px 0;
  }

  @include at-breakpoint(x-large) {
    padding: 34px 90px 34px 0;
  }

  & > span {
    display: block;
    margin: 0;
  }

  svg {
    position: absolute;
    right: 5px;
    top: 50%;
    transition: right 0.3s ease-out;

    @include at-breakpoint(large) {
      right: 40px;
    }
  }

  @media (hover: hover) {
    &:hover,
    &:focus {
      svg {
        right: 0;
      }
    }
  }

  :global(.petcurean) & {
    svg {
      path {
        fill: $petcurean-orange;
      }
    }
  }

  :global(.summit) & {
    svg {
      path {
        fill: $summit-blue;
      }
    }
  }
}

.link-title {
  @include h3();
  :global(.summit) & {
    @include h4();
    color: $summit-black;
  }
}

.link-description {
  display: block;
  @include p2();

  :global(.petcurean) & {
    color: $petcurean-grey;
  }
}
