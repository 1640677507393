
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  flex-direction: column;
}

.invisible {
  visibility: hidden;
}

.expandable {
  overflow: hidden;
  order: -1;
}

.view-all-cta {
  font-size: 12px;
  line-height: 1.5;

  :global(.gather) & {
    padding: 0;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
    }

    &:hover {
      text-decoration: none;

      &:after {
        background-position: 0%;
        transition: background-position 0.2s ease-out;
      }
    }

    p {
      font-weight: 700;
    }
  }

  :global(.go-solutions) & {
    text-transform: none;
  }

  :global(.now-fresh) & {
    font-weight: normal;
    border: none;
  }

  :global(.summit) & {
    padding: 0;
    position: relative;
    color: $summit-black;

    &:after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      bottom: -4px;
      width: 100%;
      height: 2px;
      background-image: $summit-dark-gradient;
      background-size: 200% 100%;
      background-position: 100%;
    }

    &:hover {
      text-decoration: none;

      &:after {
        background-position: 0%;
        transition: background-position 0.2s ease-out;
      }
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      letter-spacing: 1.5px;
    }

    svg path {
      fill: $summit-black;
    }
  }
}

.hidden {
  display: none;
}
