
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.file-header {
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
  }

  .remove-file {
    width: 40px;
    margin-bottom: auto;
    margin-inline-end: auto;

    svg {
      path {
        :global(.gather) & {
          fill: $gather-black;
        }
        :global(.now-fresh) & {
          fill: $now-brown;
        }
        :global(.petcurean) & {
          fill: $petcurean-grey;
        }
        :global(.summit) & {
          fill: $summit-black;
        }
      }
    }
  }

  &.loading {
    p {
      :global(.gather) & {
        color: $gather-light-sapling-green;
      }
      :global(.go-solutions) & {
        color: $color-white-light;
      }
      :global(.now-fresh) & {
        color: $now-brown;
      }
      :global(.petcurean) & {
        fill: $petcurean-light-tan;
      }
      :global(.summit) & {
        fill: $summit-dark-desaturated-blue;
      }
    }
  }

  &.error {
    @include p2();
    border-radius: 8px;
    padding: 8px 0 8px 8px;
    color: $color-dark-red;
    font-weight: 300;

    :global(.go-solutions) & {
      background-color: $go-pink;
    }

    :global(.now-fresh) & {
      background-color: $now-light-tan;
    }

    .remove-file {
      svg {
        path {
          :global(.gather) & {
            fill: $color-dark-red;
          }
          :global(.go-solutions) & {
            fill: $color-dark-red;
          }
          :global(.now-fresh) & {
            fill: $now-brown;
          }
          :global(.petcurean) & {
            fill: $color-dark-red;
          }
          :global(.summit) & {
            fill: $color-dark-red;
          }
        }
      }
    }
  }
}

.error-msg {
  @include errormsg;
  display: block;
}
