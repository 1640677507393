
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.location-input-and-list {
  width: 100%;

  @include at-breakpoint(large) {
    width: 50%;
  }

  @include at-breakpoint(x-large) {
    width: 36%;
  }

  &.map-disable {
    @include at-breakpoint(large) {
      width: 100%;
    }
  }

  &.has-background {
    :global(.now-fresh) & {
      background-color: $color-white;
    }
  }
}

.view-content {
  height: 0;
  visibility: hidden;

  @include at-breakpoint(large) {
    height: calc(100% - 190px);
    visibility: visible;
  }
}

.view-content-map {
  width: 100%;

  @include at-breakpoint(large) {
    width: 50%;
  }

  @include at-breakpoint(x-large) {
    width: 64%;
  }
}

.active-view {
  height: auto;
  visibility: visible;
}

:global(.now-fresh) {
  .view-content {
    background-color: $color-white;
  }
}

:global(.gather) {
  .container {
    height: auto;
    @include at-breakpoint(large) {
      padding: 0 44px;
    }
  }

  .view-content {
    background-color: $gather-cream;
  }

  .location-input-and-list {
    @include at-breakpoint(large) {
      padding-inline-end: 24px;
    }
  }

  .active-view {
    @include at-breakpoint(large) {
      padding: 0;
    }
  }
}
