// Maps of font stacks for different locales by brand
// I'm calling the body fonts "primary fonts"
// Fonts used for headers are "secondary fonts"

/* Gather */
$gather-primary-fonts: (
  'en': (
    'Merriweather',
    serif,
  ),
);

$gather-secondary-fonts: (
  'en': (
    'Merriweather',
    serif,
  ),
);

/* Go Solutions */
$go-primary-fonts: (
  'en': (
    Gotham,
    sans-serif,
  ),
  'ru': (
    'Open Sans',
    sans-serif,
  ),
  'zh': (
    serif,
  ),
  'he': (
    'Open Sans',
    sans-serif,
  ),
);

$go-secondary-fonts: (
  'zh': (
    sans-serif,
  ),
);

/* Now Fresh */
$nf-primary-fonts: (
  'en': (
    Almarai,
    sans-serif,
  ),
  'ru': (
    'Roboto Slab',
    sans-serif,
  ),
  'zh': (
    serif,
  ),
  'he': (
    'Noto Serif Hebrew',
    serif,
  ),
);

$nf-secondary-fonts: (
  'en': (
    'Stag',
    sans-serif,
  ),
  'ru': (
    'Oswald',
    sans-serif,
  ),
  'zh': (
    sans-serif,
  ),
  'he': (
    'Noto Serif Hebrew',
    sans-serif,
  ),
);

/* Petcurean */

$pc-primary-fonts: (
  'en': (
    'museo-sans-rounded',
    sans-serif,
  ),
  'zh': (
    serif,
  ),
);

$pc-secondary-fonts: (
  'en': (
    'museo-sans-rounded',
    sans-serif,
  ),
  'zh': (
    sans-serif,
  ),
);

/* Summit */
$summit-primary-fonts: (
  'en': (
    'rooney-sans',
    sans-serif,
  ),
  'fr': (
    'rooney-sans',
    sans-serif,
  ),
);

$summit-secondary-fonts: (
  'en': (
    'Bloc Pro Heavy',
    sans-serif,
  ),
  'fr': (
    'Bloc Pro Heavy',
    sans-serif,
  ),
);

$summit-tertiary-fonts: (
  'en': (
    'Bloc Pro Extra Condensed',
    sans-serif,
  ),
  'fr': (
    'Bloc Pro Extra Condensed',
    sans-serif,
  ),
);
