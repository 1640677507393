
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  text-align: start;
}

.error {
  color: $color-dark-red;

  :global(.go-solutions) & {
    color: $color-dark-red;
  }

  :global(.now-fresh) & {
    color: $now-red;
  }

  .button {
    box-shadow: 0px 0px 0px 2px $now-grey inset;

    :global(.now-fresh) & {
      background-color: $now-transparent-red;
      box-shadow: 0px 0px 0px 2px $now-red inset;
      border-color: $now-red;
      color: $now-red;
    }
  }

  .error-msg {
    display: block;

    :global(.summit) & {
      @include errormsg();
    }
  }
}

.input {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;

  &:focus + .label {
    .button span {
      box-shadow: 0 0 2px 2px $outline;
    }
  }
}

.label {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  padding: 6px 0 10px;
  cursor: pointer;
  font-weight: 700;

  :global(.gather) & {
    letter-spacing: normal;
  }

  :global(.go-solutions) & {
    font-weight: 700;
  }

  :global(.now-fresh) & {
    font-weight: 500;
  }

  :global(.petcurean) & {
    letter-spacing: normal;
  }

  :global(.summit) & {
    letter-spacing: normal;
  }

  &:hover {
    .button {
      :global(.gather) & {
        background-color: $gather-lighter-green;
        color: $gather-white;
      }

      :global(.go-solutions) & {
        color: $color-black;
        background-color: $color-white;
      }

      :global(.now-fresh) & {
        background-color: $now-grey;
      }

      :global(.petcurean) & {
        background-color: $petcurean-light-orange;
        color: $petcurean-white;
      }

      :global(.summit) & {
        background-color: $summit-light-blue;
        color: $petcurean-white;
      }
    }
  }
}

.label-text {
  display: block;
  margin-bottom: 12px;

  :global(.now-fresh) & {
    font-size: 16px !important;
    font-family: Stag, sans-serif !important;
  }
}

.helper-text {
  @include p3();
  display: block;

  :global(.now-fresh) & {
    font-size: 14px;
  }
}

.file-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.button {
  padding: 10px 10px;
  margin-inline-end: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;

  :global(.gather) & {
    background-color: $gather-green;
    border-radius: 28px;
    color: $gather-white;
    font-size: 14px;
    line-height: 1.67;
    padding: 12px 24px;
    transition: background-color 0.2s ease-out;
  }

  :global(.go-solutions) & {
    text-transform: uppercase;
    color: $color-black;
    background-color: $go-yellow;
    padding-inline-end: 16px;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 18px), calc(100% - 18px) 100%, 0 100%);
  }

  :global(.now-fresh) & {
    border-radius: 18px;
    font-size: 16px;
    font-weight: 400;
    background-color: $color-white;
    border: 1px solid $now-brown;
    color: $now-brown;
    font-family: Stag, sans-serif;
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    border-radius: 28px;
    color: $petcurean-white;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 1px;
    padding: 12px 24px;
    transition: background-color 0.2s ease-out;
  }

  :global(.summit) & {
    @include summit-font-i18n(true);
    font-weight: 600;
    background-color: $summit-blue;
    color: $summit-white;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 16px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    transition: background-color 0.2s ease-out;
  }
}

.file-info {
  display: block;
  margin-top: 8px;

  &:first-child {
    margin-top: 0;
  }
}

.file-name {
  display: block;
  word-break: break-word;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 2px;

  :global(.go-solutions) & {
    font-weight: 400;
  }

  :global(.now-fresh) & {
    font-weight: 300;
  }
}

.file-size {
  display: block;
}

.error-msg {
  @include errormsg;
}
