@mixin grid-container() {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  padding: 0 16px;

  @include at-breakpoint(medium) {
    padding: 0 32px;
  }

  @include at-breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    padding: 0 44px;
    max-width: $max-width;
  }

  @include at-breakpoint(x-large) {
    box-sizing: content-box;
  }

  @media screen and (min-width: 1408px) {
    padding: 0 calc((100% - #{$max-width}) / 2);
  }
}

@mixin flex-container() {
  display: flex;
  width: calc(100% - 32px);
  margin: 0 auto;
  max-width: $max-width;

  @include at-breakpoint(medium) {
    width: calc(100% - 64px);
  }

  @include at-breakpoint(large) {
    width: calc(100% - 88px);
  }
}

@mixin margin-container() {
  margin: 0 16px;

  @include at-breakpoint(medium) {
    margin: 0 32px;
  }

  @include at-breakpoint(large) {
    margin: 0 44px;
    max-width: $max-width;
  }

  @include at-breakpoint(x-large) {
    box-sizing: content-box;
  }

  @media screen and (min-width: 1408px) {
    margin: 0 auto;
  }
}
