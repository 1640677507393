
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  background-color: $color-black;
  color: $color-white;
  justify-items: center;
  align-items: center;
  padding-top: 74px;
  padding-bottom: 71px;

  :global(.gather) & {
    background-color: $gather-cream;
    color: $gather-black;
    padding-top: 40px;
    padding-bottom: 115px;
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-black;
    padding-top: 40px;
    padding-bottom: 115px;
  }

  @include at-breakpoint(medium) {
    padding-top: 142px;
    padding-bottom: 80px;

    :global(.gather) & {
      padding-top: 90px;
      padding-bottom: 125px;
    }
  }

  @include at-breakpoint(large) {
    padding-top: 178px;
    padding-bottom: 166px;

    :global(.gather) & {
      padding-top: 80px;
      padding-bottom: 125px;
    }
  }
}

.img {
  grid-column: span 6;
  margin-bottom: 16px;

  :global(.gather) &,
  :global(.summit) & {
    display: none;
  }

  @include at-breakpoint(medium) {
    grid-column: span 3;
    margin-bottom: 0;
  }

  @include at-breakpoint(large) {
    grid-column: span 5;

    :global(.gather) &,
    :global(.summit) & {
      display: block;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.copy-container {
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: 4/7;

    :global(.gather) &,
    :global(.summit) & {
      grid-column: span 6;
    }
  }

  @include at-breakpoint(large) {
    grid-column: 6/13;

    :global(.gather) &,
    :global(.summit) & {
      grid-column: 6/13;
    }
  }
}

.headline {
  text-align: center;
  margin-bottom: 32px;

  :global(.gather) & {
    @include font-sizes(34px, 64px, 64px);
    @include line-heights(43px, 75px, 75px);
    text-align: start;
  }

  :global(.summit) & {
    color: $summit-blue;
    @include font-sizes(35px, 67px, 67px);
    @include line-heights(40px, 64px, 64px);
    letter-spacing: 2px;
    text-align: start;
  }

  @include at-breakpoint(medium) {
    text-align: start;
  }
}

.description {
  @include p2();
  text-align: start;

  :global(.gather) & {
    font-size: 19px;
    line-height: 36px;
    font-weight: 300;
    text-align: start;
  }

  :global(.summit) {
    line-height: 37px;
  }

  @include at-breakpoint(medium) {
    @include p2();
  }
}

.benefits {
  @include grid-container();
  grid-column: span 6;
  padding: 0;
  align-items: center;
  margin-top: 53px;
  grid-column-gap: 0;
  width: 100%;

  :global(.gather) & {
    margin-top: 30px;
  }

  @include at-breakpoint(medium) {
    margin-top: 85px;

    :global(.gather) & {
      margin-top: 75px;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 12;
    grid-column-gap: 24px;
    margin-top: 48px;

    :global(.gather) & {
      margin-top: 40px;
    }
  }
}

.benefit {
  grid-column: span 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 0;
  min-height: 123px;
  height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);

  :global(.gather) & {
    border-color: $gather-black;
    padding-top: 25px;
    padding-bottom: 0;

    &:nth-child(-n + 2) {
      padding-top: 0;
      padding-bottom: 25px;
    }
  }

  :global(.summit) & {
    border-color: $summit-black;
    padding-top: 25px;
    padding-bottom: 0;

    &:nth-child(-n + 2) {
      padding-top: 0;
      padding-bottom: 25px;
    }
  }

  &:nth-child(2n) {
    border-right: none;
  }

  &:nth-child(n + 3) {
    border-bottom: none;
  }

  @include at-breakpoint(medium) {
    padding: 50px 0;
  }

  @include at-breakpoint(large) {
    border: none;
    padding: 0;

    :global(.gather) & {
      &:nth-child(-n + 4) {
        padding: 0;
      }
    }

    :global(.summit) & {
      &:nth-child(-n + 4) {
        padding: 0;
      }
    }
  }
}

.benefit-image {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 56px;
    height: 56px;

    :global(.gather) &,
    :global(.summit) & {
      width: 76px;
      height: 76px;
    }

    @include at-breakpoint(medium) {
      width: 76px;
      height: 76px;
    }

    path {
      fill: $color-white;

      :global(.gather) & {
        fill: $gather-green;
      }

      :global(.summit) & {
        fill: $summit-blue;
      }
    }

    polygon {
      fill: $color-white;

      :global(.gather) & {
        fill: $gather-green;
      }

      :global(.summit) & {
        fill: $summit-blue;
      }
    }
  }
}

.benefit-description {
  @include p3();
  text-align: center;
  max-width: 144px;
  margin-top: 25px;

  :global(.gather) & {
    @include font-sizes(12px, 16px, 16px);
    @include line-heights(21px, 28px, 28px);
    max-width: none;
    padding: 0 10px;
  }

  @include at-breakpoint(medium) {
    max-width: 232px;
  }

  @include at-breakpoint(large) {
    margin-top: 35px;
  }

  :global(.summit) & {
    @include font-sizes(12px, 16px, 16px);
    @include line-heights(21px, 27px, 27px);
    max-width: none;
    padding: 0 10px;
  }
}
