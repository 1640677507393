
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 65px 43px 0;
  text-align: center;
  width: 100%;
  border-top: 1px solid;
  height: 467px;

  :global(.gather) & {
    background-color: $gather-cream;
    border-color: $go-light-opaque-black;
  }

  :global(.go-solutions) & {
    border-color: $go-light-opaque-black;
  }

  :global(.now-fresh) & {
    border-color: $now-grey;
  }

  :global(.summit) & {
    background-color: $summit-white;
    border-color: $summit-black;
  }

  @include at-breakpoint(medium) {
    padding: 101px 195px 0;
    height: 587px;
  }

  @include at-breakpoint(large) {
    padding: 106px 66px 0;
  }

  @include at-breakpoint(x-large) {
    padding: 160px 110px 0;
  }

  &.desktop-hidden {
    @include at-breakpoint(large) {
      display: none;
    }
  }

  &.desktop-only {
    display: none;

    @include at-breakpoint(large) {
      display: flex;
    }
  }

  &.map {
    display: none;
  }
}

.image {
  margin-bottom: 24px;

  @include at-breakpoint(large) {
    margin-bottom: 32px;
  }
}

.message {
  margin: auto;
  max-width: 289px;

  @include at-breakpoint(medium) {
    max-width: 334px;
  }

  @include at-breakpoint(large) {
    max-width: 379px;
  }
}

:global(.go-solutions) {
  .container {
    border-top: 1px solid $go-gray;
  }
}

:global(.now-fresh) {
  .container {
    background-color: $color-white;
  }
}
