
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding: 0;
  text-align: center;
  margin-bottom: 40px;

  &:not(:first-child) {
    margin-top: 40px;
  }
}

.error {
  .description {
    color: $color-dark-red;
    // Brands added for specificity
    :global(.go-solutions) & {
      color: $color-dark-red;
    }

    :global(.now-fresh) & {
      color: $now-red;
    }
  }

  .error-msg {
    display: block;
  }
}

.description {
  margin-bottom: 16px;

  :global(.gather) & {
    font-weight: 300;
  }
  :global(.now-fresh) & {
    font-size: 16px;
    font-family: Stag, sans-serif !important;
  }
  :global(.petcurean) & {
    font-weight: normal;
  }
  :global(.summit) & {
    font-weight: normal;
  }
}

.star {
  cursor: pointer;
  position: relative;
  padding: 0 8px;
  width: 56px;
}

.input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
}

.label {
  @include visually-hidden;
}

:global(.gather) {
  // Using container here for specificity
  .container {
    .star {
      svg {
        path {
          fill: rgba($gather-black, 0.07);
        }
      }
    }

    .active {
      svg {
        path {
          fill: $gather-green;
        }
      }
    }
  }
}

:global(.go-solutions) {
  .description {
    color: $color-white;
  }

  .container {
    .star {
      svg {
        path {
          fill: #333;
        }
      }
    }

    .active {
      svg {
        path {
          fill: $go-yellow;
        }
      }
    }
  }
}

:global(.now-fresh) {
  .description {
    color: $now-brown;
  }

  .star {
    svg {
      path {
        fill: rgba($now-brown, 0.35);
      }
    }
  }

  .active {
    svg {
      path {
        fill: $now-brown;
      }
    }
  }
}

:global(.petcurean) {
  .container {
    .star {
      svg {
        path {
          fill: rgba($petcurean-grey, 0.07);
        }
      }
    }

    .active {
      svg {
        path {
          fill: $petcurean-orange;
        }
      }
    }
  }
}

:global(.summit) {
  .container {
    .star {
      svg {
        path {
          fill: rgba($summit-black, 0.07);
        }
      }
    }

    .active {
      svg {
        path {
          fill: $summit-blue;
        }
      }
    }
  }
}

.error-msg {
  @include errormsg;
}
