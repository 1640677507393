
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: flex;
  height: 100%;

  :global(.gather) & {
    background-color: $gather-green;
    color: $gather-white;
    align-items: center;
    padding: 35px 16px;
    border-radius: 20px;

    @include at-breakpoint(medium) {
      flex-direction: column;
      align-items: flex-start;
      padding: 22px 24px;
    }

    @include at-breakpoint(large) {
      padding: 30px 24px;
    }
  }

  :global(.go-solutions) & {
    background-color: $go-charcoal;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    min-height: 156px;

    @include at-breakpoint(medium) {
      flex-direction: column;
      align-items: flex-start;
      padding: 40px 32px;
    }
  }

  :global(.now-fresh) & {
    flex-direction: column;
    justify-content: center;
    position: relative;
    color: $now-brown;
    min-height: 167px;

    @include at-breakpoint(medium) {
      justify-content: flex-start;
    }
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $gather-white;
    align-items: center;
    padding: 35px 16px;

    @include at-breakpoint(medium) {
      flex-direction: column;
      align-items: flex-start;
      padding: 22px 24px;
    }

    @include at-breakpoint(large) {
      padding: 30px 24px;
    }
  }
}

.image {
  :global(.gather) & {
    width: 80px;
    height: 80px;
    margin-inline-end: 16px;

    svg {
      width: 80px;
      height: 80px;

      path,
      polygon {
        fill: $gather-white;
      }
    }

    @include at-breakpoint(medium) {
      margin-inline-end: 0;
      margin-bottom: 22px;
    }
  }

  :global(.go-solutions) & {
    width: 48px;
    height: 48px;
    margin: 0 22px 0 0;
    display: flex;

    :global(.locale-he) & {
      margin: 0 0 0 22px;
    }

    @include at-breakpoint(medium) {
      margin: 0 0 29px 0;

      :global(.locale-he) & {
        margin: 0 0 29px 0;
      }
    }

    svg {
      width: 48px;
      height: 48px;

      path,
      polygon {
        fill: $color-white;
      }
    }
  }

  :global(.now-fresh) & {
    max-width: 117px;
    max-height: 104px;
    margin-bottom: 0;
    left: 5px;
    width: auto;

    @include at-breakpoint(medium) {
      max-width: 139px;
      max-height: 144px;
    }

    @include at-breakpoint(large) {
      max-width: 117px;
      max-height: 113px;
    }

    @include at-breakpoint(x-large) {
      max-width: 158px;
      max-height: 158px;
    }
  }

  :global(.summit) & {
    width: 80px;
    height: 80px;
    margin-inline-end: 16px;

    svg {
      width: 80px;
      height: 80px;

      path,
      polygon {
        fill: $summit-white;
      }
    }

    @include at-breakpoint(medium) {
      margin-inline-end: 0;
      margin-bottom: 22px;
    }
  }
}

.headline {
  @include h5();
  margin-bottom: 8px;

  :global(.gather) & {
    font-size: 18px;
  }

  :global(.go-solutions) & {
    text-transform: uppercase;
    @include at-breakpoint(medium) {
      margin-bottom: 24px;
    }
  }

  :global(.now-fresh) & {
    font-size: 18px;
    line-height: 20px;
    @include at-breakpoint(medium) {
      margin-bottom: 16px;
    }
  }
}

.description {
  p {
    @include p2();

    :global(.gather) & {
      color: $gather-white;
      @include font-sizes(12px, 16px, 16px);
      @include line-heights(21px, 28px, 28px);
    }

    :global(.go-solutions) & {
      color: $color-white;
    }

    :global(.now-fresh) & {
      color: $now-brown;
      font-size: 16px;
    }
  }
}
