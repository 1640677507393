
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.map {
  height: 467px;
  width: 100%;

  @include at-breakpoint(medium) {
    height: 587px;
  }

  @include at-breakpoint(large) {
    min-height: 800px;
    height: 100%;
  }

  &.hidden {
    height: 0;

    @include at-breakpoint(large) {
      height: 800px;
    }
  }

  :global(.gather) & {
    @include at-breakpoint(large) {
      border-radius: 20px;
    }
  }
}

.map-button {
  background-color: $color-white;
  display: none;
  margin-inline-end: 19px;

  @include at-breakpoint(medium) {
    margin-inline-end: 32px;
  }
}

.location-button {
  border-radius: 3px;
  box-shadow: 2px 4px 9px rgba($color-black, 0.5);
  cursor: pointer;
  padding: 6px;

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px $outline;
  }
}

.zoom {
  background-color: $color-white;
  border-radius: 3px;
  box-shadow: 2px 4px 9px rgba($color-black, 0.5);
  flex-direction: column;
  justify-content: center;
  margin-bottom: 23px;
  margin-top: 8px;
  padding: 5px 4px;

  @include at-breakpoint(medium) {
    margin-bottom: 32px;
  }
}

.zoom-in {
  height: 29px;
  margin-bottom: 7px;
  padding: 0 9px;
  position: relative;

  &::after {
    content: '';
    display: block;
    height: 1px;
    left: 0;
    margin: 7px 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px $outline;
  }
}

.zoom-out {
  height: 29px;
  margin-top: 7px;
  padding: 0 9px;

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px $outline;
  }
}

:global(#map) {
  .map-button {
    display: flex;
  }
}

:global(.go-solutions) {
  .map-button {
    path {
      stroke: $color-black;
    }
  }

  .zoom-in {
    &::after {
      background-color: rgba($color-black, 0.2);
    }
  }
}

:global(.now-fresh) {
  .map-button {
    path {
      stroke: $now-brown;
    }
  }

  .zoom-in {
    &::after {
      background-color: $now-grey;
    }
  }
}
