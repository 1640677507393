
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container;
  @include default-padding-bottom();
  position: relative;

  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;

    a {
      color: $color-white;
    }

    &:hover a,
    &:focus a {
      color: $color-white;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    outline: 1px solid $now-brown;
    padding: 0;
    max-width: none;
    width: auto;
    grid-column-gap: 0;

    &:hover a,
    &:focus a {
      color: $now-brown;
    }
  }
}

.eyebrow {
  @include eyebrow;
  text-align: center;
  grid-column: span 6;
  z-index: 1;

  @include at-breakpoint(large) {
    grid-column: span 12;
  }

  :global(.gather) & {
    color: $gather-green;
    margin-bottom: 8px;
  }

  :global(.go-solutions) & {
    margin-bottom: 8px;
  }

  :global(.now-fresh) & {
    margin-bottom: 16px;
    padding-top: 30px;
  }

  :global(.petcurean) & {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  :global(.summit) & {
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1px;
    color: $summit-black;
    margin-bottom: 16px;
  }
}

.title {
  text-align: center;
  margin-bottom: 24px;
  grid-column: span 6;
  z-index: 1;

  @include h3();

  @include at-breakpoint(medium) {
    margin-bottom: 48px;
  }

  @include at-breakpoint(large) {
    grid-column: span 12;
  }

  :global(.gather) & {
    color: $gather-black;
    @include font-sizes(28px, 45px, 45px, 45px);
    @include line-heights(37px, 51px, 51px, 51px);
  }

  :global(.now-fresh) & {
    margin-bottom: 0;
    border-bottom: 1px solid $now-brown;
    padding-bottom: 30px;
  }

  :global(.petcurean) & {
    @include font-sizes(28px, 45px, 45px, 45px);
    @include line-heights(32px, 50px, 50px, 50px);
    color: $petcurean-orange;
    font-weight: 600;
  }

  :global(.summit) & {
    @include font-sizes(28px, 45px, 45px, 45px);
    @include line-heights(32px, 50px, 50px, 50px);
    color: $summit-blue;
    text-transform: uppercase;
    font-weight: 400;
  }
}

.wrapper {
  display: block;
  width: 100%;
  height: auto;
  grid-column: span 6;
  position: relative;
  z-index: 1;

  :global(.now-fresh) & {
    border-bottom: 1px solid $now-brown;
    padding: 40px;

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }

  @include at-breakpoint(medium) {
    :global(.now-fresh) & {
      border-bottom: none;

      &:nth-child(odd) {
        border-right: 1px solid $now-brown;
      }
    }
    grid-column: span 3;
  }

  @include at-breakpoint(large) {
    &:nth-child(odd) {
      grid-column: 2/7;
    }

    &:nth-child(even) {
      grid-column: 7/12;
    }

    :global(.gather) &,
    :global(.now-fresh) &,
    :global(.petcurean) &,
    :global(.summit) & {
      &:nth-child(odd) {
        grid-column: 1/7;
      }

      &:nth-child(even) {
        grid-column: 7/13;
      }
    }
  }

  @include at-breakpoint(x-large) {
    :global(.gather) &,
    :global(.petcurean) &,
    :global(.summit) & {
      &:nth-child(odd) {
        grid-column: 2/7;
      }

      &:nth-child(even) {
        grid-column: 7/12;
      }
    }

    :global(.now-fresh) & {
      padding: 40px 103px;
    }
  }

  & > span {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;

    @include at-breakpoint(medium) {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }

    :global(.gather) &,
    :global(.now-fresh) &,
    :global(.petcurean) &,
    :global(.summit) & {
      @include at-breakpoint(small) {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
      }
    }
  }

  & > svg {
    display: none;
  }

  &:last-of-type {
    .article-btn {
      margin-bottom: 0;
    }
  }
}

.accent {
  position: absolute;
  z-index: 0;
  display: none;

  @include at-breakpoint(large) {
    display: block;
    opacity: 0;
    transition: opacity 400ms ease-in;

    img {
      display: block;
      max-width: 250px;
    }
  }

  @include at-breakpoint(x-large) {
    img {
      max-width: none;
    }
  }
}

.first-accent {
  @include at-breakpoint(large) {
    left: 0;
    top: 150px;
    transform: translate(-50%, 50%);

    :global(.locale-he) & {
      left: auto;
      right: 0;
      transform: translate(50%, 50%);
    }
  }

  @include at-breakpoint(x-large) {
    transform: translate(-50%, 50%);

    :global(.locale-he) & {
      transform: translate(50%, 50%);
    }
  }
}

.second-accent {
  @include at-breakpoint(large) {
    right: 0;
    top: -30px;
    overflow: hidden;

    :global(.locale-he) & {
      right: auto;
      left: 0;
    }

    img {
      transform: translateX(50%);
      max-width: 400px;

      :global(.locale-he) & {
        transform: translateX(-50%);
      }
    }
  }

  @include at-breakpoint(x-large) {
    top: -80px;

    img {
      max-width: none;
    }
  }
}

.img-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 32px;
  opacity: 0;
  transition: opacity 400ms ease-in;

  :global(.now-fresh) & {
    order: 2;
  }
}

.anim-in {
  opacity: 1;
}

.article-img {
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    :global(.gather) &,
    :global(.petcurean) & {
      border-radius: 20px;
    }
  }
}

.article-title {
  @include h5();
  width: 100%;
  margin-bottom: 16px;

  :global(.gather) & {
    @include font-sizes(22px, 22px, 28px, 28px);
    @include line-heights(28px, 28px, 37px, 37px);
    color: $gather-black;
  }

  :global(.now-fresh) & {
    order: 1;
    font-size: 24px;
    line-height: 32px;
  }

  :global(.petcurean) & {
    color: $petcurean-grey;
    @include font-sizes(22px, 22px, 28px, 28px);
    @include line-heights(28px, 28px, 32px, 32px);
  }

  :global(.summit) & {
    color: $summit-black;
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 2px;
    font-weight: 400;
  }
}

.article-subtitle {
  width: 100%;
  margin-bottom: 32px;

  :global(.gather) & {
    @include font-sizes(16px, 16px, 19px, 19px);
    @include line-heights(28px, 28px, 36px, 36px);
    color: $gather-black;
  }

  :global(.go-solutions) & {
    p,
    a {
      color: $color-white;
      font-size: 16px;
    }

    a {
      text-decoration: underline;
    }
  }

  :global(.now-fresh) & {
    a {
      text-decoration: underline;
      font-weight: 500;
    }
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    order: 3;
  }

  :global(.petcurean) & {
    @include font-sizes(16px, 16px, 21px, 21px);
    @include line-heights(26px, 26px, 37px, 37px);
    color: $petcurean-grey;
    font-weight: 300;
  }

  :global(.summit) & {
    @include font-sizes(16px, 16px, 21px, 21px);
    @include line-heights(27px, 27px, 30px, 30px);
    color: $summit-black;
    font-weight: 100;
  }
}

.article-btn {
  display: inline-block;
  margin-bottom: 32px;

  @include at-breakpoint(medium) {
    margin-bottom: 0;
    margin-top: auto;
  }

  :global(.gather) & {
    text-transform: capitalize;
  }

  :global(.go-solutions) & {
    color: white;
  }

  :global(.now-fresh) & {
    order: 4;
  }

  :global(.petcurean) & {
    text-transform: capitalize;
  }
}
