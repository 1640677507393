
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.aria-live-status {
  @include visually-hidden;
}

.container {
  @include grid-container;
  padding-top: 16px;
  padding-bottom: 16px;

  @include at-breakpoint(medium) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  @include at-breakpoint(large) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @include at-breakpoint(x-large) {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  :global(.gather) & {
    background-color: $gather-cream;
    color: $gather-black;
  }

  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;

    a {
      color: $color-white;

      &:hover {
        color: $go-yellow;
      }
    }
  }

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
    @include default-margin-bottom();
    color: $now-brown;
    padding: 60px 24px;
    max-width: none;
    width: auto;

    @include at-breakpoint(large) {
      padding: 80px 24px;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-white;
    color: $petcurean-grey;
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-black;
  }

  &:not(.free-form-component) {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}

.free-form-component {
  @include default-margin-bottom();
  @include scroll-offset;
  padding-top: 0;
  padding-bottom: 0;

  .form {
    margin-bottom: 0;
  }
}

.success {
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: span 12;
  }
}

.header {
  text-align: center;
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: 2/6;
  }

  @include at-breakpoint(large) {
    grid-column: 3/11;
  }

  @include at-breakpoint(x-large) {
    grid-column: 4/10;
  }
}

.eyebrow {
  @include eyebrow;
  text-align: center;
  margin-bottom: 16px;

  :global(.now-fresh) & {
    display: inline-block;
    font-size: 16px;
    width: auto;
    background-color: $now-light-tan;
    border: 1px solid $now-brown;
    padding: 2px 10px;
    border-radius: 23px;
    line-height: 1.3333;
  }

  :global(.gather) & {
    color: $gather-green;
  }

  :global(.petcurean) & {
    color: $petcurean-orange;
  }
}

.title {
  text-align: center;
  margin-bottom: 16px;
  @include h3();

  @include at-breakpoint(large) {
    padding: 0;
  }

  :global(.summit) & {
    color: $summit-blue;
  }
}

.instructions {
  @include p2;
  margin-bottom: 16px;

  @include at-breakpoint(large) {
    @include p1;
  }

  :global(.now-fresh) & {
    :global(.locale-he) & {
      @include font-sizes(18px, 18px, 21px, 24px);
    }
  }

  :global(.summit) & {
    @include p1;
  }
}

.phonenum {
  margin-bottom: 24px;

  @include at-breakpoint(large) {
    margin-bottom: 32px;

    p {
      :global(.go-solutions) & {
        @include p1();
      }

      :global(.now-fresh) & {
        @include p1();
        font-weight: 300;
      }
    }
  }
  // Star used to set more specificity & overwrite hover effect
  :global(.gather) & {
    color: $gather-green;
  }

  :global(.go-solutions) & * {
    color: $color-white;
  }

  :global(.petcurean) & * {
    color: $petcurean-grey;
  }
}

.form {
  grid-column: span 6;
  position: relative;
  margin: 16px 0;

  @include at-breakpoint(medium) {
    grid-column: 2/6;
  }

  @include at-breakpoint(large) {
    grid-column: 4/10;
  }

  @include at-breakpoint(x-large) {
    grid-column: 5/9;
  }
}

.inline-form {
  margin: 0;
}

.form-error-msg {
  @include p2();
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 32px;
  color: $color-dark-red;
  font-weight: 300;
  background-color: $color-light-red;

  :global(.go-solutions) & {
    background-color: $go-pink;
  }

  :global(.now-fresh) & {
    color: $now-red;
    background-color: $now-transparent-red;
    border-radius: 0;
  }
}

.bot-field {
  display: none;
}

.field:not(fieldset) {
  margin-bottom: 16px;
}

.agreement {
  margin-bottom: 40px;
}

.submit-btn {
  width: 100%;
  margin-top: 40px;
}
