
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  background-color: $color-white;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 39px 17px;
  width: 100%;
  height: calc(100% - 152px);
  z-index: 1;
  border-top: 1px solid $go-light-opaque-black;

  &.static {
    position: static;
  }

  :global(.gather) & {
    background: url('/gather/transitionCta.jpg') center no-repeat;
    background-size: cover;
    color: $gather-white;
    height: auto;
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;
    height: auto;
  }

  @include at-breakpoint(medium) {
    padding: 40px 32px;
    justify-content: center;
    height: auto;
    border-top: none;
  }

  @include at-breakpoint(large) {
    position: static;
    height: 100%;
    padding: 32px;
    border-top: 1px solid;

    :global(.gather) & {
      background: $gather-cream;
      color: $gather-black;
    }

    :global(.go-solutions) & {
      border-color: $go-light-opaque-black;
    }

    :global(.now-fresh) & {
      border-color: $now-grey;
    }

    :global(.summit) & {
      background: $summit-white;
      color: $summit-black;
    }
  }
}

.headline {
  @include p2();
  @include bold();
  margin-bottom: 8px;
  max-width: 368px;

  @include at-breakpoint(large) {
    @include p1();
    @include bold();
    max-width: none;
  }

  :global(.summit) & {
    @include p2();
    @include bold();
  }
}

.subheadline {
  @include p3();
  margin-bottom: 28px;
  max-width: 364px;

  @include at-breakpoint(large) {
    @include p2();
    margin-bottom: 32px;
    max-width: none;
  }

  :global(.summit) & {
    @include p3();
  }
}

.cta {
  :global(.gather) & {
    background-color: $gather-white;
    color: $gather-green;
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-blue;
  }
  @include at-breakpoint(large) {
    :global(.gather) & {
      background-color: $gather-green;
      color: $gather-white;
      border-color: $gather-green;
    }

    :global(.summit) & {
      background-color: $summit-blue;
      color: $summit-white;
      border-color: $summit-blue;
    }
  }
}
