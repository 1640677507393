
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding-bottom: 100px;

  :global(.go-solutions) & {
    padding-top: 16px;

    @include at-breakpoint(medium) {
      padding-top: 0;
    }

    @include at-breakpoint(large) {
      padding-top: 24px;
    }

    @include at-breakpoint(x-large) {
      padding-top: 100px;
    }
  }

  :global(.now-fresh) & {
    background: $now-tan;
    color: $now-brown;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    padding: 20px;
    display: flex;
    min-height: 600px;
    align-items: center;

    @include at-breakpoint(large) {
      padding: 100px 0;
      padding-top: 84px;
      padding-bottom: 100px;
    }
  }

  :global(.petcurean) & {
    padding-top: 16px;

    @include at-breakpoint(medium) {
      padding-top: 24px;
    }

    @include at-breakpoint(x-large) {
      padding-top: 100px;
    }
  }
}

.content-wrapper {
  @include grid-container();
  max-width: 1280px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;

  @media screen and (min-width: 1408px) {
    padding: 0;
  }

  :global(.gather) & {
    @include flex-container();
    justify-content: center;
    align-items: center;
    margin: 80px auto 0;
    text-align: center;
  }
}

.bg-image {
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: 2/6;
    width: 100%;
  }

  @include at-breakpoint(large) {
    grid-column: 8/-1;
    grid-row: 1;
    width: auto;
    margin-inline-end: -44px;
  }

  @include at-breakpoint(x-large) {
    grid-column: 6/-1;
    width: 100%;
    margin-inline-end: 0;
  }

  picture {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include at-breakpoint(large) {
      justify-content: flex-end;
    }

    img {
      max-height: 220px;
      width: auto;

      @include at-breakpoint(medium) {
        max-height: 300px;
      }

      @include at-breakpoint(large) {
        max-height: none;
        width: 100%;
      }
    }
  }

  :global(.gather) & {
    display: none;
  }

  :global(.go-solutions) & {
    @include at-breakpoint(x-large) {
      padding-inline-start: 54px;
    }
  }

  :global(.now-fresh) & {
    display: none;
  }

  :global(.petcurean) & {
    @include at-breakpoint(x-large) {
      padding-inline-start: 54px;
    }
  }
}

.content {
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: span 5;
  }

  @include at-breakpoint(large) {
    grid-column: span 7;
    grid-row: 1;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 5;
  }

  :global(.gather) & {
    max-width: 700px;
  }

  :global(.now-fresh) & {
    @include at-breakpoint(x-large) {
      grid-column: 1 / span 6;
    }
  }
}

.headline {
  @include h3();
  margin: 0 0 32px;
  padding-inline-end: 16.6667%;

  @include at-breakpoint(medium) {
    margin: 0 0 16px;
    padding-inline-end: 0;
  }

  @include at-breakpoint(x-large) {
    margin: 0 0 32px;
  }

  :global(.gather) & {
    @include font-sizes(40px, 87px, 87px, 87px);
    @include line-heights(44px, 92px, 92px, 92px);
    padding: 0;
  }

  :global(.go-solutions) & {
    font-size: 44px;
    line-height: 1.0682;
    margin-top: 28px;

    @include at-breakpoint(medium) {
      margin: 60px 0 24px 0;
    }
  }

  :global(.now-fresh) & {
    @include font-sizes(30px, 48px, 48px);
    font-weight: 400;
    margin-top: 40px;

    @include at-breakpoint(large) {
      margin-bottom: 24px;
    }

    @include at-breakpoint(x-large) {
      margin-bottom: 32px;
    }
  }

  :global(.petcurean) & {
    color: $petcurean-orange;
    margin-top: 28px;

    @include at-breakpoint(medium) {
      margin: 60px 0 24px 0;
    }
  }
}

.subhead {
  margin: 0 0 32px;
  font-size: 21px;
  line-height: 1.5238;

  :global(.gather) & {
    @include font-sizes(16px, 19px, 19px, 19px);
    @include line-heights(28px, 36px, 36px, 36px);
  }

  :global(.go-solutions) & {
    p {
      color: $color-white;
    }

    @include at-breakpoint(large) {
      margin: 0 0 24px;
    }
  }

  :global(.now-fresh) & {
    margin: 0 0 16px;

    @include at-breakpoint(medium) {
      margin: 0 0 40px;
    }

    @include at-breakpoint(x-large) {
      padding-inline-end: 28.6%;
    }
  }

  :global(.petcurean) & {
    @include at-breakpoint(large) {
      margin: 0 0 24px;
    }
  }
}

.error-message {
  @include p3();
  grid-row: 4;
  margin: 0 0 40px;

  @include at-breakpoint(medium) {
    margin-bottom: 60px;
  }

  :global(.gather) & {
    @include eyebrow();
  }

  :global(.go-solutions) & {
    color: $go-yellow;
  }

  :global(.now-fresh) & {
    margin: 0 0 60px;
    color: $now-red;
    font-size: 16px;
  }

  :global(.petcurean) & {
    @include eyebrow();
    color: $petcurean-orange;
  }
}

.ctas {
  display: flex;
  flex-wrap: wrap;

  :global(.gather) & {
    justify-content: center;
    gap: 16px;
  }
}

.cta {
  margin-inline-end: 16px;
  margin-bottom: 16px;

  :global(.gather) & {
    margin: 0;
  }

  :global(.go-solutions) & {
    margin-inline-end: 24px;
    margin-bottom: 18px;
  }

  :global(.petcurean) & {
    margin-inline-end: 24px;
    margin-bottom: 18px;
  }
}
