
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.element {
  display: flex;
  align-items: center;
  justify-content: center;

  :global(.go-solutions) & {
    margin-inline-end: 15px;

    @include at-breakpoint(medium) {
      margin-inline-end: 30px;
    }
  }

  &:last-child {
    margin-inline-end: 0;
  }

  button {
    @include p1();
    @include bold();
  }

  :global(.gather) & {
    width: 35px;
    height: 35px;
  }

  :global(.go-solutions) & {
    width: 35px;
    height: 35px;
  }

  :global(.now-fresh) & {
    width: 40px;
    height: 40px;
  }

  :global(.petcurean) & {
    width: 35px;
    height: 35px;
  }
}

.page-indicator {
  composes: element;

  :global(.gather) &,
  :global(.petcurean) & {
    margin: 0 10px;
  }

  button {
    :global(.now-fresh) & {
      color: $now-brown;
    }

    :global(.gather) & {
      color: $petcurean-grey;
      font-size: 22px;
    }

    :global(.go-solutions) & {
      color: $color-black;
    }

    :global(.petcurean) & {
      color: $petcurean-grey;
      font-size: 21px;
    }

    &:hover {
      :global(.now-fresh) & {
        font-weight: bold;
        text-decoration: underline;
        text-decoration-thickness: 3px;
      }
    }
  }
}

.active-indicator {
  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
    border-radius: 50%;
  }

  :global(.gather) &,
  :global(.petcurean) &,
  :global(.summit) & {
    min-width: 35px;
    min-height: 35px;
  }

  :global(.gather) & {
    background-color: $gather-green;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    button {
      color: $gather-white;
    }
  }

  :global(.go-solutions) & {
    button {
      color: $color-white;
    }
    background-color: $color-black;
    border-radius: 35px;
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    button {
      color: $petcurean-white;
    }
  }
}

.pages {
  display: flex;
  width: 100%;
  justify-content: center;
}

.previous {
  composes: element;

  :global(.now-fresh) & {
    transform: rotate(180deg);
    margin-top: 5px;

    svg {
      path {
        fill: $now-brown;
      }
    }
  }

  :global(.gather) & {
    margin-top: 4px;
    margin-right: 70px;
    svg {
      path {
        fill: $petcurean-grey;
      }
    }
  }

  :global(.go-solutions) & {
    margin-inline-end: 15px;

    @include at-breakpoint(medium) {
      margin-inline-end: 76px;
    }
    svg {
      path {
        fill: $color-black;
      }
    }
  }

  :global(.petcurean) & {
    margin-top: 3px;
    margin-right: 70px;
    svg {
      path {
        fill: $petcurean-grey;
      }
    }
  }
}

.next {
  composes: element;

  svg {
    transform: rotate(180deg);
  }

  :global(.now-fresh) & {
    margin-top: 5px;
    transform: rotate(180deg);
    svg {
      path {
        fill: $now-brown;
      }
    }
  }

  :global(.gather) & {
    margin-top: 4px;
    margin-left: 70px;
    svg {
      path {
        fill: $petcurean-grey;
      }
    }
  }

  :global(.go-solutions) & {
    @include at-breakpoint(medium) {
      margin-inline-start: 46px;
    }

    svg {
      path {
        fill: $color-black;
      }
    }
  }

  :global(.petcurean) & {
    margin-top: 3px;
    margin-left: 70px;
    svg {
      path {
        fill: $petcurean-grey;
      }
    }
  }
}

.invisible {
  visibility: hidden;
}
