
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
$svgScale: scale(0.7);

.container {
  position: relative;
  padding: 24px 0;

  &.is-open {
    .answer {
      display: block;
    }

    .caret {
      margin-top: -8px;
      transform: $svgScale rotate(90deg);
    }
  }

  :global(.gather) & {
    border-bottom: 1px solid $gather-green;
  }

  :global(.go-solutions) & {
    border-bottom: 1px solid $go-semidark-grey;
  }

  :global(.now-fresh) & {
    border-bottom: 1px solid $now-brown;
  }

  :global(.petcurean) & {
    border-bottom: 1px solid $petcurean-orange;
  }

  :global(.summit) & {
    border-bottom: 1px solid $summit-blue;
  }
}

.question-wrapper {
  position: relative;
  margin: 0;
}

.toggle {
  display: block;
  padding-inline-end: 30px;
  width: 100%;
  text-align: start;
  line-height: 0;

  &:focus {
    box-shadow: 0 0 2px 2px $outline;
    outline: none;
  }

  @include at-breakpoint(medium) {
    padding-inline-end: 60px;
  }

  @include at-breakpoint(large) {
    padding-inline-end: 44px;
  }

  @include at-breakpoint(x-large) {
    padding-inline-end: 100px;
  }

  :global(.petcurean) & {
    padding-inline-end: 44px;

    @include at-breakpoint(medium) {
      padding-inline-end: 56px;
    }

    @include at-breakpoint(x-large) {
      padding-inline-end: 66px;
    }
  }
}

.question {
  @include p2();
  color: $color-white;

  :global(.gather) & {
    @include h5();
    @include font-sizes(16px, 19px, 19px, 19px);
    @include line-heights(150%, 189%, 189%, 189%);
    color: $gather-black;
  }

  :global(.go-solutions) & {
    font-size: 12px;
    line-height: 1.33333;
    font-weight: 700;

    @include at-breakpoint(large) {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-size: 16px;
    line-height: 1.33333;
    font-weight: 400;

    @include at-breakpoint(large) {
      line-height: 1.5;
    }
  }

  :global(.petcurean) & {
    @include h5();
    font-weight: 600;
    @include font-sizes(16px, 21px, 21px, 21px);
    @include line-heights(119%, 152%, 152%, 152%);
    color: $petcurean-grey;
  }

  :global(.summit) & {
    @include h5();
    @include font-sizes(16px, 21px, 21px, 21px);
    @include line-heights(119%, 152%, 152%, 152%);
    color: $summit-black;
  }
}

.caret {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  right: 0;
  margin-top: -12px;
  transform: $svgScale rotate(-90deg);

  :global(.locale-he) & {
    right: auto;
    left: 0;
  }

  @include at-breakpoint(x-large) {
    right: 3px;

    :global(.locale-he) & {
      right: auto;
      left: 3px;
    }
  }

  path {
    :global(.gather) & {
      fill: $gather-green;
    }

    :global(.go-solutions) & {
      fill: $go-yellow;
    }

    :global(.now-fresh) & {
      fill: $now-brown;
    }

    :global(.petcurean) & {
      fill: $petcurean-orange;
    }

    :global(.summit) & {
      fill: $summit-blue;
    }
  }
}

.answer {
  display: none;
  padding-top: 16px;
  padding-inline-end: 44px;

  @include at-breakpoint(medium) {
    padding-inline-end: 96px;
  }

  @include at-breakpoint(large) {
    padding-inline-end: 56px;
  }

  @include at-breakpoint(x-large) {
    padding-inline-end: 110px;
  }

  :global(.gather) & {
    font-weight: 300;
    @include at-breakpoint(medium) {
      padding-inline-end: 66px;
    }

    @include at-breakpoint(x-large) {
      padding-inline-end: 76px;
    }
  }

  :global(.go-solutions) & {
    @include p3();

    @include at-breakpoint(large) {
      @include p2();
    }
  }

  :global(.now-fresh) & {
    font-size: 14px;
    line-height: 1.33333;
    font-weight: 400;

    @include at-breakpoint(large) {
      @include p2();
    }
  }

  :global(.petcurean) & {
    font-weight: 300;
    @include at-breakpoint(medium) {
      padding-inline-end: 66px;
    }

    @include at-breakpoint(x-large) {
      padding-inline-end: 76px;
    }
  }

  :global(.summit) & {
    @include at-breakpoint(medium) {
      padding-inline-end: 66px;
    }

    @include at-breakpoint(x-large) {
      padding-inline-end: 76px;
    }
  }
}

.answer-content {
  p,
  a {
    :global(.gather) & {
      color: $gather-black;
      @include font-sizes(12px, 16px, 16px, 16px);
      @include line-heights(175%, 175%, 175%, 175%);
    }

    :global(.go-solutions) & {
      color: $color-white;
    }

    :global(.now-fresh) & {
      color: $now-brown;
    }

    :global(.petcurean) & {
      @include font-sizes(12px, 16px, 16px, 16px);
      @include line-heights(175%, 162%, 162%, 162%);
    }

    :global(.summit) & {
      color: $summit-black;
      @include font-sizes(12px, 16px, 16px, 16px);
      @include line-heights(175%, 169%, 169%, 169%);
    }
  }

  p,
  ol li,
  ul li {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
  }

  a {
    text-decoration: underline;

    svg {
      display: none;
    }
  }

  hr {
    :global(.go-solutions) & {
      border-color: $color-white;
    }
  }

  ul {
    li {
      &::before {
        :global(.go-solutions) & {
          background-color: $color-white;
        }

        :global(.now-fresh) & {
          background-color: $now-brown;
        }
      }
    }
  }

  :global(.petcurean) & {
    @include at-breakpoint(x-large) {
      padding-bottom: 24px;
    }
  }
}
