@mixin font-sizes($sm, $md, $lg, $xlg: $lg) {
  font-size: $sm;

  @include at-breakpoint(medium) {
    font-size: $md;
  }

  @include at-breakpoint(large) {
    font-size: $lg;
  }

  @include at-breakpoint(x-large) {
    font-size: $xlg;
  }
}

@mixin line-heights($sm, $md, $lg, $xlg: $lg) {
  line-height: $sm;

  @include at-breakpoint(medium) {
    line-height: $md;
  }

  @include at-breakpoint(large) {
    line-height: $lg;
  }

  @include at-breakpoint(x-large) {
    line-height: $xlg;
  }
}
