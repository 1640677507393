
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.background {
  align-items: center;
  padding: 80px 0 120px;

  :global(.gather) & {
    background-color: $gather-green;
    color: $gather-white;
    @include default-margin-bottom();
    padding: 80px 0 80px;

    @include at-breakpoint(large) {
      padding: 80px 0 100px;
    }

    @include at-breakpoint(x-large) {
      padding: 100px 0;
    }
  }

  :global(.go-solutions) & {
    background-color: $color-black;

    a {
      color: $color-white;
    }
  }

  :global(.now-fresh) & {
    padding: 0;
    color: $now-brown;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-black;
  }
}

.container {
  @include grid-container();

  :global(.go-solutions) & {
    color: $color-white;
  }

  :global(.now-fresh) & {
    margin: 0;
    max-width: none;
    width: auto;
    padding: 0;
    grid-column-gap: 0;
  }
}

.image {
  grid-column: span 6;
  height: 0;
  position: relative;
  padding-bottom: 100%;

  :global(.gather) & {
    padding-bottom: 100%;

    @include at-breakpoint(medium) {
      padding-bottom: 82%;
    }

    @include at-breakpoint(large) {
      padding-bottom: 134%;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 7;
      padding-bottom: 70%;
    }
  }

  :global(.now-fresh) & {
    grid-column: span 6;
    height: auto;
    padding-bottom: 0;

    img {
      position: static;
      object-fit: cover;
    }
  }

  :global(.summit) & {
    grid-column: span 6;
    padding-bottom: 100%;

    @include at-breakpoint(large) {
      grid-column: span 5;
      padding-bottom: 134%;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 5;
      padding-bottom: 100%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.copy-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-column: span 6;
  align-items: center;
  text-align: center;
  margin-top: 60px;

  @include at-breakpoint(large) {
    grid-column: 7/13;
    align-items: flex-start;
    text-align: start;
  }

  @include at-breakpoint(x-large) {
    grid-column: 8/13;

    :global(.gather) & {
      grid-column: 9/13;
    }

    :global(.summit) & {
      grid-column: 8/13;
    }
  }

  :global(.now-fresh) & {
    margin-top: 0;
    justify-content: center;
    border-top: 1px solid $now-brown;
    padding: 64px 48px;

    @include at-breakpoint(large) {
      grid-column: 7/13;
      border-top: none;
      border-left: 1px solid $now-brown;

      :global(.locale-he) & {
        border-left: none;
        border-right: 1px solid $now-brown;
      }
    }

    @include at-breakpoint(x-large) {
      padding: 90px 100px;
    }
  }

  :global(.gather) &,
  :global(.summit) & {
    text-align: start;
    align-items: flex-start;
  }
}

.eyebrow {
  @include eyebrow-box();
  margin-bottom: 15px;

  :global(.gather) & {
    margin-bottom: 8px;
  }

  :global(.summit) & {
    margin-bottom: 5px;
  }
}

.headline {
  @include h2();
  margin-bottom: 32px;

  @include at-breakpoint(large) {
    @include h3();
  }

  :global(.gather) & {
    @include font-sizes(28px, 45px, 45px);
    @include line-heights(37px, 51px, 51px);
    font-weight: 400;
  }

  :global(.summit) & {
    @include font-sizes(28px, 45px, 45px);
    @include line-heights(37px, 51px, 51px);
    font-weight: 400;
    text-transform: uppercase;
    color: $summit-blue;
  }
}

.select-headline {
  @include p1();

  :global(.gather) & {
    @include font-sizes(16px, 19px, 19px);
    @include line-heights(28px, 36px, 36px);
  }

  :global(.summit) & {
    @include font-sizes(16px, 19px, 19px);
    @include line-heights(28px, 36px, 36px);
  }
}

.dropdowns-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 24px;
  width: 100%;
  text-align: start;
  margin: 24px 0 0;
}

.dropdown {
  position: relative;
  grid-column: span 6;

  @include at-breakpoint(medium) {
    grid-column: span 3;
  }

  &:only-child {
    @include at-breakpoint(medium) {
      grid-column: 2 / 6;
    }

    @include at-breakpoint(large) {
      grid-column: span 3;
    }
  }

  :global(.gather) & {
    > div {
      background-color: rgba($gather-white, 0.15);
      &:after {
        border-color: $gather-white;
      }
    }
  }

  :global(.now-fresh) & {
    > div {
      border-radius: 35px;
    }
  }

  :global(.summit) & {
    > div {
      background-color: $summit-black;
      color: $summit-white;

      &:after {
        border-color: $summit-white;
      }
    }
  }
}

.feeding-option {
  @include p1();
  @include bold();
  margin-top: 40px;
}

.contact-btn {
  margin-top: 24px;
}

.static-form {
  @include visually-hidden;
}

.full-text-container {
  margin-top: 40px;

  & > div {
    display: flex;
    flex-direction: column;
  }

  button {
    color: $color-white;
    margin: 40px auto;

    :global(.gather) & {
      margin-inline-start: 0;
      margin-inline-end: 0;
      align-self: flex-start;
      color: $gather-white;

      svg {
        path {
          fill: $gather-white;
        }
      }

      &:after {
        background-image: $gather-light-gradient;
        bottom: -6px;
      }
    }

    :global(.summit) & {
      margin-inline-start: 0;
      margin-inline-end: 0;
      align-self: flex-start;
    }

    @include at-breakpoint(large) {
      margin: 40px auto 0 0;

      :global(.locale-he) & {
        margin: 40px 0 0 auto;
      }
    }
  }

  :global(.collapsed) {
    margin-top: 0;
  }

  :global(.go-solutions) & {
    button {
      color: $color-white;

      p {
        font-size: 12px;
        line-height: 1.5;
        text-transform: none;
        font-weight: 700;

        @include at-breakpoint(medium) {
          font-size: 16px;
        }
      }
    }

    svg {
      path {
        fill: $color-white;
      }
    }
  }

  :global(.now-fresh) & {
    button {
      color: $now-brown;
      border-bottom: 2px solid $now-brown;
    }

    svg {
      path {
        fill: $now-brown;
      }
    }
  }
}

.full-text {
  @include p3();
  white-space: pre-wrap;

  :global(.now-fresh) & {
    font-size: 16px;
  }
}

.cta-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @include at-breakpoint(large) {
    justify-content: flex-start;
  }
}

.chart-cta {
  margin: 0 10px 15px 10px;
  width: 100%;

  @include at-breakpoint(medium) {
    width: fit-content;
  }

  @include at-breakpoint(large) {
    margin: 32px 20px 0 0;

    :global(.locale-he) & {
      margin: 32px 0 0 auto;
    }
  }
}
