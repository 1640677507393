
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.menu-item {
  @include eyebrow();
  display: flex;
  color: $color-white;
  align-items: center;
  line-height: 32px;
  width: 100%;

  svg {
    margin-inline-start: auto;
    display: inline;
    width: 18px;

    @include at-breakpoint(large) {
      margin-inline-start: 10px;

      :global(.locale-he) & {
        margin-inline-start: 0;
        margin-inline-end: 10px;
      }
    }
  }

  @media (hover: hover) {
    &:hover,
    :global(.active) & {
      color: $go-yellow;

      svg {
        path {
          fill: $go-yellow;
        }
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
    width: 100%;
  }

  @include at-breakpoint(large) {
    max-width: 200px;
  }
}

.background {
  position: relative;
  width: 100%;
  top: 100%;
  left: 0;
  transition:
    visibility 0.25s,
    height 0.25s,
    margin-top 0.25s;
  visibility: hidden;
  overflow: hidden;

  :global(.sub-nav-open) & {
    margin-top: 32px;
  }

  :global(.active) & {
    visibility: visible;
    margin-top: 32px;
  }

  @include at-breakpoint(large) {
    width: 100%;
    position: absolute;
    min-height: 0;
    margin-top: 0;

    :global(.sub-nav-open) & {
      min-height: 300px;
      transition: min-height 0.25s;
      margin-top: 0;
    }
  }
}

.subnav-container {
  width: 100%;
  max-width: $max-width;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  transition:
    height 0.25s,
    margin 0.25s;

  :global(.active) & {
    height: 100%;
    overflow: visible;
  }

  @include at-breakpoint(large) {
    width: calc(100% - 64px);

    :global(.active) & {
      height: calc(100% - 63px - 32px);
      margin: 32px auto 63px;
    }
  }
}

.subnav-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  text-transform: none;
}

.subnav {
  grid-column: span 8;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include at-breakpoint(large) {
    grid-column: span 7;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 8;
  }
}

.subnav-item {
  width: 100%;

  @include at-breakpoint(large) {
    margin-bottom: 16px;
  }
}

/* Gather styles */
:global(.gather) {
  :global(.active) {
    .subnav-container {
      @include at-breakpoint(large) {
        height: calc(100% - 64px - 64px);
        margin: 64px auto;
      }
    }

    .menu-item {
      color: $gather-white;

      @include at-breakpoint(large) {
        color: $gather-dark-green;
      }

      svg {
        path {
          fill: $gather-white;

          @include at-breakpoint(large) {
            fill: $gather-dark-green;
          }
        }
      }

      @media (hover: hover) {
        &:hover {
          color: $gather-lighter-green;

          svg path {
            fill: $gather-lighter-green;
          }
        }
      }
    }
  }

  .menu-item {
    @include gather-nav();

    svg {
      path {
        fill: $gather-white;
      }
    }

    @media (hover: hover) {
      &:hover {
        color: $gather-dark-green;

        svg {
          path {
            fill: $gather-dark-green;
          }
        }
      }
    }
  }

  .background {
    @include at-breakpoint(large) {
      background-color: $gather-green;
      min-height: 300px;
    }
  }

  .subnav {
    @include at-breakpoint(x-large) {
      grid-column: span 7;
    }
  }
}
/* End Gather Styles */

/* Go Solutions Styles */
:global(.go-solutions) {
  .menu-item {
    font-size: 21px;
    font-weight: 900;

    @include at-breakpoint(large) {
      font-size: 14px;
      line-height: 16px;
      @include bold();
    }
  }

  .background {
    background-color: transparent;

    @include at-breakpoint(large) {
      background-color: $color-black;
      min-height: 370px;
    }
  }
}
/* End Go Solutions Styles */

/* Now Fresh Styles */
:global(.now-fresh) {
  :global(.active) {
    .menu-item {
      color: $now-brown;
      font-weight: 500;

      svg {
        path {
          fill: $now-brown;
          stroke: $now-brown;
        }
      }

      @include at-breakpoint(large) {
        color: $now-brown;
      }
    }
  }

  .menu-item {
    @include now-fresh-nav();

    svg {
      path {
        fill: $now-brown;
      }
    }

    @media (hover: hover) {
      &:hover {
        color: $now-brown;
        font-weight: 500;

        svg {
          path {
            fill: $now-brown;
            stroke: $now-brown;
          }
        }

        :global(.locale-he) &,
        :global(.locale-zh) & {
          font-weight: 900;
        }
      }
    }
  }

  :global(.active) {
    .background {
      background-color: $color-white;

      @include at-breakpoint(large) {
        background-color: $now-tan;
      }
    }
  }

  .background {
    background-color: $now-tan;
    transition:
      height 0.25s,
      background-color 0.25s,
      visibility 0.25s;

    @include at-breakpoint(large) {
      background-color: $now-tan;
      outline: 1px solid $now-brown;
    }
  }
}
/* End Now Fresh Styles */

/* Petcurean Styles */
:global(.petcurean) {
  :global(.active) {
    .subnav-container {
      @include at-breakpoint(large) {
        height: calc(100% - 64px - 64px);
        margin: 64px auto;
      }
    }

    .menu-item {
      color: $petcurean-dark-tan;

      svg {
        path {
          fill: $petcurean-dark-tan;
        }
      }
    }
  }

  .menu-item {
    @include petcurean-nav();

    svg {
      path {
        fill: $petcurean-white;
      }
    }

    @media (hover: hover) {
      &:hover {
        color: $petcurean-dark-tan;

        svg {
          path {
            fill: $petcurean-dark-tan;
          }
        }
      }
    }

    @include at-breakpoint(large) {
      max-width: 300px;
    }
  }

  .background {
    @include at-breakpoint(large) {
      background-color: $petcurean-orange;
    }
  }

  .subnav {
    @include at-breakpoint(x-large) {
      grid-column: span 7;
    }
  }
}
/* End Petcurean Styles */

/* Summit Styles */
:global(.summit) {
  :global(.active) {
    .subnav-container {
      @include at-breakpoint(large) {
        height: calc(100% - 64px - 64px);
        margin: 64px auto;
      }
    }

    .menu-item {
      color: $summit-light-desaturated-blue;

      svg {
        path {
          fill: $summit-light-desaturated-blue;
        }
      }
    }
  }

  .menu-item {
    @include summit-nav();
    color: $summit-white;

    svg {
      path {
        fill: $summit-white;
      }
    }

    @media (hover: hover) {
      &:hover {
        color: $summit-light-desaturated-blue;

        svg {
          path {
            fill: $summit-light-desaturated-blue;
          }
        }
      }
    }
  }

  .background {
    @include at-breakpoint(large) {
      background-color: $summit-blue;
      min-height: 320px;
    }
  }

  .subnav {
    @include at-breakpoint(x-large) {
      grid-column: span 7;
    }
  }
}
/* End Summit Styles */
