
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  min-height: 600px;
  @include grid-container();
  background-color: $color-white;
  padding-bottom: 57px;
  grid-template-rows: auto 1fr;

  @include at-breakpoint(medium) {
    padding-bottom: 57px;
  }

  @include at-breakpoint(large) {
    padding-bottom: 97px;
  }

  :global(.go-solutions) & {
    grid-row-gap: 34px;
  }

  :global(.now-fresh) & {
    grid-column-gap: 16px;
    grid-row-gap: 10px;
    min-height: auto;
    outline: 1px solid $now-brown;
    background-color: $now-tan;

    @include at-breakpoint(large) {
      grid-column-gap: 25px;
    }
  }
}

.results {
  @include p3();
  grid-column: span 6;
  display: flex;
  justify-content: flex-end;
  margin: 36px 0 -14px;

  @include at-breakpoint(medium) {
    margin-top: 39px;
  }

  @include at-breakpoint(large) {
    margin: 67px 0 -6px;
    grid-column: span 12;
  }

  :global(.gather) & {
    margin-bottom: 27px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $petcurean-grey;
  }

  :global(.go-solutions) & {
    color: $go-semidark-grey;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-size: 14px;
    margin: 36px 0 18px;

    @include at-breakpoint(large) {
      margin-bottom: 26px;
    }
  }

  :global(.petcurean) & {
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $petcurean-grey;
  }
}

.card-container {
  grid-column: span 6;
  display: flex;

  @include at-breakpoint(medium) {
    grid-column: span 3;
  }

  @include at-breakpoint(large) {
    grid-column: span 4;
  }

  :global(.gather) & {
    margin-bottom: 32px;
    color: $petcurean-grey;
  }

  :global(.now-fresh) & {
    margin-bottom: 32px;
  }

  :global(.petcurean) & {
    margin-bottom: 32px;
    color: $petcurean-grey;
  }

  & > span {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  @media (hover: hover) {
    &:hover {
      :global(.gather) & {
        p {
          color: $gather-green;
        }

        .arrow {
          path {
            fill: $gather-green;
          }
        }
      }

      :global(.petcurean) & {
        p {
          color: $petcurean-orange;
        }

        .arrow,
        .external-arrow {
          path {
            fill: $petcurean-orange;
          }
        }
      }

      :global(.go-solutions) & {
        color: $color-black;
      }

      :global(.now-fresh) & {
        color: $now-brown;
      }

      .arrow {
        path {
          :global(.go-solutions) & {
            fill: $color-black;
          }

          :global(.now-fresh) & {
            fill: $now-brown;
          }
        }
      }

      .video-icon {
        path {
          :global(.go-solutions) & {
            fill: $color-white;
          }

          :global(.now-fresh) & {
            fill: $now-tan;
          }
        }
      }

      img {
        transform: scale(1.05);
      }
    }
  }
}

.img-container {
  min-width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  opacity: 0;
  overflow: hidden;
  transition: opacity 400ms ease-in;

  :global(.gather) &,
  :global(.petcurean) & {
    border-radius: 20px;
  }

  .video-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    :global(.now-fresh) & {
      margin: 0;

      path {
        fill: $now-tan;
      }
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    transition:
      transform 0.25s,
      opacity 0.5s;
  }
}

.anim-in {
  opacity: 1;
}

.date {
  margin-top: 32px;

  @include at-breakpoint(large) {
    margin-top: 24px;
  }

  :global(.petcurean) & {
    color: $petcurean-transparent-grey;
    @include font-sizes(12px, 12px, 12px, 14px);
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

.tags {
  @include eyebrow();
  color: $go-charcoal;
  margin-top: 32px;

  @include at-breakpoint(large) {
    margin-top: 24px;
  }

  span:first-child:after {
    content: '|';
    margin: 0 10px;
  }

  span:only-child:after {
    content: '';
  }

  & + .card-headline {
    margin-top: 16px;
  }

  span {
    :global(.now-fresh) & {
      &:hover {
        font-weight: 400;
      }
    }
  }

  :global(.gather) & {
    color: $petcurean-grey;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  :global(.petcurean) & {
    color: $petcurean-grey;
    @include font-sizes(12px, 12px, 12px, 14px);
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
  }
}

.external-blog-tags {
  :global(.petcurean) & {
    margin-top: 8px;
    color: $petcurean-orange;
  }
}

.card-headline-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 16px;

  :global(.now-fresh) & {
    align-items: center;
  }

  :global(.now-fresh) &,
  :global(.gather) &,
  :global(.petcurean) & {
    margin-top: 8px;
    position: relative;
  }
}

.align-arrow-icon {
  align-items: flex-end;
}

.card-headline {
  @include h5();
  text-transform: uppercase;

  :global(.gather) & {
    text-transform: capitalize;
    color: $petcurean-grey;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  :global(.now-fresh) & {
    text-transform: none;
    @include p2();
    font-size: 16px;
    font-family: Almarai, sans-serif !important;
  }

  :global(.petcurean) & {
    text-transform: capitalize;
    @include font-sizes(18px, 18px, 18px, 20px);
    @include line-heights(24px, 24px, 24px, 26px);
    font-weight: 600;
  }
}

svg.arrow {
  min-width: 24px;

  :global(.go-solutions) & {
    margin: 0;

    path {
      fill: $color-black;
    }
  }

  :global(.now-fresh) & {
    margin: 0 0 2px;
    path {
      fill: $now-brown;
    }
  }

  :global(.gather) &,
  :global(.petcurean) & {
    path {
      fill: $petcurean-grey;
    }
  }
}

svg.external-arrow {
  min-width: 24px;
  margin: 0;
  position: absolute;
  bottom: 5px;
  right: 0;
}

.pagination {
  @include p1();
  grid-column: span 6;
  margin-top: 6px;
  border-top: 2px solid $go-light-opaque-black;
  padding-top: 44px;

  :global(.now-fresh) & {
    border: none;
    margin-top: 0;
  }

  @include at-breakpoint(medium) {
    button {
      font-size: 21px;
      line-height: 32px;
    }
  }

  @include at-breakpoint(large) {
    margin-top: 30px;
    padding-top: 56px;
    grid-column: span 12;
  }
}
