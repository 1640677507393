
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container;
  padding: 0 16px 0 32px;
}

.quote {
  font-size: 30px;
  quotes: '“' '”' '‘' '’';
  position: relative;

  &::before {
    content: open-quote;
  }

  &::after {
    content: no-close-quote;
  }
}

.author {
  align-items: center;
  display: flex;

  @include at-breakpoint(large) {
    padding-inline-start: 30px;
    padding-top: 32px;
  }

  @include at-breakpoint(x-large) {
    padding-inline-start: 39px;
  }
}

.photo {
  border-radius: 50%;
  height: 62px;
  margin-inline-end: 18px;
  width: 62px;

  @include at-breakpoint(x-large) {
    margin-inline-end: 34px;
  }
}

.address {
  display: flex;
  flex-direction: column;
}

// Gather styling
:global(.gather) {
  .container {
    color: $gather-black;
    @include at-breakpoint(medium) {
      padding: 0;
      padding-inline-start: 32px;
    }

    @include at-breakpoint(large) {
      // we repeat the same class for specificity
      // to override the parent blog post grid-column: 2/12;
      &.container {
        grid-column: span 12;
        margin-bottom: 10px;
      }
    }
  }

  .quote {
    grid-column-start: 1;
    grid-column-end: 7;
    margin-bottom: 46px;
    @include line-heights(30px, 35px, 43px, 54px);
    @include font-sizes(22px, 24px, 32px, 45px);

    @include at-breakpoint(small) {
      grid-column-end: 7;
      margin-bottom: 24px;
      padding-inline-start: 10px;
    }

    @include at-breakpoint(medium) {
      grid-column-end: 4;
      margin-bottom: 40px;
      padding-inline-start: 20px;
    }

    @include at-breakpoint(large) {
      grid-column-end: 8;
      line-height: 1.5;
      margin-bottom: 0;
      padding-inline-start: 40px;
      width: 110%;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 1;
      grid-column-end: 9;
      padding-inline-start: 60px;
    }

    &::before {
      color: $gather-green;
      @include font-sizes(70px, 70px, 90px, 90px);
      @include line-heights(68px, 68px, 82px, 82px);
      font-weight: 400;
      left: -40px;
      top: -2px;
      position: absolute;

      @include at-breakpoint(medium) {
        left: -49px;
      }

      @include at-breakpoint(large) {
        top: -5px;
      }

      @include at-breakpoint(x-large) {
        left: 0;
      }
    }
  }

  .author-container {
    grid-column-start: 1;
    grid-column-end: 7;
    position: relative;

    @include at-breakpoint(medium) {
      grid-column-start: 4;
      grid-column-end: 7;
      padding-inline-start: 53px;
    }

    @include at-breakpoint(large) {
      grid-column-start: 9;
      grid-column-end: 13;
      padding-inline-start: 0;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 10;
      grid-column-end: 13;
    }

    &::before {
      background-color: $gather-green;
      content: '';
      display: block;
      height: 7px;
      position: absolute;
      width: 87px;

      @include at-breakpoint(small) {
        height: 87px;
        top: 2px;
        width: 7px;
      }

      @include at-breakpoint(medium) {
        height: 87px;
        top: 2px;
        width: 7px;
      }
    }
  }

  .author {
    @include at-breakpoint(small) {
      padding-top: 12px;
      margin-left: 25px;
    }

    @include at-breakpoint(medium) {
      padding-top: 10px;
      margin-left: 50px;
    }

    @include at-breakpoint(large) {
      padding-top: 10px;
      margin-left: 0px;
    }
  }

  .name {
    @include font-sizes(16px, 16px, 18px, 18px);
    text-transform: capitalize;
    font-weight: 700;
    line-height: 24px;
  }

  .attribution {
    @include p3;
    @include bold;
  }
}

// Go Solutions styling
:global(.go-solutions) {
  .container {
    @include at-breakpoint(medium) {
      padding: 0;
      padding-inline-start: 32px;
    }

    @include at-breakpoint(large) {
      // we repeat the same class for specificity
      // to override the parent blog post grid-column: 2/12;
      &.container {
        grid-column: span 12;
        margin-bottom: 10px;
      }
    }
  }

  .quote {
    font-weight: 700;
    grid-column-start: 1;
    grid-column-end: 7;
    letter-spacing: -1px;
    line-height: 47px;
    margin-bottom: 46px;

    @include at-breakpoint(medium) {
      grid-column-end: 6;
      margin-bottom: 40px;
      padding-inline-start: 53px;
    }

    @include at-breakpoint(large) {
      font-size: 32px;
      grid-column-end: 8;
      line-height: 1.5;
      margin-bottom: 0;
      padding-inline-start: 0;
      width: 110%;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 1;
      padding-inline-start: 79px;
    }

    &::before {
      font-size: 74px;
      font-weight: 400;
      left: -40px;
      line-height: 82px;
      top: -2px;
      position: absolute;

      @include at-breakpoint(medium) {
        left: 0;
      }

      @include at-breakpoint(large) {
        left: -49px;
        top: -5px;
      }

      @include at-breakpoint(x-large) {
        left: 0;
      }
    }
  }

  .author-container {
    grid-column-start: 1;
    grid-column-end: 7;
    position: relative;

    @include at-breakpoint(medium) {
      grid-column-end: 6;
      padding-inline-start: 53px;
    }

    @include at-breakpoint(large) {
      grid-column-start: 9;
      grid-column-end: 13;
      padding-inline-start: 0;
    }

    &::before {
      background-color: $color-black;
      content: '';
      display: block;
      height: 4px;
      position: absolute;
      width: 87px;

      @include at-breakpoint(large) {
        height: 87px;
        top: 2px;
        width: 6px;
      }
    }
  }

  .author {
    @include at-breakpoint(small) {
      padding-top: 34px;
    }

    @include at-breakpoint(medium) {
      padding-top: 34px;
    }

    @include at-breakpoint(large) {
      padding-top: 10px;
    }
  }

  .name {
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
  }

  .attribution {
    @include p3;
    @include bold;
  }
}

// Now Fresh styling
:global(.now-fresh) {
  .container {
    outline: 1px solid $now-brown;
    column-gap: 0;
    padding: 20px 40px;

    @include at-breakpoint(large) {
      grid-column: span 12;
      margin-bottom: 10px;
      padding: 0;
    }
  }

  .quote {
    color: $now-brown;
    font-family: 'Stag', sans-serif;
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 8;
    line-height: 27px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    font-size: 25px;
    padding: 40px 0;

    @include at-breakpoint(medium) {
      grid-column-start: 1;
      grid-column-end: -1;
    }

    @include at-breakpoint(large) {
      grid-column-end: 9;
      line-height: 1.5;
      margin-bottom: 0;
      padding: 80px 0;
      padding-inline-start: 50px;
      padding-inline-end: 40px;
      width: 110%;
    }

    @include at-breakpoint(x-large) {
      padding-inline-start: 50px;
      grid-column-start: 1;
    }

    &::before {
      color: $now-brown;
      font-size: 50px;
      letter-spacing: 1px;
      line-height: 16px;

      @include at-breakpoint(large) {
        left: 25px;
        position: absolute;
        top: 90px;
      }
    }
  }

  .author-container {
    grid-column: span 6;
    border-top: 1px solid $now-brown;

    @include at-breakpoint(medium) {
      grid-column-start: 1;
      grid-column-end: 6;
    }

    @include at-breakpoint(large) {
      grid-column-start: 10;
      grid-column-end: 13;
      padding-inline-start: 0;
      border-left: 1px solid $now-brown;
      border-top: none;
      justify-items: center;
    }
  }

  .author {
    display: flex;
    padding: 40px 0;
    height: 100%;

    @include at-breakpoint(large) {
      padding: 80px 20px;
      flex-direction: column;
      align-items: flex-start;
    }

    @include at-breakpoint(x-large) {
    }
  }

  .photo {
    border: 1px solid $now-brown;
    margin-inline-start: 0;

    @include at-breakpoint(large) {
      margin-inline-start: 0;
      margin-inline-end: 8px;
    }

    @include at-breakpoint(x-large) {
      margin-inline-end: 32px;
    }
  }

  .address {
    color: $now-brown;
  }

  .name {
    @include p1;

    margin-bottom: 2px;
    font-family: 'Stag', sans-serif;

    @include at-breakpoint(large) {
      @include p2;
    }

    @include at-breakpoint(x-large) {
      @include p1;

      margin-bottom: 0;
    }
  }

  .attribution {
    @include p2;
  }
}

:global(.petcurean) {
  .container {
    padding: 0;
    margin-top: 108px;

    :global(.locale-zh) & {
      margin-top: 40px;
    }

    @include at-breakpoint(medium) {
      padding: 0;
      :global(.locale-zh) & {
        margin-top: 80px;
      }
    }

    @include at-breakpoint(large) {
      &.container {
        grid-column: span 12;
        margin-bottom: 10px;
      }
    }
  }

  .quote {
    color: $petcurean-orange;
    font-weight: 600;
    @include font-sizes(20px, 20px, 30px, 45px);
    @include line-heights(26px, 26px, 35px, 50px);
    grid-column-start: 2;
    grid-column-end: 7;
    line-height: 32px;
    margin-bottom: 50px;

    @include at-breakpoint(small) {
      grid-column-start: 1;
      grid-column-end: 7;
      padding-top: 5px;
      padding-inline-start: 30px;
    }

    @include at-breakpoint(medium) {
      grid-column-start: 1;
      grid-column-end: 4;
      margin-bottom: 58px;
      padding-top: 10px;
      padding-inline-start: 40px;
      width: 110%;
      :global(.locale-zh) & {
        padding-inline-start: 15.6%;
      }
    }

    @include at-breakpoint(large) {
      grid-column-end: 8;
      line-height: 1.5;
      margin-bottom: 0;
      padding-top: 0;
      padding-inline-start: 40px;
      width: 110%;

      :global(.locale-zh) & {
        grid-column-start: 2;
        grid-column-end: 8;
        line-height: 54px;
        margin-bottom: 0;
        padding-inline-start: 0;
      }
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 1;
      grid-column-end: 9;
      padding-inline-start: 60px;
      :global(.locale-zh) & {
        grid-column-start: 1;
        grid-column-end: 10;
        padding-inline-start: 113px;
      }
    }

    &::before {
      @include font-sizes(70px, 70px, 90px, 90px);
      font-family: 'museo-sans-rounded', sans-serif;
      font-weight: 400;
      left: -16.6%;
      line-height: 82px;
      top: -5px;
      position: absolute;

      @include at-breakpoint(small) {
        left: 0;
      }

      @include at-breakpoint(medium) {
        left: 0px;
        :global(.locale-zh) & {
          left: 0;
        }
      }

      @include at-breakpoint(large) {
        top: -5px;
        :global(.locale-zh) & {
          left: -11%;
        }
      }

      @include at-breakpoint(x-large) {
        left: 0;
      }
    }
  }

  .author-container {
    grid-column-start: 2;
    grid-column-end: 7;
    position: relative;

    @include at-breakpoint(small) {
      grid-column-start: 1;
      grid-column-end: 7;
      padding-inline-start: 30px;
    }

    @include at-breakpoint(medium) {
      grid-column-start: 4;
      grid-column-end: 7;
      padding-inline-start: 53px;
      margin-left: 50px;

      :global(.locale-zh) & {
        grid-column-start: 1;
        grid-column-end: 6;
        padding-inline-start: 15.6%;
      }
    }

    @include at-breakpoint(large) {
      grid-column-start: 9;
      grid-column-end: 13;
      padding-inline-start: 0;
      margin-left: 0;

      :global(.locale-zh) & {
        grid-column-start: 8;
        grid-column-end: 13;
        padding-inline-start: 0;
        margin-top: 5px;
      }
    }
    @include at-breakpoint(x-large) {
      grid-column-start: 10;
      grid-column-end: 13;
      margin-top: 0;
    }

    &::before {
      background-color: $petcurean-orange;
      content: '';
      display: block;
      height: 7px;
      position: absolute;
      width: 86px;

      @include at-breakpoint(small) {
        height: 87px;
        top: 2px;
        width: 7px;
      }

      @include at-breakpoint(medium) {
        height: 87px;
        top: 2px;
        width: 7px;
      }

      @include at-breakpoint(large) {
        height: 86px;
        top: 2px;
        width: 7px;
      }
    }
  }

  .photo {
    border-radius: 50%;
    height: 62px;
    margin-inline-end: 25px;
    width: 62px;
  }

  .author {
    @include at-breakpoint(small) {
      padding-top: 12px;
      margin-left: 25px;
    }

    @include at-breakpoint(medium) {
      padding-top: 10px;
      margin-left: 50px;
    }

    @include at-breakpoint(large) {
      padding-top: 10px;
      margin-left: 0px;
    }

    :global(.locale-zh) & {
      padding-top: 24.5px;
      @include at-breakpoint(large) {
        padding-top: 0;
        padding-inline-start: 26px;
        min-height: 86px;
      }
    }
  }

  .name {
    @include font-sizes(16px, 16px, 18px, 18px);
    font-weight: 600;
    line-height: 24px;
  }

  .attribution {
    @include p3;
    line-height: 16px;
    :global(.locale-zh) & {
      font-size: 12px;
      font-weight: 700;
      margin-top: 11px;

      @include at-breakpoint(medium) {
        margin-top: 18px;
      }
    }
  }
}

//Summit styling
:global(.summit) {
  .container {
    color: $summit-black;
    @include at-breakpoint(medium) {
      padding: 0;
      padding-inline-start: 32px;
    }

    @include at-breakpoint(large) {
      // we repeat the same class for specificity
      // to override the parent blog post grid-column: 2/12;
      &.container {
        grid-column: span 12;
        margin-bottom: 10px;
      }
    }
  }

  .quote {
    grid-column-start: 1;
    grid-column-end: 7;
    margin-bottom: 46px;
    font-weight: 400;
    @include line-heights(30px, 35px, 43px, 54px);
    @include font-sizes(22px, 24px, 32px, 45px);

    @include at-breakpoint(small) {
      grid-column-end: 7;
      margin-bottom: 24px;
      padding-inline-start: 10px;
    }

    @include at-breakpoint(medium) {
      grid-column-end: 4;
      margin-bottom: 40px;
      padding-inline-start: 20px;
    }

    @include at-breakpoint(large) {
      grid-column-end: 8;
      line-height: 1.5;
      margin-bottom: 0;
      padding-inline-start: 40px;
      width: 110%;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 1;
      grid-column-end: 9;
      padding-inline-start: 60px;
    }

    &::before {
      color: $summit-blue;
      @include font-sizes(70px, 70px, 90px, 90px);
      @include line-heights(68px, 68px, 82px, 82px);
      font-weight: 400;
      left: -40px;
      top: -2px;
      position: absolute;

      @include at-breakpoint(medium) {
        left: -49px;
      }

      @include at-breakpoint(large) {
        top: -5px;
      }

      @include at-breakpoint(x-large) {
        left: 0;
      }
    }
  }

  .author-container {
    grid-column-start: 1;
    grid-column-end: 7;
    position: relative;

    @include at-breakpoint(medium) {
      grid-column-start: 4;
      grid-column-end: 7;
      padding-inline-start: 53px;
    }

    @include at-breakpoint(large) {
      grid-column-start: 9;
      grid-column-end: 13;
      padding-inline-start: 0;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 10;
      grid-column-end: 13;
    }

    &::before {
      background-color: $summit-blue;
      content: '';
      display: block;
      height: 7px;
      position: absolute;
      width: 87px;

      @include at-breakpoint(small) {
        height: 87px;
        top: 2px;
        width: 7px;
      }

      @include at-breakpoint(medium) {
        height: 87px;
        top: 2px;
        width: 7px;
      }
    }
  }

  .author {
    @include at-breakpoint(small) {
      padding-top: 12px;
      margin-left: 25px;
    }

    @include at-breakpoint(medium) {
      padding-top: 10px;
      margin-left: 50px;
    }

    @include at-breakpoint(large) {
      padding-top: 10px;
      margin-left: 0px;
    }
  }

  .name {
    @include font-sizes(16px, 16px, 18px, 18px);
    font-family: 'Jost', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 24px;
  }

  .attribution {
    @include p3;
    @include bold;
  }
}
