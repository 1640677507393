
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 64px;

  :global(.gather) & {
    padding-top: 0;
  }

  @include at-breakpoint(medium) {
    margin-bottom: 116px;
  }

  @include at-breakpoint(large) {
    grid-column: 1/2;
    margin-bottom: 0;
  }

  :global(.now-fresh) & {
    border-bottom: 1px solid $now-brown;
    padding-top: 0;

    @include at-breakpoint(large) {
      border-right: 1px solid $now-brown;
      border-bottom: none;

      :global(.locale-he) & {
        border-right: none;
        border-left: 1px solid $now-brown;
      }
    }
  }

  :global(.slick-slide) {
    float: left;

    & > div {
      height: 100%;
    }
  }

  :global(.slick-track) {
    height: 100%;
  }

  :global(.summit) & {
    padding-top: 0;
  }
}

.primary-slide {
  width: 100%;
  position: relative;

  :global(.gather) & {
    height: 100%;
  }
}

.primary-img {
  position: relative;
  z-index: 1;
  width: 100%;
  opacity: 0;
  transition: opacity 400ms ease-in;
}

.img-loaded {
  opacity: 1;
}

.badge {
  box-shadow: 0 4px 4px $go-light-opaque-black;
  position: absolute;
  width: 88px;
  height: 88px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @include at-breakpoint(medium) {
    width: 104px;
    height: 104px;
  }

  &::before {
    content: '';
    background-color: transparent;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  :global(.now-fresh) & {
    background-color: $color-white;
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;

    :global(.locale-fr) & {
      width: 100px;
      height: 100px;
      p {
        font-size: 16px;
      }
    }
  }
}

.badge-border {
  content: '';
  background-color: transparent;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  :global(.go-solutions) & {
    border: 3px solid $go-orange;
  }

  :global(.now-fresh) & {
    border: 3px solid $now-grey;
  }
}

.badge-bag {
  bottom: 76%;
  right: 60%;

  @include at-breakpoint(medium) {
    bottom: 81%;
    right: 65%;
  }

  @include at-breakpoint(large) {
    bottom: 76%;
    right: 60%;
  }

  @include at-breakpoint(x-large) {
    bottom: 81%;
    right: 65%;
  }

  :global(.now-fresh) & {
    bottom: 73%;
    right: 60%;
  }
}

.badge-pouch,
.badge-large-tetrapack {
  bottom: 72%;
  right: 65%;

  @include at-breakpoint(medium) {
    bottom: 75%;
    right: 68%;
  }

  @include at-breakpoint(large) {
    bottom: 72%;
    right: 65%;
  }

  @include at-breakpoint(x-large) {
    bottom: 75%;
    right: 68%;
  }
}

.badge-small-tetrapack {
  bottom: 72%;
  right: 65%;

  @include at-breakpoint(medium) {
    bottom: 75%;
    right: 68%;
  }

  @include at-breakpoint(large) {
    bottom: 72%;
    right: 65%;
  }

  @include at-breakpoint(x-large) {
    bottom: 75%;
    right: 68%;
  }
}

.badge-large-can {
  bottom: 71%;
  right: 59%;

  @include at-breakpoint(medium) {
    bottom: 74%;
    right: 62%;
  }

  @include at-breakpoint(large) {
    bottom: 71%;
    right: 59%;
  }

  @include at-breakpoint(x-large) {
    bottom: 74%;
    right: 62%;
  }
}

.badge-small-can {
  bottom: 55%;
  right: 67%;

  @include at-breakpoint(medium) {
    bottom: 57%;
    right: 69%;
  }

  @include at-breakpoint(large) {
    bottom: 55%;
    right: 64%;
  }

  @include at-breakpoint(x-large) {
    bottom: 57%;
    right: 69%;
  }
}

.badge-treats {
  bottom: 73%;
  right: 65%;

  @include at-breakpoint(medium) {
    bottom: 75%;
    right: 68%;
  }

  @include at-breakpoint(large) {
    bottom: 73%;
    right: 65%;
  }

  @include at-breakpoint(x-large) {
    bottom: 75%;
    right: 66%;
  }
}

.badge-content {
  @include bold();
  letter-spacing: 1px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  width: 80%;

  :global(.go-solutions) & {
    font-size: 10px;
    line-height: 16px;
    @include bold();
  }

  :global(.now-fresh) & {
    font-family: Veneer, sans-serif;
    font-size: 15px;
    line-height: 17px;
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0;

    @each $locale, $font-stack in $nf-secondary-fonts {
      .locale-#{$locale} & {
        font-family: $font-stack;
      }
    }
  }
}

.gather-slide-wrapper {
  :global(.gather) & {
    width: 100%;
    height: 100%;

    @include at-breakpoint(medium) {
      display: flex;
    }

    @include at-breakpoint(x-large) {
      justify-content: center;
      align-items: center;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      picture {
        display: block;
        height: 90%;

        @include at-breakpoint(medium) {
          height: 100%;
        }

        @include at-breakpoint(large) {
          width: 90%;
        }

        @include at-breakpoint(x-large) {
          left: 0;
          width: 100%;
        }

        img {
          object-position: bottom center;
          object-fit: none;
          max-height: 100%;

          @include at-breakpoint(medium) {
            object-fit: cover;
            max-height: none;
          }

          @include at-breakpoint(large) {
            height: 100%;
            object-fit: cover;
            object-position: bottom right;
          }
        }
      }
    }

    .product {
      max-width: 100%;
      height: auto;
      flex-grow: 0;
      margin: auto auto 0;

      @include at-breakpoint(large) {
        margin: 50px 0 50px auto;
      }

      @include at-breakpoint(x-large) {
        flex-grow: 1;
        max-width: 50%;
        margin: 50px 0;
      }

      img {
        object-fit: contain;
        height: 100%;
        width: auto;
        margin: auto auto 0;

        @include at-breakpoint(medium) {
          margin: 0;
        }

        @include at-breakpoint(large) {
          max-height: 100%;
        }

        @include at-breakpoint(x-large) {
          width: 100%;
          max-height: none;
        }
      }
    }
  }
}

:global(.go-solutions) {
  .badge {
    background-color: $color-white;

    &::before {
      border: 3px solid $go-orange;
    }
  }
}

:global(.now-fresh) {
  .primary-img {
    position: relative;
    z-index: 1;
  }
}
