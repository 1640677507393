
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $cookie-banner-z-index;
}

.visible {
  display: block;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 32px 24px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.25);

  :global(.gather) & {
    background-color: $gather-white;
  }

  :global(.go-solutions) & {
    background-color: $go-charcoal;
  }

  :global(.now-fresh) & {
    box-shadow: none;
    border-radius: 0;
    outline: 1px solid $now-brown;
    background-color: $color-white;
  }

  :global(.petcurean) & {
    background-color: $petcurean-white;
  }

  :global(.summit) & {
    background-color: $summit-white;
  }

  @include at-breakpoint(large) {
    justify-content: left;
    align-items: center;
    padding: 32px 40px;
  }
}

.text {
  width: 100%;
  text-align: center;
  margin-bottom: 24px;

  @include at-breakpoint(large) {
    text-align: start;
    width: calc(100% - 180px);
    margin-bottom: 0;
  }

  p {
    @include p2;

    :global(.go-solutions) & {
      color: $color-white;
    }
  }

  a {
    text-decoration: underline;

    :global(.go-solutions) & {
      color: $color-white;

      &:hover {
        color: $go-yellow;
      }
    }
  }

  svg {
    display: none;
  }
}

.cta {
  :global(.go-solutions) & {
    color: $color-white;
  }

  @include at-breakpoint(large) {
    margin-inline-start: auto;
  }
}
