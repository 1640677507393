
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  text-align: start;
}

.minimal-layout {
  .wrapper {
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-inline-end: 40px;
    min-height: 0;

    :global(.gather) & {
      background: none;

      &:after {
        border-bottom: 2px solid $gather-white;
        border-left: 2px solid $gather-white;
      }
    }

    :global(.go-solutions) & {
      background: none;

      &:focus-within {
        box-shadow: 0px 0px 0px 1px $outline inset;
      }
    }

    :global(.now-fresh) & {
      background: none;

      &:focus-within {
        box-shadow: 0px 0px 0px 1px $outline inset;
      }
    }

    :global(.petcurean) & {
      background: none;

      &:after {
        border-bottom: 2px solid $petcurean-white;
        border-left: 2px solid $petcurean-white;
      }
    }

    :global(.summit) & {
      background: none;

      &:after {
        border-bottom: 2px solid $summit-white;
        border-left: 2px solid $summit-white;
      }
    }
  }

  .selected-option {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.33333;

    :global(.gather) & {
      @include p3();
      font-weight: 700;
    }

    :global(.go-solutions) & {
      font-weight: 700;
    }

    :global(.now-fresh) & {
      font-weight: 500;
    }

    :global(.petcurean) & {
      @include p3();
      font-weight: 600;
    }

    :global(.summit) & {
      @include p2();
    }
  }
}

.icon {
  margin-inline-end: 8px;

  :global(.gather) & {
    fill: $gather-green;
  }

  :global(.go-solutions) & {
    fill: $color-white;
  }

  :global(.now-fresh) & {
    fill: $now-brown;
  }

  :global(.petcurean) & {
    fill: $petcurean-orange;
  }

  :global(.summit) & {
    fill: $summit-blue;
  }
}

.error {
  .wrapper {
    box-shadow: 0px 0px 0px 2px $now-grey inset;
    background-color: $color-light-red;
    color: $color-dark-red;

    &:after {
      border-bottom-color: $color-dark-red;
      border-left-color: $color-dark-red;
    }

    :global(.go-solutions) & {
      background-color: $go-pink;
      color: $color-dark-red;

      &:after {
        border-bottom-color: $color-dark-red;
        border-left-color: $color-dark-red;
      }
    }

    :global(.now-fresh) & {
      background-color: $now-transparent-red;
      color: $now-red;
      border: $now-red;
      box-shadow: 0px 0px 0px 2px $now-red inset;

      &:after {
        border-bottom-color: $now-red;
        border-left-color: $now-red;
      }
    }
  }

  .error-msg {
    display: block;

    :global(.summit) & {
      @include errormsg();
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 0 16px;
  min-height: 56px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    transform: rotate(-45deg);
    margin-top: 2px;
    right: 16px;
    top: calc(50% - 8px);

    :global(.locale-he) & {
      right: auto;
      left: 16px;
    }
  }

  &:focus-within {
    box-shadow: 0px 0px 0px 2px $outline inset;
  }

  :global(.gather) & {
    background-color: rgba($gather-black, 0.05);

    &:after {
      border-bottom: 2px solid $gather-green;
      border-left: 2px solid $gather-green;
    }
  }

  :global(.go-solutions) & {
    background-color: rgba($go-light-grey, 0.25);

    &:focus-within {
      box-shadow: 0px 0px 0px 2px $color-white inset;
    }

    &:after {
      border-bottom: 2px solid $color-white;
      border-left: 2px solid $color-white;
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    border-radius: 0;
    border: 1px solid $now-brown;
    background-color: $color-white;

    &:focus-within {
      box-shadow: 0px 0px 0px 2px $now-brown inset;
    }

    &:after {
      border-bottom: 2px solid $now-brown;
      border-left: 2px solid $now-brown;
    }
  }

  :global(.petcurean) & {
    background-color: rgba($petcurean-grey, 0.05);

    &:after {
      border-bottom: 2px solid $petcurean-orange;
      border-left: 2px solid $petcurean-orange;
    }
  }

  :global(.summit) & {
    background-color: rgba($summit-black, 0.05);

    &:after {
      border-bottom: 2px solid $summit-blue;
      border-left: 2px solid $summit-blue;
    }
  }
}

.label {
  @include p2();

  :global(.selected) & {
    @include p3();
    @include bold();
    display: block;
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }
}

.selected-option {
  @include p2();
}

.select {
  background-color: transparent;
  opacity: 0;
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  appearance: none;

  :global(.locale-he) & {
    left: auto;
    right: 0;
  }
}

.error-msg {
  @include errormsg;
}

.sort-btn {
  margin-inline-start: auto;

  .wrapper {
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-inline-end: 40px;
    min-height: 0;
    height: 100%;
    border-radius: 0;

    @include at-breakpoint(large) {
      padding-top: 8px;
      padding-inlin-end: 40px;
      padding-bottom: 8px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .label {
      font-weight: 500;
      padding-inline-end: 10px;

      :global(.summit) & {
        font-weight: 700;
        text-transform: uppercase;

        @include at-breakpoint(large) {
          font-weight: 400;
          text-transform: none;
        }
      }
    }

    :global(.go-solutions) & {
      background-color: rgba($go-light-grey, 0.25);

      @include at-breakpoint(large) {
        background: none;
      }

      &:focus-within {
        box-shadow: 0px 0px 0px 1px $outline inset;
      }

      &:after {
        border-bottom: 2px solid $go-charcoal;
        border-left: 2px solid $go-charcoal;
      }
    }

    :global(.now-fresh) & {
      background-color: $now-light-tan;

      @include at-breakpoint(large) {
        color: $now-brown;
        background: none;
      }

      &:focus-within {
        box-shadow: 0px 0px 0px 1px $outline inset;
      }

      &:after {
        @include at-breakpoint(large) {
          border-bottom: 2px solid $now-brown;
          border-left: 2px solid $now-brown;
        }
      }
    }

    :global(.summit) & {
      &:after {
        border-bottom: 2px solid $summit-white;
        border-left: 2px solid $summit-white;
        @include at-breakpoint(large) {
          border-bottom: 2px solid $summit-black;
          border-left: 2px solid $summit-black;
        }
      }
    }
  }

  .selected-option {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.33333;

    :global(.gather) &,
    :global(.summit) & {
      font-weight: 700;
    }

    :global(.go-solutions) & {
      font-weight: 700;
    }

    :global(.now-fresh) & {
      font-weight: 500;
    }
  }
}
