
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();

  grid-column: span 6;

  @include at-breakpoint(large) {
    // we repeat the same class for specificity
    // to override the parent blog post grid-column: 2/12;
    &.container {
      grid-column: span 12;
    }
  }
}

.img-container {
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  @include at-breakpoint(x-large) {
    grid-column-start: 3;
    grid-column-end: 11;
  }
}

.image,
.image > img {
  width: 100%;

  :global(.now-fresh) & {
    display: block;
  }

  :global(.petcurean) & {
    height: 100%;
  }
}

.caption {
  color: $color-white;
  font-size: 14px;
  grid-column: span 6;
  line-height: 1.5;
  z-index: 1;
}

:global(.gather) {
  .container {
    padding: 0;
  }

  .caption {
    @include at-breakpoint(small) {
      transform: translatey(-29px);
    }

    @include at-breakpoint(medium) {
      grid-column: span 5;
      transform: translatey(-50px);
    }

    @include at-breakpoint(large) {
      grid-column-start: 2;
      grid-column-end: 10;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 3;
      grid-column-end: 10;
    }
  }

  :global(.go-solutions) {
    .container {
      padding: 0;
    }

    .caption {
      background-color: $color-black;
      font-weight: 900;
      margin-inline-end: 19px;
      margin-top: -16px;
      padding: 22px 17px 22px 19px;
      text-transform: uppercase;

      @include at-breakpoint(medium) {
        grid-column: span 5;
        margin-inline-end: 64px;
        margin-top: -23px;
        padding: 27px 64px 27px 38px;
      }

      @include at-breakpoint(large) {
        grid-column-start: 2;
        grid-column-end: 10;
        margin-inline-end: 8px;
        margin-top: -30px;
        padding: 25px 74px 24px 33px;
      }

      @include at-breakpoint(x-large) {
        grid-column-start: 3;
        grid-column-end: 9;
        margin-inline-end: 0;
        margin-top: -38px;
        padding: 29px 52px 25px 49px;
      }
    }
  }

  .caption-content {
    font-size: 18px;
    line-height: 133%;
    background-color: $gather-green;
    color: $gather-white;
    font-weight: 00;
    padding: 22px 16px 26px 16px;
    text-transform: capitalize;

    @include at-breakpoint(medium) {
      padding: 35px 16px 35px 32px;
    }

    @include at-breakpoint(large) {
      padding: 36px 80px 36px 39px;
    }

    @include at-breakpoint(x-large) {
      padding: 39px 224px 39px 39px;
    }
  }
}

:global(.now-fresh) {
  .container {
    padding: 0;
  }

  .caption {
    font-weight: 400;
    letter-spacing: 1px;
    margin: -18px 0 0;
    border: 1px solid $now-brown;

    @include at-breakpoint(medium) {
      margin: -50px 0 0;
      grid-column-start: 1;
      grid-column-end: 6;
    }

    @include at-breakpoint(large) {
      grid-column-start: 2;
      grid-column-end: 11;
      line-height: 27px;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 3;
      grid-column-end: 10;
    }
  }

  .caption-content {
    background-color: $color-white;
    color: $now-brown;
    font-size: 16px;
    font-family: 'Stag', sans-serif;
    padding: 24px 14px 27px 34px;

    @include at-breakpoint(medium) {
      padding: 35px 49px 35px 40px;
    }

    @include at-breakpoint(large) {
      padding: 28px 46px 28px 31px;
    }

    @include at-breakpoint(x-large) {
      padding: 29px 36px 29px 29px;
    }
  }

  .img-container {
    border: 1px solid $now-brown;
  }
}

:global(.petcurean) {
  .container {
    padding: 0;
  }

  .caption {
    @include at-breakpoint(medium) {
      grid-column: span 5;
      transform: translatey(-45px);
    }

    @include at-breakpoint(large) {
      grid-column-start: 2;
      grid-column-end: 10;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 3;
      grid-column-end: 10;
    }
  }

  .caption-content {
    font-size: 22px;
    @include line-heights(1.47, 1, 1, 1);
    background-color: $petcurean-orange;
    color: $petcurean-white;
    font-weight: 600;
    padding: 22px 16px 26px 16px;

    @include at-breakpoint(medium) {
      padding: 35px 16px 35px 32px;
    }

    @include at-breakpoint(large) {
      padding: 36px 80px 36px 39px;
    }

    @include at-breakpoint(x-large) {
      padding: 39px 224px 39px 39px;
    }
  }
}

:global(.summit) {
  .container {
    padding: 0;
  }

  .caption {
    @include at-breakpoint(small) {
      transform: translatey(-29px);
    }
    @include at-breakpoint(medium) {
      grid-column: span 5;
      transform: translatey(-50px);
    }

    @include at-breakpoint(large) {
      grid-column-start: 2;
      grid-column-end: 10;
    }

    @include at-breakpoint(x-large) {
      grid-column-start: 3;
      grid-column-end: 10;
    }
  }

  .caption-content {
    font-size: 16px;
    line-height: 131%;
    font-family: 'Jost', sans-serif;
    background-color: $summit-blue;
    color: $summit-white;
    font-weight: 500;
    padding: 22px 16px 26px 16px;
    text-transform: uppercase;
    letter-spacing: 2px;

    @include at-breakpoint(medium) {
      padding: 35px 16px 35px 32px;
    }

    @include at-breakpoint(large) {
      padding: 36px 80px 36px 39px;
    }

    @include at-breakpoint(x-large) {
      padding: 39px 224px 39px 39px;
    }
  }
}
