/* custom colors / overrides below: */
$grey: #f2f2f2;
$color-black: #000;
$color-black-85: rgba(0, 0, 0, 0.85);
$color-black-75: rgba(0, 0, 0, 0.75);
$color-black-60: rgba(0, 0, 0, 0.6);
$color-black-10: rgba(0, 0, 0, 0.1);
$color-black-vignette: linear-gradient(90deg, $color-black-75, $color-black-10);
$color-white: #fff;
$color-white-light: rgba(255, 255, 255, 0.8);
$color-white-lighter: rgba(255, 255, 255, 0.3);
$color-dark-red: #c80037;
$color-light-red: #ffccda;
$outline: #51a7e8;
$color-light-grey: #e9e9e9;

/* Go Solutions */
$go-gray: #c4c4c4;
$go-gray-midlight: #cccccc;
$go-gray-light: #cecece;
$go-gray-lighter: #ededed;
$go-dark-gray: #2e2e2e;
$go-yellow: #fec404;
$go-light-yellow: #ffdc69;
$go-orange: #dc6e0f;
$go-teal: #46c3b4;
$go-charcoal: #181818;
$go-light-charcoal: #333333;
$go-table: #222222;
$go-semidark-grey: #666666;
$go-bullet-separator: #8b8b8b;
$go-dark-grey: #7c7c7c;
$go-light-grey: #b8b8b8;
$go-filter-border: #cfcfcf;
$go-light-grey-opaque: rgba(184, 184, 184, 0.25);
$go-light-grey-opaquer: rgba(184, 184, 184, 0.1);
$go-opaque-black: rgba(0, 0, 0, 0.05);
$go-light-opaque-black: rgba(0, 0, 0, 0.2);
$go-pink: #f5dce0;

/* Now Fresh */
$now-tan: #fff6f0;
$now-dark-tan: #c4bb8b;
$now-light-tan: #e9dfb6;
$now-brown: #3d3935;
$now-grey: #a8b6cc;
$now-red: #a84100;
$now-transparent-red: rgba($now-red, 0.1);

/* Petcurean */
$petcurean-grey-light: #efeeed;
$petcurean-grey: #5a554b;
$petcurean-transparent-grey: rgb($petcurean-grey, 0.5);
$petcurean-white: #fbfbfb;
$petcurean-slightly-dark-orange: #b3500f;
$petcurean-dark-orange: #8c3e0a;
$petcurean-orange: #c25710;
$petcurean-light-orange: #c87341;
$petcurean-opaque-orange: rgb(194, 87, 16, 0.2);
$petcurean-dark-tan: #e19b5f;
$petcurean-tan: #ebbe96;
$petcurean-light-tan: #f5e1c8;
$petcurean-transparent-light-tan: rgb($petcurean-light-tan, 0.5);
$petcurean-active-nav-bg: #ad4e0e;
$pc-light-gradient: linear-gradient(90deg, $petcurean-white 50%, $petcurean-light-tan 50%);
$pc-dark-gradient: linear-gradient(90deg, $petcurean-orange 50%, $petcurean-tan 50%);

/* Gather */
$gather-dark-green: #3e4a13;
$gather-green: #697d20;
$gather-lighter-green: #acc35c;
$gather-sapling-green: #c1d285;
$gather-light-sapling-green: #d5e1ad;
$gather-lighter-sapling-green: #eaf0d6;
$gather-opaque-green: rgb(105, 125, 32, 0.2);
$gather-black: #131313;
$gather-cream: #fffced;
$gather-light-cream: #fffcf7;
$gather-dark-cream: #f3f0e2;
$gather-white: #fbfbfb;
$gather-free-acres-orange: #f09600;
$gather-light-gradient: linear-gradient(90deg, $gather-white 50%, $gather-light-sapling-green 50%);
$gather-dark-gradient: linear-gradient(90deg, $gather-green 50%, $gather-light-sapling-green 50%);
$gather-light-grey: #e5e5e5;
$gather-dark-grey: #828282;
$gather-grey: #d9d9d9;

/* Summit */
$summit-dark-blue: #405e7b;
$summit-blue: #5e8ab4;
$summit-light-blue: #84aace;
$summit-dark-desaturated-blue: #a8c3dd;
$summit-desaturated-blue: #c3daf0;
$summit-light-desaturated-blue: #e7ecf0;
$summit-opaque-blue: rgb($summit-blue, 0.2);
$summit-light-opaque-blue: rgb(#ccd5e7, 0.25);
$summit-gold: #b6a269; // may remove later, will mess up deploy if removed now
$summit-black: #4d4d4f;
$summit-white: #f7efe6;
$summit-light-gradient: linear-gradient(90deg, $summit-white 50%, $summit-desaturated-blue 50%);
$summit-dark-gradient: linear-gradient(90deg, $summit-blue 50%, $summit-desaturated-blue 50%);
$summit-grey: #d9d9d9;
