
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.background {
  :global(.gather) & {
    background-color: $gather-cream;
    color: $gather-black;
  }
  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.summit) & {
    background-color: $summit-white;
    color: $summit-black;
  }

  @include default-padding-bottom();
}

.container {
  position: relative;
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
  padding-bottom: 48px;

  @include at-breakpoint(large) {
    padding-bottom: 56px;
  }

  @include at-breakpoint(large) {
    padding-bottom: 0;
  }

  &.grid-view {
    .product-wrapper {
      @include at-breakpoint(large) {
        margin-top: 64px;
      }

      @include at-breakpoint(x-large) {
        margin-top: 96px;
      }

      &:nth-child(-n + 3) {
        margin-top: 0;
      }
    }

    .prod-cta {
      padding-top: 32px;

      @include at-breakpoint(large) {
        padding-top: 40px;
      }

      :global(.go-solutions) & {
        border-top: 1px solid $go-semidark-grey;
      }

      :global(.now-fresh) & {
        border-top: 1px solid $now-light-tan;
      }

      :global(.summit) & {
        padding-top: 0;
      }
    }
  }

  :global(.go-solutions) & {
    @include at-breakpoint(large) {
      padding-inline-start: 44px;
      padding-inline-end: 44px;
    }

    a {
      color: $color-white;
    }
  }

  :global(.now-fresh) & {
    @include at-breakpoint(large) {
      padding-inline-start: 44px;
      padding-inline-end: 44px;
    }
  }
}

.product-wrapper {
  :global(.go-solutions) & {
    &:hover {
      color: $color-white;

      svg path {
        fill: $go-yellow;
      }
    }
  }

  :global(.now-fresh) & {
    &:hover {
      color: $now-brown;

      svg path {
        fill: $now-brown;
      }
    }
  }

  & > span {
    display: block;
    height: 100%;
  }
}

.eyebrow {
  @include eyebrow;
  text-align: center;

  :global(.go-solutions) & {
    margin-bottom: 8px;
  }

  :global(.now-fresh) & {
    margin-bottom: 16px;
  }

  :global(.summit) & {
    margin-bottom: 5px;
  }
}

.title {
  text-align: center;
  margin-bottom: 24px;
  padding: 0 32px;

  @include at-breakpoint(medium) {
    margin-bottom: 48px;
    padding: 0 100px;
  }

  @include at-breakpoint(large) {
    padding: 0;
  }

  :global(.gather) & {
    @include h2();
    margin-bottom: 0;
  }

  :global(.go-solutions) & {
    @include at-breakpoint(medium) {
      font-size: 45px;
      line-height: 1.06667;
    }
  }

  :global(.now-fresh) & {
    text-transform: uppercase;
    @include at-breakpoint(medium) {
      font-size: 45px;
      line-height: 1.06667;
    }

    @include at-breakpoint(large) {
      font-size: 66px;
      line-height: 0.9697;
    }
  }

  :global(.petcurean) & {
    @include h2();
    margin-bottom: 0;
    color: $petcurean-orange;
  }

  :global(.summit) & {
    @include h2();
    color: $summit-blue;
  }
}

.description {
  @include p2();
  margin: -8px 16px 24px 16px;
  text-align: center;

  @include at-breakpoint(medium) {
    width: 66.6667%;
    margin: -32px auto 48px auto;
  }

  @include at-breakpoint(x-large) {
    width: 50%;
  }

  :global(.go-solutions) & {
    @include at-breakpoint(large) {
      margin-top: -24px;
    }
  }
}

.slide {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;

  :global(.gather) & {
    color: $gather-black;
  }

  :global(.petcurean) & {
    color: $petcurean-grey;
  }

  :global(.slick-slide.slick-center) & {
    .prod-img {
      transform: scale(1.2);
    }

    :not(:first-child) {
      opacity: 1;
    }
  }

  :global(.slick-slide:not(.slick-center)) & {
    :not(:first-child):not(img) {
      opacity: 0;
    }
  }
}

.img-container {
  width: 100%;
  padding-top: 120%;
  position: relative;
  opacity: 0;
  transition: opacity 400ms ease-in;

  @include at-breakpoint(medium) {
    padding-top: 90%;
  }

  @include at-breakpoint(large) {
    padding-top: 110%;
  }

  @include at-breakpoint(x-large) {
    padding-top: 90%;
  }
}

.anim-in {
  opacity: 1;
}

.item-without-rating {
  margin-bottom: 40px;

  @include at-breakpoint(large) {
    margin-bottom: 20px;
  }
}

.prod-img {
  transition:
    transform 0.5s,
    opacity 0.5s;
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline-start: auto;
  margin-inline-end: auto;

  @include at-breakpoint(medium) {
    width: 60%;
  }

  @include at-breakpoint(large) {
    width: 80%;
  }

  @include at-breakpoint(x-large) {
    width: 60%;
  }
}

.prod-rating {
  margin: 40px 0 24px;
  transition:
    transform 0.5s,
    opacity 0.5s;
  display: flex;
  align-items: center;

  :global(.summit) & {
    margin: 24px 0 20px;
  }
}

.rating {
  margin: 0 auto;
  height: 12px;

  span {
    width: 12px;
    margin: 0 4px;
  }

  @include at-breakpoint(medium) {
    height: 20px;

    span {
      width: 20px;
      margin: 0 7px;
    }
  }

  @include at-breakpoint(large) {
    height: 12px;

    span {
      width: 12px;
      margin: 0 4px;
    }
  }

  :global(.go-solutions) & {
    svg {
      path {
        fill: $go-yellow;
      }
    }
  }

  :global(.now-fresh) & {
    svg {
      path {
        fill: $now-brown;
      }
    }
  }
}

.prod-eyebrow {
  transition:
    transform 0.5s,
    opacity 0.5s;
  width: 160%;
  margin-inline-start: -30%;

  @include at-breakpoint(large) {
    width: 100%;
    margin-inline-start: 0;
  }

  :global(.gather) & {
    margin-bottom: 8px;
  }

  :global(.go-solutions) & {
    margin-bottom: 8px;

    @include at-breakpoint(large) {
      margin-bottom: 20px;
    }
  }

  :global(.now-fresh) & {
    margin-bottom: 12px;

    @include at-breakpoint(large) {
      margin-bottom: 16px;
    }
  }

  :global(.summit) & {
    color: $summit-black;
    margin-bottom: 8px;
  }

  :global(.petcurean) & {
    color: $petcurean-orange;
    margin-bottom: 8px;
  }

  p {
    @include eyebrow;
  }
}

.prod-title {
  transition:
    transform 0.5s,
    opacity 0.5s;
  margin: 0 -45px 24px -45px;
  text-transform: uppercase;

  :global(.gather) &,
  :global(.petcurean) & {
    text-transform: capitalize;
  }

  @include at-breakpoint(medium) {
    width: 100%;
    padding: 0 5%;
    margin: 0 0 24px 0;
  }

  @include at-breakpoint(large) {
    margin-bottom: 32px;
  }

  h3 {
    @include h5();

    :global(.locale-ru) & {
      line-height: 1.25;
    }

    :global(.summit) & {
      font-size: 28px;
      line-height: 1.14;
    }
  }

  :global(.summit) & {
    margin-bottom: 20px;
  }
}

.prod-packaging {
  @include p3();
  margin: -16px 0 24px 0;

  @include at-breakpoint(large) {
    margin: -24px 0 32px 0;
  }

  :global(.go-solutions) & {
    color: $go-gray;
  }
}

.prod-btn {
  transition:
    transform 0.5s,
    opacity 0.5s;
  padding-bottom: 4px;

  @include at-breakpoint(large) {
    margin-top: auto;
  }

  :global(.gather) & {
    text-transform: capitalize;
  }

  :global(.go-solutions) & {
    a,
    button {
      color: $color-white;
    }
  }

  :global(.petcurean) & {
    text-transform: capitalize;
  }

  a {
    display: inline-block;
  }

  :global(.now-fresh) & {
    p {
      @include bold();
    }
  }
}

.styled-link {
  :global(.go-solutions) & {
    text-transform: none;
  }
}

.cta-btn {
  margin: 0 auto;
}

.prod-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 16px -48px 16px;

  :global(.summit) & {
    margin-top: 40px;
  }

  @include at-breakpoint(medium) {
    margin: 120px 32px -56px 32px;

    :global(.summit) & {
      margin-top: 56px;
    }
  }

  @include at-breakpoint(large) {
    margin: 57px 0 0 0;

    :global(.summit) & {
      margin-top: 72px;
    }
  }

  @include at-breakpoint(x-large) {
    margin-top: 64px;

    :global(.summit) & {
      margin-top: 72px;
    }
  }
}

.cta-description {
  @include p1();
  margin-bottom: 24px;
}
