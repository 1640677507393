
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
// Media (image or video) in the header should have a 16:9 aspect ratio.
// 9 / 16 = 0.5625
$ratio: 56.25%;

.blog-post {
  background-color: $color-white;

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
  }
}

.header {
  :global(.gather) & {
    color: $gather-white;
  }

  :global(.go-solutions) & {
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.petcurean) & {
    color: $petcurean-white;
  }

  :global(.summit) & {
    color: $summit-white;
  }
}

.background {
  @include grid-container;
  padding-top: 58px;

  @include at-breakpoint(medium) {
    padding-top: 63px;
  }

  @include at-breakpoint(large) {
    padding-top: 80px;
  }

  :global(.gather) & {
    background-color: $gather-green;
  }

  :global(.go-solutions) & {
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    padding-top: 32px;
    padding-bottom: 32px;

    @include at-breakpoint(medium) {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    @include at-breakpoint(large) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;

    @include at-breakpoint(small) {
      padding-top: 52px;
    }

    @include at-breakpoint(medium) {
      padding-top: 72px;
    }

    @include at-breakpoint(large) {
      padding-top: 88px;
    }

    @include at-breakpoint(x-large) {
      padding-top: 100 px;
    }
  }

  :global(.summit) & {
    background-color: $summit-blue;
  }
}

.wrapper {
  grid-column: span 6;

  @include at-breakpoint(large) {
    grid-column: 2/12;
  }

  @include at-breakpoint(x-large) {
    grid-column: 3/11;
  }
}

.date {
  @include p3;

  :global(.gather) & {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
  }

  :global(.now-fresh) & {
    font-size: 16px;
    font-family: 'Stag', sans-serif !important;
    font-weight: 400;
  }

  :global(.petcurean) & {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;

    :global(.locale-zh) & {
      @include font-sizes(21px, 21px, 21px, 21px);
      @include line-heights(1, 1, 1, 1);
      font-weight: 500;
    }
  }

  :global(.summit) & {
    font-family: 'museo-sans-rounded', sans-serif !important;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    font-weight: 600;
  }
}

.title {
  @include h2;
  margin: 24px 0;

  @include at-breakpoint(medium) {
    @include h2;
    margin-top: 32px;
  }

  @include at-breakpoint(large) {
    @include h2;
    margin-top: 24px;
  }

  :global(.gather) & {
    @include font-sizes(28px, 45px, 45px, 45px);
    @include line-heights(132%, 113%, 113%, 113%);
    font-weight: 400;
    text-transform: capitalize;

    @include at-breakpoint(small) {
      margin-top: 8px;
    }

    @include at-breakpoint(medium) {
      margin-top: 16px;
    }

    @include at-breakpoint(large) {
      margin-top: 24px;
    }
  }

  :global(.go-solutions) & {
    margin: 34px 0;
  }

  :global(.petcurean) & {
    @include font-sizes(35px, 67px, 67px, 67px);
    @include line-heights(114%, 101%, 101%, 101%);
    font-weight: 900;
    @include at-breakpoint(small) {
      margin-top: 8px;
    }

    @include at-breakpoint(medium) {
      margin-top: 16px;
    }

    @include at-breakpoint(large) {
      margin-top: 24px;
    }

    :global(.locale-zh) & {
      @include line-heights(1.2, 1, 1, 1);
      @include font-sizes(38px, 52px, 52px, 52px);
      font-weight: 900;

      @include at-breakpoint(small) {
        margin-bottom: 40px;
        margin-top: 31px;
      }

      @include at-breakpoint(medium) {
        margin-bottom: 80px;
        margin-top: 30px;
      }

      @include at-breakpoint(x-large) {
        margin-top: 33px;
      }
    }
  }

  :global(.summit) & {
    @include font-sizes(28px, 45px, 45px, 45px);
    @include line-heights(114%, 107%, 107%, 107%);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;

    @include at-breakpoint(small) {
      margin-top: 8px;
    }

    @include at-breakpoint(medium) {
      margin-top: 16px;
    }

    @include at-breakpoint(large) {
      margin-top: 24px;
    }
  }
}

.tags {
  margin-bottom: 32px;

  @include at-breakpoint(medium) {
    margin-bottom: 32px;
  }

  @include at-breakpoint(large) {
    margin-bottom: 32px;
  }
}

.tag {
  @include p3();
  display: inline-block;
  padding: 4px 8px;
  margin: 0 8px 8px 0;

  @include at-breakpoint(large) {
    margin: 0 16px 16px 0;
  }

  :global(.gather) & {
    background-color: $gather-dark-green;
    color: $gather-white;
    text-transform: capitalize;
    font-weight: 300;
    line-height: 21px;
  }

  :global(.go-solutions) & {
    background-color: $go-charcoal;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    border: 1px solid $now-brown;
    border-radius: 20px;
    font-size: 16px;
    font-family: 'Stag', sans-serif !important;
    font-weight: 400;
  }

  :global(.summit) & {
    background-color: $summit-light-blue;
    text-transform: capitalize;
    font-family: 'museo-sans-rounded', sans-serif !important;
    font-weight: 300;
    line-height: 21px;
  }

  :global(.petcurean) & {
    background-color: $petcurean-light-tan;
    color: #000000;
    font-weight: 300;
    line-height: 21px;
    text-transform: capitalize;
  }
}

.media {
  position: relative;
  padding-top: calc(#{$ratio} / 2);

  & > picture,
  & > .video {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100% * 2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  :global(.now-fresh) & {
    border: 1px solid $now-brown;
    padding-top: 0;
    & > picture,
    & > .video {
      width: 100%;
      position: static;

      img {
        display: block;
      }
    }
  }
}

.filler {
  @include grid-container;

  & > div {
    padding-top: calc((#{$ratio} / 2) + 47px);
    grid-column: span 6;

    @include at-breakpoint(large) {
      padding-top: calc((#{$ratio} / 2) + 67px);
      grid-column: 2/12;
    }

    @include at-breakpoint(x-large) {
      grid-column: 3/11;
    }
  }

  :global(.now-fresh) & {
    display: none;
  }
}

.body {
  grid-column: span 12;
  @include grid-container();

  & > * {
    grid-column: span 6;
  }

  @include at-breakpoint(large) {
    & > * {
      grid-column: 2/12;
    }
  }

  @include at-breakpoint(x-large) {
    & > * {
      grid-column: 3/11;
    }
  }

  a {
    text-decoration: underline;

    :global(.go-solutions) & {
      color: $color-black;
    }

    :global(.now-fresh) & {
      color: $now-brown;
    }

    svg {
      display: none;
    }
  }

  :global(.now-fresh) & {
    p,
    a {
      font-size: 18px;
    }
    background-color: $now-tan;
    padding-bottom: 32px;

    @include at-breakpoint(medium) {
      padding-bottom: 48px;
    }

    @include at-breakpoint(large) {
      padding-bottom: 64px;
    }
  }
}

.footnotes {
  @include grid-container();
  flex-direction: column;
  margin: 45px 0;

  p {
    :global(.go-solutions) & {
      @include p3();
      line-height: 23px;
      color: $go-semidark-grey;
    }

    :global(.now-fresh) & {
      @include p2();
      line-height: 26px;
      color: $go-semidark-grey;
    }
  }

  a {
    word-break: break-all;
  }

  & > * {
    grid-column: span 6;

    @include at-breakpoint(large) {
      grid-column: 2/12;
    }

    @include at-breakpoint(x-large) {
      grid-column: 3/11;
    }

    svg {
      display: none;
    }

    a {
      text-decoration: underline;
      &:hover {
        :global(.go-solutions) & {
          color: $color-black;
        }

        :global(.now-fresh) & {
          color: $now-brown;
        }
      }
    }
  }

  :global(.go-solutions) & {
    @include p3();
    line-height: 23px;
    font-weight: 500;
    color: $go-semidark-grey;
  }

  :global(.now-fresh) & {
    @include p2();
    line-height: 26px;
    font-weight: 300;
    margin: 0;
    color: $now-brown;
    background-color: $now-tan;
  }
}

.related {
  padding-top: 80px;

  :global(.go-solutions) & {
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    padding-top: 0;
  }

  :global(.petcurean) & {
    padding-top: 0;
    margin-top: 80px;
  }
}

.authors-margin {
  margin-top: 88px;
}
