
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  background-color: $color-white;
  outline: 1px solid $now-brown;
  margin-bottom: 1px;
  overflow: hidden;
}

.content {
  @include flex-container();
  justify-content: center;
  padding: 56px 0;
  @include at-breakpoint(large) {
    padding: 80px;
  }
}

.copy {
  color: $now-brown;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.headline {
  @include h2();

  @include at-breakpoint(large) {
    @include h3();
  }
  text-align: center;
  margin-bottom: 24px;
}

.description {
  @include p1();
  text-align: center;
}

.benefits {
  composes: benefits from 'benefits.module.scss';
  padding: 0;
  position: relative;
  margin-top: 27px;
  justify-content: flex-start;

  @include at-breakpoint(large) {
    margin-top: 88px;
  }
}

.benefit {
  composes: benefit from 'benefits.module.scss';
  border: none;
  padding: 12px 0;

  @include at-breakpoint(medium) {
    padding: 8px 0;
  }

  @include at-breakpoint(large) {
    padding: 0;
  }

  :global(.locale-zh) & {
    padding: 12px 4px;
  }
}

.benefit-image {
  composes: benefit-image from 'benefits.module.scss';

  svg {
    width: 68px;
    height: 68px;

    @include at-breakpoint(medium) {
      width: 96px;
      height: 96px;
    }

    @include at-breakpoint(large) {
      width: 76px;
      height: 76px;
    }

    path {
      fill: $now-brown;
    }

    polygon {
      fill: $now-brown;
    }
  }
}

.benefit-description {
  composes: benefit-description from 'benefits.module.scss';
  margin-bottom: 25px;
  @include p2();

  @include at-breakpoint(medium) {
    max-width: 169px;
  }

  @include at-breakpoint(large) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
