//when the mixin is used in the format
// :global(.now-fresh) {
//  .some-selector {
//    @include p2()
//  }
// }
//the styles won't be applied
// the result selector would be :global(.now-fresh) :global(.now-fresh) .some-selector{}
// to fix this issue, the unified selector needs to be created
// for more info Check:
//https://sass-lang.com/documentation/at-rules/at-root#:~:text=The%20%40at%2Droot%20rule%20is,parent%20selector%20and%20selector%20functions.
@mixin global-unified-gather-selector($global: false) {
  @if $global {
    .gather & {
      @content;
    }
  } @else {
    $selector: append-global(&, ':global(.gather)');
    @at-root #{$selector} {
      @content;
    }
  }
}

@mixin global-unified-go-selector($global: false) {
  @if $global {
    .go-solutions & {
      @content;
    }
  } @else {
    $unified-selector: selector-unify(&, selector-nest(':global(.go-solutions)', &));

    @at-root #{$unified-selector} {
      @content;
    }
  }
}

@mixin global-unified-nf-selector($global: false) {
  @if $global {
    .now-fresh & {
      @content;
    }
  } @else {
    $unified-selector: selector-unify(&, selector-nest(':global(.now-fresh)', &));

    @at-root #{$unified-selector} {
      @content;
    }
  }
}

@mixin global-unified-pc-selector($global: false) {
  @if $global {
    .petcurean & {
      @content;
    }
  } @else {
    $selector: append-global(&, ':global(.petcurean)');
    @at-root #{$selector} {
      @content;
    }
  }
}

@mixin global-unified-summit-selector($global: false) {
  @if $global {
    .summit & {
      @content;
    }
  } @else {
    $selector: append-global(&, ':global(.summit)');
    @at-root #{$selector} {
      @content;
    }
  }
}

@mixin gather-font-i18n($secondary: false, $global: false) {
  @if ($global) {
    @if $secondary {
      @each $locale, $font-stack in $gather-secondary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $gather-primary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    }
  } @else {
    @if $secondary {
      @each $locale, $font-stack in $gather-secondary-fonts {
        @at-root #{selector-replace(&, ':global(.gather)', ':global(.locale-#{$locale} .gather)')} {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $gather-primary-fonts {
        @at-root #{selector-replace(&, ':global(.gather)', ':global(.locale-#{$locale} .gather)')} {
          font-family: $font-stack;
        }
      }
    }
  }
}

@mixin go-font-i18n($secondary: false, $global: false) {
  @if ($global) {
    @if $secondary {
      @each $locale, $font-stack in $go-secondary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $go-primary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    }
  } @else {
    @if $secondary {
      @each $locale, $font-stack in $go-secondary-fonts {
        @at-root #{selector-replace(&, ':global(.go-solutions)', ':global(.locale-#{$locale} .go-solutions)')} {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $go-primary-fonts {
        @at-root #{selector-replace(&, ':global(.go-solutions)', ':global(.locale-#{$locale} .go-solutions)')} {
          font-family: $font-stack;
        }
      }
    }
  }
}

@mixin nf-font-i18n($secondary: false, $global: false) {
  @if ($global) {
    @if $secondary {
      @each $locale, $font-stack in $nf-secondary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $nf-primary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    }
  } @else {
    @if $secondary {
      @each $locale, $font-stack in $nf-secondary-fonts {
        @at-root #{selector-replace(&, ':global(.now-fresh)', ':global(.locale-#{$locale} .now-fresh)')} {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $nf-primary-fonts {
        @at-root #{selector-replace(&, ':global(.now-fresh)', ':global(.locale-#{$locale} .now-fresh)')} {
          font-family: $font-stack;
        }
      }
    }
  }
}

@mixin pc-font-i18n($secondary: false, $global: false) {
  @if ($global) {
    @if $secondary {
      @each $locale, $font-stack in $pc-secondary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $pc-primary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    }
  } @else {
    @if $secondary {
      @each $locale, $font-stack in $pc-secondary-fonts {
        @at-root #{selector-replace(&, ':global(.petcurean)', ':global(.locale-#{$locale} .petcurean)')} {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $pc-primary-fonts {
        @at-root #{selector-replace(&, ':global(.petcurean)', ':global(.locale-#{$locale} .petcurean)')} {
          font-family: $font-stack;
        }
      }
    }
  }
}

@mixin summit-font-i18n($secondary: false, $tertiary: false, $global: false) {
  @if ($global) {
    @if $secondary {
      @each $locale, $font-stack in $summit-secondary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    } @else if $tertiary {
      @each $locale, $font-stack in $summit-tertiary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $summit-primary-fonts {
        .locale-#{$locale} & {
          font-family: $font-stack;
        }
      }
    }
  } @else {
    @if $secondary {
      @each $locale, $font-stack in $summit-secondary-fonts {
        @at-root #{selector-replace(&, ':global(.summit)', ':global(.locale-#{$locale} .summit)')} {
          font-family: $font-stack;
        }
      }
    } @else if $tertiary {
      @each $locale, $font-stack in $summit-tertiary-fonts {
        @at-root #{selector-replace(&, ':global(.summit)', ':global(.locale-#{$locale} .summit)')} {
          font-family: $font-stack;
        }
      }
    } @else {
      @each $locale, $font-stack in $summit-primary-fonts {
        @at-root #{selector-replace(&, ':global(.summit)', ':global(.locale-#{$locale} .summit)')} {
          font-family: $font-stack;
        }
      }
    }
  }
}

// Check if the selector string already includes the global selector
// That way we don't append it twice, which breaks the styles
// The following example now works as expected
// :global(.summit) & {
//    @include h5();
// }
@function append-global($selector, $globalSelector) {
  $global-selector-present: str-index(#{$selector}, #{$globalSelector}) != null;

  @if not $global-selector-present {
    $selector: selector-unify(&, selector-nest($globalSelector, &));
  }

  @return $selector;
}

@mixin h1($global: false) {
  @include font-sizes(44px, 44px, 101px);
  @include line-heights(1.0681, 1.0681, 1.1089);
  font-weight: 900;

  @include global-unified-gather-selector($global) {
    font-weight: 400;
    @include font-sizes(40px, 40px, 101px);
    @include line-heights(1.1, 1.1, 0.95);
    @include gather-font-i18n(true, $global);
  }

  @include global-unified-go-selector($global) {
    @include go-font-i18n(true, $global);
  }

  @include global-unified-nf-selector($global) {
    font-family: Stag, sans-serif;
    font-weight: 400;
    @include font-sizes(36px, 36px, 64px, 64px);
    @include line-heights(1.037, 1.037, 1.2727, 1);
    @include nf-font-i18n(true, $global);
  }

  @include global-unified-pc-selector($global) {
    font-weight: 900;
    @include font-sizes(44px, 44px, 101px);
    @include line-heights(1.07, 1.07px, 0.95);
    @include pc-font-i18n(true, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    @include font-sizes(44px, 44px, 101px);
    @include line-heights(1.07, 1.07, 0.95);
    @include summit-font-i18n(true, false, $global);
  }
}

@mixin h2($global: false) {
  @include font-sizes(35px, 35px, 67px);
  @include line-heights(1.1428, 1.1428, 0.9552);
  font-weight: 900;

  @include global-unified-gather-selector($global) {
    font-weight: 400;
    @include font-sizes(34px, 34px, 64px);
    @include line-heights(1.26, 1.26, 1.17);
    @include gather-font-i18n(true, $global);
  }

  @include global-unified-go-selector($global) {
    @include go-font-i18n(true, $global);
  }

  @include global-unified-nf-selector($global) {
    font-family: Stag, sans-serif;
    font-weight: 400;
    @include font-sizes(30px, 30px, 48px);
    @include line-heights(1.0666, 1.0666, 0.9696);
    @include nf-font-i18n(true, $global);
  }

  @include global-unified-pc-selector($global) {
    font-weight: 900;
    @include font-sizes(35px, 35px, 67px);
    @include line-heights(1.14, 1.14, 1.01);
    @include pc-font-i18n(true, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    @include font-sizes(35px, 35px, 67px);
    @include line-heights(1.14, 1.14, 0.96);
    @include summit-font-i18n(true, false, $global);
  }
}

@mixin h3($global: false) {
  @include font-sizes(28px, 28px, 45px);
  @include line-heights(1.1428, 1.1428, 1.0666);
  font-weight: 900;

  @include global-unified-gather-selector($global) {
    font-weight: 400;
    @include font-sizes(28px, 28px, 45px);
    @include line-heights(1.32, 1.32, 1.13);
    @include gather-font-i18n(true, $global);
  }

  @include global-unified-go-selector($global) {
    @include go-font-i18n(true, $global);
  }

  @include global-unified-nf-selector($global) {
    font-family: Stag, sans-serif;
    font-weight: 400;
    @include font-sizes(25px, 25px, 36px);
    @include line-heights(1.081, 1.081, 1.12);
    @include nf-font-i18n(true, $global);
  }

  @include global-unified-pc-selector($global) {
    font-weight: 600;
    @include font-sizes(28px, 28px, 45px);
    @include line-heights(1.14, 1.14, 1.11);
    @include pc-font-i18n(true, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    @include font-sizes(28px, 28px, 45px);
    @include line-heights(1.14, 1.14, 1.07);
    @include summit-font-i18n(true, false, $global);
  }
}

@mixin h4($global: false) {
  @include font-sizes(22px, 22px, 30px);
  @include line-heights(1.2727, 1.2727, 1.0666);
  font-weight: 900;

  @include global-unified-gather-selector($global) {
    font-weight: 400;
    @include font-sizes(22px, 22px, 30px);
    @include line-heights(1.27, 1.27, 1.33);
    @include gather-font-i18n(true, $global);
  }

  @include global-unified-go-selector($global) {
    @include go-font-i18n(true, $global);
  }

  @include global-unified-nf-selector($global) {
    font-family: Stag, sans-serif;
    font-weight: 400;
    @include font-sizes(18px, 18px, 26px);
    @include line-heights(1.0322, 1.0322, 1.1578);
    @include nf-font-i18n(true, $global);
    @include at-breakpoint(large) {
      letter-spacing: 1px;
    }
  }

  @include global-unified-pc-selector($global) {
    font-weight: 600;
    @include font-sizes(22px, 22px, 30px, 30px);
    @include line-heights(1.27, 1.27, 1.17, 1.17);
    @include pc-font-i18n(true, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    @include font-sizes(20px, 20px, 30px);
    @include line-heights(1.2, 1.2, 1.07);
    @include summit-font-i18n(false, true, $global);
  }
}

@mixin h5($global: false) {
  @include font-sizes(18px, 18px, 20px);
  @include line-heights(1.3333, 1.3333, 1.2);
  font-weight: 900;

  @include global-unified-gather-selector($global) {
    font-weight: 400;
    @include gather-font-i18n(true, $global);
  }

  @include global-unified-go-selector($global) {
    @include go-font-i18n(true, $global);
  }

  @include global-unified-nf-selector($global) {
    font-family: Stag, sans-serif;
    font-weight: 400;
    @include font-sizes(12px, 12px, 20px);
    @include line-heights(1.076, 1.076, 1.1428);
    @include nf-font-i18n(true, $global);
  }

  @include global-unified-pc-selector($global) {
    font-weight: 600;
    @include font-sizes(18px, 18px, 20px, 20px);
    @include line-heights(1.33, 1.33, 1.3, 1.3);
    @include pc-font-i18n(true, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    @include font-sizes(16px, 16px, 20px);
    @include line-heights(1.31, 1.31, 1.2);
    @include summit-font-i18n(false, true, $global);
  }
}

@mixin p1($global: false) {
  @include font-sizes(16px, 16px, 21px);
  @include line-heights(1.5, 1.5, 1.5238);
  font-weight: normal;

  @include global-unified-gather-selector($global) {
    font-weight: 300;
    font-size: 19px;
    line-height: 1.9;
    @include gather-font-i18n(false, $global);
  }

  @include global-unified-go-selector($global) {
    @include go-font-i18n(false, $global);
  }

  @include global-unified-nf-selector($global) {
    font-family: Stag, sans-serif;
    font-weight: 300;
    font-size: 16px;
    @include nf-font-i18n(false, $global);
  }

  @include global-unified-pc-selector($global) {
    font-weight: 300;
    line-height: 1.76;
    @include pc-font-i18n(false, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 300;
    font-size: 21px;
    line-height: 1.43;
    @include summit-font-i18n(false, false, $global);
  }
}

@mixin p2($global: false) {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: normal;

  @include global-unified-gather-selector($global) {
    font-weight: 300;
    line-height: 1.75;
    @include gather-font-i18n(false, $global);
  }

  @include global-unified-go-selector($global) {
    @include go-font-i18n(false, $global);
  }

  @include global-unified-nf-selector($global) {
    font-family: Stag, sans-serif;
    font-weight: 300;
    font-size: 14px;
    @include nf-font-i18n(false, $global);
  }

  @include global-unified-pc-selector($global) {
    font-weight: 300;
    line-height: 1.63;
    @include pc-font-i18n(false, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 300;
    line-height: 1.69;
    @include summit-font-i18n(false, false, $global);
  }
}

@mixin p3($global: false) {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;

  @include global-unified-gather-selector($global) {
    font-weight: 300;
    line-height: 1.75;
    @include gather-font-i18n(false, $global);
  }

  @include global-unified-go-selector($global) {
    @include go-font-i18n(false, $global);
  }

  @include global-unified-nf-selector($global) {
    font-family: Stag, sans-serif;
    font-weight: 300;
    font-size: 12px;
    @include nf-font-i18n(false, $global);
  }

  @include global-unified-pc-selector($global) {
    font-weight: 300;
    line-height: 1.75;
    @include pc-font-i18n(false, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 300;
    line-height: 1.75;
    @include summit-font-i18n(false, false, $global);
  }
}

@mixin bold($global: false) {
  @include global-unified-gather-selector($global) {
    font-weight: 700;
  }

  @include global-unified-go-selector($global) {
    font-weight: bold;
  }

  @include global-unified-nf-selector($global) {
    font-weight: 500;
  }

  @include global-unified-pc-selector($global) {
    font-weight: 600;
  }

  @include global-unified-summit-selector($global) {
    font-weight: 700;
  }
}

@mixin italic {
  font-style: italic;
}

@mixin underline {
  text-decoration: underline;
}

@mixin eyebrow($global: false) {
  @include global-unified-gather-selector($global) {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.14;
    @include gather-font-i18n(true, $global);
  }

  @include global-unified-go-selector($global) {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 1.1428;
    @include go-font-i18n(true, $global);
  }

  @include global-unified-nf-selector($global) {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3333;
    @include nf-font-i18n(true, $global);

    :global(.locale-he) & {
      font-size: 14px;
    }
  }

  @include global-unified-pc-selector($global) {
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 1.14;
    text-transform: uppercase;
    @include pc-font-i18n(true, $global);
  }

  @include global-unified-summit-selector($global) {
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    @include summit-font-i18n(false, true, $global);
  }
}

@mixin eyebrow-box($global: false) {
  @include eyebrow($global); // Include all eyebrow styles for all brands

  @include global-unified-nf-selector($global) {
    font-weight: 400;
    font-size: 16px;
    width: fit-content;
    background-color: $now-light-tan;
    border: 1px solid $now-brown;
    padding: 2px 10px;
    border-radius: 23px;
    line-height: 1.3333;
    @include nf-font-i18n(true, $global);
  }
}

@mixin gather-nav() {
  font-weight: 400;
  @include font-sizes(22px, 30px, 13px, 17px);
  @include line-heights(1.27, 1.33, 1.85, 1.41);
}

@mixin now-fresh-nav() {
  font-family: Stag, sans-serif;
  font-weight: 400;
  @include font-sizes(20px, 30px, 16px);
  @include line-heights(24px, 32px, 24px);
  color: $now-brown;
  @include nf-font-i18n(true);

  @include at-breakpoint(large) {
    line-height: 1.3333;
  }
}

@mixin petcurean-nav() {
  text-transform: none;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0px;
  line-height: 1.3;

  @include at-breakpoint(medium) {
    font-size: 30px;
    line-height: 1.2;
  }

  @include at-breakpoint(large) {
    font-size: 14px;
    line-height: 1.7;
  }

  @include at-breakpoint(x-large) {
    font-size: 18px;
    line-height: 1.3;
  }
}

@mixin summit-nav() {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 2px;

  @include at-breakpoint(medium) {
    font-size: 30px;
    line-height: 1.1;
  }

  @include at-breakpoint(large) {
    font-size: 13px;
    line-height: 1.85;
    letter-spacing: normal;
  }

  @include at-breakpoint(x-large) {
    font-size: 16px;
    line-height: 1.5;
  }
}
