
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 24px;
  grid-auto-columns: minmax(10px, auto);

  @include at-breakpoint(large) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.copy-container {
  grid-column: span 6;

  :global(.now-fresh) & {
    color: $now-brown;
  }
}

.text-section {
  margin-bottom: 40px;
}

.title {
  margin-bottom: 8px;
  @include h5();

  :global(.now-fresh) & {
    font-size: 18px;
  }
}

.text {
  @include p2();

  :global(.dots) {
    display: none;
  }

  :global(.collapsed) & {
    :global(.expanded) {
      display: none;
    }

    :global(.dots) {
      display: inline;
    }

    @include at-breakpoint(medium) {
      :global(.dots) {
        display: none;
      }

      :global(.expanded) {
        display: inline;
      }
    }
  }

  &.medium {
    display: none;

    @include at-breakpoint(medium) {
      display: block;
    }
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }

  :global(.gather) &,
  :global(.summit) & {
    @include p2(false);
  }
}

.text::first-letter {
  text-transform: uppercase;
}

.recipe {
  composes: view-all-cta from '../ExpandableElement/expandableElement.module.scss';

  button {
    margin: 24px auto 0 0;

    :global(.gather) & {
      &:after {
        background-image: $gather-dark-gradient;
      }
    }

    :global(.now-fresh) & {
      border: none;
    }

    :global(.summit) & {
      padding: 0;
      &:after {
        background-image: $summit-dark-gradient;
      }
    }

    :global(.locale-he) & {
      margin: 24px 0 0 auto;
    }

    @include at-breakpoint(medium) {
      display: none;
    }
  }

  :global(.gather) & {
    p {
      @include p2();
      color: $gather-black;
    }
    svg {
      path {
        fill: $gather-green;
      }
    }
    &:after {
      content: none;
    }
  }

  :global(.go-solutions) & {
    p {
      color: $color-white;
    }

    svg {
      path {
        fill: $color-white;
      }
    }
  }

  :global(.now-fresh) & {
    p {
      color: $now-brown;
    }

    svg {
      path {
        fill: $now-brown;
      }
    }
  }

  :global(.summit) & {
    p {
      @include p2();
      color: $summit-black;
    }

    svg {
      path {
        fill: $summit-blue;
      }
    }

    &:after {
      content: none;
    }

    button {
      p {
        @include eyebrow();
      }
      svg {
        path {
          fill: $summit-black;
        }
      }
    }
  }

  @include at-breakpoint(medium) {
    display: none;
  }
}

.recommended-list {
  margin-top: 16px;
}

.recommended {
  @include p2();
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  :global(.gather) & {
    @include p2(false);

    svg {
      path {
        fill: $gather-green;
      }
    }
  }

  :global(.go-solutions) & {
    svg {
      path {
        fill: $go-teal;
      }
    }
  }

  :global(.now-fresh) & {
    svg {
      path {
        fill: $now-brown;
      }
    }
  }

  :global(.summit) & {
    @include p2(false);

    svg {
      path {
        fill: $summit-blue;
      }
    }
  }
  svg {
    margin-inline-end: 12px;
  }
}

.download-cta {
  display: inline-flex;
  align-items: center;
  margin-top: 40px;
  width: auto;

  svg {
    margin-top: 2px;
  }

  :global(.go-solutions) & {
    &:hover {
      svg {
        path {
          fill: black;
        }
      }
    }
  }

  :global(.now-fresh) & {
    color: $now-brown;
    border-color: $now-brown;

    svg {
      margin-top: 2px;

      path {
        fill: $now-brown;
      }
    }
  }
}
