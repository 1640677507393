
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include flex-container();
  flex-direction: column;
  @include default-margin-bottom();

  :global(.now-fresh) & {
    max-width: none;
    margin: 0 0 1px 0;
    width: auto;
    outline: 1px solid $now-brown;
    color: $now-brown;
    padding: 30px;

    @include at-breakpoint(large) {
      padding: 80px;
    }
  }

  .eyebrow {
    @include eyebrow-box();

    :global(.now-fresh) & {
      margin-bottom: 15px;
    }

    :global(.petcurean) & {
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 600 !important;
      text-transform: uppercase;
      margin-bottom: 8px;
    }
  }

  .headline {
    @include h2();
    margin-bottom: 14px;

    :global(.petcurean) & {
      @include font-sizes(35px, 67px, 67px, 67px);
      @include line-heights(35px, 67px, 67px, 67px);
      font-weight: 900;
      color: $petcurean-orange;
      margin-bottom: 23px;
    }
  }

  .copy {
    :global(.now-fresh) & {
      @include p2();
    }
    :global(.petcurean) & {
      @include font-sizes(16px, 21px, 21px, 21px);
      @include line-heights(26px, 37px, 37px, 37px);
    }
  }
}
