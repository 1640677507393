
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  background-color: $color-white;
  padding-top: 80px;
  padding-bottom: 83px;

  :global(.gather) & {
    background-color: $gather-cream;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    max-width: none;
    width: auto;
    padding: 0;
    column-gap: 0;
  }

  :global(.summit) & {
    background-color: $summit-white;
  }

  @include at-breakpoint(medium) {
    padding-top: 100px;
    padding-bottom: 103px;
  }

  @include at-breakpoint(large) {
    padding-top: 120px;
    padding-bottom: 108px;
  }

  @include at-breakpoint(x-large) {
    padding-top: 120px;
    padding-bottom: 123px;
  }
}

.aggregate-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.aggregate {
  @include h5();
  padding: 16px;
  margin-inline-end: 16px;

  :global(.gather) & {
    font-size: 16px;
    background-color: $gather-green;
    color: $gather-white;
    font-weight: 700;
  }

  :global(.go-solutions) & {
    background-color: $color-black;
    color: $color-white;
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    color: $now-brown;
    border: 1px solid $now-brown;
    border-radius: 100%;
    padding: 8px 16px;
  }

  :global(.summit) & {
    @include p2();
    font-weight: 700;
    background-color: $summit-blue;
    color: $summit-white;
    padding: 12px;
  }
}

.aggregate-star {
  height: 32px;

  span {
    width: 32px;
    margin-inline-end: 8px;
  }

  svg {
    height: 100%;
    display: block;
  }

  :global(.gather) &,
  :global(.summit) & {
    height: 23px;

    span {
      width: 23px;

      svg {
        path {
          fill: $go-yellow;
        }
      }
    }
  }

  :global(.now-fresh) & {
    span {
      svg {
        path {
          fill: $now-brown;
        }
      }
    }
  }
}

.total-reviews {
  font-size: 16px;
  width: 100%;
  margin-top: 8px;
  display: none;

  :global(.gather) & {
    display: block;
    color: $go-gray;
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
  }

  :global(.go-solutions) & {
    color: $go-dark-gray;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.summit) & {
    @include p2();
    color: $summit-black;
    display: block;
  }

  @include at-breakpoint(medium) {
    display: block;
  }
}

.headline-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @include at-breakpoint(medium) {
    flex-wrap: nowrap;
  }

  :global(.gather) &,
  :global(.summit) & {
    align-items: flex-end;
  }
}

.headline {
  margin-bottom: 24px;
  max-width: 872px;

  @include at-breakpoint(medium) {
    margin-bottom: 47px;
  }

  :global(.gather) & {
    @include h3(true);
    font-weight: 400;

    @include at-breakpoint(medium) {
      max-width: 50%;
    }

    @include at-breakpoint(large) {
      max-width: calc(7 / 12 * 100%);
    }

    @include at-breakpoint(x-large) {
      max-width: calc(5 / 12 * 100%);
    }
  }

  :global(.now-fresh) & {
    font-weight: 400;
    color: $now-brown;
    margin-bottom: 20px;

    @include at-breakpoint(medium) {
      margin-bottom: 0;
      @include font-sizes(30px, 30px, 36px, 36px);
    }
  }

  :global(.summit) & {
    @include h3();
    color: $summit-blue;
  }
}

.cta {
  flex-shrink: 0;
  font-weight: 500;
  margin: 0 0 57px 0;

  :global(.locale-he) & {
    margin: 0 0 57px 0;
  }

  @include at-breakpoint(medium) {
    margin: 0 0 57px 18px;

    :global(.locale-he) & {
      margin: 0 18px 57px 0;
    }
  }

  :global(.now-fresh) & {
    font-weight: 400;
    padding: 13px 16px;
    margin: 0;
  }
}

.sort {
  display: block;
  margin-inline-start: auto;
  width: calc(50% - 4px);

  :global(.gather) & {
    background-color: $gather-light-grey;
    color: $gather-black;
  }

  :global(.now-fresh) & {
    [class^='styledSelect_wrapper'] {
      border: 1px solid $now-brown;
      background-color: $color-white;
    }
  }

  :global(.summit) & {
    background-color: rgba($summit-black, 0.35);
    color: $summit-white;
    font-weight: 700;
  }

  @include at-breakpoint(large) {
    :global(.gather) &,
    :global(.gather) & > div {
      background-color: transparent;
      color: $gather-black;
    }

    :global(.now-fresh) & {
      [class^='styledSelect_wrapper'] {
        border: none;
        background-color: $now-tan;
      }

      [class^='styledSelect_select'] {
        font-size: 16px;
      }

      [class^='styledSelect_label'] {
        font-size: 16px;
      }
    }

    :global(.summit) &,
    :global(.summit) & > div {
      background-color: transparent;
      color: $summit-black;
    }
  }

  @include at-breakpoint(large) {
    width: auto;
    margin-inline-start: 0;
  }
}

.static-form {
  @include visually-hidden;
}

.reviews-header {
  grid-column: span 12;

  :global(.now-fresh) & {
    border-bottom: 1px solid $now-brown;
    padding: 24px;

    @include at-breakpoint(medium) {
      padding: 48px 48px 32px 48px;
    }
    @include at-breakpoint(large) {
      padding: 64px 64px 48px 64px;
    }
  }
}

.reviews-list {
  grid-column: span 12;

  @include at-breakpoint(large) {
    grid-column: 4/-1;
  }

  ul {
    margin-top: 40px;

    @include at-breakpoint(medium) {
      margin-top: 0;
    }
  }

  :global(.now-fresh) & {
    ul {
      margin-top: 0;
    }
    @include at-breakpoint(large) {
      border-left: 1px solid $now-brown;

      :global(.locale-he) & {
        border-left: none;
        border-right: 1px solid $now-brown;
      }
    }
  }
}

.review {
  border: 1px solid $color-black;
  margin-bottom: 15px;

  :global(.gather) & {
    border-radius: 20px;
    border: 1px solid $gather-dark-green;
  }

  :global(.now-fresh) & {
    border: none;
    margin-bottom: 0;
    border-bottom: 1px solid $now-brown;
    padding: 40px;

    @include at-breakpoint(medium) {
      padding: 0;
    }
  }

  :global(.summit) & {
    border-color: $summit-light-blue;
  }
}

.no-reviews-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-column: span 12;

  :global(.now-fresh) & {
    padding: 40px;
  }
}

.no-reviews-headline {
  text-align: center;
  margin-bottom: 40px;

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.summit) & {
    color: $summit-blue;
  }
}

.no-reviews-subheadline {
  text-align: center;
  margin-bottom: 40px;

  :global(.now-fresh) & {
    color: $now-brown;
  }
}

.button-bar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  @include at-breakpoint(large) {
    align-items: center;
    height: 40px;
  }

  :global(.now-fresh) & {
    padding: 40px;
    height: auto;
    border-bottom: 1px solid $now-brown;
  }
}

.pagination {
  font-size: 12px;
  margin-top: 9px;

  :global(.gather) &,
  :global(.summit) & {
    font-size: 12px;
    line-height: 21px;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  &.pagination-top {
    display: none;
    margin-bottom: 8px;
    margin-top: 21px;

    @include at-breakpoint(medium) {
      display: block;
      order: 3;
      width: 100%;
    }

    @include at-breakpoint(large) {
      width: auto;
      order: 0;
      margin: 0;
    }
  }
}

.open-filters-btn {
  @include p2();
  @include bold();
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 4px);
  padding: 17px 11px 15px 11px;
  text-transform: uppercase;
  color: $color-black;

  :global(.gather) & {
    background-color: $gather-light-grey;
    color: $gather-black;
    text-transform: none;
    font-size: 14px;
    line-height: 16px;
  }

  :global(.go-solutions) & {
    background-color: $go-gray-lighter;
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
    text-transform: none;
  }

  :global(.summit) & {
    background-color: rgba($summit-black, 0.35);
    color: $summit-white;
    font-size: 14px;
    line-height: 16px;
  }

  @include at-breakpoint(large) {
    display: none;
  }

  svg {
    margin-inline-start: 6px;

    path {
      :global(.gather) & {
        fill: $gather-green;
      }

      :global(.go-solutions) & {
        fill: $color-black;
      }

      :global(.now-fresh) & {
        fill: $now-brown;
      }

      :global(.summit) & {
        fill: $summit-white;
      }
    }
  }

  :global(.now-fresh) & {
    font-weight: 500;
    color: $now-brown;
  }
}

.pagination-wrapper {
  :global(.gather) & {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :global(.now-fresh) & {
    padding: 40px;
  }
}
