
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include default-margin-bottom();
  position: relative;
  max-width: 1680px;
  margin-inline-start: auto;
  margin-inline-end: auto;

  :global(.gather) & {
    color: $gather-black;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    outline: 1px solid $now-brown;
    max-width: none;
  }

  :global(.petcurean) & {
    color: $petcurean-grey;
  }

  :global(.summit) & {
    color: $summit-black;
  }

  &.align-right {
    .image-wrapper {
      :global(.summit) & {
        margin-inline-end: 0;
      }

      @include at-breakpoint(medium) {
        grid-column: 4 / -1;

        :global(.gather) &,
        :global(.petcurean) & {
          grid-column: 1 / -1;
          grid-row: 1;
        }
      }

      @include at-breakpoint(large) {
        grid-column: 8 / -1;

        :global(.gather) &,
        :global(.petcurean) & {
          grid-column: 8 / -1;
          grid-row: 1;
        }
      }
    }

    .content {
      :global(.now-fresh) & {
        grid-column: span 6;
        grid-row: 2;
      }

      @include at-breakpoint(medium) {
        grid-row: 1;

        :global(.gather) &,
        :global(.petcurean) & {
          grid-row: 2;
        }

        :global(.summit) & {
          padding-inline-start: 0;
          padding-inline-end: 60px;
        }
      }

      @include at-breakpoint(large) {
        grid-column: span 6;

        :global(.gather) &,
        :global(.petcurean) & {
          grid-row: 1;
        }

        :global(.now-fresh) & {
          grid-column: span 6;
          padding: 80px;
          grid-row: 1;
        }

        :global(.summit) & {
          padding-inline-start: 0;
          padding-inline-end: 32px;
        }
      }

      @include at-breakpoint(x-large) {
        grid-column: 2 / span 5;

        :global(.gather) &,
        :global(.petcurean) & {
          grid-column: 1 / span 6;
        }

        :global(.summit) & {
          grid-column: span 7;
          padding-inline-start: 0;
          padding-inline-end: 40px;
        }
      }
    }
  }

  &.full-width {
    :global(.now-fresh) & {
      margin: 0;
      width: auto;
      max-width: none;
      padding: 0;
      column-gap: 0;
    }

    .image-wrapper {
      margin-inline-start: -16px;

      :global(.petcurean) &,
      :global(.gather) &,
      :global(.summit) & {
        margin-inline-start: 0;
      }

      @include at-breakpoint(medium) {
        margin-inline-start: -32px;

        :global(.petcurean) & {
          margin-inline-start: 0;
          grid-column: span 6;
        }

        :global(.summit) & {
          grid-column: span 3;
        }
      }

      @include at-breakpoint(large) {
        grid-column: span 6;
        margin-inline-start: -44px;

        :global(.petcurean) & {
          margin-inline-start: -44px;
        }

        :global(.summit) & {
          margin-inline-start: 0;
          grid-column: span 5;
        }
      }

      @include at-breakpoint(x-large) {
        margin-inline-start: 0;
      }

      :global(.now-fresh) & {
        margin-inline-start: 0;

        @include at-breakpoint(medium) {
          grid-column: span 6;
        }

        @include at-breakpoint(large) {
          border-right: 1px solid $now-brown;

          :global(.locale-he) & {
            border-left: 1px solid $now-brown;
            border-right: none;
          }
        }
      }

      :global(.petcurean) & {
        @include at-breakpoint(large) {
          grid-column: span 5;
        }

        @include at-breakpoint(x-large) {
          grid-column: span 6;
        }
      }

      img {
        @include at-breakpoint(x-large) {
          position: absolute;
          top: 0;
          left: 0;
          // Calculate one row over from middle. 134px is the grid width at 1320px + 24px for the gutter
          width: calc(50% - 134px);

          :global(.locale-he) & {
            top: 0;
            left: auto;
            right: 0;
          }
        }

        :global(.now-fresh) & {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        :global(.summit) & {
          position: relative;
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    &.align-right {
      .image-wrapper {
        margin-inline-start: 0;
        margin-inline-end: -16px;
        justify-content: flex-end;

        @include at-breakpoint(small) {
          :global(.gather) &,
          :global(.now-fresh) &,
          :global(.petcurean) &,
          :global(.summit) {
            margin-inline-end: 0;
          }
        }

        @include at-breakpoint(medium) {
          margin-inline-end: -32px;

          :global(.now-fresh) &,
          :global(.petcurean) & {
            margin-inline-end: 0;
          }
        }

        @include at-breakpoint(large) {
          grid-column: 7 / -1;
          margin-inline-end: -44px;

          :global(.now-fresh) & {
            margin-inline-end: 0;
            border-left: 1px solid $now-brown;
            border-right: none;

            :global(.locale-he) & {
              border-right: 1px solid $now-brown;
              border-left: none;
            }
          }

          :global(.petcurean) & {
            margin-inline-end: -44px;
          }

          :global(.summit) & {
            grid-column: 8 / -1;
            margin-inline-end: 0;
          }
        }

        @include at-breakpoint(x-large) {
          margin-inline-end: 0;

          :global(.now-fresh) & {
            margin-inline-end: 0;
          }
          img {
            left: auto;
            right: 0;

            :global(.locale-he) & {
              left: 0;
              right: auto;
            }
          }
        }

        :global(.petcurean) & {
          @include at-breakpoint(large) {
            grid-column: 8 / -1;
          }

          @include at-breakpoint(x-large) {
            grid-column: 7 / -1;
          }
        }
      }
    }
  }

  &.benefits-layout {
    :global(.now-fresh) & {
      margin: 0;
      max-width: none;
      width: auto;
      padding: 0;
      column-gap: 0;
      margin-top: 1px;
    }

    .image-wrapper {
      @include at-breakpoint(medium) {
        grid-column: span 3;

        :global(.petcurean) & {
          grid-column: 1 / -1;
        }
      }

      @include at-breakpoint(large) {
        grid-column: span 6;

        :global(.petcurean) & {
          grid-column: span 5;
          grid-row: 1;
        }
      }

      @include at-breakpoint(x-large) {
        grid-column: span 5;

        :global(.petcurean) & {
          grid-column: span 5;
        }
      }

      :global(.go-solutions) & {
        padding: 0 36px;

        @include at-breakpoint(medium) {
          padding: 0;
        }
      }

      :global(.now-fresh) & {
        @include at-breakpoint(medium) {
          grid-column: span 6;
        }

        @include at-breakpoint(large) {
          border-right: 1px solid $now-brown;

          :global(.locale-he) & {
            border-left: 1px solid $now-brown;
            border-right: none;
          }
        }
      }

      img {
        :global(.now-fresh) & {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .content {
      margin-top: 16px;

      @include at-breakpoint(medium) {
        grid-column: span 3;
        text-align: start;
        margin: 0;

        :global(.petcurean) & {
          margin: 32px 0 30px 0;
          grid-column: span 6;
        }
      }

      @include at-breakpoint(large) {
        grid-column: span 6;
      }

      :global(.now-fresh) & {
        margin-top: 0;

        @include at-breakpoint(medium) {
          margin: 0;
          @include at-breakpoint(medium) {
            grid-column: span 6;
          }
        }

        @include at-breakpoint(large) {
          grid-column: 7 / -1;
        }
      }

      :global(.petcurean) & {
        @include at-breakpoint(large) {
          grid-column: 7 / -1;
        }
      }
    }

    .headline {
      @include font-sizes(35px, 35px, 45px, 67px);
      @include line-heights(1.1428, 1.1428, 1.0666, 0.9552);
      padding: 0;

      :global(.gather) & {
        @include font-sizes(28px, 45px, 45px);
        @include line-heights(37px, 50px, 50px, 50px);
      }

      :global(.now-fresh) & {
        @include font-sizes(25px, 40px, 40px);
        @include line-heights(1.0811, 1.0666, 0.9697, 0.9697);
      }

      :global(.locale-ru) &,
      :global(.locale-ru) :global(.now-fresh) & {
        line-height: 1.25;
      }

      :global(.locale-fr) &,
      :global(.locale-fr) :global(.now-fresh) & {
        line-height: 1.1;
      }

      :global(.petcurean) & {
        @include font-sizes(28px, 45px, 45px, 45px);
        @include line-heights(32px, 50px, 50px, 50px);
        font-weight: 600;
        margin-bottom: 8px;

        :global(.locale-zh) & {
          @include line-heights(1.25, 1.2, 1.2, 1.2);
        }
      }
    }

    .copy {
      :global(.now-fresh) & {
        font-size: 16px;
      }

      @include at-breakpoint(medium) {
        margin-top: 16px;
      }

      @include at-breakpoint(large) {
        margin-top: 24px;
      }
    }
  }

  &.no-image {
    .content-wrapper {
      .content {
        grid-column: span 6;

        @include at-breakpoint(large) {
          grid-column: span 12;
        }

        @include at-breakpoint(x-large) {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          padding-top: 160px;
          padding-bottom: 160px;
        }
      }

      .headline {
        @include at-breakpoint(x-large) {
          grid-column: span 6;
        }
      }

      .copy {
        @include at-breakpoint(x-large) {
          grid-column: span 6;
          margin: 0;
        }
      }
    }
  }
}

.content-wrapper {
  @include grid-container();

  @media screen and (min-width: 1408px) {
    max-width: $max-width;
    margin: 0 auto;

    :global(.go-solutions) & {
      padding: 0;
    }

    :global(.now-fresh) & {
      padding: 0;
    }

    :global(.petcurean) & {
      padding: 0;
    }
  }

  &.anim-in {
    .image-wrapper {
      img {
        @include animateIn();
      }
    }

    .content {
      @include animateIn(0.4s);
    }
  }

  :global(.now-fresh) & {
    margin: 0;
    max-width: none;
    width: auto;
    padding: 0;
    column-gap: 0;
  }
}

.image-wrapper {
  grid-column: span 6;

  :global(.gather) & {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  :global(.now-fresh) & {
    grid-column: span 6;
    border-bottom: 1px solid $now-brown;

    @include at-breakpoint(large) {
      border-bottom: none;
      border-right: 1px solid $now-brown;
    }
  }

  @include at-breakpoint(medium) {
    grid-column: span 3;

    :global(.gather) &,
    :global(.petcurean) & {
      grid-column: 1 / -1;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 5;

    :global(.gather) &,
    :global(.petcurean) & {
      grid-column: span 5;
    }
  }

  img {
    @include willAnimateIn();
    display: block;
    width: 100%;

    :global(.now-fresh) & {
      @include willAnimateInNowFresh();
    }

    :global(.petcurean) & {
      border-radius: 20px;
    }
  }
}

.content {
  @include willAnimateIn();
  grid-column: span 6;
  margin-top: 48px;

  :global(.now-fresh) & {
    padding: 40px;
    @include willAnimateInNowFresh();
  }

  :global(.petcurean) & {
    margin-top: 32px;
  }

  :global(.summit) & {
    margin-top: 16px;
  }

  @include at-breakpoint(medium) {
    margin: 30px 0;
    align-self: center;
    grid-column: span 3;

    :global(.petcurean) & {
      margin-top: 48px;
      grid-column: span 6;
    }

    :global(.now-fresh) & {
      grid-column: span 6;
      padding: 80px;
    }

    :global(.summit) & {
      margin-top: 48px;
      padding-inline-start: 60px;
      grid-column: span 3;
    }
  }

  @include at-breakpoint(medium) {
    :global(.gather) & {
      grid-column: span 6;
    }
  }

  @include at-breakpoint(large) {
    grid-column: span 6 / -1;

    :global(.petcurean) & {
      grid-column: span 6 / -1;
    }

    :global(.summit) & {
      grid-column: span 7;
      padding-inline-start: 32px;
    }
  }

  @include at-breakpoint(x-large) {
    grid-column: span 5 / -2;
    margin: 80px 0;

    :global(.summit) & {
      padding-inline-start: 40px;
    }

    :global(.gather) & {
      grid-column: span 6 / -1;
    }
  }

  :global(.now-fresh) & {
    margin: 0;
  }

  :global(.petcurean) & {
    @include at-breakpoint(medium) {
      margin: 32px 0 30px 0;
    }
  }
}

.eyebrow {
  @include eyebrow-box();
  margin-bottom: 8px;

  @include at-breakpoint(large) {
    margin-bottom: 16px;
  }

  :global(.gather) & {
    color: $gather-green;
    font-weight: 700;
    margin-bottom: 8px;
  }

  :global(.petcurean) & {
    color: $petcurean-grey;
    margin-bottom: 8px;
    font-size: 14px;

    :global(.locale-zh) & {
      @include font-sizes(16px, 18px, 21px);
    }
  }

  :global(.summit) & {
    margin-bottom: 8px;
  }
}

.headline {
  @include h3;
  overflow-wrap: break-word;

  @include at-breakpoint(medium) {
    font-size: 44px;
    line-height: 1.0227;
  }

  @include at-breakpoint(large) {
    font-size: 45px;
    line-height: 1.0667;
  }

  :global(.gather) & {
    @include font-sizes(28px, 45px, 45px);
    @include line-heights(37px, 50px, 50px, 50px);
  }

  :global(.go-solutions) & {
    @include at-breakpoint(x-large) {
      padding-inline-end: 20px;
    }
  }

  :global(.now-fresh) & {
    @include font-sizes(25px, 40px, 40px);
    @include line-heights(1.0323, 1.0811, 0.9697, 0.9697);

    :global(.locale-ru) & {
      @include at-breakpoint(x-large) {
        @include font-sizes(31px, 37px, 50px, 50px);
      }
    }

    @include at-breakpoint(medium) {
      letter-spacing: 1px;
    }
  }

  :global(.petcurean) & {
    color: $petcurean-orange;
    @include font-sizes(28px, 45px, 45px, 45px);
    @include line-heights(32px, 50px, 50px, 50px);
    font-weight: 600;

    :global(.locale-zh) & {
      font-weight: 900;
      @include line-heights(1.25, 1.2, 1.2, 1.2);
      @include font-sizes(25px, 32px, 45px, 45px);
    }
  }

  :global(.summit) & {
    color: $summit-blue;
    font-size: 28px;
    line-height: 1.14;
    margin-bottom: 20px;
  }
}

.copy {
  a {
    :global(.gather) &,
    :global(.petcurean) & {
      text-decoration: underline;
    }

    svg {
      display: none;
    }
  }

  @include p1();
  margin-top: 16px;

  @include at-breakpoint(medium) {
    margin-top: 24px;
  }

  :global(.gather) & {
    @include font-sizes(16px, 19px, 19px, 19px);
    @include line-heights(28px 28px, 36px, 36px);
  }

  :global(.now-fresh) & {
    font-size: 16px;
    @include at-breakpoint(large) {
      margin-top: 32px;
    }
  }

  :global(.petcurean) & {
    margin-top: 16px;
    @include font-sizes(16px, 21px, 21px, 21px);
    @include line-heights(26px 37px, 37px, 37px);

    :global(.locale-zh) & {
      @include font-sizes(16px, 16px, 21px, 21px);
      @include line-heights(1.5, 1.5, 1.5, 1.5);

      @include at-breakpoint(medium) {
        margin-top: 30px;
      }
      @include at-breakpoint(large) {
        margin-top: 24px;
      }
    }
  }

  :global(.summit) & {
    margin-top: 0;
  }

  p,
  a {
    :global(.go-solutions) & {
      color: $color-white;
    }
  }

  a {
    text-decoration: underline;
  }
}

.cta-container {
  margin-top: 32px;

  :global(.summit) & {
    margin-top: 20px;
  }
}

.cta-btn {
  margin-right: 24px;
  margin-bottom: 24px;
  display: inline-flex;

  :global(.gather) & {
    font-size: 14px;
    line-height: 16px;
  }
}
