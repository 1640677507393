
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include grid-container();
  position: relative;
  color: $color-white;
  overflow: hidden;

  :global(.gather) & {
    color: $gather-black;
    position: static;
    margin-bottom: 230px;

    @include at-breakpoint(medium) {
      margin-bottom: 400px;
    }

    @include at-breakpoint(large) {
      @include default-margin-bottom();
    }
  }

  :global(.go-solutions) & {
    margin-bottom: 70px;

    @include at-breakpoint(large) {
      margin-bottom: 87px;
    }

    @include at-breakpoint(x-large) {
      margin-bottom: 48px;
    }
  }

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
    color: $now-brown;
    margin-bottom: -1px;
    max-width: none;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 0;

    @include at-breakpoint(medium) {
      margin-bottom: 1px;
      @media (orientation: portrait) {
        height: calc(100vh - 88px);
      }
    }

    @include at-breakpoint(large) {
      height: calc(100vh - 88px);
    }

    @include at-breakpoint(x-large) {
      display: grid;
      column-gap: 0;
    }
  }

  :global(.petcurean) & {
    background-color: $petcurean-orange;
    color: $petcurean-white;
    position: relative;
    @include default-margin-bottom();
    overflow: hidden;
    grid-auto-flow: dense;

    @include at-breakpoint(medium) {
      min-height: 700px;
    }

    @include at-breakpoint(large) {
      min-height: 742px;
    }

    @include at-breakpoint(x-large) {
      min-height: 742px;
    }
  }
}

.content-wrapper {
  position: relative;
  grid-column: span 6;
  z-index: 1;

  :global(.gather) & {
    padding: 40px 0;

    @include at-breakpoint(medium) {
      padding: 80px 0;
    }

    @include at-breakpoint(large) {
      padding: 95px 0;
      grid-column: span 7;
    }

    @include at-breakpoint(x-large) {
      padding: 215px 0;
      grid-column: span 6;
    }
  }

  :global(.go-solutions) & {
    padding-top: 165px;
    background-image: linear-gradient(0deg, $color-black 50%, rgba(0, 0, 0, 0) 92%);

    @include at-breakpoint(medium) {
      padding-top: 190px;
    }

    @include at-breakpoint(large) {
      padding-top: 96px;
      background: none;
    }

    @include at-breakpoint(x-large) {
      padding-top: 170px;
    }
  }

  :global(.now-fresh) & {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    background-color: $color-white;
    border-bottom: 1px solid $now-brown;

    @include at-breakpoint(medium) {
      padding: 40px;
    }

    @include at-breakpoint(x-large) {
      max-height: none;
      padding: 60px;
      border-bottom: none;
      border-right: 1px solid $now-brown;
      grid-column: span 6;
    }
  }

  :global(.petcurean) & {
    padding-top: 40px;
    grid-column: span 6;

    @include at-breakpoint(large) {
      grid-column: span 9;
      padding: 100px 0;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 8;
      padding: 146px 0;
    }
  }
}

.headline {
  @include h2();
  margin: 0;

  :global(.gather) & {
    @include font-sizes(40px, 87px, 87px, 87px);
    @include line-heights(44px, 92px, 92px, 92px);
  }

  :global(.go-solutions) & {
    @include at-breakpoint(medium) {
      font-size: 67px;
      line-height: 0.9552;
    }
  }

  :global(.now-fresh) & {
    margin: 0;
    grid-column: span 4;
    color: $now-brown;
    @include font-sizes(32px, 32px, 40px, 67px);
    @include line-heights(1.037, 1.037, 0.9697, 1);
  }

  :global(.petcurean) & {
    @include font-sizes(44px, 101px, 101px, 101px);
    @include line-heights(47px, 96px, 96px, 96px);
  }
}

.subhead {
  a {
    :global(.gather) &,
    :global(.petcurean) & {
      text-decoration: underline;
    }

    svg {
      display: none;
    }
  }

  @include p2();
  margin-top: 8px;

  @include at-breakpoint(medium) {
    margin-top: 16px;
  }

  @include at-breakpoint(large) {
    @include p1();
  }

  :global(.gather) & {
    @include font-sizes(16px, 19px, 19px, 19px);
    @include line-heights(28px, 36px, 36px, 36px);
    font-weight: 300;
    width: 100%;

    @include at-breakpoint(large) {
      width: 90%;
    }

    @include at-breakpoint(x-large) {
      width: 100%;
    }
  }

  :global(.go-solutions) & {
    p,
    a {
      color: $color-white;
    }

    a {
      text-decoration: underline;
    }

    @include at-breakpoint(large) {
      margin-inline-end: 5px;
    }

    @include at-breakpoint(x-large) {
      margin-inline-end: 0;
    }
  }

  :global(.now-fresh) & {
    margin-top: 8px;

    p,
    a {
      @include font-sizes(16px, 18px, 18px);
    }

    a {
      text-decoration: underline;
    }

    @include at-breakpoint(large) {
      margin-top: 24px;
    }
  }

  :global(.petcurean) & {
    p,
    a {
      color: $petcurean-white;
    }

    a {
      text-decoration: underline;
    }

    @include font-sizes(16px, 21px, 21px, 21px);
    @include line-heights(26px, 37px, 37px, 37px);
    width: 100%;

    @include at-breakpoint(large) {
      width: 80%;
    }
  }
}

.ctas {
  margin-top: 32px;

  @include at-breakpoint(medium) {
    margin-top: 28px;
  }

  span {
    :global(.gather) & {
      font-size: 16px;
      line-height: 16px;
    }

    :global(.petcurean) & {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 1px;
    }
  }

  :global(.now-fresh) & {
    margin-top: 24px;

    @include at-breakpoint(medium) {
      margin-top: 20px;
    }
  }

  &.no-ctas {
    :global(.petcurean) & {
      margin: 0;
    }
  }
}

.cta {
  display: inline-block;
  margin: 8px 24px 8px 0;

  :global(.locale-he) & {
    margin: 8px 0 8px 24px;
  }

  @include at-breakpoint(medium) {
    margin: 12px 24px 12px 0;

    :global(.locale-he) & {
      margin: 12px 0 12px 24px;
    }
  }

  &:last-of-type {
    margin-inline-end: 0;
  }

  :global(.now-fresh) & {
    margin-inline-end: 16px;
  }
}

.bg-image {
  grid-column: span 6;

  picture {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }

  :global(.gather) & {
    position: absolute;
    top: 200px;
    left: -100px;
    width: calc(($max-width - 400px));
    height: 550px;

    @include at-breakpoint(medium) {
      top: 300px;
      left: -150px;
      height: 1000px;
      width: calc(($max-width - 100px));
    }

    @include at-breakpoint(large) {
      right: calc(($max-width / 2) - 850px);
      top: -150px;
      height: 800px;
      width: calc(8 / 12 * 100%);
      left: auto;
    }

    @include at-breakpoint(x-large) {
      right: calc(($max-width / 2) - 860px);
      height: 1007px;
      width: 920px;
    }

    picture,
    img {
      height: 100%;
      width: 100%;
    }
  }

  :global(.go-solutions) & {
    grid-row: 1;
    margin: 0 -38px -160px 0;

    :global(.locale-he) & {
      margin: 0 0 -160px -38px;
    }

    img {
      width: 370px;
    }

    @include at-breakpoint(medium) {
      margin: 0 0 -250px 0;
      padding-top: 17px;

      //test if this applies in hebrew

      img {
        width: 450px;
      }
    }

    @include at-breakpoint(large) {
      grid-row: auto;
      margin: 0 -80px 0 -12px;
      padding-top: 10px;

      :global(.locale-he) & {
        margin: 0 -12px 0 -80px;
      }

      img {
        width: 100%;
      }
    }

    @include at-breakpoint(x-large) {
      margin: 0;
      padding-inline-start: 50px;
    }
  }

  :global(.now-fresh) & {
    grid-column: span 6;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: none;

    @include at-breakpoint(medium) {
      display: block;
    }

    picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      display: block;
    }
  }

  :global(.petcurean) & {
    position: relative;
    padding-top: 50px;

    @include at-breakpoint(large) {
      position: absolute;
      padding-top: 70px;
      bottom: 0;
      right: -380px;
      height: 760px;
    }

    @include at-breakpoint(x-large) {
      padding-top: 40px;
      right: -270px;
    }

    picture {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
    }

    img {
      width: 85%;

      @include at-breakpoint(medium) {
        width: 720px;
      }

      @include at-breakpoint(large) {
        width: 826px;
        height: 100%;
      }
    }
  }
}

.accent {
  :global(.petcurean) & {
    position: absolute;
    width: 400px;
    transform: rotate(-25deg);
    left: -250px;

    @include at-breakpoint(medium) {
      left: -350px;
      transform: rotate(-5deg);
      width: 700px;
    }

    @include at-breakpoint(large) {
      top: 30px;
      left: -430px;
      transform: rotate(-80deg);
      width: 700px;
    }

    @include at-breakpoint(x-large) {
      top: 0;
      left: calc((100% - $max-width) / 2 - 650px);
      transform: rotate(45deg);
      width: 750px;
    }
  }
}

// if hasVerboseCopy prop is enabled
.has-verbose-copy-container {
  :global(.gather) & {
    margin-bottom: 150px;

    @include at-breakpoint(medium) {
      margin-bottom: 300px;
    }

    @include at-breakpoint(large) {
      margin-bottom: 70px;
    }

    @include at-breakpoint(x-large) {
      margin-bottom: 30px;
    }

    .content-wrapper {
      padding: 40px 0;

      @include at-breakpoint(medium) {
        padding: 80px 0;
      }

      @include at-breakpoint(large) {
        padding: 75px 0;
      }

      @include at-breakpoint(x-large) {
        padding: 140px 0;
      }
    }

    .bg-image {
      top: 350px;

      @include at-breakpoint(medium) {
        top: 550px;
        right: calc(($max-width / 2) - 1050px);
        height: 900px;
      }

      @include at-breakpoint(large) {
        top: -150px;
        height: 1007px;
      }
    }
  }
}
