
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include default-margin-bottom();
  position: relative;
  color: $color-white;
  overflow: hidden;

  :global(.go-solutions) & {
    background-color: $go-charcoal;
  }

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
  }

  :global(.petcurean) & {
    background-color: $petcurean-grey-light;
  }
}

.background-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 214px;

  :global(.locale-he) & {
    left: auto;
    right: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  @include at-breakpoint(medium) {
    width: 266px;
  }

  @include at-breakpoint(large) {
    width: 316px;
  }

  @include at-breakpoint(x-large) {
    width: 454px;
  }
}

.content {
  position: relative;
  display: flex;
  max-width: $max-width;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 64px 0 54px 0;
  z-index: 1;

  @include at-breakpoint(medium) {
    padding: 80px 0 70px;
  }

  :global(.now-fresh) & {
    padding: 48px;

    @include at-breakpoint(large) {
      padding: 64px;
    }
  }

  :global(.petcurean) & {
    padding: 54px 14px 50px 16px;

    @include at-breakpoint(medium) {
      padding: 65px 27px 66px 27px;
    }

    @include at-breakpoint(large) {
      padding: 80px 113px 56px 113px;
    }

    @include at-breakpoint(large) {
      padding-top: 120px;
    }
  }
}

.title {
  @include h4();
  margin-bottom: 22px;

  :global(.go-solutions) & {
    text-transform: uppercase;
    padding: 0 54px;

    @include at-breakpoint(medium) {
      width: 432px;
      padding: 0;
    }

    @include at-breakpoint(large) {
      width: 768px;
      margin-bottom: 30px;
    }

    @include at-breakpoint(x-large) {
      width: 864px;
    }
  }

  :global(.now-fresh) & {
    @include font-sizes(25px, 30px, 36px);
    @include line-heights(1.081, 1.081, 1.1579);
    color: $now-brown;

    @include at-breakpoint(large) {
      width: 616px;
    }

    @include at-breakpoint(x-large) {
      width: 648px;
    }
  }

  :global(.locale-ru) & {
    line-height: 1.25;
  }

  :global(.petcurean) & {
    @include font-sizes(22px, 30px, 32px, 32px);
    @include line-heights(1.2, 1.06, 1.125, 1.125);

    text-align: center;
    font-weight: 900;
    margin-bottom: 40px;
  }
}

.cta-container {
  display: flex;

  @include at-breakpoint(small) {
    flex-direction: column;
    width: 100%;

    :global(.now-fresh) & {
      width: auto;
    }
  }

  @include at-breakpoint(medium) {
    :global(.gather) &,
    :global(.summit) &,
    :global(.petcurean) & {
      flex-direction: column;
      width: 100%;
    }
  }

  @include at-breakpoint(large) {
    :global(.gather) &,
    :global(.summit) &,
    :global(.petcurean) & {
      width: auto;
      flex-direction: row;
    }
  }
}

.btn {
  margin: 10px;
  * {
    :global(.gather) & {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
    }

    :global(.now-fresh) & {
      font-size: 18px;
      line-height: 16px;
    }

    :global(.petcurean) & {
      font-size: 15px;
      font-weight: 600;
      line-height: 16px;
    }
  }
}

/* Gather Styles */
:global(.gather) {
  .container-light {
    background-color: $gather-cream;

    .title {
      color: $gather-black;
    }
  }

  .container-dark {
    background: url('/gather/transitionCta.jpg') center no-repeat;
    background-size: cover;
    background-color: $gather-green;

    .title {
      color: $gather-white;
    }
  }

  .content {
    @include at-breakpoint(medium) {
      max-width: 90%;
      justify-content: space-between;
    }

    @include at-breakpoint(large) {
      flex-direction: column;
      max-width: $max-width;
    }
  }

  .title {
    @include at-breakpoint(small) {
      max-width: 345px;
    }

    @include at-breakpoint(medium) {
      text-align: center;
      max-width: 440px;
    }

    @include at-breakpoint(large) {
      text-align: center;
      max-width: 784px;
    }
  }
}

/* Petcurean Styles */
:global(.petcurean) {
  .container-light {
    background-color: $petcurean-white;

    .title {
      color: $petcurean-orange;
    }
  }

  .container-dark {
    background-color: $petcurean-orange;

    .title {
      color: $petcurean-white;
    }
  }

  .content {
    @include at-breakpoint(medium) {
      max-width: 95%;
      justify-content: space-between;
    }

    @include at-breakpoint(large) {
      flex-direction: column;
      max-width: $max-width;
    }
  }

  .title {
    @include at-breakpoint(small) {
      max-width: 345px;
    }

    @include at-breakpoint(medium) {
      text-align: center;
      max-width: 440px;
    }

    @include at-breakpoint(large) {
      text-align: center;
      max-width: 784px;
    }
  }
}

/* Summit Styles */
:global(.summit) {
  .container-light {
    background-color: $summit-white;

    .title {
      color: $summit-blue;
    }
  }

  .container-dark {
    background-color: $summit-blue;

    .title {
      color: $summit-white;
    }
  }

  .content {
    // override title fonts
    .title {
      font-family: 'Bloc Pro Heavy', sans-serif;
      font-size: 35px;
      line-height: 40px;
      letter-spacing: 2px;
    }

    @include at-breakpoint(medium) {
      max-width: 90%;
      justify-content: space-between;
    }

    @include at-breakpoint(large) {
      flex-direction: column;
      max-width: $max-width;
    }
  }

  .title {
    @include at-breakpoint(small) {
      max-width: 345px;
      text-align: center;
    }

    @include at-breakpoint(medium) {
      text-align: center;
      max-width: 440px;
    }

    @include at-breakpoint(large) {
      text-align: center;
      max-width: 784px;
    }
  }

  .btn {
    font-size: 18px;
    letter-spacing: 1.5px;
    line-height: 16px;
  }
}
