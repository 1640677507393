
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  width: 100%;
  margin: 0 0 64px;

  :global(.now-fresh) & {
    margin: 0;
    background-color: $color-white;
  }
}

.category {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  padding: 24px 0;

  @include at-breakpoint(large) {
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
  }

  &.full-width {
    @include at-breakpoint(large) {
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 24px;
    }
  }

  :global(.now-fresh) & {
    padding: 0;
    column-gap: 0;
  }
}

.category-unique {
  padding: 48px 0;

  @include at-breakpoint(large) {
    padding: 24px 0;
  }

  :global(.now-fresh) & {
    padding: 0;
  }
}

.headline,
.filtered-headline {
  @include p2;
  @include bold;
  text-align: center;
  margin: 32px 0 8px;
  grid-column: span 6;

  :global(.go-solutions) & {
    color: $color-black;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    margin: 0;
    padding: 40px 0 16px 40px;
    background-color: $now-tan;
    font-size: 20px;
    font-family: 'Stag', sans-serif !important;
    font-weight: 400;
  }

  @include at-breakpoint(large) {
    grid-column: span 8;
    text-align: start;
  }
}

.description,
.filtered-description {
  @include p3;
  text-align: center;
  margin-bottom: 44px;
  grid-column: span 6;
  position: relative;

  :global(.go-solutions) & {
    color: $go-semidark-grey;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    margin-bottom: 0;
    padding: 0 0 40px 40px;
    background-color: $now-tan;
    border-bottom: 1px solid $now-brown;
    font-size: 16px;
  }

  @include at-breakpoint(medium) {
    margin-bottom: 54px;
  }

  @include at-breakpoint(large) {
    grid-column: span 8;
    margin-bottom: 44px;
    text-align: start;
  }

  &::after {
    content: '';
    width: 50px;
    height: 1px;
    position: absolute;
    top: calc(100% + 14px);
    left: 50%;
    transform: translate(-50%, 0);

    :global(.go-solutions) & {
      background-color: $go-gray;
    }

    :global(.now-fresh) & {
      display: none;
    }

    @include at-breakpoint(medium) {
      width: 100%;
      top: calc(100% + 18px);
      left: 0;
      transform: none;
    }
  }
}

.headline,
.description {
  &.full-width {
    grid-column: 1/-1;
  }
}

.filtered-headline,
.filtered-description {
  display: none;

  @include at-breakpoint(large) {
    display: block;
  }
}

.product-container {
  grid-column: span 6;
  text-align: center;
  margin: 0 0 64px;

  @include at-breakpoint(medium) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: span 3;
    margin: 0 0 48px;
  }

  @include at-breakpoint(large) {
    grid-column: span 4;
  }

  :global(.now-fresh) & {
    justify-content: flex-end;
    margin: 0;
    border-bottom: 1px solid $now-brown;
    margin-bottom: -1px;

    @include at-breakpoint(medium) {
      margin-bottom: 0;
      &:nth-child(odd) {
        border-right: 1px solid $now-brown;
      }

      &:nth-last-child(2):nth-child(odd) {
        border-bottom: none;
      }

      &:nth-last-child(1) {
        border-bottom: none;
      }

      :global(.locale-he) & {
        &:nth-child(odd) {
          border-left: 1px solid $now-brown;
        }
      }
    }
  }
}

.product {
  position: relative;

  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }

  :global(.now-fresh) & {
    &:hover {
      transform: none;
    }
  }
}

.img-container {
  width: 100%;
  position: relative;

  @include at-breakpoint(medium) {
    padding-top: 100%;
  }

  @include at-breakpoint(large) {
    padding-top: 84%;
  }

  :global(.go-solutions) & {
    :global(.locale-zh) & {
      @include at-breakpoint(large) {
        margin-bottom: 32px;
      }

      @include at-breakpoint(x-large) {
        margin-bottom: 16px;
      }
    }
  }

  :global(.now-fresh) & {
    padding-top: 0;
    :global(.locale-zh) & {
      @include at-breakpoint(large) {
        margin-bottom: 32px;
      }

      @include at-breakpoint(x-large) {
        margin-bottom: 16px;
      }
    }
  }
}

.img-wrapper {
  position: relative;
  width: 72%;
  margin: 0 auto;

  @include at-breakpoint(medium) {
    position: absolute;
    display: flex;
    align-items: flex-end;
    bottom: 0;
    left: 0;
    right: 0;
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-height: 100%;
  }

  @include at-breakpoint(large) {
    width: 56%;
  }
  opacity: 0;
  transition: opacity 400ms ease-in;

  :global(.now-fresh) & {
    position: static;
    display: block;
    width: 100%;
    height: 520px;
  }
}

.anim-in {
  opacity: 1;
}

.prod-img {
  width: 100%;

  :global(.now-fresh) & {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.badge {
  box-shadow: 0 4px 4px $go-light-opaque-black;
  position: absolute;
  top: -24px;
  left: 8px;
  width: 88px;
  height: 88px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include at-breakpoint(large) {
    top: -18px;
    left: -32px;
  }

  @include at-breakpoint(x-large) {
    top: -18px;
    left: -16px;
  }

  :global(.go-solutions) & {
    background-color: $color-white;
  }

  :global(.now-fresh) & {
    top: 70px;
    left: calc(100% / 4.5);

    @include at-breakpoint(medium) {
      left: calc(100% / 4);
    }
    background-color: $color-white;
  }

  :global(.summit) & {
    background-color: $summit-blue;
    color: $summit-white;
  }
}

.badge-border {
  background-color: transparent;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  :global(.go-solutions) & {
    border: 3px solid $go-orange;
  }

  :global(.now-fresh) & {
    border: 3px solid $now-grey;
  }
}

.badge-content {
  @include bold();
  letter-spacing: 1px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  width: 80%;

  :global(.go-solutions) & {
    font-size: 10px;
    line-height: 16px;
    @include bold();
  }

  :global(.now-fresh) & {
    font-family: Veneer, sans-serif;
    font-size: 15px;
    line-height: 17px;
    font-weight: normal;
    text-transform: none;

    @each $locale, $font-stack in $nf-secondary-fonts {
      .locale-#{$locale} & {
        font-family: $font-stack;
      }
    }
  }

  :global(.summit) & {
    @include eyebrow();
  }
}

.prod-rating {
  margin: 16px 0;
  display: flex;
  align-items: center;

  :global(.now-fresh) & {
    background-color: $color-white;
    margin: 0;
    padding: 16px 0;
  }
}

.no-reviews {
  display: none;

  & ~ div {
    margin-top: 10px;
  }

  @include at-breakpoint(medium) {
    display: flex;

    & ~ div {
      margin-top: 0;
    }
  }
}

.rating {
  margin: 0 auto;
  height: 12px;

  span {
    width: 12px;
    margin: 0 4px;
  }
}

.prod-category {
  width: 100%;
  padding: 0 8px;

  :global(.go-solutions) & {
    color: $go-semidark-grey;
    margin-bottom: 8px;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    background-color: $color-white;
    padding-bottom: 12px;
  }

  :global(.summit) & {
    color: $summit-blue;
    margin-bottom: 8px;
  }

  p {
    font-weight: 700;
    @include eyebrow();

    :global(.go-solutions) & {
      font-size: 12px;
      text-transform: capitalize;
      letter-spacing: 0;
    }

    :global(.summit) & {
      font-size: 18px !important;
    }
  }
}

.prod-title {
  width: 88%;
  margin: 0 auto 8px;
  padding: 0 8px;

  :global(.go-solutions) & {
    color: $color-black;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    background-color: $color-white;
    width: 100%;
    margin: 0;
    margin-top: 16px;

    @include at-breakpoint(medium) {
      margin-top: 0;
    }

    h2 {
      line-height: 1.5em;
      text-transform: none;
    }
  }

  h2 {
    text-transform: uppercase;
    @include h5;

    :global(.locale-ru) & {
      line-height: 1.25;
    }

    :global(.now-fresh) & {
      font-size: 16px;
      @include at-breakpoint(large) {
        font-size: 20px;
      }
    }
  }
}

.prod-packaging {
  width: 100%;
  padding: 0 8px;

  :global(.go-solutions) & {
    color: $go-semidark-grey;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    background-color: $color-white;
  }

  p {
    @include p3;
  }
}

.cta {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &:hover,
  &:focus {
    @include at-breakpoint(large) {
      span {
        display: block;
      }
    }
  }

  span {
    display: none;
    margin-top: 70%;
  }
}

.cta-btn {
  :global(.go-solutions) & {
    &:hover {
      background-color: $go-yellow;
      color: $color-black;
    }
  }

  :global(.now-fresh) & {
    &:hover {
      background-color: $now-grey;
    }
  }

  :global(.summit) & {
    &:hover {
      background-color: $summit-blue;
    }
  }
}

.wtb-cta {
  align-self: center;

  :global(.now-fresh) & {
    padding: 16px 0;
    background-color: $color-white;
    display: block;
    width: 100%;

    & > button {
      border: none;
      border-bottom: 2px solid $now-brown;
      border-radius: 0;
      padding: 0;
      height: 85%;
      padding: 1px;

      &:hover {
        background-color: $color-white;
        border-width: 3px;
        font-weight: 500;
      }
    }
  }

  :global(.summit) & {
    margin-top: 10px;
  }
}
