
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include page-container();

  :global(.gather) & {
    overflow: hidden;
    position: relative;
  }
}

.benefits-image-text {
  margin-top: 80px;

  @include at-breakpoint(large) {
    margin-top: 120px;
  }

  @include at-breakpoint(x-large) {
    margin-top: 128px;
  }

  :global(.now-fresh) & {
    margin: 0;
    max-width: none;
    width: auto;
    padding: 0 !important;
    margin-bottom: 1px;
  }
}

.philosophy-styles {
  :global(.gather) & {
    h2 {
      color: $gather-green;
    }
  }
}

.benefits {
  @include willAnimateIn();

  @include margin-container();
  margin-bottom: 80px;

  @include at-breakpoint(medium) {
    margin-bottom: 90px;
  }

  @include at-breakpoint(large) {
    margin-bottom: 100px;
  }

  &.anim-in {
    @include animateIn();
  }

  :global(.go-solutions) & {
    margin-top: -20px;

    @include at-breakpoint(medium) {
      margin-top: -32px;
    }

    @include at-breakpoint(large) {
      margin-top: -60px;
    }

    @include at-breakpoint(x-large) {
      margin-top: 0;
    }
  }

  :global(.now-fresh) & {
    transform: none;
    margin: 0;
    max-width: none;
    width: auto;
    padding: 0 !important;
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
  }
}
