
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  h1 {
    margin-top: 0;
  }

  :global(.now-fresh) & {
    outline: 1px solid $now-brown;
    margin-bottom: 1px;
    overflow: hidden;
  }
}

.widget {
  :global(.now-fresh) & {
    padding: 0 0 60px 0;
    background-color: $color-white;
    @include at-breakpoint(medium) {
      padding: 30px 0 60px 0;
    }
  }
}
