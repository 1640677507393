
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  text-align: start;
}

.error {
  .wrapper {
    box-shadow: 0px 0px 0px 2px $now-grey inset;
    color: $color-dark-red;

    :global(.go-solutions) & {
      background-color: $go-pink;
    }

    :global(.now-fresh) & {
      background-color: $now-transparent-red;
      box-shadow: 0px 0px 0px 2px $now-red inset;
      border-color: $now-red;
      color: $now-red;
    }
  }

  .input {
    color: $color-dark-red;
    // Brands added for specificity
    :global(.go-solutions) & {
      color: $color-dark-red;
    }

    :global(.now-fresh) & {
      color: $now-red;
    }
  }

  .error-msg {
    display: block;

    :global(.summit) & {
      @include errormsg();
    }
  }
}

.wrapper {
  display: flex;
  // Visually the label comes before the input.
  // In the markup the label is placed after the input
  // to be able to style it based on the input focus.
  // That's why we reverse the order.
  flex-flow: column-reverse;
  padding: 0 16px;
  width: 100%;
  min-height: 56px;
  border-radius: 8px;

  &:focus-within {
    box-shadow: 0px 0px 0px 2px $outline inset;
  }

  :global(.gather) & {
    background-color: rgba($gather-black, 0.05);
  }

  :global(.go-solutions) & {
    background-color: rgba($go-light-grey, 0.25);

    &:focus-within {
      box-shadow: 0px 0px 0px 2px $color-white inset;
    }
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
    border-radius: 0;

    &:focus-within {
      box-shadow: 0px 0px 0px 2px $now-brown inset;
    }
  }

  :global(.petcurean) & {
    background-color: rgba($petcurean-grey, 0.05);
  }

  :global(.summit) & {
    background-color: rgba($summit-black, 0.05);
  }
}

.input {
  background: none;
  border: 0;
  line-height: 24px;
  padding: 0;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  box-shadow: none;

  // Visually, the label will be the placeholder for the input
  &::placeholder {
    color: transparent;
  }

  // When we haven't typed anything this places the input vertically centered
  // (with the label in the same position as the placeholder
  &:not(:focus):placeholder-shown {
    transform: translateY(-10px);
  }

  // When we start typing the input changes to be a little lower (with the label a little higher top)
  &:focus {
    transform: translateY(0);
  }

  &::-webkit-search-cancel-button {
    appearance: none;
  }

  :global(.go-solutions) & {
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-weight: 300;
  }
}

.label {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  display: flex;

  :global(.gather) & {
    letter-spacing: normal;
    font-weight: 700;
  }

  :global(.go-solutions) & {
    font-weight: 700;
  }

  :global(.now-fresh) & {
    font-weight: 500;
  }

  :global(.petcurean) & {
    letter-spacing: normal;
    font-weight: 700;
  }

  :global(.summit) & {
    letter-spacing: normal;
    font-weight: 700;
  }

  .max-length {
    margin-inline-start: auto;
    padding-inline-start: 5px;
  }
}

// When we haven't typed anything this places the label vertically centered as the input placeholder
// (with the input in the same position)
.input:not(:focus):placeholder-shown + .label {
  font-size: 16px;
  line-height: 24px;
  transform: translateY(16px);

  :global(.gather) & {
    letter-spacing: normal;
    font-weight: 300;
  }

  :global(.go-solutions) & {
    font-weight: 400;
  }

  :global(.now-fresh) & {
    font-weight: 300;
  }

  :global(.petcurean) & {
    font-weight: 300;
  }

  :global(.summit) & {
    @include p2();
  }
}

// When we start typing the label changes to be a little higher (with the input a little lower on the bottom)
.input:focus + .label {
  transform: translateY(0);
}

.error-msg {
  @include errormsg;
}
