
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding: 0 16px 60px;

  @include at-breakpoint(medium) {
    padding: 0 32px 80px;
  }

  @include at-breakpoint(large) {
    grid-column: 2/3;
    max-width: 640px;
    padding: 64px 44px 64px 16.6%;
    align-self: center;
    height: auto;

    :global(.locale-he) & {
      padding: 64px 16.6% 64px 44px;
    }
  }

  @include at-breakpoint(x-large) {
    :global(.gather) & {
      // half the grid plus the padding
      max-width: calc(($max-width / 2) + 44px);
      // padding left equivalent to 1 col;
      padding: 64px 44px 64px calc($max-width / 12);
    }
  }

  :global(.summit) & {
    color: $summit-black;
  }
}

.eyebrow {
  @include eyebrow-box();
  display: block;
  margin-bottom: 16px;

  :global(.now-fresh) & {
    font-size: 16px;
  }

  :global(.gather) & {
    color: $gather-green;
    margin-bottom: 8px;
  }

  :global(.summit) & {
    color: $summit-blue;
    margin-bottom: 5px;
  }
}

.headline {
  @include h3();
  margin-top: 0;
  margin-bottom: 0;

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.go-solutions) & {
    @include at-breakpoint(large) {
      font-size: 40px;
    }
  }

  :global(.summit) & {
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.rating-container {
  display: flex;
  align-items: center;
  margin-top: 21px;
}

.rating {
  height: 16px;

  span {
    width: 16px;
    margin-inline-end: 4px;
  }

  :global(.go-solutions) &,
  :global(.gather) &,
  :global(.summit) & {
    svg {
      path {
        fill: $go-yellow;
      }
    }
  }

  :global(.now-fresh) & {
    svg {
      path {
        fill: $now-brown;
      }
    }
  }
}

.reviews {
  margin-inline-start: 6px;

  &:after {
    bottom: 0;
  }

  p {
    @include p3();
    @include bold();
  }

  :global(.gather) & {
    color: $gather-black;
  }
}

.description {
  @include p2();
  margin-top: 30px;
  margin-bottom: 16px;

  :global(.gather) & {
    @include font-sizes(16px, 19px, 19px);
    @include line-heights(28px, 36px, 36px);
    font-weight: 300;
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }

  :global(.summit) & {
    line-height: 30px;
    font-weight: 300;
  }
}

.packages {
  @include p3();
  margin-bottom: 20px;

  :global(.gather) & {
    @include p3(true);
    font-weight: 700;

    @include at-breakpoint(medium) {
      @include p2(true);
    }
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }

  :global(.summit) & {
    @include p2(true);
    font-weight: 600;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  @include p3();
  padding: 4px 8px;
  margin: 4px 4px 0 0;

  :global(.locale-he) & {
    margin: 4px 0 0 4px;
  }

  :global(.gather) & {
    color: $gather-white;
    background-color: $gather-green;
    font-weight: 700;
  }

  :global(.go-solutions) & {
    color: $color-white;
    background-color: $color-black;
  }

  :global(.now-fresh) & {
    color: $color-white;
    background-color: $now-brown;
    font-size: 16px;
    font-weight: 500;
  }

  :global(.summit) & {
    color: $summit-white;
    background-color: $summit-blue;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.buttons-container {
  margin-top: 40px;
  display: flex;

  a {
    &:first-child {
      margin-inline-end: 12px;

      :global(.summit) & {
        text-align: center;
      }

      &:hover {
        :global(.go-solutions) & {
          background-color: $go-light-yellow;
          color: $color-black;
        }
      }
    }

    :global(.now-fresh) & {
      font-weight: normal;
    }
  }

  button {
    &:hover {
      :global(.go-solutions) & {
        color: $go-yellow;
      }
    }
  }

  svg {
    display: none;
  }

  @include at-breakpoint(large) {
    margin-top: 36px;
  }
}

.static-form {
  @include visually-hidden;
}

.buy-online-container {
  margin-top: 40px;

  @include at-breakpoint(large) {
    margin-top: 36px;
  }
}

.buy-online-link-list {
  display: flex;
  flex-wrap: wrap;

  .buy-online-link-container {
    margin-bottom: 8px;
    padding: 5px 0;
    display: flex;
  }

  .buy-online-link {
    margin-inline-end: 16px;
    display: flex;

    > span {
      margin: 0;
      display: flex;
      align-items: center;

      .link-icon {
        margin: 0;
        margin-inline-end: 5px;
      }
    }

    svg {
      align-self: flex-end;
      margin: 0 0 3px 5px;

      :global(.locale-he) & {
        margin: 0 5px 3px 0;
      }
    }
  }
}

// overrides
:global(.now-fresh) {
  .reviews {
    p {
      font-size: 16px;
    }
  }
}

:global(.summit) {
  .reviews {
    color: $summit-black;

    p {
      @each $locale, $font-stack in $summit-tertiary-fonts {
        :global(.locale-#{$locale}) & {
          font-family: $font-stack;
        }
      }
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      margin-bottom: 5px;
    }
  }
}
