
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.placeholder {
  :global(.go-solutions) & {
    background-color: $go-light-grey-opaquer;
  }

  :global(.now-fresh) & {
    background-color: $now-light-tan;
  }
}

.placeholder-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.image-wrapper {
  opacity: 0;
  transition: opacity 400ms ease-in;
}
