
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.link {
  &:focus {
    outline: none;

    & > .wrapper {
      box-shadow: 0 0 2px 2px $outline;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.wrapper {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;

  &:focus {
    outline: none;
  }
}

:global(.go-solutions) {
  @media (hover: hover) {
    .wrapper:hover {
      svg {
        path {
          fill: $go-yellow;
        }
      }
    }
  }
}

.link {
  svg {
    margin: -15px 0 0 5px;

    :global(.locale-he) & {
      margin: -15px 0px 0 0;
      transform: scaleX(-1);
    }
  }

  :global(.now-fresh) & {
    span {
      font-family: 'Stag', sans-serif;
      font-weight: 400;

      &:hover {
        font-weight: 500;
      }
    }

    svg {
      margin-top: -10px;
    }
  }
}
