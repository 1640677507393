
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  @include flex-container();
  flex-direction: column;
  @include default-margin-bottom();

  :global(.now-fresh) & {
    max-width: none;
    margin: 0 0 1px 0;
    width: auto;
    outline: 1px solid $now-brown;
    color: $now-brown;
    padding: 30px;

    @include at-breakpoint(large) {
      padding: 80px;
    }
  }

  :global(.summit) & {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.top {
  margin-bottom: 35px;

  & * {
    margin-bottom: 15px;
  }
}

.eyebrow {
  @include eyebrow-box();
  font-weight: 600;

  :global(.gather) & {
    font-size: 14px;
    line-height: 114%;
    color: $gather-green;
    font-weight: 700;
    margin-bottom: 8px;
  }

  :global(.petcurean) & {
    line-height: 114%;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  :global(.summit) & {
    @include eyebrow();
  }
}

.header {
  @include h3();

  :global(.gather) & {
    @include line-heights(132%, 113%, 113%, 113%);
    @include font-sizes(28px, 45px, 45px, 45px);
    font-weight: 400;
  }

  :global(.now-fresh) & {
    @include font-sizes(25px, 50px, 50px);
  }

  :global(.petcurean) & {
    color: $petcurean-orange;
    @include line-heights(114%, 111%, 111%, 111%);
    @include font-sizes(28px, 45px, 45px, 45px);
    font-weight: 600;
  }

  :global(.summit) & {
    color: $summit-blue;
    @include h3();
  }
}

.description {
  :global(.gather) & {
    font-weight: 300;
    @include font-sizes(16px, 19px, 19px, 19px);
    @include line-heights(175%, 189%, 189%, 189%);
    @include font-sizes(16px, 21px, 21px, 21px);
  }

  :global(.now-fresh) & {
    @include p2();
  }

  :global(.petcurean) & {
    font-weight: 300;
    @include line-heights(162%, 176%, 176%, 176%);
    @include font-sizes(16px, 21px, 21px, 21px);
  }

  :global(.summit) & {
    @include p2();

    @include at-breakpoint(medium) {
      @include p1();
    }
  }
}

.list-items {
  display: flex;
  margin-bottom: 40px;
  align-items: center;

  :global(.summit) & {
    margin-bottom: 32px;

    @include at-breakpoint(medium) {
      margin-bottom: 38px;
    }
  }
}

.list-icon {
  width: 50px;
  height: 50px;
  margin-inline-end: 16px;
  display: flex;
  justify-content: center;

  @include at-breakpoint(medium) {
    margin-bottom: 16px;
  }

  :global(.now-fresh) & {
    width: 40px;
    height: 40px;
  }

  svg * {
    :global(.gather) & {
      max-height: 40px;
      width: auto;
      fill: $gather-green;
    }

    :global(.now-fresh) & {
      width: auto;
      fill: $now-brown;
    }

    :global(.petcurean) & {
      fill: $petcurean-orange;
    }

    :global(.summit) & {
      max-height: 40px;
      width: auto;
      fill: $summit-blue;
    }
  }
}

.list-items-text {
  width: 90%;
  :global(.gather) & {
    @include font-sizes(18px, 22px, 22px, 22px);
    font-weight: 600;
    font-weight: 400;
    @include line-heights(133%, 127%, 127%, 127%);
  }

  :global(.now-fresh) & {
    @include p2();
  }

  :global(.petcurean) & {
    font-weight: 600;
    @include line-heights(133%, 127%, 127%, 127%);
    @include font-sizes(18px, 22px, 22px, 22px);
  }

  :global(.summit) & {
    @include h5();

    @include at-breakpoint(medium) {
      @include h4();
    }
  }
}
