
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  text-align: start;
}

.error {
  .wrapper {
    border: 2px solid $now-grey;
    color: $color-dark-red;

    :global(.go-solutions) & {
      background-color: $go-pink;
    }

    :global(.now-fresh) & {
      background-color: $now-transparent-red;
      color: $now-red;
      border-color: $now-red;
      box-shadow: 0px 0px 0px 2px $now-red inset;
    }

    .label {
      :global(.go-solutions) & {
        background-color: $go-pink;
      }
    }
  }

  .textarea {
    color: $color-dark-red;

    :global(.go-solutions) & {
      color: $color-dark-red;
    }

    :global(.no-fresh) & {
      color: $color-dark-red;
    }
  }

  .error-msg {
    display: block;

    :global(.summit) & {
      @include errormsg();
    }
  }
}

.wrapper {
  width: 100%;
  min-height: 56px;
  border-radius: 8px;
  position: relative;

  &:focus-within {
    box-shadow: 0px 0px 0px 2px $outline inset;
  }

  :global(.gather) & {
    background-color: rgba($gather-black, 0.05);
  }

  :global(.go-solutions) & {
    background-color: rgba($go-light-grey, 0.25);

    &:focus-within {
      box-shadow: 0px 0px 0px 2px $color-white inset;
    }
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
    border-radius: 0;

    &:focus-within {
      box-shadow: 0px 0px 0px 2px $now-brown inset;
    }
  }

  :global(.petcurean) & {
    background-color: rgba($petcurean-grey, 0.05);
  }

  :global(.summit) & {
    background-color: rgba($summit-black, 0.05);
  }
}

.textarea {
  background: none;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  min-height: 112px;
  border: 0;
  line-height: 24px;
  text-overflow: ellipsis;

  // Visually, the label will be the placeholder for the input
  &::placeholder {
    color: transparent;
  }

  // When we start typing the input changes to be a little lower (with the label a little higher top)
  &:focus {
    transform: translateY(0);
  }

  &::-webkit-search-cancel-button {
    appearance: none;
  }

  :global(.go-solutions) & {
    color: $color-white;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-weight: 300;
  }

  :global(.locale-ru) & {
    min-height: 140px;
  }
}

.label {
  position: absolute;
  top: 16px;
  left: 0;
  margin: 0;
  padding: 0 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  display: flex;

  :global(.locale-he) & {
    left: auto;
    right: 0;
  }

  :global(.gather) & {
    letter-spacing: normal;
    font-weight: 700;
  }

  :global(.go-solutions) & {
    font-weight: 700;
  }

  :global(.now-fresh) & {
    font-weight: 500;
  }

  :global(.petcurean) & {
    letter-spacing: normal;
    font-weight: 700;
  }

  :global(.summit) & {
    @include p2();
    letter-spacing: normal;
  }

  .max-length {
    margin-inline-start: auto;
    padding-inline-start: 5px;
  }
}

// When we haven't typed anything this places the label vertically centered as the input placeholder
// (with the input in the same position)
.textarea:not(:focus):placeholder-shown + .label {
  font-size: 16px;
  line-height: 24px;

  :global(.gather) & {
    font-weight: 300;
  }

  :global(.go-solutions) & {
    font-weight: 400;
  }

  :global(.now-fresh) & {
    font-weight: 300;
  }

  :global(.petcurean) & {
    font-weight: 300;
  }

  :global(.summit) & {
    font-weight: 400;
  }
}

// When we start typing the label changes to be a little higher (with the input a little lower on the bottom)
.textarea:focus + .label {
  top: 0;
  padding: 6px 14px 0;
  width: 100%;
  border-radius: 8px 8px 0 0;

  :global(.go-solutions) & {
    background-color: $go-dark-gray;
    border-left: 2px solid $color-white;
    border-top: 2px solid $color-white;
    border-right: 2px solid $color-white;
  }

  :global(.now-fresh) & {
    border-radius: 0;
  }
}

// When there is something in the input we don't show the label/placeholder
.textarea:not(:focus):not(:placeholder-shown) + .label {
  top: 0;
  padding-top: 8px;
  width: 100%;
  border-radius: 8px 8px 0 0;

  :global(.go-solutions) & {
    background-color: $go-dark-gray;
  }

  :global(.now-fresh) & {
    border-radius: 0;
  }

  .error & {
    :global(.go-solutions) & {
      background-color: inherit;
    }

    :global(.now-fresh) & {
      background-color: inherit;
    }
  }
}

.error-msg {
  @include errormsg;
}
