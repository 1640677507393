
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding: 24px 0 0;

  @include at-breakpoint(medium) {
    padding-bottom: 30px;
  }

  @include at-breakpoint(large) {
    padding-bottom: 95px;
    padding-top: 40px;
  }

  @include at-breakpoint(large) {
    padding-bottom: 71px;
  }

  :global(.now-fresh) & {
    background-color: $color-white;
  }
}

.disclaimer {
  line-height: 1.5;
  margin-bottom: 32px;
  padding: 0 15px;

  @include at-breakpoint(medium) {
    padding: 0 33px;
  }

  @include at-breakpoint(large) {
    margin: 0 auto 40px;
    max-width: $max-width;
    padding-inline-start: 0;
    padding-inline-end: 0;
    width: calc(100% - 64px);
  }
}

.list + .list {
  margin-top: 40px;

  @include at-breakpoint(medium) {
    margin-top: 48px;
  }
}

.territory {
  margin-bottom: 8px;
  padding: 0 15px;

  @include at-breakpoint(medium) {
    margin-bottom: 0;
    padding: 0 33px;
  }

  @include at-breakpoint(large) {
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: $max-width;
    padding-inline-start: 0;
    padding-inline-end: 0;
    width: calc(100% - 64px);
  }
}

.retailers {
  @include at-breakpoint(medium) {
    @include grid-container();
  }

  @include at-breakpoint(large) {
    grid-column-gap: 26px;
    margin: auto;
    max-width: $max-width;
  }

  @include at-breakpoint(x-large) {
    grid-column-gap: 42px;
  }
}

.retailer {
  border-bottom: 1px solid;
  padding: 24px 15px;

  @include at-breakpoint(medium) {
    grid-column: span 6;
    padding: 40px 0 24px;
  }

  @include at-breakpoint(x-large) {
    grid-column: span 4;
  }
}

.icon {
  margin-inline-start: 5px;
}

:global(.gather) {
  .container {
    padding: 64px 15px;

    @include at-breakpoint(medium) {
      padding: 80px 0;
    }

    @include at-breakpoint(x-large) {
      padding: 104px 0;
    }

    background-color: $gather-cream;
  }

  .disclaimer {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    padding: 0;

    @include at-breakpoint(medium) {
      padding: 0 32px;
    }

    @include at-breakpoint(large) {
      padding: 0 16px;
    }

    @include at-breakpoint(x-large) {
      padding: 0;
    }
  }

  .territory {
    @include p1();
    @include bold();
    text-transform: none;
    color: $gather-green;
    padding: 0;

    @include at-breakpoint(medium) {
      margin-bottom: 0;
      padding: 0 33px;
    }

    @include at-breakpoint(large) {
      margin-inline-start: auto;
      margin-inline-end: auto;
      max-width: $max-width;
      padding: 0 12px;
    }

    @include at-breakpoint(x-large) {
      padding-inline-start: 0;
    }
  }

  .retailers {
    @include at-breakpoint(medium) {
    }

    @include at-breakpoint(medium) {
      @include grid-container();
      grid-column-gap: 26px;
    }

    @include at-breakpoint(x-large) {
      grid-column-gap: 42px;
    }
  }

  .retailer {
    padding: 24px 0;

    @include at-breakpoint(medium) {
      grid-column: span 3;
      padding: 40px 0 24px;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 4;
    }

    border-color: $gather-sapling-green;
    a {
      color: $gather-black;

      :hover {
        color: $gather-lighter-green;
      }
    }
  }
}

:global(.go-solutions) {
  .retailer {
    border-color: $go-gray;
  }

  .territory {
    color: $go-semidark-grey;
  }

  .link {
    color: $color-black;

    &:hover {
      color: $color-black;
    }
  }

  .icon {
    color: $color-black;
  }
}

:global(.now-fresh) {
  .disclaimer {
    font-size: 14px;
  }
  .retailer {
    border-color: $now-brown;
    border-width: 1px;

    & > a {
      :global(.locale-ru) & {
        font-weight: 700;
      }
      font-size: 16px;
    }
  }

  .territory {
    color: $now-brown;
    font-size: 16px;
  }

  .icon {
    color: $now-brown;

    &:hover {
      color: $now-brown;
    }
  }
}

:global(.summit) {
  .container {
    background-color: $summit-white;
    padding: 64px 15px;

    @include at-breakpoint(medium) {
      padding: 64px 0;
    }
  }

  .disclaimer {
    @include p3();
    font-weight: 700;
    padding: 0;

    @include at-breakpoint(medium) {
      padding: 0 32px;
    }

    @include at-breakpoint(large) {
      padding: 0 16px;
    }

    @include at-breakpoint(x-large) {
      padding: 0;
    }
  }

  .territory {
    @include h5();
    color: $summit-blue;

    padding: 0;

    @include at-breakpoint(medium) {
      margin-bottom: 0;
      padding: 0 33px;
    }

    @include at-breakpoint(large) {
      margin-inline-start: auto;
      margin-inline-end: auto;
      max-width: $max-width;
      padding: 0 12px;
    }

    @include at-breakpoint(x-large) {
      padding-inline-start: 0;
    }
  }

  .retailer {
    border-color: $summit-desaturated-blue;
    padding: 24px 0;
    position: relative;

    @include at-breakpoint(medium) {
      grid-column: span 3;
      padding: 40px 0 24px;
    }

    @include at-breakpoint(x-large) {
      grid-column: span 4;
    }

    a {
      color: $summit-black;

      :hover {
        color: $summit-blue;
      }
    }

    svg.icon {
      position: absolute;
      bottom: 19px;
    }
  }
}
