
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  position: relative;
  text-align: start;
}

.error {
  color: $color-dark-red;

  :global(.now-fresh) & {
    color: $now-red;
  }

  .dropzone {
    :global(.gather) & {
      background-color: rgba($color-dark-red, 0.2);
    }

    :global(.go-solutions) & {
      background-color: $go-pink;
    }

    :global(.now-fresh) & {
      background-color: $now-transparent-red;
      color: $now-red;
      border-color: $now-red;
      border-width: 3px;
    }

    :global(.petcurean) & {
      background-color: rgba($color-dark-red, 0.2);
    }

    :global(.summit) & {
      background-color: rgba($color-dark-red, 0.2);
    }

    &:focus {
      border-color: $now-grey;

      :global(.now-fresh) & {
        border-color: $now-brown;
        border-width: 2px;
      }
    }
  }

  .error-msg {
    display: block;

    :global(.summit) & {
      @include errormsg();
    }
  }
}

.label {
  display: block;
  margin-bottom: 12px;

  :global(.now-fresh) & {
    font-size: 16px !important;
    font-family: Stag, sans-serif !important;
  }
}

.helper-text {
  @include p3();
  display: block;

  :global(.now-fresh) & {
    font-size: 14px;
  }
}

.dropzone {
  display: flex;
  justify-content: center;
  padding: 30px;
  border: 2px dashed;
  border-radius: 8px;
  background-color: $go-light-grey-opaque;
  outline: none;
  transition: border 0.2s ease-in-out;
  cursor: pointer;

  :global(.go-solutions) & {
    background-color: rgba($go-light-grey, 0.25);
    border-color: $go-dark-grey;
  }

  :global(.now-fresh) & {
    background-color: transparent;
    border-color: $now-brown;
  }

  &:focus {
    :global(.gather) & {
      border-color: $gather-green;
    }

    :global(.go-solutions) & {
      border-color: $color-white;
    }

    :global(.now-fresh) & {
      border-color: $now-brown;
      border-width: 2px;
    }

    :global(.petcurean) & {
      border-color: $petcurean-dark-tan;
    }

    :global(.summit) & {
      border-color: $summit-dark-desaturated-blue;
    }
  }

  p {
    @include p2();
  }
}

.file-list {
  margin-top: 12px;

  li {
    margin-top: 5px;
  }
}

.file-list-title {
  @include eyebrow();
}

.file-rejected {
  color: $color-dark-red;
}

.error-msg {
  @include errormsg;
}
