
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  padding: 48px 40px;
  grid-column: span 6;
  display: flex;
  flex-direction: column;

  @include at-breakpoint(large) {
    grid-column: 8/13;
  }

  @include at-breakpoint(x-large) {
    grid-column: 9/13;
  }

  :global(.gather) & {
    background-color: $gather-lighter-sapling-green;
    border-radius: 20px;
  }

  :global(.go-solutions) & {
    background-color: $go-table;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;
    color: $now-brown;

    @include at-breakpoint(large) {
      padding-top: 0;
    }
  }

  :global(.summit) & {
    background-color: $summit-desaturated-blue;
  }
}

.headline {
  margin-bottom: 24px;

  :global(.gather) & {
    font-weight: 700;
  }

  :global(.now-fresh) & {
    font-size: 18px;
  }
}

.table {
  width: 100%;
  margin-bottom: 16px;
}

.headers {
  width: 100%;
}

.column-header {
  @include p3();
  @include bold();
  padding-bottom: 23px;

  &:last-child {
    text-align: end;
  }
}

.row {
  &:first-of-type {
    .data {
      padding-top: 0;
    }
  }

  :global(.gather) & {
    border-bottom: 1px solid $gather-dark-green;
  }

  :global(.go-solutions) & {
    border-bottom: 1px solid $color-white;
  }

  :global(.now-fresh) & {
    border-bottom: 1px solid $now-brown;
  }

  :global(.summit) & {
    border-bottom: 1px solid $summit-black;
  }
}

.data {
  @include p2();
  padding: 16px 0;

  &:last-child {
    text-align: end;
  }

  :global(.gather) & {
    line-height: 1.25;
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }
}

.bottom-line {
  @include p3();
  margin-top: auto;

  :global(.gather) & {
    font-size: 14px;
    line-height: 1.5;
  }

  :global(.now-fresh) & {
    font-size: 16px;
  }

  :global(.summit) & {
    @include p2();
    line-height: 1.5;
  }
}
