
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.agree {
  display: flex;
  margin-bottom: 32px;
  padding: 0;
  align-items: center;
}

.error {
  .agree-checkbox {
    svg {
      fill: $color-dark-red;
    }
  }

  .checkbox {
    box-shadow: 0px 0px 0px 2px $now-grey inset;

    :global(.go-solutions) & {
      background-color: $go-pink;
    }

    :global(.now-fresh) & {
      background-color: $now-transparent-red;
      box-shadow: 0px 0px 0px 2px $now-red inset;
      border-color: $now-red;
    }
  }

  .label {
    // Brands added for specificity
    :global(.go-solutions) & {
      color: $color-white;

      div,
      a {
        color: $color-white;
      }
    }

    :global(.now-fresh) & {
      color: $color-dark-red;

      div,
      a {
        color: $now-red;
      }
    }
  }
}

.legend {
  @include visually-hidden;
}

.checkbox {
  appearance: none;
  border: none;
  border-radius: 4px;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
  margin-inline-end: 12px;

  &:focus {
    :global(.gather) & {
      box-shadow: 0px 0px 0px 2px $outline inset;
    }

    :global(.go-solutions) & {
      box-shadow: 0px 0px 0px 2px $color-white inset;
    }

    :global(.now-fresh) & {
      box-shadow: 0px 0px 0px 2px $now-brown inset;
    }

    :global(.petcurean) & {
      box-shadow: 0px 0px 0px 2px $outline inset;
    }

    :global(.summit) & {
      box-shadow: 0px 0px 0px 2px $outline inset;
    }
  }

  &:checked {
    :global(.gather) & {
      background-color: $gather-green;
    }

    :global(.go-solutions) & {
      background-color: $color-white;
    }

    :global(.now-fresh) & {
      background-color: $now-grey;
    }

    :global(.petcurean) & {
      background-color: $petcurean-orange;
    }

    :global(.summit) & {
      background-color: $summit-blue;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px $outline inset;

      :global(.go-solutions) & {
        box-shadow: 0px 0px 0px 2px $go-yellow inset;
      }

      :global(.now-fresh) & {
        box-shadow: 0px 0px 0px 2px $now-brown inset;
      }
    }

    & + svg,
    & + svg path {
      display: block;

      :global(.gather) & {
        fill: $gather-cream;
      }

      :global(.go-solutions) & {
        fill: $color-black;
      }

      :global(.now-fresh) & {
        fill: $now-brown;
      }

      :global(.petcurean) & {
        fill: $petcurean-white;
      }

      :global(.summit) & {
        fill: $summit-white;
      }
    }
  }

  :global(.gather) & {
    background-color: rgba($gather-black, 0.07);
  }

  :global(.go-solutions) & {
    background-color: rgba(255, 255, 255, 0.18);
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
  }

  :global(.petcurean) & {
    background-color: rgba($petcurean-grey, 0.05);
  }

  :global(.summit) & {
    background-color: rgba($summit-black, 0.07);
  }
}

.agree-checkbox {
  position: relative;

  svg {
    display: none;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;

    :global(.locale-he) & {
      left: auto;
      right: 3px;
    }

    :global(.go-solutions) & {
      fill: $color-white;
    }

    :global(.now-fresh) & {
      fill: $now-brown;
    }
  }
}

.agree-label {
  p {
    @include p3();
    letter-spacing: 1px;

    :global(.gather) & {
      @include p2();
      font-weight: 300;
      letter-spacing: normal;
    }

    :global(.go-solutions) & {
      color: $color-white;
    }

    :global(.now-fresh) & {
      font-size: 16px;
    }

    :global(.petcurean) & {
      @include p2();
      font-weight: 300;
      letter-spacing: normal;
    }

    :global(.summit) & {
      @include p2();
      font-weight: 400;
      letter-spacing: normal;
    }
  }

  a {
    text-decoration: underline;

    :global(.go-solutions) & {
      color: $color-white;

      @media (hover: hover) {
        &:hover {
          color: $go-yellow;
        }
      }
    }
  }

  svg {
    display: none;
  }
}
