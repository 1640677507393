
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.skip-btn {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  font-size: 16px;

  &:focus {
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
    padding: 16px;
    z-index: 9999999;

    span {
      box-shadow: 0 0 2px 2px $outline;
    }
  }

  :global(.go-solutions) & {
    background-color: $go-yellow;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 18px), calc(100% - 18px) 100%, 0 100%);
    text-transform: uppercase;
    color: $color-black;
    font-weight: 700;

    &:hover {
      background-color: $color-white;
      color: $color-black;
      text-decoration: none;
    }
  }

  :global(.now-fresh) & {
    background-color: $color-white;
    border: 1px solid $now-brown;
    color: $now-brown;
    border-radius: 28px;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: $now-grey;
      color: $now-brown;
      text-decoration: none;
    }
  }
}

.now-fresh-padding {
  padding: 14px;

  @include at-breakpoint(medium) {
    padding: 24px;
  }

  @include at-breakpoint(large) {
    padding: 44px;
  }
}
