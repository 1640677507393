
        @import "variables.scss";
        @import "mixins/mixins.scss";
        
.container {
  grid-column: span 7;
  @include at-breakpoint(large) {
    grid-column: span 3;
  }

  :global(.now-fresh) {
    color: $now-brown;
  }

  &:before {
    content: '';
    visibility: hidden;
    opacity: 0.5;
    position: fixed;
    background-color: $color-black;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: $over-nav-z-index;
    transition:
      visibility 0.25s,
      opacity 0.25s;

    @include at-breakpoint(large) {
      content: none;
    }
  }

  &.filters-open {
    &:before {
      visibility: visible;
      opacity: 0.5;
    }
  }
}

.open-filters-btn {
  @include p2();
  @include bold();
  display: none;
  align-items: center;
  color: $color-black;

  @include at-breakpoint(large) {
    display: flex;
  }

  svg {
    margin-inline-start: 6px;

    path {
      :global(.go-solutions) & {
        fill: $color-black;
      }

      :global(.now-fresh) & {
        fill: $now-brown;
      }
    }
  }

  :global(.now-fresh) & {
    font-weight: 500;
    color: $now-brown;
  }
}

.filters-container {
  position: fixed;
  height: 100%;
  width: 100vw;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    left 0.25s,
    opacity 0.25s,
    visibility 0.25s;

  :global(.locale-he) & {
    transition:
      right 0.25s,
      opacity 0.25s,
      visibility 0.25s;
  }

  &.visible {
    left: 0;
    opacity: 1;
    visibility: visible;
    z-index: $filters-z-index;

    :global(.locale-he) & {
      left: auto;
      right: 0;
    }
  }

  @include at-breakpoint(medium) {
    left: -50vw;
    width: 50vw;
    opacity: 1;
    visibility: hidden;

    :global(.locale-he) & {
      left: auto;
      right: -50vw;
    }

    &.visible {
      left: 0;
      visibility: visible;
      z-index: $filters-z-index;

      :global(.locale-he) & {
        left: auto;
        right: 0;
      }
    }
  }

  @include at-breakpoint(large) {
    width: auto;
    position: relative;
    top: 0;
    left: auto;
    margin-bottom: 1px;
    height: auto;
    max-height: calc(100vh - 120px);
    overflow: auto;
    z-index: 1;
    visibility: visible;

    :global(.locale-he) & {
      right: auto;
    }

    &.visible {
      z-index: 1;
    }
  }

  @include reducedMotion() {
    transition: none;
  }

  :global(.gather) & {
    background-color: $gather-cream;
  }

  :global(.go-solutions) & {
    background-color: $color-white;
  }

  :global(.now-fresh) & {
    background-color: $now-tan;

    @include at-breakpoint(medium) {
      border-right: 1px solid $now-brown;
    }

    @include at-breakpoint(large) {
      border-right: none;
      background-color: transparent;
    }
  }

  :global(.summit) & {
    background-color: $summit-white;
  }
}

.categories-container {
  max-height: calc(100% - 190px);
  overflow: auto;

  @include at-breakpoint(large) {
    max-height: none;
    overflow: visible;
  }
}

.interactive-headline-container {
  display: flex;
  padding: 37px 24px 52px;
  width: 100%;

  :global(.now-fresh) & {
    padding: 37px 24px 24px 16px;
  }

  & > span {
    display: flex;
    align-items: center;
  }

  svg {
    position: absolute;
    transform: scale(0.6);

    :global(.locale-he) & {
      transform: scale(0.6) scaleX(-1);
    }
  }

  @include at-breakpoint(large) {
    display: none;
  }
}

.caret {
  pointer-events: none;
}

.interactive-headline {
  @include eyebrow();
  width: 100%;
  flex: 1 1 calc(100% - 100px);
  color: $color-black;

  :global(.locale-ru) & {
    font-size: 10px;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-weight: bold;
  }
}

.clear-cta {
  display: none;
  min-width: 0;

  &.visible {
    display: inline-block;
    position: absolute;
    right: 18px;

    :global(.locale-he) & {
      left: 18px;
      right: auto;
    }

    @include at-breakpoint(large) {
      margin: 0;
    }

    :global(.gather) & {
      border-color: $gather-green;
    }

    // Added here to overwrite p3 font weights
    :global(.go-solutions) & {
      p {
        font-weight: 700;
      }
    }

    :global(.now-fresh) & {
      p {
        font-weight: 500;
      }
    }
  }

  :global(.go-solutions) & {
    p {
      @include p3();
      text-transform: capitalize;
      padding-bottom: 2px;
    }
  }

  :global(.now-fresh) & {
    padding-bottom: 2px;
    border: none;
    color: $now-brown;
    border-bottom: 1px solid $now-brown;

    p {
      @include p3();
      font-size: 16px;
    }
  }

  :global(.summit) & {
    border-color: $summit-blue;
  }

  :global(.locale-ru) & {
    margin-top: 0;
  }
}

.headline-container {
  display: none;

  @include at-breakpoint(large) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;

    button {
      margin: 10px 3px 0 0;

      :global(.locale-he) & {
        margin: 10px 0 0 3px;
      }
    }

    :global(.now-fresh) & {
      height: 121px;
      padding: 40px;
      border-bottom: 1px solid $now-brown;
    }
  }
}

.headline {
  display: none;
  @include p2();
  text-transform: capitalize;

  @include at-breakpoint(large) {
    display: block;
    font-weight: 700;
    margin: 9px 0 8px 0;
  }

  :global(.gather) & {
    text-transform: none;
    font-size: 16px;
    font-weight: 700;
  }

  :global(.now-fresh) & {
    color: $now-brown;
    font-weight: 400;
    font-size: 36px;
    font-family: 'Stag', sans-serif !important;
  }

  :global(.summit) & {
    @include p2();
    font-weight: 700;
  }
}

.category-container {
  overflow: hidden;
  transition: height 0.25s;
  padding: 24px 16px 24px;

  @include at-breakpoint(large) {
    padding: 24px 5px 24px 3px;

    :global(.gather) & {
      padding-inline-end: 0;
    }

    :global(.locale-he) & {
      padding: 24px 3px 24px 5px;
    }
  }

  @include reducedMotion() {
    transition: none;
  }

  :global(.gather) & {
    border-bottom: 1px solid $gather-dark-green;

    &:first-of-type {
      border-top: 1px solid $gather-dark-green;
    }
  }

  :global(.go-solutions) & {
    border-bottom: 1px solid $go-filter-border;

    &:first-of-type {
      border-top: 1px solid $go-filter-border;
    }
  }

  :global(.now-fresh) & {
    padding: 0;
    border-bottom: none;

    &:first-of-type {
      border-top: none;
    }
  }

  :global(.summit) & {
    border-bottom: 1px solid $summit-light-blue;

    &:first-of-type {
      border-top: 1px solid $summit-light-blue;
    }
  }
}

.category-title {
  @include eyebrow();
  width: 100%;
  color: $color-black;

  :global(.now-fresh) & {
    border-bottom: none;
    padding: 20px 40px;

    @include at-breakpoint(large) {
      padding: 40px;
      border-bottom: 1px solid $now-brown;
      font-size: 20px;
    }
  }

  span {
    display: flex;
    align-items: center;
    text-align: start;
    width: 100%;

    svg {
      transform: scale(0.5) rotate(-90deg);
      margin-inline-start: auto;
      pointer-events: none;
      transition: transform 0.25s;
      @include reducedMotion() {
        transition: none;
      }

      path {
        :global(.gather) & {
          fill: $gather-green;
        }

        :global(.go-solutions) & {
          fill: $color-black;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.summit) & {
          fill: $summit-blue;
        }
      }
    }
  }

  .open & {
    svg {
      transform: scale(0.5) rotate(-270deg);
      margin-inline-start: auto;
      pointer-events: none;

      path {
        :global(.gather) & {
          fill: $gather-green;
        }

        :global(.go-solutions) & {
          fill: $go-teal;
        }

        :global(.now-fresh) & {
          fill: $now-brown;
        }

        :global(.summit) & {
          fill: $summit-blue;
        }
      }
    }
  }

  :global(.gather) & {
    font-size: 16px;
    font-weight: 700;
  }

  :global(.now-fresh) & {
    font-weight: 400;
    color: $now-brown;
  }

  :global(.summit) & {
    @include p2();
    @include summit-font-i18n(false, false);
    letter-spacing: normal;
    font-weight: 700;
    text-transform: none;
  }
}

.filters {
  padding: 2px;
  visibility: hidden;
  transition: visibility 0.25s;
  margin-top: 32px;

  .open.filters-open & {
    visibility: visible;
  }

  .open & {
    @include at-breakpoint(large) {
      visibility: visible;
    }
    :global(.now-fresh) & {
      margin-top: 0;
      padding: 0 40px 40px 40px;
      @include at-breakpoint(large) {
        padding: 17px 40px;
        margin-top: 0;
        background-color: $color-white;
        border-bottom: 1px solid $now-brown;
      }
    }
  }

  @include reducedMotion() {
    transition: none;
  }
}

.filter {
  @include p2();
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  :global(.now-fresh) & {
    margin-bottom: 0;
  }
}

.filters-btn {
  @include eyebrow();
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: $color-black;

  :global(.locale-he) & {
    left: auto;
    right: 0;
  }

  span {
    padding: 32px 66px;
  }

  @include at-breakpoint(large) {
    display: none;
  }

  :global(.gather) & {
    background-color: $gather-green;
    color: $gather-cream;

    &:disabled {
      background-color: $gather-light-grey;
      color: $gather-black;
    }
  }

  :global(.go-solutions) & {
    background-color: $go-yellow;

    &:disabled {
      background-color: $go-light-grey;
      color: $color-black;
    }
  }

  :global(.now-fresh) & {
    font-weight: 500;
    color: $now-brown;
    background-color: $color-white;
    border-top: 1px solid $now-brown;

    &:disabled {
      background-color: $now-grey;
      color: $now-brown;
    }
  }

  :global(.summit) & {
    color: $summit-white;
    background-color: $summit-blue;
  }
}

.review-count {
  margin-inline-start: 8px;
  font-size: 14px;
  color: $go-dark-grey;

  :global(.gather) & {
    color: $go-light-grey;
  }

  :global(.now-fresh) & {
    color: $now-brown;
  }

  :global(.summit) & {
    color: rgba($summit-black, 0.35);
  }
}
